import { Reducer } from 'redux';
import { SharedActionTypes } from '../shared/types';
import { SearchActionTypes, SearchState } from './types';
import {
    SearchHistoryActionTypes,
} from '../../components/page-header/search-page-header/search-history-actions';

export function initialState(): SearchState {
    return {
        isToolbarOpen: false,
        toolbarContext: undefined,
        rootSearchRequestInProgress: false,
        baseSearchRequestInProgress: false,
        rootSearchRequestInError: false,
        baseSearchRequestInError: false,
        quickFilterRequestInProgress: false,
        listRequestInError: false,
        listRequestInProgress: false,
        listTooManyResults: false,

        alignmentRequestInProgress: false,
        alignmentRequestInError: false,
        alignmentTooManyResults: false,

        explorerRequestInProgress: false,
        explorerRequestInError: false,

        rootQuery: '',
        baseQueryId: '',
        dimensions: [],
        currentDimension: [],
        rootTaxonomy: undefined,
        currentTaxonomy: undefined,
        queryMatches: 0,
        queryMetadata: undefined,
        isSearchRunning: false,
    };
}

const searchReducer: Reducer<SearchState, any> = (state = initialState(), action): SearchState => {
    switch (action.type) {
        case  SearchActionTypes.SEARCH_IS_RUNNING: {
            return {
                ...state,
                isSearchRunning: action.payload[0],
                rootQuery: '',
            };
        }
        case SearchActionTypes.ROOT_SEARCH_REQUEST: {
            return {
                ...state,
                rootSearchRequestInProgress: true,
                rootSearchRequestInError: false,
                listTooManyResults: false,
                alignmentTooManyResults: false,
                rootQuery: '',
            };
        }
        case SearchActionTypes.ROOT_SEARCH_SUCCESS: {
            return {
                ...state,
                rootSearchRequestInError: false,
                rootSearchRequestInProgress: false,
                rootQuery: action.payload[0],
                queryMatches: action.payload[1],
                queryMetadata: action.payload[3],
                baseQueryId: action.payload[2],
                baseSearchParameters: { rootQuery: action.payload[0], filters: [] },
            };
        }
        case SearchActionTypes.ROOT_SEARCH_REQUEST_ERROR: {
            return {
                ...state,
                rootSearchRequestInError: true,
                rootSearchRequestInProgress: false,
            };
        }
        case SearchActionTypes.BASE_SEARCH_REQUEST: {
            return {
                ...state,
                baseSearchRequestInProgress: true,
                baseSearchRequestInError: false,
                listTooManyResults: false,
                alignmentTooManyResults: false,
            };
        }
        case SearchActionTypes.BASE_SEARCH_REQUEST_ERROR: {
            return {
                ...state,
                baseSearchRequestInError: true,
                baseSearchRequestInProgress: false,
            };
        }
        case SearchActionTypes.BASE_SEARCH_SUCCESS: {
            return {
                ...state,
                baseSearchRequestInError: false,
                baseSearchRequestInProgress: false,
                baseSearchParameters: action.payload[0],
                baseQueryId: action.payload[1],
                queryMatches: action.payload[2],
                queryMetadata: action.payload[3],
            };
        }
        case SearchActionTypes.QUICK_FILTER_SEARCH_REQUEST: {
            return {
                ...state,
                quickFilterRequestInProgress: true,
            };
        }
        case SearchActionTypes.QUICK_FILTER_SEARCH_SUCCESS: {
            const [quickFilterSearchParameters, quickFilterResultViewData] = action.payload;
            return {
                ...state,
                quickFilterRequestInProgress: false,
                quickFilterSearchParameters,
                quickFilterResultViewData,
            };
        }
        case SearchActionTypes.ALIGNMENT_SEARCH_REQUEST: {
            return { ...state, alignmentRequestInProgress: true, alignmentRequestInError: false, alignmentTooManyResults:false };
        }
        case SearchActionTypes.ALIGNMENT_SEARCH_ERROR: {
            return { ...state, alignmentRequestInProgress: false, alignmentRequestInError: true };
        }
        case SearchActionTypes.ALIGNMENT_SEARCH_CANCELED: {
            return { ...state, alignmentRequestInProgress: false, alignmentRequestInError: false };
        }
        case SearchActionTypes.ALIGNMENT_SEARCH_TOO_MANY_RESULTS: {
            return { ...state, alignmentTooManyResults: true, alignmentRequestInProgress: false, alignmentRequestInError: false };
        }
        case SearchActionTypes.ALIGNMENT_SEARCH_SUCCESS: {
            return {
                ...state,
                alignmentRequestInProgress: false,
                alignmentSearchParameters: action.payload[0],
                alignmentResultViewData: action.payload[1],
            };
        }
        case SearchActionTypes.LIST_SEARCH_REQUEST: {
            return { ...state, listRequestInProgress: true, listRequestInError: false, listTooManyResults:false };
        }
        case SearchActionTypes.LIST_SEARCH_SUCCESS: {
            return {
                ...state,
                listRequestInProgress: false,
                listRequestInError: false,
                listSearchParameters: action.payload[0],
                listResultViewData: action.payload[1],
                listAvailableColumns: action.payload[2],
            };
        }
        case SearchActionTypes.LIST_SEARCH_ERROR: {
            return { ...state, listRequestInError: true, listRequestInProgress: false,  listTooManyResults:false, listErrorMessage: action.payload };
        }
        case SearchActionTypes.LIST_SEARCH_TOO_MANY_RESULTS: {
            return { ...state, listTooManyResults: true,  listRequestInError: false, listRequestInProgress: false };
        }
        case SearchActionTypes.LIST_SEARCH_CANCELED: {
            return { ...state,  listRequestInError: false, listRequestInProgress: false, listTooManyResults:false };
        }

        case SearchActionTypes.EXPLORER_SEARCH_REQUEST: {
            return { ...state, explorerRequestInProgress: true };
        }
        case SearchActionTypes.EXPLORER_SEARCH_SUCCESS: {
            return {
                ...state,
                explorerRequestInProgress: false,
                explorerSearchParameters: action.payload[0],
                explorerResultViewData: action.payload[1],
            };
        }
        case SearchActionTypes.EXPLORER_SEARCH_ERROR: {
            return { ...state, explorerRequestInError: true, explorerRequestInProgress: false };
        }
        case SearchActionTypes.EXPLORER_SEARCH_CANCELED: {
            return { ...state,  explorerRequestInError: false, explorerRequestInProgress: false };
        }

        case SearchActionTypes.SET_DIMENSIONS: {
            return {
                ...state,
                dimensions: action.payload.dimensions,
            };
        }
        case SearchActionTypes.SET_CURRENT_DIMENSIONS: {
            return {
                ...state,
                currentDimension: action.payload.currentDimensions,
            };
        }
        case SearchActionTypes.SET_ROOT_QUERY_TAXONOMY: {
            return {
                ...state,
                rootTaxonomy: action.payload.taxonomy,
            };
        }
        case SearchActionTypes.SET_CURRENT_QUERY_TAXONOMY: {
            return {
                ...state,
                currentTaxonomy: action.payload.taxonomy,
            };
        }
        case SearchHistoryActionTypes.SET_SEARCH_HISTORY: {
            return {
                ...state,
                history: action.payload,
            };
        }
        case SearchActionTypes.TOGGLE_TOOLBAR: {
            return { ...state, isToolbarOpen: action.payload };
        }
        case SearchActionTypes.OPEN_TOOLBAR: {
            return { ...state, isToolbarOpen: true, toolbarContext: action.payload };
        }
        case SearchActionTypes.CLOSE_TOOLBAR: {
            return { ...state, isToolbarOpen: false, toolbarContext: undefined };
        }
        case SearchActionTypes.CLEAR_QUERY: {
            return {
                ...state,
                rootQuery: '',
                baseQueryId: '',
                quickFilterResultViewData: undefined,
                quickFilterSearchParameters: undefined,
                listResultViewData: undefined,
                listSearchParameters: undefined,
                listRequestInError: false,
                listRequestInProgress: false,
                listTooManyResults: false,

                explorerResultViewData: undefined,
                explorerSearchParameters: undefined,
                explorerRequestInProgress: false,
                explorerRequestInError: false,

                alignmentResultViewData: undefined,
                alignmentSearchParameters: undefined,
                alignmentRequestInError: false,
                alignmentRequestInProgress: false,
                alignmentTooManyResults: false,
            };
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return initialState();
        default: {
            return state;
        }
    }
};

export { searchReducer };
export { initialState as initialSearchState };
