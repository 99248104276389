import { faCheckSquare, faExternalLink, faSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GetSequencesResultSequenceMap } from '../../../../../../store/search';
import { conditionalClassList } from '../../../../../../utils/class-helpers';
import { ColumnConfig } from './column-config';
import { resultListWidthPerUnit } from './results-list';
import { ColumnReferenceMap } from './results-list-header';
import styles from './results-list-row.module.scss';

class ResultsListRow extends React.Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            columnReferences: {},
        };
    }

    public componentDidMount(): void {
        const { columns } = this.props;

        if (columns) {
            this.setState({
                columnReferences: this.initializeColumnReferences(columns),
            });
        }
    }

    public componentDidUpdate(previousProps: Readonly<AllProps>, previousState: Readonly<AllState>): void {
        const { columns, data, onChangeReferences } = this.props;
        const { columnReferences } = this.state;

        if (columns && columns !== previousProps.columns) {
            this.setState({
                columnReferences: this.initializeColumnReferences(columns),
            });
        }

        if (columnReferences !== previousState.columnReferences) {
            onChangeReferences(data.id, columnReferences);
        }
    }

    public render(): JSX.Element {
        const { columns, data, isSelected } = this.props;
        const { columnReferences } = this.state;

        const selectorCellClasses = conditionalClassList(styles, {
            isSelected,
            cell: true,
            selector: true,
        });

        const cellClasses = conditionalClassList(styles, {
            isSelected,
            cell: true,
        });

        return (
            <div className={styles.row}>
                <div className={styles.rowSpacer} />
                <div className={styles.cells}>
                    <div className={selectorCellClasses} onClick={() => this.onSequenceSelectionToggle()}>
                        {isSelected ? (
                            <FontAwesomeIcon icon={faCheckSquare} size='lg' />
                        ) : (
                            <FontAwesomeIcon icon={faSquare} size='lg' />
                        )}
                    </div>
                    {columns.map((columnConfig) => {
                        const values = columnConfig.labelKey ? data[columnConfig.labelKey] : data[columnConfig.key];
                        const title = values ? values.join('\n') : null;
                        const label = values ? values.join(', ') : null;
                        return (
                            <div
                                key={columnConfig.key}
                                onClick={(): void => this.onShowDetails(data)}
                                className={cellClasses}
                                style={{
                                    width: resultListWidthPerUnit * columnConfig.widthUnits,
                                    minWidth: resultListWidthPerUnit * columnConfig.widthUnits,
                                }}
                                ref={columnReferences[columnConfig.key]}>
                                <span className={styles.cellText} title={title}>
                                    {label}
                                </span>
                                {columnConfig.isLink ? (
                                    <a
                                        className={`${styles.cellLink}`}
                                        href={data[columnConfig.key]}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        onClick={this.stopEvent}>
                                        <IconButton>
                                            <FontAwesomeIcon icon={faExternalLink} style={{ fontSize: 12 }} />
                                        </IconButton>
                                    </a>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    private onSequenceSelectionToggle() {
        const { data } = this.props;
        const { onSequenceSelectionToggle } = this.props;

        onSequenceSelectionToggle(data.id);
    }

    private onShowDetails(sequence: GetSequencesResultSequenceMap): void {
        const { onShowSequenceDetails } = this.props;

        onShowSequenceDetails(sequence);
    }

    stopEvent = (event: MouseEvent): void => {
        event.stopPropagation();
    };

    private initializeColumnReferences(columns: ColumnConfig[]): ColumnReferenceMap {
        return columns.reduce((references, column) => {
            return { ...references, [column.key]: React.createRef<HTMLDivElement>() };
        }, {});
    }
}

export default withTranslation()(ResultsListRow);

interface OwnProps {
    columns: ColumnConfig[];
    data: GetSequencesResultSequenceMap;
    isSelected: boolean;
    onShowSequenceDetails: (sequence: GetSequencesResultSequenceMap) => void;
    onChangeReferences: (rowKey: string, references: ColumnReferenceMap) => void;
    onSequenceSelectionToggle: (sequenceId: string) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    columnReferences: ColumnReferenceMap;
}

type AllState = OwnState;

export interface RowColumnReferenceMap {
    [rowKey: string]: ColumnReferenceMap;
}
