import { Http, WorkspaceResponse } from '../http/http';
import {
    CreateSetupIntentRequestParameters,
    Customer,
    DeletePaymentMethodRequestParameters,
    PaymentMethod,
    SetDefaultPaymentMethodRequestParameters,
    SubscriptionOverview,
    UpdateCustomerRequestParameters,
} from '../store/subscription';
import { WorkspaceInfo } from '../store/workspaces';
import { ApiType, ApiUrl } from './api-urls';

export class SubscriptionApi {
    public static async createSetupIntent(
        parameters: CreateSetupIntentRequestParameters,
    ): Promise<WorkspaceResponse<WorkspaceInfo>> {
        return Http.post<CreateSetupIntentRequestParameters, WorkspaceResponse<WorkspaceInfo>>(
            ApiType.Gateway,
            ApiUrl.CreateSetupIntent,
            parameters,
            {
                organizationId: parameters.organizationId,
            },
        );
    }

    public static async getPaymentMethods(organizationId: string): Promise<WorkspaceResponse<PaymentMethod[]>> {
        return Http.get<WorkspaceResponse<PaymentMethod[]>>(ApiType.Gateway, ApiUrl.GetPaymentMethods, {
            organizationId,
        });
    }

    public static async setDefaultPaymentMethod(
        parameters: SetDefaultPaymentMethodRequestParameters,
    ): Promise<WorkspaceResponse<PaymentMethod[]>> {
        return Http.put<SetDefaultPaymentMethodRequestParameters, WorkspaceResponse<PaymentMethod[]>>(
            ApiType.Gateway,
            ApiUrl.SetDefaultPaymentMethod,
            parameters,
            {
                organizationId: parameters.organizationId,
            },
        );
    }

    public static async deletePaymentMethod(
        parameters: DeletePaymentMethodRequestParameters,
    ): Promise<WorkspaceResponse<{}>> {
        return Http.delete<WorkspaceResponse<{}>>(ApiType.Gateway, ApiUrl.DeletePaymentMethod, {
            organizationId: parameters.organizationId,
            paymentMethodId: parameters.paymentMethodId,
        });
    }

    public static async getCustomer(organizationId: string): Promise<WorkspaceResponse<Customer>> {
        return Http.get<WorkspaceResponse<Customer>>(ApiType.Gateway, ApiUrl.GetCustomer, {
            organizationId,
        });
    }

    public static async updateCustomer(
        parameters: UpdateCustomerRequestParameters,
    ): Promise<WorkspaceResponse<Customer>> {
        return Http.put<UpdateCustomerRequestParameters, WorkspaceResponse<Customer>>(
            ApiType.Gateway,
            ApiUrl.UpdateCustomer,
            parameters,
            {
                organizationId: parameters.organizationId,
            },
        );
    }

    public static async getSubscription(organizationId: string): Promise<WorkspaceResponse<SubscriptionOverview>> {
        return Http.get<WorkspaceResponse<SubscriptionOverview>>(ApiType.Gateway, ApiUrl.GetSubscription, {
            organizationId,
        });
    }

    public static async cancelSubscription(organizationId: string): Promise<WorkspaceResponse<SubscriptionOverview>> {
        return Http.post<{ organizationId: string }, WorkspaceResponse<SubscriptionOverview>>(
            ApiType.Gateway,
            ApiUrl.CancelSubscription,
            {
                organizationId,
            },
            {
                organizationId,
            },
        );
    }

    public static async renewSubscription(organizationId: string): Promise<WorkspaceResponse<SubscriptionOverview>> {
        return Http.post<{ organizationId: string }, WorkspaceResponse<SubscriptionOverview>>(
            ApiType.Gateway,
            ApiUrl.RenewSubscription,
            {
                organizationId,
            },
            {
                organizationId,
            },
        );
    }
}
