import {
    Dimension,
    DimensionFilter, DimensionWithAllElements, DimensionWithPlaceholder,
    SearchAggregatesResultSummary,
} from '../../../../../store/search';
import { DimensionFilterHelper } from '../../../../../store/search/helpers/dimension-filter-helper';
import { ExcludedElementsCollection } from '../types/excludes-collection';
import { QuickFilterElement, QuickFilterGroup, QuickFilterItem } from '../types/quick-filter-model';
import { defaultSelectedDimensionNames } from '../../../constants/dimension-constants';

export const otherLabelKey = 'Others';

const otherThreshold = 5;

export function createOthersElement(
    allElements: QuickFilterItem[],
    allExcludedElementNames: string[],
): QuickFilterItem[] {
    if (allElements.length <= otherThreshold) {
        return allElements;
    } else {
        const result = allElements.slice(0, otherThreshold - 1);
        const otherElements = allElements.slice(otherThreshold - 1, allElements.length);

        let filteredCount = 0;
        let originalCount = 0;
        let isExcluded = true;
        const grouping: string[] = [];
        otherElements.forEach((e) => {
            originalCount += e.originalCount;
            filteredCount += e.filteredCount;
            grouping.push(e.elementName);
            if (isExcluded) {
                isExcluded = allExcludedElementNames.includes(e.elementName);
            }
        });

        result.push({
            filteredCount,
            originalCount,
            grouping,
            elementName: otherLabelKey,
            excluded: isExcluded,
        });
        return result;
    }
}

export function createQuickFilterItems(
    allElements: string[],
    filteredSummaries: SearchAggregatesResultSummary[],
    activelyFilteredElements: string[],
    allSummaries: SearchAggregatesResultSummary[],
    allExcludedElementNames: string[],
    dimensionField: string,
    totalMatches?: number,
): QuickFilterItem[] {
    let result: QuickFilterItem[] = [];
    allElements
        .filter((e) => !activelyFilteredElements.includes(e))
        .forEach((elementName) => {
            if (!elementName.startsWith('EXCLUDE')) {
                let filteredCount = 0;
                let originalCount = 0;

                allSummaries.forEach((summary) => {
                    if (summary[dimensionField].includes(elementName)) {
                        originalCount += summary.count;
                    }
                });

                // max sure the original count never exceeds the overall matches
                if (totalMatches) {
                    originalCount = originalCount > totalMatches ? totalMatches : originalCount;
                }

                filteredCount = originalCount;

                result.push({
                    filteredCount,
                    originalCount,
                    elementName,
                    excluded: allExcludedElementNames.includes(elementName),
                });
            }
        });
    // Sort elements by their originalCount
    result.sort((a, b) => b.originalCount - a.originalCount);
    // Group elements in 'other buckets when there are more than 5.
    result = createOthersElement(result, allExcludedElementNames);

    return result;
}

export function isOtherElement(elementName: string): boolean {
    return elementName === otherLabelKey;
}

export function filterAllSummariesOnExcludedElementsAndActiveFilters(
    quickFilterDimensions: string[],
    allSummaries: SearchAggregatesResultSummary[],
    excludedCollection: ExcludedElementsCollection,
    filters: DimensionFilter[],
): SearchAggregatesResultSummary[] {
    if (allSummaries) {
        return allSummaries.filter((s) => {
            let alreadyExcluded = false;
            // loop over all dimensions and check whether the summary needs to be excluded
            quickFilterDimensions.forEach((dimensionName) => {
                if (!alreadyExcluded) {
                    let exclude = true;
                    const elementNames = s[dimensionName];
                    if (elementNames) {
                        elementNames.forEach((elementName) => {
                            const filter = filters.find((f) => f.dimensionName === dimensionName);
                            if (!filter || DimensionFilterHelper.isElementIncluded(filter, elementName)) {
                                if (exclude) {
                                    exclude = excludedCollection.containsElement({
                                        elementName,
                                        dimensionName,
                                    });
                                }
                            }
                        });
                    }
                    alreadyExcluded = exclude;
                }
            });
            return !alreadyExcluded;
        });
    }
    return [];
}

export const getAllUids = (viewData: QuickFilterGroup[]) => {
    const uids: string[] = [];
    viewData.forEach((section) => {
        section.elements.forEach(({ uid }) => {
            uids.push(uid);
        });
    });
    return uids;
};

export const OTHER_INDEX = 4;

const createFullDimensionGroup = (
    dimension: DimensionWithAllElements,
    filters: DimensionFilter[],
    total: number,
): QuickFilterGroup => {
    const relatedFilter = filters.find((f) => f.dimensionName === dimension.name);
    const relatedFilterIncludes = relatedFilter?.includes;

    const fullValuesList: any[] = (dimension.elements || [])
        .filter((e) => !relatedFilterIncludes || relatedFilterIncludes.indexOf(e.name) > -1)
        .map(({ name, count }) => ({
            uid: `${dimension.name}::${name}`,
            label: name,
            count,
            ratio: count / total,
        }))
        .sort((a, b) => (a.count > b.count ? -1 : 1));

    const elements: QuickFilterElement[] = [];
    const stopIndex = Math.min(OTHER_INDEX, fullValuesList.length);
    for (let i = 0; i < stopIndex; i++) {
        elements.push(fullValuesList[i]);
    }

    if (fullValuesList.length > OTHER_INDEX) {
        let otherTotal = 0;
        const items: QuickFilterElement[] = [];
        for (let i = stopIndex; i < fullValuesList.length; i++) {
            const value = fullValuesList[i];
            otherTotal += value.count;
            items.push(value);
        }
        elements.push({
            uid: `${dimension.name}::Other`,
            label: 'Other',
            count: otherTotal,
            relatedElements: items,
        });
    }

    return {
        uid: dimension.name,
        title: dimension.displayName,
        elements,
    };
};

export const prepareQuickViewData = (
    dimensions: Dimension[],
    filters: DimensionFilter[],
    total: number,
): QuickFilterGroup[] => {
    const viewData: QuickFilterGroup[] = dimensions
        .filter((dimension) => defaultSelectedDimensionNames.indexOf(dimension.name) > -1)
        .map((dimension) => {
            if ((dimension as DimensionWithPlaceholder).placehold) {
                return {
                    uid: dimension.name,
                    title: dimension.displayName,
                    elements: [],
                    summary: (dimension as DimensionWithPlaceholder).placehold.count,
                };
            }
            return createFullDimensionGroup(dimension as DimensionWithAllElements, filters, total);
        });

    return viewData.sort((d1, d2) => {
        return defaultSelectedDimensionNames.indexOf(d1.uid) > defaultSelectedDimensionNames.indexOf(d2.uid) ? 1 : -1;
    });
};
