import React, { ReactElement, useMemo } from 'react';
import { SharedMolType, AlignAlignResponse } from '../../../../api/generated';
import { AlignmentViewRenderData, Highlight } from './alignment-view-types';
import { prepareAlignmentViewRenderData } from './utils/prepare-alignment-view-render-data';
import AlignmentViewer from './alignment-viewer';

interface AllProps {
    alignmentResultViewData?: AlignAlignResponse;
    targetMolType: SharedMolType.AA | SharedMolType.DNA;
    onFilterApply: (highlight: Highlight) => void;
    highlighted?: Highlight;
}

function AlignmentPageViewer(props: AllProps): ReactElement {
    const { alignmentResultViewData = null, targetMolType, onFilterApply, highlighted } = props;

    const renderData: AlignmentViewRenderData | null = useMemo(
        () => prepareAlignmentViewRenderData(alignmentResultViewData, targetMolType),
        [alignmentResultViewData, targetMolType],
    );
    return (<AlignmentViewer renderData={renderData} showMenu onFilterApply={onFilterApply} highlighted={highlighted} allowSearch />)
}


export default AlignmentPageViewer;

