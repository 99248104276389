import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../../../../../assets/icons/filter-icon.svg';
import {
    Dimension,
    DimensionFilter,
    DimensionWithAllElements,
    DimensionWithPlaceholder,
    TAXONOMY_TREE_DIMENSION,
} from '../../../../../store/search';
import { DimensionFilterHelper } from '../../../../../store/search/helpers/dimension-filter-helper';
import { conditionalClassList } from '../../../../../utils/class-helpers';
import { generateUUID } from '../../../../../utils/uuid-helpers';
import styles from './filter-component.module.scss';
import FilterDimensionWithElementsPanel from './filter-dimension-elements-panel';
import FilterDimensionWithPlaceholderPanel from './filter-dimension-placeholder-panel';
import TaxonomyFilterDimension from './taxonomy-filter-dimension';
import { CollectionUtil } from '../../../../../utils/collection-util';

class FilterComponent extends React.Component<AllProps, AllState> {
    constructor(props) {
        super(props);

        const { dimensions, contextOptions } = this.props;

        if (contextOptions && dimensions) {
            const selectedDimension = dimensions.find((d) => d.name === contextOptions.dimensionName);
            this.state = { selectedDimension };
        } else {
            this.state = {};
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { isOpen, contextOptions } = this.props;

        if (!isOpen && prevProps.isOpen !== isOpen) {
            this.setState({ selectedDimension: undefined });
        }

        if (contextOptions && contextOptions !== prevProps.contextOptions) {
            this.setState({ selectedDimension: contextOptions.dimensionName });
        } else if (!isOpen && prevProps.isOpen !== isOpen) {
            this.setState({ selectedDimension: undefined });
        }
    }

    private renderDimensionFilter() {
        const { selectedDimension } = this.state;
        const { filters } = this.props;
        let filterOnSelectedDimension;

        if (selectedDimension) {
            const filtersIndex = selectedDimension
                ? filters.findIndex((f) => selectedDimension.name === f.dimensionName)
                : -1;
            filterOnSelectedDimension = filtersIndex !== -1 ? filters[filtersIndex] : undefined;
        }
        if (selectedDimension) {
            if (selectedDimension.name === 'taxonomyTree') {
                return <TaxonomyFilterDimension
                    dimension={selectedDimension as DimensionWithPlaceholder}
                    filterOnSelectedDimension={filterOnSelectedDimension}
                    updateFilterForPlaceholder={(dimensionName, elementNameFilter) =>
                        this.updateFilterForPlaceholder(dimensionName, elementNameFilter)
                    }
                />;
            }

            if ((selectedDimension as DimensionWithAllElements).elements) {
                return (
                    <FilterDimensionWithElementsPanel
                        dimension={selectedDimension as DimensionWithAllElements}
                        filterOnSelectedDimension={filterOnSelectedDimension}
                        selectExclusively={(dimensionName, elementName): void =>
                            this.selectExclusively(dimensionName, elementName)
                        }
                        toggleFilters={(include, dimensionName, elementNames): void =>
                            this.toggleFilters(include, dimensionName, elementNames)
                        }
                        toggleAllNone={(include, dimensionName): void => this.toggleAllNone(include, dimensionName)}
                    />
                );
            }
            if ((selectedDimension as DimensionWithPlaceholder).placehold) {
                return (
                    <FilterDimensionWithPlaceholderPanel
                        key={selectedDimension.name}
                        dimension={selectedDimension as DimensionWithPlaceholder}
                        removeFilter={() => this.removeFilter(selectedDimension.name)}
                        filterOnSelectedDimension={filterOnSelectedDimension}
                        updateFilterForPlaceholder={(dimensionName, elementNameFilter, excludeAll = false) =>
                            this.updateFilterForPlaceholder(dimensionName, elementNameFilter, excludeAll)
                        }
                    />
                );
            }
        }
        return null;
    }

    public render(): JSX.Element {
        const { dimensions, filters } = this.props;
        const { selectedDimension } = this.state;

        return (
            <div className={styles.filterContainer}>
                <div className={styles.dimensionSelector}>
                    {dimensions.map((dimension) => {
                        const selected = !!selectedDimension && dimension.name === selectedDimension.name;
                        const filtered = DimensionFilterHelper.hasFilterOnDimension(filters, dimension.name);

                        const dimensionItemClasses = conditionalClassList(styles, {
                            selected,
                            dimensionItem: true,
                        });

                        const indicatorClasses = conditionalClassList(styles, {
                            filtered,
                            indicator: true,
                        });

                        return (
                            <div
                                key={dimension.name || generateUUID()}
                                className={dimensionItemClasses}
                                onClick={(): void => this.selectDimension(dimension)}>
                                <div className={indicatorClasses}>{filtered ? <FilterIcon /> : null}</div>
                                <div className={styles.caption}>{dimension.displayName}</div>
                            </div>
                        );
                    })}
                </div>
                {this.renderDimensionFilter()}
            </div>
        );
    }

    private updateFilterForPlaceholder(dimensionName: string, elementNameFilter: string | string[], excludesAll = false): void {
        const { filters, onFiltersChanged, dimensions } = this.props;
        const dimension = dimensions.find((d) => d.name === dimensionName);

        if (dimensionName === TAXONOMY_TREE_DIMENSION) {
            const updatedFilters = DimensionFilterHelper.cloneFilters(filters);

            const filter = updatedFilters.find((f) => f.dimensionName === dimensionName);

            if (elementNameFilter && elementNameFilter.length > 0) {
                if (filter) {
                    filter.includes = Array.isArray(elementNameFilter) ? elementNameFilter : [elementNameFilter];
                    filter.excludesAll = excludesAll;
                } else {
                    updatedFilters.push({
                        includes: Array.isArray(elementNameFilter) ? elementNameFilter : [elementNameFilter],
                        dimensionName,
                        excludesAll,
                    });
                }
            } else {
                CollectionUtil.removeIfExists(updatedFilters, filter);
            }
            onFiltersChanged(updatedFilters);
            return;
        }

        if (dimension && (dimension as DimensionWithPlaceholder).placehold) {
            const updatedFilters = DimensionFilterHelper.cloneFilters(filters);
            DimensionFilterHelper.updatePlaceholderFilter(updatedFilters, dimensionName, elementNameFilter, excludesAll);
            onFiltersChanged(updatedFilters);
        }
    }

    private removeFilter(dimensionName: string): void {
        const { filters, onFiltersChanged } = this.props;

        const updatedFilters = DimensionFilterHelper.cloneFilters(filters);
        DimensionFilterHelper.removeFilter(updatedFilters, dimensionName);
        onFiltersChanged(updatedFilters);
    }

    private toggleFilters(include: boolean, dimensionName: string, elementNames: string[]): void {
        const { filters, onFiltersChanged, dimensions } = this.props;
        const dimension = dimensions.find((d) => d.name === dimensionName);

        if (dimension && (dimension as DimensionWithAllElements).elements) {
            const updatedFilter = DimensionFilterHelper.cloneFilters(filters);
            DimensionFilterHelper.updateMergeElementsFilter(
                updatedFilter,
                include,
                dimensionName,
                elementNames,
                (dimension as DimensionWithAllElements).elements.map((e) => e.name),
            );
            onFiltersChanged(updatedFilter);
        }
    }

    private selectExclusively(dimensionName: string, elementName: string): void {
        const { filters, onFiltersChanged, dimensions } = this.props;
        const dimension = dimensions.find((d) => d.name === dimensionName);
        const updatedFilter = DimensionFilterHelper.cloneFilters(filters);

        if (dimension && (dimension as DimensionWithAllElements)) {
            DimensionFilterHelper.updateReplaceElementsFilter(
                updatedFilter,
                true,
                dimensionName,
                [elementName],
                (dimension as DimensionWithAllElements).elements.map((e) => e.name),
            );
        }
        onFiltersChanged(updatedFilter);
    }

    private toggleAllNone(include: boolean, dimensionName: string): void {
        const { filters, onFiltersChanged, dimensions } = this.props;
        const dimension = dimensions.find((d) => d.name === dimensionName);

        if (dimension && (dimension as DimensionWithAllElements).elements) {
            const updatedFilter = DimensionFilterHelper.cloneFilters(filters);
            DimensionFilterHelper.updateMergeElementsFilter(
                updatedFilter,
                include,
                dimensionName,
                (dimension as DimensionWithAllElements).elements.map((e) => e.name),
                (dimension as DimensionWithAllElements).elements.map((e) => e.name),
            );
            onFiltersChanged(updatedFilter);
        }
    }

    private selectDimension(dimension: Dimension): void {
        this.setState({ selectedDimension: dimension });
    }
}

export default withTranslation()(FilterComponent);

interface OwnProps {
    dimensions: Dimension[];
    filters: DimensionFilter[];
    isOpen: boolean;
    contextOptions: any;
    onFiltersChanged: (filters: DimensionFilter[]) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    selectedDimension?: Dimension;
}

type AllState = OwnState;
