import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './confirmation-popup.module.scss';

class ConfirmationPopup extends Component<AllProps> {
    public render(): JSX.Element {
        const { t, header, messages, confirmLabel, cancelLabel } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.header}>{header}</div>
                    <div className={styles.messages}>
                        {messages.map((message) => {
                            return (
                                <div className={styles.message} key={message}>
                                    {message}
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <button
                            className={`${styles.button} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onConfirm()}>
                            {confirmLabel || t('Confirm')}
                        </button>
                        <button
                            className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onCancel()}>
                            {cancelLabel || t('Cancel')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onConfirm(): void {
        const { onConfirm } = this.props;

        onConfirm();
    }

    private onCancel(): void {
        const { onCancel } = this.props;

        onCancel();
    }
}

export default withTranslation()(ConfirmationPopup);

interface OwnProps {
    header: string;
    messages: string[];
    onConfirm: () => void;
    onCancel: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
}

type AllProps = OwnProps & WithTranslation;
