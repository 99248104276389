import { ContentCopy } from 'mdi-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import copy from 'copy-to-clipboard';

import React, { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../alignment-viewer.module.scss';

import { addToast, ToastType } from '../../../../../store/toast';
import { generateUUID } from '../../../../../utils/uuid-helpers';
import { LabelRowProps } from './alignment-view-component-types';
import { closePopup, PopupType, showPopup } from '../../../../../store/popup';
import { SequenceDetailsPopup } from '../../list-view/components/sequence-details-popup/sequence-details-popup';


const AlignmentViewLabelRow = (props: LabelRowProps): ReactElement => {
    const { row, onSelect, selected } = props;

    const dispatch = useDispatch();

    const clickHandler = useCallback(() => {
        if (!onSelect) return;

        if (selected) {
            onSelect(null);
        } else {
            onSelect(row.id as string);
        }
    }, [onSelect, row.id, selected]);

    const showSequenceDetails = (event) => {
        event.stopPropagation();
        dispatch(showPopup({
            type: PopupType.SEQUENCE_DETAILS,
            content: (
                <SequenceDetailsPopup
                    sequenceId={row.id as string}
                    onClose={(): void => dispatch(closePopup())}
                />
            ),
            isDismissible: false,
        }));
    };

    const copySeqHandler = (event): void => {
        event.stopPropagation();
        copy(row.sequence as string);
        dispatch(addToast({
            id: generateUUID(),
            type: ToastType.Info,
            messages: ['Sequence is copied'],
            removeAfterMilliseconds: 1000,
        }));
    };

    const title = row.name ? `${row.name}\n\nID: ${row.id}` : `ID: ${row.id}`;

    return (
        <div className={selected ? styles.labelRowSelected : styles.labelRow} onClick={clickHandler}
             title={title}>
            {row.label}
            {selected && (
                <div className={styles.labelButtons}>
                    <div style={{ marginRight: 4, display: 'flex', flexDirection: 'row' }} onClick={showSequenceDetails}
                         title='Show sequence info popup'>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 2 }} /> details
                    </div>
                    {!!row.name &&
                    <div onClick={copySeqHandler} title='Copy translated sequence'
                         style={{ marginRight: 8, marginLeft: 2, display: 'flex', flexDirection: 'row' }}>
                        <ContentCopy style={{ fontSize: 12, marginRight: 2 }} /> sequence
                    </div>}
                </div>
            )}
        </div>
    );
};

export default AlignmentViewLabelRow;
