import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { conditionalClassList } from '../../../../utils/class-helpers';
import { ToolbarAction, ToolbarActionType } from './page-toolbar';
import styles from './page-toolbar-button.module.scss';

class PageToolbarButton extends React.Component<AllProps> {
    private executeAction(action: ToolbarAction): void {
        const { deactivateAction, activateAction, activeToolbarAction } = this.props;

        if (action.onClick) {
            action.onClick();
        } else if (activeToolbarAction && activeToolbarAction.title === action.title) {
            deactivateAction();
        } else {
            activateAction(action);
        }
    }

    private renderIcon() {
        const { action } = this.props;
        if (action.faIcon) {
            return <FontAwesomeIcon icon={action.faIcon} className={styles.buttonIcon} />;
        }
        if (action.customIcon) {
            return action.customIcon;
        }
        return null;
    }

    public render(): JSX.Element {
        const { action, isToolbarOpen, activeToolbarAction, fixedWidth} = this.props;

        const isActive = isToolbarOpen && !!activeToolbarAction && activeToolbarAction.title === action.title;
        const toolbarButtonClasses = conditionalClassList(styles, {
            active: isActive,
            toolbarButton: true,
            fixedWidth,
            toolbarOpen: isToolbarOpen,
            toggle: action.type === ToolbarActionType.toggle,
            selected: action.isSelected || false,
        });

        return (
            <div className={toolbarButtonClasses} onClick={(): void => this.executeAction(action)} key={action.title}>
                {this.renderIcon()}
                {action.title}
            </div>
        );
    }
}

export default withTranslation()(PageToolbarButton);

interface OwnProps {
    action: ToolbarAction;
    activeToolbarAction?: ToolbarAction;
    isToolbarOpen: boolean;
    deactivateAction: () => void;
    activateAction: (action: ToolbarAction) => void;
    fixedWidth: boolean;
}

type AllProps = OwnProps & WithTranslation;
