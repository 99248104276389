import { Http, WorkspaceResponse } from '../http/http';
import { CreateWorkspaceRequestParameters, WorkspaceInfo } from '../store/workspaces';
import { ApiType, ApiUrl } from './api-urls';

export class WorkspacesApi {
    public static async getWorkspacesForOrganization(
        organizationId: string,
    ): Promise<WorkspaceResponse<WorkspaceInfo[]>> {
        return Http.get<WorkspaceResponse<WorkspaceInfo[]>>(ApiType.Gateway, ApiUrl.GetWorkspacesForOrganization, {
            organizationId,
        });
    }

    public static async createWorkspaceForOrganization(
        parameters: CreateWorkspaceRequestParameters,
    ): Promise<WorkspaceResponse<WorkspaceInfo>> {
        return Http.post<CreateWorkspaceRequestParameters, WorkspaceResponse<WorkspaceInfo>>(
            ApiType.Gateway,
            ApiUrl.CreateWorkspaceForOrganization,
            parameters,
            {
                organizationId: parameters.organizationId,
            },
        );
    }
}
