import { ActionType } from 'typesafe-actions';
import { Membership, OrganizationRole } from '../global';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum UsersActionTypes {
    GET_USERS_FOR_ORGANIZATION_REQUEST = '@@users/GET_USERS_FOR_ORGANIZATION_REQUEST',
    GET_USERS_FOR_ORGANIZATION_SUCCESS = '@@users/GET_USERS_FOR_ORGANIZATION_SUCCESS',
    GET_USERS_FOR_ORGANIZATION_FAILURE = '@@users/GET_USERS_FOR_ORGANIZATION_FAILURE',

    UPDATE_USER_REQUEST = '@@users/UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS = '@@users/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE = '@@users/UPDATE_USER_FAILURE',

    INVITE_USER_REQUEST = '@@users/INVITE_USER_REQUEST',
    INVITE_USER_SUCCESS = '@@users/INVITE_USER_SUCCESS',
    INVITE_USER_FAILURE = '@@users/INVITE_USER_FAILURE',

    DELETE_USER_REQUEST = '@@users/DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS = '@@users/DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE = '@@users/DELETE_USER_FAILURE',
}

export interface UsersState {
    readonly usersForOrganization?: UserInfo[];

    readonly inviteUserRequestStatus?: RequestStatus;
    readonly getUsersForOrganizationRequestStatus?: RequestStatus;
    readonly updateUserRequestStatus?: RequestStatus;
    readonly deleteUserRequestStatus?: RequestStatus;
}

export type UsersAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface UserInfo {
    email: string;
    organizationRole: OrganizationRole;
    status: UserStatus;
    createdOn: string;
    lastLoggedIn: string;
}

export enum UserStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}

export interface InviteUserRequestParameters {
    organizationId: string;
    email: string;
    organizationRole: OrganizationRole;
    workspaceId: string;
}

export interface InviteUserResponse {
    membership: Membership[];
    user: UserInfo;
}

export interface UpdateUserRequestParameters {
    email: string;
    organizationRole: OrganizationRole;
    organizationId: string;
    status: UserStatus;
}

export interface DeleteUserRequestParameters {
    organizationId: string;
    email: string;
}
