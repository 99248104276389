import { schemeTableau10 } from 'd3-scale-chromatic';
import camelCase from 'lodash.camelcase';
import { SearchAndRelateTaxonomyLevel, SearchAndRelateTaxonomyResponse } from '../../api/generated';
import {
    FlattenTx,
    TaxonomyCollapsedItems,
    TaxonomyLevelItem,
    TaxonomyRenderItem,
    TAXONOMY_ITEM_TYPE,
} from './taxonomy-tree-types';

export const getLevelColor = (depth) => schemeTableau10[depth] || schemeTableau10[0];

const createTaxonomyNodeId = (n: SearchAndRelateTaxonomyLevel) => {
    return `::${camelCase(n.type)}::${n.value}`;
};
const getTotal = (itemsList) => itemsList.reduce((t, tItem) => t + tItem.value, 0);

const flatLevels = (levels: SearchAndRelateTaxonomyLevel[], flattTx?: FlattenTx, depth = 0, parent?): FlattenTx => {
    let ft: FlattenTx = flattTx || { items: [], total: 0, levelDescriptions: [] };
    const sortedSubItems = levels.sort((i1, i2) => (i2.count && i1.count ? i2.count - i1.count : 0));

    sortedSubItems.forEach((level) => {
        const id = createTaxonomyNodeId(level);

        const item: TaxonomyLevelItem = {
            id: parent ? `${parent.id}${id}` : (id as string),
            parentId: parent ? parent.id : '',
            depth,
            color: getLevelColor(depth),
            value: level.count || 0,
            node: level,
            name: level.value || '',
            parent,
            type: TAXONOMY_ITEM_TYPE.SINGLE,
        };

        ft.items.push(item);
        if (level.subitems) {
            ft = flatLevels(level.subitems, ft, depth + 1, item);
        }

        if (!ft.levelDescriptions[depth]) {
            ft.levelDescriptions[depth] = { name: level.type, depth };
        }
    });

    return ft;
};

export const flattenTaxonomy = (taxonomyResponse: SearchAndRelateTaxonomyResponse): FlattenTx | undefined => {
    if (taxonomyResponse && taxonomyResponse.taxonomy && taxonomyResponse.taxonomy.subitems) {
        const flatTaxonomy = flatLevels(taxonomyResponse.taxonomy.subitems);

        const zeroLevelItems = flatTaxonomy.items.filter((item) => item.depth === 0);
        flatTaxonomy.total = getTotal(zeroLevelItems);
        flatTaxonomy.levelDescriptions.forEach((ld) => {
            const lItems = flatTaxonomy.items.filter((item) => item.depth === ld.depth);
            // eslint-disable-next-line no-param-reassign
            ld.variants = lItems.length;
        });

        return flatTaxonomy;
    }
    return undefined;
};

export const MINIMAL_ITEM_SIZE = 12;

export const isChildId = (id: string, parentId: string) => {
    return id.indexOf(`${parentId}::`) === 0;
};

const getChilden = (flTaxonomy: FlattenTx, parentId: string): TaxonomyLevelItem[] => {
    return flTaxonomy.items.filter((child) => child.parentId === parentId);
};

const getSmallItemId = ( items: TaxonomyLevelItem[] ) => {
    return `${items[0].id}`; // -${items.length}`;
}

const calculateCollapsedItemChilds = (
    collapsedItem: TaxonomyCollapsedItems,
    flTaxonomy: FlattenTx,
    zoom,
): TaxonomyRenderItem[] => {
    const { items } = collapsedItem;
    const childItems: TaxonomyLevelItem[] = [];

    const collapsedChildren: TaxonomyRenderItem[] = [];

    if (items) {
        items.forEach((item) => {
            const subitems = getChilden(flTaxonomy, item.id);

            // eslint-disable-next-line prefer-spread
            childItems.push.apply(childItems, subitems);
            // childItems.push(...subitems);
        });

        if (childItems.length === 0) return collapsedChildren;
        const total = getTotal(childItems);
        const nextLevelCollapsedItem: TaxonomyCollapsedItems = {
            ...collapsedItem,
            items: childItems,
            name: `${childItems.length} variations (${total})`,
            id: getSmallItemId(childItems),
            type: TAXONOMY_ITEM_TYPE.COLLAPSED,
            depth: collapsedItem.depth + 1,
        };
        collapsedChildren.push(nextLevelCollapsedItem);
        const nextLevel = calculateCollapsedItemChilds(nextLevelCollapsedItem, flTaxonomy, zoom);

        // eslint-disable-next-line prefer-spread
        collapsedChildren.push.apply(collapsedChildren, nextLevel)
//        collapsedChildren.push(...nextLevel);
        return collapsedChildren;
    }

    return collapsedChildren;
};

const calculateItemChildren = (
    parentId: string,
    depth: number,
    flTaxonomy: FlattenTx,
    viewportHeight,
    zoom,
): TaxonomyRenderItem[] => {
    const childLevelItems = getChilden(flTaxonomy, parentId);
    const total = getTotal(childLevelItems);
    const pixelsPerItem = (viewportHeight / total) * zoom;
    const itemThreshold = MINIMAL_ITEM_SIZE;

    const bigEnoughItems: TaxonomyRenderItem[] = childLevelItems.filter((item) => {
        return item.value * pixelsPerItem > itemThreshold;
    });
    const smallItems: TaxonomyLevelItem[] = childLevelItems.filter(
        (item) => item.value * pixelsPerItem <= itemThreshold,
    );

    if (smallItems.length > 0) {
        // make sure that there is enough space for collapsed + big items, if not, take one from the big items and mode it to the small
        while (bigEnoughItems.length > 0 && (bigEnoughItems.length + 1) * MINIMAL_ITEM_SIZE > viewportHeight * zoom) {
            smallItems.push(bigEnoughItems.pop() as TaxonomyLevelItem);
        }
    }

    // items are sorted by value (must be, side effect), the bigger items will be first, so "outliers" will be last
    let lastItem: TaxonomyRenderItem;

    const result: TaxonomyRenderItem[] = [];

    if (smallItems.length > 0) {
        const totalSmall = getTotal(smallItems);
        const template = smallItems[0];
        lastItem = {
            ...template,
            items: smallItems,
            name: `${smallItems.length} variations (${totalSmall})`,
            id: getSmallItemId(smallItems),
            value: totalSmall,
            type: TAXONOMY_ITEM_TYPE.COLLAPSED,
            color: getLevelColor(depth - 1),
        };

        const totalSmallHeight = totalSmall * pixelsPerItem * zoom;

        lastItem.height = totalSmallHeight > MINIMAL_ITEM_SIZE ? totalSmallHeight : MINIMAL_ITEM_SIZE;

        // in case if there are only small items they should take whole viewport
        if (bigEnoughItems.length === 0) {
            lastItem.height = viewportHeight * zoom;
        }

        const availableHeightForBigItems = viewportHeight * zoom - lastItem.height;
        const totalBig = getTotal(bigEnoughItems);

        const bigPxPerValue = availableHeightForBigItems / totalBig;

        bigEnoughItems.forEach((bigItem) => {
            // eslint-disable-next-line no-param-reassign
            bigItem.height = bigItem.value * bigPxPerValue;
            // eslint-disable-next-line no-param-reassign
            const bbb = calculateItemChildren(bigItem.id, bigItem.depth + 1, flTaxonomy, bigItem.height / zoom, zoom);
            result.push(bigItem);

            // eslint-disable-next-line prefer-spread
            result.push.apply(result, bbb)

        });
        result.push(lastItem);
        const collapsedChildren = calculateCollapsedItemChilds(lastItem as TaxonomyCollapsedItems, flTaxonomy, zoom);

        // eslint-disable-next-line prefer-spread
        result.push.apply(result, collapsedChildren)
        // result.push(...collapsedChildren);
    } else {
        childLevelItems.forEach((fli) => {
            // eslint-disable-next-line no-param-reassign
            fli.height = fli.value * pixelsPerItem;
            // eslint-disable-next-line no-param-reassign
            const aaa = calculateItemChildren(fli.id, fli.depth + 1, flTaxonomy, fli.height / zoom, zoom);
            result.push(fli);
            // eslint-disable-next-line prefer-spread
            result.push.apply(result, aaa)
        });
    }

    return result;
};

const updateYCoordinate = (items: TaxonomyRenderItem[]) => {
    const yCache: number[] = [];
    items.forEach((item) => {
        const y = yCache[item.depth] || 0;
        // eslint-disable-next-line no-param-reassign
        item.y = y;
        yCache[item.depth] = y + (item.height || 0);
    });
};

export const collapseTaxonomy = (flTaxonomy: FlattenTx, viewportHeight, zoom = 1): TaxonomyRenderItem[] => {

    console.time('collapseTaxonomy');
    const result = calculateItemChildren('', 0, flTaxonomy, viewportHeight, zoom);
    console.timeEnd('collapseTaxonomy');
    updateYCoordinate(result);

    return result;
};

export const isMatch2Parent = (id, parentId) => {
    if (id.indexOf(parentId) === 0) {
        if (parentId.length === id.length) return true;
        if (id.indexOf(`${parentId}::`) === 0) return true;
    }

    return false;
};
