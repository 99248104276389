/* eslint-disable react/no-array-index-key */
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { push } from 'connected-react-router';
import copy from 'copy-to-clipboard';
import { LocationDescriptorObject } from 'history';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { removeToast, Toast, ToastType } from '../../store/toast';
import { conditionalClassList } from '../../utils/class-helpers';
import styles from './toast-container.module.scss';

class ToastContainer extends Component<AllProps> {
    public componentDidUpdate(previousProps: Readonly<AllProps>): void {
        const { toasts, dispatchRemoveToast } = this.props;

        const newToasts = toasts.filter(
            (toast) => !previousProps.toasts.find((previousToast) => toast.id === previousToast.id),
        );

        newToasts.forEach((toast) => {
            if (toast.removeAfterMilliseconds) {
                setTimeout(() => {
                    dispatchRemoveToast(toast);
                }, toast.removeAfterMilliseconds);
            }
        });
    }

    getMail = (toast: Toast) => {
        const { currentLocation } = this.props;
        return encodeURI(`${toast.messages.join('\n')}\n\nPage: ${currentLocation.pathname}${currentLocation.search}`);
    };

    public render(): JSX.Element | null {
        const { toasts, dispatchRemoveToast } = this.props;

        const toastClasses: (toast: Toast) => string = (toast) =>
            conditionalClassList(styles, {
                toast: true,
                toastSuccess: toast.type === ToastType.Success,
                toastError: toast.type === ToastType.Error,
                toastWarning: toast.type === ToastType.Warning,
                toastInfo: toast.type === ToastType.Info,
                toastLink: toast.navigateTo !== undefined,
            });
        return toasts.length > 0 ? (
            <div className={styles.toasterContainer}>
                {toasts.map((toast, index) => {
                    return (
                        <div
                            className={toastClasses(toast)}
                            key={`${toast.id}-${index}`}
                            onClick={(): void => this.handleClick(toast)}>
                            <div className={styles.toastPanel}>
                                <div className={styles.headerButtons}>
                                    {toast.type === ToastType.Error ? (
                                        <>
                                            <a
                                                href={`mailto:support@biostrand.be?subject=Error%20report&body=${this.getMail(
                                                    toast,
                                                )}`}>
                                                <IconButton onClick={() => {}}>
                                                    <FontAwesomeIcon icon={faPaperPlane} size='xs' color='#ffffff' />
                                                </IconButton>
                                            </a>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    copy(toast.messages.join('\n'));
                                                }}>
                                                <FontAwesomeIcon icon={faClipboard} size='xs' color='#ffffff' />
                                            </IconButton>
                                        </>
                                    ) : null}
                                    <IconButton onClick={() => dispatchRemoveToast(toast)}>
                                        <FontAwesomeIcon color='#ffffff' size='xs' icon={faTimes} />
                                    </IconButton>
                                </div>

                                <div className={styles.iconPanel}>{this.getIcon(toast.type)}</div>
                                <div className={styles.textPanel} title={toast.messages.join('\n')}>
                                    {toast.messages.map((message, i2) => {
                                        return (
                                            <div className={styles.toastMessageText} key={`${message}-${i2}`}>
                                                {message}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : null;
    }

    private getIcon(type: ToastType): JSX.Element {
        switch (type) {
            case ToastType.Success:
                return <FontAwesomeIcon size='3x' icon={faCheckCircle} />;
            case ToastType.Error:
                return <FontAwesomeIcon size='3x' icon={faExclamationCircle} />;
            case ToastType.Warning:
            case ToastType.Info:
            default:
                return <FontAwesomeIcon size='3x' icon={faInfoCircle} />;
        }
    }

    private handleClick(toast: Toast): void {
        const { dispatchRemoveToast, dispatchNavigateTo } = this.props;

        if (toast.navigateTo) {
            dispatchRemoveToast(toast);
            dispatchNavigateTo({ pathname: toast.navigateTo });
        }
    }
}

const mapStateToProps = ({ toast, router }: ApplicationState) => ({
    toasts: toast.toasts,
    currentLocation: router.location,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchRemoveToast: (toast: Toast) => dispatch(removeToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ToastContainer));

interface PropsFromState {
    toasts: Toast[];
    currentLocation: Location;
}

interface PropsFromDispatch {
    dispatchRemoveToast: typeof removeToast;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
