import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { HelpLinkUrl } from '../../../components/help-link/help-link-urls';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import { Credentials, loginRequest } from '../../../store/authentication';
import { FailureCodes, RequestStatus } from '../../../store/shared/types';
import styles from './login.module.scss';
import { LeftPanel } from '../left-panel';

class LoginPage extends React.Component<AllProps, AllState> {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showFeedback: false,
            feedbackMessage: '',
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { loginRequestStatus } = this.props;

        if (loginRequestStatus !== prevProps.loginRequestStatus && loginRequestStatus?.isFailed) {
            this.setState({
                feedbackMessage: this.getMessageForFailureCode(loginRequestStatus),
                showFeedback: true,
            });
        }
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const { showFeedback, feedbackMessage } = this.state;
        const { loginRequestStatus, initialAuthorizationFlowHasFinished } = this.props;

        return (
            <>
                {initialAuthorizationFlowHasFinished ? (
                    <div>
                        <div className={styles.container}>
                            <div className={styles.loginPanel}>
                                <LeftPanel />
                                <div className={styles.rightPanel}>
                                    <div className={styles.rightTopPanel}>
                                        {/*<span className={styles.account}>{t("Don't have an account?")}</span>*/}
                                        {/*<span className={styles.link}>*/}
                                        {/*    <Link to={RouteUrl.CreateOrganizationPage}>{t('Sign up here')}</Link>*/}
                                        {/*</span>*/}
                                    </div>
                                    <div className={styles.rightMiddlePanel} onKeyDown={(e) => this.onKeyPressed(e)}>
                                        <div className={styles.header}>
                                            <div className={styles.titleOne}>Sign in to</div>
                                            <div className={styles.titleTwo}>BioStrand</div>
                                            <div className={styles.subtitle}>Please enter your credentials below</div>
                                        </div>
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Email')}</div>
                                            <input
                                                type='text'
                                                key='EmailField'
                                                autoFocus
                                                placeholder={t('james@organization.com')}
                                                onChange={(e) => this.onEmailChange(e)}
                                            />
                                        </div>
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Password')}</div>
                                            <input
                                                type='password'
                                                key='PasswordField'
                                                placeholder={t('Enter your password')}
                                                onChange={(e) => this.onPasswordChange(e)}
                                            />
                                        </div>
                                        <div className={styles.errorMessage}>{showFeedback ? feedbackMessage : ''}</div>
                                        <div className={styles.buttons}>
                                            <button
                                                type='button'
                                                className={styles.button}
                                                onClick={() => this.onLoginButtonClick()}>
                                                {loginRequestStatus?.isInProgress ? (
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                ) : (
                                                    t('Login')
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.rightBottomPanel}>
                                        <div className={styles.link}>
                                            <Link to={RouteUrl.ForgotPassword}>{t('Forgot your password?')}</Link>
                                        </div>
                                        <a
                                            className={styles.link}
                                            href={HelpLinkUrl.Login}
                                            rel='noopener noreferrer'
                                            target='_blank'>
                                            {t('Need help?')}
                                        </a>
                                        <div className={styles.knowMore}>
                                            {t('Like to know more about our service?')}
                                            <span className={styles.link}> {t('Contact us here')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    public getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            case FailureCodes.AUTH1:
                return t('Incorrect username/password combination, please try again.');
            case FailureCodes.AUTH2:
                return t('Account inactive, please check your email communication.');
            default:
                return t('Something went wrong please try again or contact your administrator.');
        }
    }

    private onKeyPressed(e): void {
        if (e.keyCode === 13) {
            this.onLoginButtonClick();
        } else {
            this.setState({ showFeedback: false });
        }
    }

    private onEmailChange(e): void {
        this.setState({ email: e.target.value });
    }

    private onPasswordChange(e): void {
        this.setState({ password: e.target.value });
    }

    private onLoginButtonClick(): void {
        const { email, password } = this.state;
        const { dispatchLogin, loginRequestStatus } = this.props;

        if (!loginRequestStatus?.isInProgress) {
            dispatchLogin({ email, password });
        }
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogin: (credentials: Credentials) => dispatch(loginRequest(credentials)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    authentication,
    global,
}: ApplicationState) => ({
    authorizationInProgress: global.authorizationInProgress,
    loginRequestStatus: authentication.loginRequestStatus,
    initialAuthorizationFlowHasFinished: global.initialAuthorizationFlowHasFinished,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginPage));

interface PropsFromState {
    authorizationInProgress: boolean;
    loginRequestStatus?: RequestStatus;
    initialAuthorizationFlowHasFinished: boolean;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchLogin: (credentials: Credentials) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    password: string;
    showFeedback: boolean;
    feedbackMessage: string;
}

type AllState = OwnState;
