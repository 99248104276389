import { Location, Search } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteUrl } from '../../../../routing/route-url';
import styles from './results-navigation-bar.module.scss';
import ResultsNavigationItem, { NavItemTypes } from './results-navigation-item';
import HelpLink from '../../../../components/help-link/help-link';
import { ALIGNMENT_RESULT_LIMIT, LIST_RESULT_LIMIT } from '../../../../store/search';

class ResultsNavigationBar extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { helpUrl, t, isNavigationDisabled, totalResults } = this.props;

        const alignInfo = totalResults > ALIGNMENT_RESULT_LIMIT ? `The Alignment View is not enabled as you have more than ${ALIGNMENT_RESULT_LIMIT} results/matches. Please use the Quick Filter and/or Explorer View to bring the number of matches down to below ${ALIGNMENT_RESULT_LIMIT} to enable Alignment View` : undefined;
        const listInfo = totalResults > LIST_RESULT_LIMIT ? `The List View is not enabled as you have more than ${LIST_RESULT_LIMIT} results/matches. Please use the Quick Filter and/or Explorer View to bring the number of matches down to below ${LIST_RESULT_LIMIT} to enable List View` : undefined;

        return (
            <div className={styles.navigationBarContainer}>
                <div className={styles.navigationItemsContainer}>
                    <ResultsNavigationItem
                        disabled={isNavigationDisabled}
                        type={NavItemTypes.quickFilter}
                        caption={t('NavigationItem_QuickFilter')}
                        onClick={() => this.navigateTo(RouteUrl.QuickFilter)}
                        active={this.isActive(RouteUrl.QuickFilter)}
                    />
                    <ResultsNavigationItem
                        disabled={isNavigationDisabled}
                        type={NavItemTypes.listView}
                        caption={t('NavigationItem_ListView')}
                        onClick={() => this.navigateTo(RouteUrl.List)}
                        active={this.isActive(RouteUrl.List)}
                        info={listInfo}
                    />
                    <ResultsNavigationItem
                        disabled={isNavigationDisabled}
                        type={NavItemTypes.explorerView}
                        caption={t('NavigationItem_ExplorerView')}
                        onClick={() => this.navigateTo(RouteUrl.Explorer)}
                        active={this.isActive(RouteUrl.Explorer)}
                    />
                    <ResultsNavigationItem
                        disabled={isNavigationDisabled}
                        type={NavItemTypes.alignmentView}
                        caption={t('NavigationItem_AlignmentView')}
                        onClick={() => this.navigateTo(RouteUrl.Alignment)}
                        active={this.isActive(RouteUrl.Alignment)}
                        info={alignInfo}
                    />
                </div>
                <HelpLink url={helpUrl} size='1x' />
            </div>
        );
    }

    public navigateTo(url: RouteUrl): void {
        const { location, navigateTo } = this.props;
        navigateTo(url, location.search);
    }

    public isActive(url: RouteUrl): boolean {
        const { location } = this.props;
        return location.pathname.includes(url);
    }
}

export default withTranslation()(ResultsNavigationBar);

interface OwnProps {
    location: Location;
    isNavigationDisabled: boolean;
    navigateTo: (pathname: RouteUrl, search: Search) => void;
    helpUrl: string;
    totalResults: number;
}

type AllProps = OwnProps & WithTranslation;
