import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as DnaIcon } from '../../../assets/icons/dna-icon.svg';
import styles from './loading-panel.module.scss';

class LoadingPanel extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { style, text } = this.props;

        return (
            <div className={styles.loadingPanel} style={style}>
                <DnaIcon className={styles.progressIcon} />
                <div className={styles.progressText}>{text}</div>
            </div>
        );
    }
}

export default withTranslation()(LoadingPanel);

interface OwnProps {
    style?: {};
    text: string;
}

type AllProps = OwnProps & WithTranslation;
