import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import * as actions from './search-actions';

export enum FilterActionTypes {
    UPDATE_FILTERS = '@@filters/UPDATE_FILTERS',
    REMOVE_FILTER_ITEM = '@@search/REMOVE_FILTER_ITEM',
}

export type FilterAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;
