import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { Dataset, DatasetsAction, DatasetsActionTypes, DatasetsState } from './types';

export function initialState(): DatasetsState {
    return {};
}

const reducer: Reducer<DatasetsState, DatasetsAction> = (state = initialState(), action): DatasetsState => {
    switch (action.type) {
        case DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_REQUEST: {
            return {
                ...state,
                getDatasetsForOrganizationRequestStatus: new RequestStatus(RequestStatusType.InProgress),
                datasetsForOrganization: undefined,
            };
        }
        case DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                getDatasetsForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Success),
                datasetsForOrganization: action.payload,
            };
        }
        case DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_FAILURE: {
            return {
                ...state,
                getDatasetsForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_REQUEST: {
            return {
                ...state,
                getDatasetsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
                datasetsForWorkspace: undefined,
            };
        }
        case DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_SUCCESS: {
            return {
                ...state,
                getDatasetsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                datasetsForWorkspace: action.payload,
            };
        }
        case DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_FAILURE: {
            return {
                ...state,
                getDatasetsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_REQUEST: {
            return {
                ...state,
                linkDatasetsToWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_SUCCESS: {
            const newDatasets = action.payload;
            const updatedArray: Dataset[] = state.datasetsForWorkspace
                ? state.datasetsForWorkspace.concat(newDatasets)
                : newDatasets;
            return {
                ...state,
                linkDatasetsToWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                datasetsForWorkspace: updatedArray,
            };
        }
        case DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_FAILURE: {
            return {
                ...state,
                linkDatasetsToWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_REQUEST: {
            return {
                ...state,
                unlinkDatasetsFromWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_SUCCESS: {
            const deletedDatasetIds = action.payload;
            const updatedArray: Dataset[] = state.datasetsForWorkspace
                ? state.datasetsForWorkspace.filter((d) => !deletedDatasetIds.includes(d.id))
                : [];
            return {
                ...state,
                unlinkDatasetsFromWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                datasetsForWorkspace: updatedArray,
            };
        }
        case DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_FAILURE: {
            return {
                ...state,
                unlinkDatasetsFromWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case DatasetsActionTypes.UPDATE_DATASET_PIPELINE_STATUS: {
            const [datasetPipeline] = action.payload;
            return {
                ...state,
                datasetPipelinesMap: {...state.datasetPipelinesMap, [datasetPipeline.datasetId as string]: datasetPipeline}
            }
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return initialState();
        default:
            return state;
    }
};

export { reducer as datasetsReducer };
export { initialState as initialDatasetsState };
