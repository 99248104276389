import { Http, WorkspaceResponse } from '../http/http';
import { AuthorizationContext } from '../store/global';
import { ApiType, ApiUrl } from './api-urls';

class AuthorizationApi {
    public static async getAuthorizationContext(): Promise<WorkspaceResponse<AuthorizationContext>> {
        return Http.get<WorkspaceResponse<AuthorizationContext>>(ApiType.Gateway, ApiUrl.GetAuthorizationContext);
    }
}

export default AuthorizationApi;
