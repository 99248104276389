import { faSortAmountDown, faSortAmountUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject, UIEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SearchAggregatesDimensionElement } from '../../../../../../store/search';
import { ExplorerSorting, SortAxis, SortDirection, SortProperty } from '../../../../../../types/sorting';
import { CollectionUtil } from '../../../../../../utils/collection-util';
import { generateUUID } from '../../../../../../utils/uuid-helpers';
import { ColumnCombinationTransformer } from '../../utils/column-combination-transformer';
import ExplorerSelectionBar from '../explorer-selection-bar/explorer-selection-bar';
import styles from './explorer-table.module.scss';

class ExplorerTable extends React.Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            selectedRowElements: [],
            selectedColumnElements: [],
            columnContainers: [],
            rowKeyContainer: React.createRef<HTMLDivElement>(),
        };
    }

    public componentDidMount(): void {
        const { rows, columnCombinations, sorting, selectedColumnDimensions } = this.props;

        if (rows && columnCombinations) {
            this.sort(sorting);

            this.setState({
                columnContainers: selectedColumnDimensions.map((_) => React.createRef<HTMLDivElement>()),
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, previousState: Readonly<AllState>): void {
        const { rows, columnCombinations, selectedColumnDimensions, sorting } = this.props;

        const rowsEqual = rows === prevProps.rows;

        // const rowsEqual = CollectionUtil.areEqualByProperty(rows, prevProps.rows, 'keys');
        const columnsEqual = CollectionUtil.areEqual(columnCombinations, prevProps.columnCombinations);

        if (rows && columnCombinations && (!rowsEqual || !columnsEqual)) {
            this.sort(sorting);

            this.setState({
                columnContainers: selectedColumnDimensions.map((_) => React.createRef<HTMLDivElement>()),
            });
        }

        if (prevProps.columnCombinations !== columnCombinations || prevProps.rows !== rows) {
            this.setState({
                selectedRowElements: [],
                selectedColumnElements: [],
            });
        }
    }

    public render(): JSX.Element | null {
        const { sorting, selectedRowDimensions, selectedColumnDimensions, t } = this.props;
        const {
            sortedRows,
            sortedColumnCombinations,
            sortedColumns,
            selectedColumnElements,
            selectedRowElements,
            columnContainers,
            rowKeyContainer,
        } = this.state;
        const hasSelection = selectedColumnElements.length > 0 || selectedRowElements.length > 0;

        return sortedRows &&
            sortedRows.length > 0 &&
            sortedColumnCombinations &&
            sortedColumnCombinations.length > 0 &&
            sortedColumns &&
            sortedColumns.length > 0 ? (
            <div className={styles.tableContainer}>
                <div
                    className={`${styles.table}`}
                    style={{
                        marginTop: selectedColumnDimensions.length * 50,
                        marginLeft: selectedRowDimensions.length * 240 + 41,
                    }}
                    onScroll={(event): void => {
                        this.onScroll(event);
                    }}>
                    <div
                        className={styles.rowKeyContainer}
                        style={{ height: `calc(100% - ${50 * selectedColumnDimensions.length}px` }}
                        ref={rowKeyContainer}>
                        {sortedRows.map((row: ExplorerTableRow) => {
                            const rowSelected = selectedRowElements.find((rowElements) =>
                                rowElements.every((rowElement) => row.keys.includes(rowElement)),
                            );

                            return (
                                <div
                                    className={styles.rowKey}
                                    key={generateUUID()}
                                    onClick={(): void => this.onToggleRowSelection(row.keys)}>
                                    <div className={styles.rowSpacer} />
                                    {row.keys.map((key, index) => {
                                        return (
                                            <div
                                                className={`${styles.cell} ${styles.key} ${
                                                    rowSelected ? styles.selected : ''
                                                }`}
                                                key={key + index}>
                                                <span className={styles.cellText} title={key}>
                                                    {key}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.rowContainer}>
                        {sortedColumns.map((columns, index) => {
                            return (
                                <div
                                    className={`${styles.row} ${styles.rowHeader}`}
                                    style={{ top: index * 50 }}
                                    key={generateUUID()}>
                                    <div className={styles.rowSpacer} />
                                    {selectedRowDimensions.slice(1).map((selectedRowDimension) => {
                                        return <div className={styles.headerCell} key={selectedRowDimension} />;
                                    })}
                                    {index === 0 ? (
                                        <div className={styles.headerCell}>
                                            <span className={styles.sortingText}>{t('Sort')}</span>
                                            <span
                                                className={styles.sortingIcon}
                                                onClick={(): void => this.onToggleSortAxis(sorting.axis)}>
                                                {sorting.axis === SortAxis.Row ? t('Row') : t('Column')}
                                            </span>
                                            <span
                                                className={styles.sortingIcon}
                                                onClick={(): void => this.onToggleSortProperty(sorting.property)}>
                                                {sorting.property === 'name' ? t('Name') : t('Count')}
                                            </span>
                                            <span
                                                className={styles.sortingIcon}
                                                onClick={(): void => this.onToggleSortDirection(sorting.direction)}>
                                                {sorting.direction === SortDirection.Descending ? (
                                                    <FontAwesomeIcon icon={faSortAmountDown} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortAmountUp} />
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className={styles.headerCell} />
                                    )}
                                    <div className={styles.columnContainer} ref={columnContainers[index]}>
                                        {columns.map((column, columnIndex) => {
                                            const columnCombination = sortedColumnCombinations[columnIndex];
                                            const selected =
                                                selectedColumnElements.length > 0 &&
                                                selectedColumnElements.some((columnElements) =>
                                                    columnElements.every((columnElement) =>
                                                        columnCombination.includes(columnElement),
                                                    ),
                                                );

                                            return (
                                                <div
                                                    className={`${styles.headerCell} ${
                                                        selected ? styles.selected : ''
                                                    }`}
                                                    onClick={(): void =>
                                                        this.onToggleColumnSelection(columnCombination)
                                                    }
                                                    key={generateUUID()}>
                                                    <span className={styles.headerCellText} title={column}>
                                                        {column}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {sortedRows.map((row: ExplorerTableRow) => {
                            const rowSelected =
                                selectedRowElements.find((rowElements) =>
                                    rowElements.every((rowElement) => row.keys.includes(rowElement)),
                                ) !== undefined;

                            return (
                                <div key={generateUUID()} className={styles.row}>
                                    {sortedColumnCombinations.map((columnCombination) => {
                                        const columnCombinationSelected =
                                            selectedColumnElements.length > 0 &&
                                            selectedColumnElements.some((columnElements) =>
                                                columnElements.every((columnElement) =>
                                                    columnCombination.includes(columnElement),
                                                ),
                                            );

                                        const cellSelected = hasSelection
                                            ? (rowSelected &&
                                                  (selectedColumnElements.length === 0 || columnCombinationSelected)) ||
                                              (!rowSelected &&
                                                  selectedRowElements.length === 0 &&
                                                  columnCombinationSelected)
                                            : false;

                                        const columnCombinationKey = columnCombination.toString();
                                        const value = row.values[columnCombinationKey];

                                        return (
                                            <div
                                                key={generateUUID()}
                                                className={`${styles.cell} ${cellSelected ? styles.selected : ''}`}>
                                                <span className={styles.cellText}>{value}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {hasSelection ? <ExplorerSelectionBar applyFilter={(): void => this.applyFilter()} /> : null}
            </div>
        ) : null;
    }

    private onScroll(event: UIEvent<HTMLDivElement>): void {
        const { columnContainers, rowKeyContainer } = this.state;

        columnContainers.forEach((columnContainer) => {
            const element = columnContainer;

            if (element && element.current) {
                element.current.scrollLeft = event.currentTarget.scrollLeft;
            }
        });

        if (rowKeyContainer && rowKeyContainer.current) {
            rowKeyContainer.current.scrollTop = event.currentTarget.scrollTop;
        }
    }

    private onToggleRowSelection(elementNames: string[]): void {
        const { selectedRowElements } = this.state;
        CollectionUtil.toggleElement(selectedRowElements, elementNames);
        this.setState({ selectedRowElements });
    }

    private onToggleColumnSelection(elementNames: string[]): void {
        const { selectedColumnElements } = this.state;
        CollectionUtil.toggleElement(selectedColumnElements, elementNames);
        this.setState({ selectedColumnElements });
    }

    private onToggleSortAxis(axis: SortAxis): void {
        const { sorting, changeSorting } = this.props;

        const newAxis = (): SortAxis => {
            switch (axis) {
                case SortAxis.Row:
                    return SortAxis.Column;
                case SortAxis.Column:
                    return SortAxis.Row;
                default:
                    return SortAxis.Row;
            }
        };
        const newSorting: ExplorerSorting = { ...sorting, axis: newAxis() };

        this.sort(newSorting);

        changeSorting(newSorting);
    }

    private onToggleSortDirection(direction: SortDirection): void {
        const { sorting, changeSorting } = this.props;

        const newDirection = (): SortDirection => {
            switch (direction) {
                case SortDirection.Descending:
                    return SortDirection.Ascending;
                case SortDirection.Ascending:
                    return SortDirection.Descending;
                default:
                    return SortDirection.Ascending;
            }
        };
        const newSorting: ExplorerSorting = { ...sorting, direction: newDirection() };

        this.sort(newSorting);

        changeSorting(newSorting);
    }

    private onToggleSortProperty(property: SortProperty): void {
        const { sorting, changeSorting } = this.props;

        const newProperty = (): SortProperty => {
            switch (property) {
                case 'count':
                    return 'name';
                case 'name':
                    return 'count';
                default:
                    return 'count';
            }
        };
        const newSorting: ExplorerSorting = { ...sorting, property: newProperty() };

        this.sort(newSorting);

        changeSorting(newSorting);
    }

    private applyFilter(): void {
        const { applyFilter } = this.props;
        const { selectedRowElements, selectedColumnElements } = this.state;

        applyFilter(selectedRowElements, selectedColumnElements);
    }

    private sort(sorting: ExplorerSorting): void {
        const { rows, columnCombinations, selectedColumnDimensions } = this.props;

        if (sorting.axis === SortAxis.Row) {
            const countsPerRow = rows.reduce((summedCounts, row) => {
                const summedCount = Object.keys(row.values).reduce((sum, valueKey) => {
                    const value = row.values[valueKey];
                    return value ? sum + value : sum;
                }, 0);

                return summedCounts.concat({ name: row.keys.toString(), count: summedCount });
            }, [] as SearchAggregatesDimensionElement[]);

            const newSortedRows = this.sortRows(rows, countsPerRow, sorting.direction, sorting.property);
            const columns = ColumnCombinationTransformer.transformColumnCombinationsIntoColumns(
                columnCombinations,
                selectedColumnDimensions.length,
            );

            this.setState({
                sortedRows: newSortedRows,
                sortedColumnCombinations: columnCombinations,
                sortedColumns: columns,
            });
        } else {
            const countsPerColumn = columnCombinations.reduce((summedCounts, columnCombination) => {
                const columnCombinationKey = columnCombination.toString();
                const summedCount = rows.reduce((sum, row) => {
                    const value = row.values[columnCombinationKey];
                    return value ? sum + value : sum;
                }, 0);

                return summedCounts.concat({ name: columnCombinationKey, count: summedCount });
            }, [] as SearchAggregatesDimensionElement[]);

            const sortedColumnCombinations = this.sortColumnCombinations(
                columnCombinations,
                countsPerColumn,
                sorting.direction,
                sorting.property,
            );
            const sortedColumns = ColumnCombinationTransformer.transformColumnCombinationsIntoColumns(
                sortedColumnCombinations,
                selectedColumnDimensions.length,
            );

            this.setState({ sortedColumnCombinations, sortedColumns, sortedRows: rows });
        }
    }

    private sortRows(
        rows: ExplorerTableRow[],
        counts: SearchAggregatesDimensionElement[],
        direction: SortDirection,
        property: SortProperty,
    ): ExplorerTableRow[] {
        return counts
            ? [...rows].sort((a, b) => {
                  const objectA = counts.find((value) => value.name === a.keys.toString());
                  const objectB = counts.find((value) => value.name === b.keys.toString());

                  return this.determineSortingRank(objectA, objectB, direction, property);
              })
            : rows;
    }

    private sortColumnCombinations(
        columnCombinations: string[][],
        counts: SearchAggregatesDimensionElement[],
        direction: SortDirection,
        property: SortProperty,
    ): string[][] {
        return counts
            ? [...columnCombinations].sort((a, b) => {
                  const objectA = counts.find((value) => value.name === a.toString());
                  const objectB = counts.find((value) => value.name === b.toString());

                  return this.determineSortingRank(objectA, objectB, direction, property);
              })
            : columnCombinations;
    }

    private determineSortingRank(
        objectA: SearchAggregatesDimensionElement | undefined,
        objectB: SearchAggregatesDimensionElement | undefined,
        direction: SortDirection,
        property: SortProperty,
    ): number {
        if (objectA && objectB) {
            const valueA = objectA[property];
            const valueB = objectB[property];

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                if (direction === SortDirection.Ascending) {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                if (direction === SortDirection.Ascending) {
                    return valueA - valueB;
                } else {
                    return valueB - valueA;
                }
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }
}

export default withTranslation()(ExplorerTable);

interface OwnProps {
    rows: ExplorerTableRow[];
    columnCombinations: string[][];
    selectedRowDimensions: string[];
    selectedColumnDimensions: string[];
    sorting: ExplorerSorting;
    applyFilter: (selectedRowElements: string[][], selectedColumnElements: string[][]) => void;
    changeSorting: (sorting: ExplorerSorting) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    sortedRows?: ExplorerTableRow[];
    sortedColumnCombinations?: string[][];
    sortedColumns?: string[][];
    selectedRowElements: string[][];
    selectedColumnElements: string[][];
    columnContainers: RefObject<HTMLDivElement>[];
    rowKeyContainer: RefObject<HTMLDivElement>;
}

type AllState = OwnState;

export interface ExplorerTableRow {
    keys: string[];
    values: { [key: string]: number };
}
