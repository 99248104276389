import { Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BiostrandLogo } from '../../../assets/images/biostrand_logo_title2.svg';
import styles from './left-panel.module.scss';

export const LeftPanel = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={styles.leftPanel}>
            <BiostrandLogo
                style={{
                    width: 138,
                    marginTop: 24,
                    height: 32,
                }}
            />
            <div style={{ margin: 40 }}>
                <Typography variant='h4' style={{ color: '#ffffff', fontWeight: 500, fontStyle: 'normal' }}>
                    {t('Retrieve & Relate')}
                </Typography>
                <Typography variant='subtitle1' style={{ color: '#ffffff', fontWeight: 500 }}>
                    {t('Search your sequences among:')}
                </Typography>
                <Typography
                    component='div'
                    variant='subtitle1'
                    style={{
                        color: '#ffffff',
                        fontWeight: 500,
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                    }}>
                    <Check />
                    <span style={{ paddingLeft: 8 }}>{t('440 million sequences')}</span>
                </Typography>
                <Typography
                    component='div'
                    variant='subtitle1'
                    style={{
                        color: '#ffffff',
                        fontWeight: 500,
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                    }}>
                    <Check />
                    <span style={{ paddingLeft: 8 }}>{t('any known databases')}</span>
                </Typography>
                <Typography
                    component='div'
                    variant='subtitle1'
                    style={{
                        color: '#ffffff',
                        fontWeight: 500,
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                    }}>
                    <Check />
                    <span style={{ paddingLeft: 8 }}>{t('with only 3 seconds')}</span>
                </Typography>
            </div>
        </div>
    );
};
