import { push } from 'connected-react-router';
import * as FileSaver from 'file-saver';
import { Location, LocationDescriptorObject } from 'history';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import EmptyPanel from '../../../../components/empty-panel/empty-panel';
import { HelpLinkUrl } from '../../../../components/help-link/help-link-urls';
import LoadingPanel from '../../../../components/loading/loading-panel';
import ResultsPageHeader from '../../../../components/page-header/results-page-header/results-page-header';
import TooManyResults from '../../../../components/wrong-page/too-many-results';
import SomethingWentWrong from '../../../../components/wrong-page/wrong-page';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import { InstanceType } from '../../../../store/global';
import { closePopup, Popup, PopupType, showPopup } from '../../../../store/popup';
import {
    BaseSearchParameters,
    baseSearchRequest,
    closeToolbarRequest,
    DimensionFilter,
    GetSequencesResultSequenceMap,
    ListResultViewData,
    ListSearchParameters,
    listSearchRequest,
    LIST_RESULT_LIMIT,
    openToolbarRequest,
    rootSearchRequest,
} from '../../../../store/search';
import { updateFilters } from '../../../../store/search/filter-actions';
import { DimensionFilterHelper } from '../../../../store/search/helpers/dimension-filter-helper';
import { queryErrorSelector } from '../../../../store/search/metadata-selectors';
import { ListState, updateListColumnSettings } from '../../../../store/uistate';
import { ListSorting } from '../../../../types/sorting';
import { CollectionUtil } from '../../../../utils/collection-util';
import { getDownloadFileName } from '../../../../utils/get-file-name-util';
import { ToolbarActionId, ToolbarContext } from '../../components/page-toolbar/page-toolbar';
import ResultsNavigationBar from '../../components/results-navigation-bar/results-navigation-bar';
import ResultsPageOverlay from '../../components/results-page-overlay/results-page-overlay';
import { SEQUENCE_ID_DIMENSION_NAME } from '../../constants/dimension-constants';
import { ResultViewBasePage, ResultViewStatus } from '../../result-view-base.page';
import { DimensionFiltersComparator } from '../../utils/dimension-filters-comparator';
import ColumnConfigurationPopup from './components/column-configuration-popup/column-configuration-popup';
import ListBottomBar from './components/list-bottom-bar/list-bottom-bar';
import { ColumnConfig } from './components/results-list/column-config';
import ResultsList from './components/results-list/results-list';
import { SequenceDetailsPopup } from './components/sequence-details-popup/sequence-details-popup';
import { ListInputValidator } from './list-input-validator';
import ListToolbar from './list-toolbar';
import { ListResultViewUrlParameters, ListUrlParametersHandler } from './list-url-parameters-handler';
import styles from './list.module.scss';
import { ListViewCsvCreator } from './utils/list-view-csv-creator';

const LIST_VIEW_UID = 'LIST_VIEW_UID';

class ListPage extends ResultViewBasePage<AllProps, AllState, ListResultViewUrlParameters> {
    private calculateSelectedColumns(selectedColumns: ColumnConfig[], listColumnConfiguration: ColumnConfig[]) {
        if (selectedColumns.length === 0) {
            return listColumnConfiguration.slice(0, 3);
        }

        return listColumnConfiguration.filter(
            (column) => !!selectedColumns.find((selCol) => column.key === selCol.key),
        );
    }

    // Hack due to making sure the filter is set at least once
    private renderedAtLeastOneTime = false;

    constructor(props: AllProps) {
        super(props, new ListUrlParametersHandler(), new ListInputValidator());
        const { listColumnConfiguration, listSettings } = this.props;

        const selectedColumns = this.calculateSelectedColumns(
            listSettings?.selectedColumns || [],
            listColumnConfiguration || [],
        );

        this.state = {
            sequenceSelection: [],
            selectedFilters: [],
            availableColumns: listColumnConfiguration || [],
            selectedColumns,
            isFiltersUpdated: false,
        };

        // Make sure defaults are applied to url state
        this.updateUrl(this.urlParameters);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        super.componentDidUpdate(prevProps, prevState);
        const { listResultViewData, listColumnConfiguration, dispatchUpdateListColumnSettings, listTooManyResults } = this.props;
        const { selectedFilters, selectedColumns } = this.state;

        const dataChanged = listResultViewData !== prevProps.listResultViewData;

        // Execute new search when URL state is different from last executed search
        if (this.needsNewSearch(this.urlParameters)) {
            this.executeListViewSearch();
        } else if ((listResultViewData && (dataChanged || !this.renderedAtLeastOneTime)) || listTooManyResults) {
            if (!DimensionFiltersComparator.equals(selectedFilters, this.urlParameters.activeFilters)) {
                this.setState({ selectedFilters: this.urlParameters.activeFilters });
            }
            this.renderedAtLeastOneTime = true;
        }

        if (listColumnConfiguration && listColumnConfiguration !== prevProps.listColumnConfiguration) {
            const newSelectedColumns = this.calculateSelectedColumns(selectedColumns, listColumnConfiguration);

            this.setState({
                availableColumns: listColumnConfiguration,
                selectedColumns: newSelectedColumns,
            });

            dispatchUpdateListColumnSettings(LIST_VIEW_UID, newSelectedColumns);
        }
    }

    public render(): JSX.Element | null {
        const { location, isToolbarOpen, totalResults } = this.props;
        const { sequenceSelection, selectedFilters } = this.state;
        const { activeFilters } = this.urlParameters;
        const isAtLeastOneSequenceSelected = sequenceSelection.length > 0;

        const viewStatus = this.determineViewStatus();

        return (
            <div className={styles.container}>
                <ResultsPageHeader filters={activeFilters} />
                <ResultsNavigationBar
                    isNavigationDisabled={viewStatus === ResultViewStatus.inProgress}
                    location={location}
                    navigateTo={(url: RouteUrl, search) => this.navigateTo(url, search)}
                    helpUrl={HelpLinkUrl.ListView}
                    totalResults={totalResults}
                />
                <ListToolbar
                    selectedFilters={selectedFilters}
                    onDownloadCsv={() => this.onDownload()}
                    onShowColumnConfiguration={() => this.onShowColumnConfiguration()}
                    onFiltersChanged={(changedFilters: DimensionFilter[]) => this.onFiltersChanged(changedFilters)}
                />

                <div className={styles.pageContentContainer}>
                    <ResultsPageOverlay show={isToolbarOpen} onClick={() => this.overlayClicked()} />
                    <div
                        className={styles.pageContent}
                        style={{
                            overflowY: isToolbarOpen ? 'hidden' : 'auto',
                            paddingBottom: isAtLeastOneSequenceSelected ? 60 : 0,
                        }}>
                        {this.renderViewContent(viewStatus)}
                        {isAtLeastOneSequenceSelected ? (
                            <ListBottomBar applyFilter={() => this.applyIndividualSelectionFilter()} />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    protected renderViewContent(viewStatus: ResultViewStatus): JSX.Element {
        const { listResultViewData, isToolbarOpen, t, listErrorMessage, location } = this.props;
        const { sequenceSelection, selectedColumns } = this.state;
        const { activeSortingDirection, activeSortingColumnKey, activePage, activePageSize } = this.urlParameters;

        switch (viewStatus) {
            case ResultViewStatus.tooManyResults:
                return (
                    <TooManyResults
                        location={location}
                        message={`The list is not shown as you have more than ${LIST_RESULT_LIMIT} results/matches. Please use the Quick Filter to bring the number of matches down to below ${LIST_RESULT_LIMIT} to see the list`}
                    />
                );
            case ResultViewStatus.error:
                return <SomethingWentWrong message={listErrorMessage} />;
            case ResultViewStatus.inProgress:
                return <LoadingPanel style={{ padding: '30px 40px' }} text={t('Fetching the details...')} />;
            case ResultViewStatus.emptyResult:
                return <EmptyPanel />;
            default:
                return (
                    <ResultsList
                        isToolbarOpen={isToolbarOpen}
                        columns={selectedColumns}
                        sortedData={listResultViewData}
                        sorting={{ direction: activeSortingDirection, columnKey: activeSortingColumnKey }}
                        page={activePage}
                        pageSize={activePageSize}
                        sequenceSelection={sequenceSelection}
                        onOpenFilter={(dimensionName: string): void => this.openFilter(dimensionName)}
                        onChangeSorting={(newSorting: ListSorting): void => this.onChangeSorting(newSorting)}
                        onShowSequenceDetails={(sequence): void => this.onShowSequenceDetails(sequence)}
                        onChangePage={(newPage): void => this.onChangePage(newPage)}
                        onSequenceSelectionToggle={(sequenceId: string) => {
                            this.onSequenceSelectionToggle(sequenceId);
                        }}
                    />
                );
        }
    }

    public determineViewStatus(): ResultViewStatus {
        const {
            rootSearchRequestInProgress,
            listRequestInProgress,
            baseSearchRequestInProgress,
            listResultViewData,
            queryInError,
            listSearchInError,
            listTooManyResults,
        } = this.props;

        if (queryInError || listSearchInError) return ResultViewStatus.error;

        if (listTooManyResults) {
            return ResultViewStatus.tooManyResults;
        } else if (rootSearchRequestInProgress || listRequestInProgress || baseSearchRequestInProgress || queryInError) {
            return ResultViewStatus.inProgress;
        } else if (!listResultViewData || !listResultViewData.sequences || listResultViewData.sequences.length === 0) {
            return ResultViewStatus.emptyResult;
        } else  {
            return ResultViewStatus.ready;
        }
    }

    private executeListViewSearch(): void {
        const { dispatchBaseSearchRequest, dispatchListSearchRequest, baseSearchParameters, baseQueryId } = this.props;

        if (!baseSearchParameters || this.baseParametersOutOfSync(this.urlParameters, baseSearchParameters)) {
            dispatchBaseSearchRequest({
                rootQuery: this.urlParameters.rootQuery,
                filters: this.urlParameters.activeFilters,
            });
        } else {
            const {
                rootQuery,
                activeFilters,
                activePage,
                activePageSize,
                activeSortingColumnKey,
                activeSortingDirection,
            } = this.urlParameters;
            const { selectedColumns, availableColumns } = this.state;
            const fields = ListPage.getColumnNames(selectedColumns, availableColumns);
            dispatchListSearchRequest({
                rootQuery,
                filters: activeFilters,
                baseQueryId,
                page: activePage,
                pageSize: activePageSize,
                sortingColumnKey: activeSortingColumnKey,
                sortingDirection: activeSortingDirection,
                fields,
            });
        }
    }

    private needsNewSearch(urlParameters: ListResultViewUrlParameters): boolean {
        const {
            listSearchParameters,
            listRequestInProgress,
            baseSearchRequestInProgress,
            queryInError,
            listSearchInError,
            listTooManyResults,
        } = this.props;

        // api calls already in progress or in error
        if (
            this.initializingRootQuery ||
            listRequestInProgress ||
            baseSearchRequestInProgress ||
            queryInError ||
            listSearchInError ||
            listTooManyResults
        ) {
            return false;
        }

        if (!listSearchParameters) {
            return true;
        }

        if (this.baseParametersOutOfSync(urlParameters, listSearchParameters)) {
            return true;
        }

        const pageIsEqual = urlParameters.activePage === listSearchParameters.page;
        if (!pageIsEqual) {
            return true;
        }

        const pageSizeIsEqual = urlParameters.activePageSize === listSearchParameters.pageSize;
        if (!pageSizeIsEqual) {
            return true;
        }

        const sortingDirectionIsEqual = urlParameters.activeSortingDirection === listSearchParameters.sortingDirection;
        if (!sortingDirectionIsEqual) {
            return true;
        }

        const sortingColumnKeyIsEqual = urlParameters.activeSortingColumnKey === listSearchParameters.sortingColumnKey;
        if (!sortingColumnKeyIsEqual) {
            return true;
        }

        return false;
    }

    protected overlayClicked(): void {
        super.overlayClicked();
        const { dispatchUpdateFilters } = this.props;
        const { selectedFilters, isFiltersUpdated } = this.state;

        if (isFiltersUpdated) dispatchUpdateFilters(selectedFilters);

        this.setState({ isFiltersUpdated: false });
    }

    private onSequenceSelectionToggle(sequenceId: string): void {
        const { sequenceSelection } = this.state;
        CollectionUtil.toggleElement(sequenceSelection, sequenceId);
        this.setState({ sequenceSelection });
    }

    private onShowColumnConfiguration(): void {
        const { dispatchShowPopup } = this.props;
        const { availableColumns, selectedColumns } = this.state;

        dispatchShowPopup({
            type: PopupType.COLUMN_CONFIGURATION,
            content: (
                <ColumnConfigurationPopup
                    availableColumns={availableColumns}
                    selectedColumns={selectedColumns}
                    onApply={(c): void => this.onApplyColumnConfiguration(c)}
                    onCancel={(): void => this.onClosePopup()}
                />
            ),
            isDismissible: false,
        });
    }

    private onDownload(): void {
        const { listResultViewData, rootQuery } = this.props;
        const { selectedColumns } = this.state;

        if (listResultViewData) {
            const csv = this.createCsv(selectedColumns, listResultViewData.sequences);

            const fileName = `BioStrand-${getDownloadFileName(rootQuery)}-list data.csv`;
            const blob = new Blob([csv], { type: 'text/csv' });

            FileSaver.saveAs(blob, fileName);
        }
    }

    private onShowSequenceDetails(sequence: GetSequencesResultSequenceMap): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.SEQUENCE_DETAILS,
            content: <SequenceDetailsPopup sequenceId={sequence.id} onClose={(): void => this.onClosePopup()} />,
            isDismissible: false,
        });
    }

    private onChangeSorting(sorting: ListSorting): void {
        const { activeFilters, rootQuery, activePageSize, activePage } = this.urlParameters;
        this.updateUrl({
            rootQuery,
            activeFilters,
            activePage,
            activePageSize,
            activeSortingColumnKey: sorting.columnKey,
            activeSortingDirection: sorting.direction,
        });
    }

    private onChangePage(newPage: number): void {
        const {
            activeFilters,
            rootQuery,
            activePageSize,
            activeSortingColumnKey,
            activeSortingDirection,
        } = this.urlParameters;
        this.updateUrl({
            rootQuery,
            activeFilters,
            activePage: newPage,
            activePageSize,
            activeSortingColumnKey,
            activeSortingDirection,
        });
    }

    private getCalculatedColumnNames(columns: ColumnConfig[]): string[] {
        return columns
            .filter((c) => c.computed)
            .map((c) => c.key)
            .sort((a, b) => (a > b ? 1 : -1));
    }

    private isComputedColumnsAdded(oldColumns: ColumnConfig[], newColumns: ColumnConfig[]): boolean {
        const oldKey = this.getCalculatedColumnNames(oldColumns);
        const newKey = this.getCalculatedColumnNames(newColumns);

        if (newKey.length === 0) {
            // no any computed column found
            return false;
        }

        if (oldKey.length > newKey.length) {
            // computed column is removed
            return false;
        }

        return oldKey.join('-') !== newKey.join('-'); // column are changed (added or replaced);
    }

    private static getColumnNames(selectedColumns: ColumnConfig[], availableColumns: ColumnConfig[]): string[] {
        const onlySimpleColumns = availableColumns.filter((c) => !c.computed).map((c) => c.key);

        const computed = selectedColumns.filter((c) => c.computed).map((c) => c.key);
        const resultColumns = onlySimpleColumns.concat(computed);

        // make sure sequenceId is included with any other dimensions,
        // because there is hack relate to sequence id column :(
        if (resultColumns.length) {
            resultColumns.push(SEQUENCE_ID_DIMENSION_NAME);
        }

        return resultColumns;
    }

    private onApplyColumnConfiguration(selectedColumns: ColumnConfig[]): void {
        const {
            dispatchClosePopup,
            dispatchUpdateListColumnSettings,
            dispatchListSearchRequest,
            baseQueryId,
        } = this.props;
        const { availableColumns } = this.state;

        dispatchClosePopup();
        const oldSelectedColumns = this.state.selectedColumns;
        this.setState({ selectedColumns });

        if (this.isComputedColumnsAdded(oldSelectedColumns, selectedColumns)) {
            const {
                rootQuery,
                activeFilters,
                activePage,
                activePageSize,
                activeSortingColumnKey,
                activeSortingDirection,
            } = this.urlParameters;

            const fields = ListPage.getColumnNames(selectedColumns, availableColumns);

            dispatchListSearchRequest({
                rootQuery,
                filters: activeFilters,
                baseQueryId,
                page: activePage,
                pageSize: activePageSize,
                sortingColumnKey: activeSortingColumnKey,
                sortingDirection: activeSortingDirection,
                fields,
            });
        }

        dispatchUpdateListColumnSettings(LIST_VIEW_UID, selectedColumns);
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        this.setState({ selectedFilters: filters, isFiltersUpdated: true });
    }

    private applyIndividualSelectionFilter(): void {
        const { dispatchUpdateFilters } = this.props;
        const filters = this.updateFilters();
        dispatchUpdateFilters(filters);
    }

    private updateFilters(): DimensionFilter[] {
        const { sequenceSelection, selectedFilters } = this.state;
        const newFilters = DimensionFilterHelper.cloneFilters(selectedFilters || []);
        const filter = newFilters.find((df) => df.dimensionName === SEQUENCE_ID_DIMENSION_NAME);
        if (filter) {
            filter.includes = [...sequenceSelection];
            filter.elementNamesContains = undefined;
            filter.excludesAll = false;
        } else {
            newFilters.push({
                dimensionName: SEQUENCE_ID_DIMENSION_NAME,
                includes: [...sequenceSelection],
                excludesAll: false,
            });
        }
        return newFilters;
    }

    private onClosePopup(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private openFilter(dimensionName: string): void {
        const { dispatchOpenToolbar } = this.props;
        dispatchOpenToolbar({ actionId: ToolbarActionId.GlobalFilterId, options: { dimensionName } });
    }

    private createCsv(columns: ColumnConfig[], sequences: GetSequencesResultSequenceMap[]): string {
        return ListViewCsvCreator.createCsv(columns, sequences);
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = (state: ApplicationState) => {
    const { search, global, router, uistate } = state;

    return {
        baseQueryId: search.baseQueryId,
        rootQuery: search.rootQuery,
        currentLocation: router.location,
        isToolbarOpen: search.isToolbarOpen,
        rootSearchRequestInProgress: search.rootSearchRequestInProgress,
        listResultViewData: search.listResultViewData,
        listRequestInProgress: search.listRequestInProgress,
        listSearchParameters: search.listSearchParameters,
        listSearchInError: search.listRequestInError,
        listTooManyResults: search.listTooManyResults,
        baseSearchParameters: search.baseSearchParameters,
        baseSearchRequestInProgress: search.baseSearchRequestInProgress,
        listErrorMessage: search.listErrorMessage,
        totalResults: search.queryMatches,
        queryInError: queryErrorSelector(state),
        instanceType: global.instanceType,
        activeWorkspaceId: global.activeWorkspaceId,
        listColumnConfiguration: search.listAvailableColumns,
        listSettings: uistate.listsSettings[LIST_VIEW_UID],
    };
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchRootSearchRequest: (workspaceId: string, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchOpenToolbar: (context: ToolbarContext) => dispatch(openToolbarRequest(context)),
    dispatchCloseToolbar: () => dispatch(closeToolbarRequest()),
    dispatchListSearchRequest: (parameters: ListSearchParameters) => dispatch(listSearchRequest(parameters)),
    dispatchBaseSearchRequest: (parameters: BaseSearchParameters) => dispatch(baseSearchRequest(parameters)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchUpdateListColumnSettings: (listUID: string, columnsSettings: ColumnConfig[]) =>
        dispatch(updateListColumnSettings(listUID, columnsSettings)),
    dispatchUpdateFilters: (parameters: DimensionFilter[]) => dispatch(updateFilters(parameters)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ListPage));

interface PropsFromState {
    baseQueryId: string;
    rootQuery: string;
    currentLocation: Location;
    isToolbarOpen: boolean;
    rootSearchRequestInProgress: boolean;
    listResultViewData?: ListResultViewData;
    listRequestInProgress: boolean;
    listSearchParameters?: ListSearchParameters;
    listColumnConfiguration?: ColumnConfig[];
    listTooManyResults?: boolean;
    baseSearchParameters?: BaseSearchParameters;
    baseSearchRequestInProgress: boolean;
    instanceType: InstanceType;
    activeWorkspaceId?: string;
    listSettings?: ListState;
    queryInError?: boolean;
    listSearchInError?: boolean;
    totalResults: number;
    listErrorMessage?: string;
}

interface PropsFromDispatch {
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchOpenToolbar: typeof openToolbarRequest;
    dispatchCloseToolbar: typeof closeToolbarRequest;
    dispatchListSearchRequest: typeof listSearchRequest;
    dispatchBaseSearchRequest: typeof baseSearchRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchUpdateListColumnSettings: typeof updateListColumnSettings;
    dispatchUpdateFilters: typeof updateFilters;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    availableColumns: ColumnConfig[];
    sequenceSelection: string[];
    selectedFilters: DimensionFilter[];
    selectedColumns: ColumnConfig[];
    isFiltersUpdated: boolean;
}

type AllState = OwnState;
