import { AuthorizationContext, OrganizationRole } from '../../global';
import { Customer, PaymentMethod, StripeSubscriptionStatus } from '../types';

export class SubscriptionHelper {
    public static hasValidSubscription(authorizationContext?: AuthorizationContext): boolean {
        return authorizationContext
            ? authorizationContext.subscription.status === StripeSubscriptionStatus.Active ||
                  authorizationContext.subscription.status === StripeSubscriptionStatus.Trialing
            : true;
    }

    public static shouldBeReminded(authorizationContext: AuthorizationContext): boolean {
        const trialMoreThanHalfwayOver =
            authorizationContext.subscription.remainingTrialDays / authorizationContext.subscription.totalTrialDays <
            0.5;

        return (
            (!authorizationContext.subscription.hasDefaultPaymentMethod ||
                !authorizationContext.subscription.areBillingDetailsComplete) &&
            authorizationContext.user.organizationRole === OrganizationRole.ADMIN &&
            trialMoreThanHalfwayOver
        );
    }

    public static areSettingsComplete(authorizationContext?: AuthorizationContext, customer?: Customer): boolean {
        return (
            authorizationContext !== undefined &&
            customer !== undefined &&
            (authorizationContext.subscription.areBillingDetailsComplete ||
                (customer.address.country !== undefined &&
                    customer.address.line1 !== undefined &&
                    customer.address.city !== undefined &&
                    customer.address.postalCode !== undefined &&
                    customer.taxId.value !== undefined &&
                    customer.taxId.type !== undefined))
        );
    }

    public static arePaymentMethodsComplete(
        authorizationContext?: AuthorizationContext,
        paymentMethods?: PaymentMethod[],
    ): boolean {
        return (
            authorizationContext !== undefined &&
            paymentMethods !== undefined &&
            (authorizationContext.subscription.hasDefaultPaymentMethod || paymentMethods.length > 0)
        );
    }
}
