export enum SharedActionTypes {
    LOGOUT_SUCCESS = '@@shared/LOGOUT_SUCCESS',
}

export enum RequestStatusType {
    New,
    InProgress,
    Success,
    Failed,
}

export class RequestStatus {
    constructor(public type: RequestStatusType, public failureCode?: FailureCodes) {}

    public get isNew(): boolean {
        return this.type === RequestStatusType.New;
    }

    public get isInProgress(): boolean {
        return this.type === RequestStatusType.InProgress;
    }

    public get isSuccess(): boolean {
        return this.type === RequestStatusType.Success;
    }

    public get isFailed(): boolean {
        return this.type === RequestStatusType.Failed;
    }
}

export enum FailureCodes {
    WorkspaceGeneral = 'WS1',
    WorkspaceUnauthorized = 'WS2',
    WorkspaceNotFound = 'WS3',
    WorkspaceBadRequest = 'WS4',

    WS5 = 'EmailAlreadyAssociatedToAnotherAccount',
    WS7 = 'MembershipAlreadyExists',
    WS10 = 'UserNotFoundDuringMembershipCreation',
    WS12 = 'UserHasSetWorkspaceAsDefault',
    WS13 = 'CannotDeleteMembershipBecauseItIsDefault',
    WS25 = 'CannotDeleteUser',

    AUTH1 = 'WrongUsernamePwd',
    AUTH2 = 'NewPasswordRequired',
    AUTH3 = 'CodeNoLongerValid',
    AUTH4 = 'ResetLimitReached',

    Unexpected = 'UnexpectedError',
}
