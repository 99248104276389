import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import { forgotPasswordRequest } from '../../../store/authentication';
import { RequestStatus } from '../../../store/shared/types';
import styles from './forgot-password.module.scss';
import { LeftPanel } from '../left-panel';

class ForgotPasswordPage extends React.Component<AllProps, AllState> {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            showFeedback: false,
            feedbackMessage: '',
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { requestStatus } = this.props;

        if (requestStatus !== prevProps.requestStatus && requestStatus?.isFailed) {
            this.setState({
                showFeedback: true,
                feedbackMessage: this.getMessageForFailureCode(requestStatus),
            });
        }
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const { requestStatus } = this.props;
        const { showFeedback, feedbackMessage } = this.state;

        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.contentPanel}>
                        <LeftPanel />
                        <div className={styles.rightPanel}>
                            <div className={styles.rightTopPanel}>
                                <Link to={RouteUrl.Login}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> {t('Back to Login')}
                                </Link>
                            </div>
                            <div className={styles.rightMiddlePanel} onKeyDown={(e) => this.onKeyPressed(e)}>
                                <div className={styles.header}>
                                    <div className={styles.titleTwo}>Forgot your password?</div>
                                    <div className={styles.subtitle}>
                                        {t("Don't worry! Just give us the email address associated with your account")}
                                    </div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.label}>{t('Email')}</div>
                                    <input
                                        type='text'
                                        key='EmailField'
                                        autoFocus
                                        placeholder={t('james@organization.com')}
                                        onChange={(e) => this.onEmailChange(e)}
                                    />
                                </div>
                                <div className={styles.errorMessage}>{showFeedback ? feedbackMessage : ''}</div>
                                <div className={styles.buttons}>
                                    <button type='button' className={styles.button} onClick={() => this.onSubmitForm()}>
                                        {requestStatus?.isInProgress ? (
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        ) : (
                                            t('Reset Password')
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            default:
                return t('Password reset failed, please contact your administrator if this error persists.');
        }
    }

    private onKeyPressed(e): void {
        if (e.keyCode === 13) {
            this.onSubmitForm();
        } else {
            this.setState({ showFeedback: false });
        }
    }

    private validateForm(): boolean {
        const { t } = this.props;
        const { email } = this.state;

        if (!email || email.length < 6) {
            this.setState({
                showFeedback: true,
                feedbackMessage: t("Oops, your email doesn't seem correct"),
            });
            return false;
        }

        return true;
    }

    private onEmailChange(e): void {
        this.setState({ email: e.target.value });
    }

    private onSubmitForm(): void {
        const { dispatchForgotPassword, requestStatus } = this.props;
        const { email } = this.state;

        if (!requestStatus?.isInProgress && this.validateForm()) {
            dispatchForgotPassword(email.trim());
        }
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchForgotPassword: (email: string) => dispatch(forgotPasswordRequest(email)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ authentication }: ApplicationState) => ({
    requestStatus: authentication.forgotPasswordRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage));

interface PropsFromState {
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchForgotPassword: (email: string) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    showFeedback: boolean;
    feedbackMessage: string;
}

type AllState = OwnState;
