import { ColumnConfig } from '../views/list-view/components/results-list/column-config';

export const defaultExplorerRowDimensionSelection: string[] = ['dataSourceId'];
export const defaultExplorerColumnDimensionSelection: string[] = ['molType'];

export const defaultRootQueryDimensionNames = [];

/* expected sort order of dimentions
Sequence Type
Data Source
GO Definition
Top Concepts
Taxonomy
Cath Topology
High level Ontology

*/
export const defaultSelectedDimensionNames = ['molType', 'dataSourceId', 'goDefinition', 'topConcepts', 'taxonomyTree', 'cathTopology', 'pfc'];
export const SEQUENCE_ID_DIMENSION_NAME = 'sequenceId';
export const SEQUENCE_DIMENSION_NAME = 'sequence';

export const COLUMNS_SORT_ORDER = {
    'url': '00', // TODO:  hack, sequenceId column uses the url as column key. The list view grid should be reworked.
    'sequenceId': '000',
    'dataSourceId': '001',
    'description': '002',
    'molType': '003',
    'goDefinition': '004',
    'topConcepts': '005',
    'taxonomy': '006',
    'cathTopology': '007',
    'pfc': '008',
    'ranking': '009',
    'score': '010',
};


export const sortColumns = (columns: ColumnConfig[]): ColumnConfig[] => {
    return columns.sort((c1, c2) => {
        const key1 = COLUMNS_SORT_ORDER[c1.key] ? COLUMNS_SORT_ORDER[c1.key] : c1.key;
        const key2 = COLUMNS_SORT_ORDER[c2.key] ? COLUMNS_SORT_ORDER[c2.key] : c2.key;
        return key1 > key2 ? 1 : -1;
    });
};
