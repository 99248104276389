import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DimensionFilter, DimensionWithPlaceholder } from '../../../../../store/search';
import styles from './filter-dimension-placeholder-panel.module.scss';

const  SequenceMotifFilterDimensionComponent = (props:AllProps) => {
    const { dimension, t, filterOnSelectedDimension } = props;
    const filterText = filterOnSelectedDimension?.elementNamesContains || '';


    const onFilterTextChange = (dimensionName: string, text: string, excludesAll) => {
        const { updateFilterForPlaceholder } = props;
        updateFilterForPlaceholder(dimensionName, text, excludesAll);
    }

    return (
            <div className={styles.placeholderContainer}>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.formLabel}>{t('Sequence motif')}</div>
                        <div>
                            <input
                                className={styles.search}
                                value={filterText}
                                onChange={(event): void =>
                                    onFilterTextChange(dimension.name, event.currentTarget.value, !!filterOnSelectedDimension?.excludesAll)
                                }
                            />
                            <FormControlLabel
                                style={{marginLeft: 16 }}
                                control={
                                    <Checkbox
                                        color='primary'
                                        disabled={!filterOnSelectedDimension?.elementNamesContains}
                                        checked={!!filterOnSelectedDimension?.excludesAll}
                                        onChange={(event) => {
                                            const pattern = filterOnSelectedDimension && filterOnSelectedDimension.elementNamesContains ? filterOnSelectedDimension.elementNamesContains : '';
                                            onFilterTextChange(dimension.name, pattern, event.target.checked)
                                        }
                                        } />
                                }
                                label={t('exclude pattern')}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: 16 }}>{t('Use ? to define any symbol, * to define few symbols')}</div>
            </div>
        );
}

const SequenceMotifFilterDimension = withTranslation()(SequenceMotifFilterDimensionComponent);

export default SequenceMotifFilterDimension

interface OwnProps {
    dimension: DimensionWithPlaceholder;
    filterOnSelectedDimension?: DimensionFilter;
    updateFilterForPlaceholder: (dimensionName, elementNameFilter, exclude) => void;
}

type AllProps = OwnProps & WithTranslation;
