import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import * as actions from './ui-state-actions';
import { ColumnConfig } from '../../pages/results/views/list-view/components/results-list/column-config';

export interface ListSettingsMap {
    [listId: string] : ListState
}

export interface ListState {
    selectedColumns?: ColumnConfig[];
}

export enum ListStateActionTypes {
    UPDATE_COLUMN_SETTINGS = '@@popup/UPDATE_COLUMN_SETTINGS',
}

export type UIStateActions = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface UIState {
    readonly listsSettings: ListSettingsMap;
}
