import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteUrl } from '../../routing/route-url';
import { AuthorizationContext, Membership, OrganizationRole } from '../../store/global';
import styles from './page-header-menu.module.scss';
import { HelpLinkUrl } from '../help-link/help-link-urls';

class PageHeaderMenu extends React.Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            containerRef: React.createRef<HTMLDivElement>(),
            isOpen: false,
        };
    }

    public render(): JSX.Element | null {
        const { authorizationContext, activeMembership, t } = this.props;
        const { isOpen, containerRef } = this.state;

        return authorizationContext && activeMembership ? (
            <div
                ref={containerRef}
                className={styles.container}
                tabIndex={0}
                onClick={() => this.toggle(isOpen)}
                onBlur={() => this.toggle(true)}>
                <div className={styles.summaryPanel}>
                    <span className={styles.email}>{`${authorizationContext.user.email}`}</span>
                    <span className={styles.workspace}>{`${activeMembership.workspace.name.toUpperCase()}`}</span>
                </div>
                {isOpen ? (
                    <div className={styles.menuContainer}>
                        <div className={styles.section}>
                            <div className={styles.email}>{`${authorizationContext.user.email}`}</div>
                            <div className={styles.workspace}>{`${activeMembership.workspace.name.toUpperCase()}`}</div>
                        </div>
                        {authorizationContext.memberships.length > 1 ||
                        authorizationContext.user.organizationRole === OrganizationRole.ADMIN ? (
                            <div className={styles.section}>
                                <>
                                    <div className={styles.sectionTitle}>Switch active workspaces</div>
                                    {authorizationContext.memberships.map((m: Membership) => {
                                        return (
                                            <div
                                                className={styles.workspaceLink}
                                                key={m.workspace.id}
                                                onClick={() => this.onSwitchWorkspace(m.workspace.id)}>
                                                <div className={styles.workspaceIndicator}>
                                                    {m.workspace.id === activeMembership?.workspace.id ? (
                                                        <FontAwesomeIcon icon={faCheck}/>
                                                    ) : null}
                                                </div>
                                                <div className={styles.workspaceText}>{m.workspace.name}</div>
                                            </div>
                                        );
                                    })}
                                </>

                                <div className={styles.link} onClick={() => this.onShowWorkspaceSelection()}>
                                    Manage your workspaces
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {authorizationContext.user.organizationRole === OrganizationRole.ADMIN ? (
                            <div className={styles.section}>
                                <div className={styles.link} onClick={() => this.navigate(RouteUrl.ManageUsers)}>
                                    Manage your organization
                                </div>
                            </div>
                        ) : null}
                        <div className={styles.section}>
                            <a className={styles.link} href={HelpLinkUrl.Faq} rel='noopener noreferrer' target='_blank'
                               onMouseDown={(event) => event.preventDefault()}>
                                {t('FAQ')}
                            </a>
                            <div className={styles.link} onClick={(event) => this.logOut(event)}>
                                {t('Sign out')}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        ) : null;
    }

    private toggle(isOpen: boolean): void {
        this.setState({ isOpen: !isOpen });
    }

    private forceBlur(): void {
        const { containerRef } = this.state;
        if (containerRef && containerRef.current) {
            containerRef!.current.blur();
        }
    }

    private navigate(route: RouteUrl): void {
        const { onNavigate } = this.props;
        onNavigate(route);
    }

    private onShowWorkspaceSelection(): void {
        const { onShowWorkspaceSelection } = this.props;
        onShowWorkspaceSelection();
        this.forceBlur();
    }

    private onSwitchWorkspace(workspaceId: string): void {
        const { onSwitchWorkspace } = this.props;
        onSwitchWorkspace(workspaceId);
        this.forceBlur();
    }

    private logOut(event: any): void {
        const { onLogout } = this.props;
        event.preventDefault();
        onLogout();
    }
}

export default withTranslation()(PageHeaderMenu);

interface OwnProps {
    onSwitchWorkspace: (workspaceId: string) => void;
    onShowWorkspaceSelection: () => void;
    onNavigate: (route: RouteUrl) => void;
    authorizationContext?: AuthorizationContext;
    activeMembership?: Membership;
    onLogout: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOpen: boolean;
    containerRef: RefObject<HTMLDivElement>;
}

type AllState = OwnState;
