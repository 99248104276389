import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';
import * as React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import {
Dataset,
getDatasetsForOrganizationRequest,
startDatasetProcessing
} from '../../../../store/datasets';
import { AuthorizationContext,OrganizationRole } from '../../../../store/global';
import { Popup,showPopup } from '../../../../store/popup';
import { RequestStatus } from '../../../../store/shared/types';
import { addToast } from '../../../../store/toast';
import { DateHelper } from '../../../../utils/date-helper';
import { AdminBasePage } from '../../admin-base.page';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getOrganizationAdminMenuConfiguration } from '../organization-admin-menu.configuration';
import DatasetPipelineStatus from './dataset-pipeline-status';
import styles from './manage-organization-datasets.module.scss';
import { PipelinePipeline } from '../../../../api/generated';

class ManageOrganizationDatasetsPage extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);
        const { authorizationContext, dispatchGetDatasetsForOrganizationRequest } = props;

        if (authorizationContext) {
            dispatchGetDatasetsForOrganizationRequest();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { authorizationContext, dispatchGetDatasetsForOrganizationRequest } = this.props;

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            dispatchGetDatasetsForOrganizationRequest();
        }
    }

    private isAdmin = () => {
        const { authorizationContext } = this.props;
        return authorizationContext?.user.organizationRole === OrganizationRole.ADMIN;
    };

    public render(): JSX.Element {
        const { datasets, requestStatus, t, datasetPipelinesMap } = this.props;
        return (
            <div>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div className={styles.adminContainer}>
                    <AdminMenu menuItems={getOrganizationAdminMenuConfiguration()} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Datasets')}</div>
                        </div>
                        {requestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin /> Loading...
                            </div>
                        ) : (
                            <div className={styles.table}>
                                <div className={`${styles.row} ${styles.header}`}>
                                    <div className={styles.cell} style={{ flexGrow: 2 }}>
                                        {t('Name')}
                                    </div>
                                    <div className={styles.cell}>{t('Owner')}</div>
                                    <div className={styles.cell}>{t('Created On')}</div>
                                    <div className={styles.cell}>{t('Last Updated On')}</div>
                                    {this.isAdmin() && <div className={styles.optionsCellSpacer} />}
                                </div>
                                <div className={styles.rows}>
                                    {datasets?.map((d) => {
                                        const dsPipeline = datasetPipelinesMap ? datasetPipelinesMap[d.id] : undefined;
                                        return (
                                            <div className={styles.row} key={`${d.name}${d.createdOn}`}>
                                                <div
                                                    className={styles.cell}
                                                    style={{
                                                        flexGrow: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',

                                                    }}>
                                                    <div>{d.name}</div>
                                                    <DatasetPipelineStatus datasetPipeline={dsPipeline} dataset={d} />
                                                </div>
                                                <div className={styles.cell}>{d.owner || t('-')}</div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(d.createdOn)}
                                                </div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(d.lastUpdatedOn)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div className={styles.upload}>
                            {t('To upload your own datasets, please contact')}
                            &nbsp;
                            <a
                                className={styles.link}
                                href='mailto:support@biostrand.be?subject=Custom%20dataset'
                                rel='noopener noreferrer'
                                target='_blank'>
                                support@biostrand.be
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchGetDatasetsForOrganizationRequest: () =>
        dispatch(getDatasetsForOrganizationRequest()),
    dispatchStartDatasetProcessing: (datasetId) => dispatch(startDatasetProcessing(datasetId)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    global,
    datasets,
}: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    currentLocation: router.location,
    datasets: datasets.datasetsForOrganization,
    requestStatus: datasets.getDatasetsForOrganizationRequestStatus,
    datasetPipelinesMap: datasets.datasetPipelinesMap,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageOrganizationDatasetsPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    currentLocation: Location;
    datasets?: Dataset[];
    requestStatus?: RequestStatus;
    datasetPipelinesMap?: { [datasetId: string]: PipelinePipeline };
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchGetDatasetsForOrganizationRequest: typeof getDatasetsForOrganizationRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchStartDatasetProcessing: typeof startDatasetProcessing;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedItem: Dataset | null;
    menuAnchor: any;
}

type AllState = OwnState;
