import { faBars, faChartBar, faColumns, faFileDownload, faTable } from '@fortawesome/pro-regular-svg-icons';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dimension, DimensionFilter } from '../../../../store/search';
import PageToolbar, { ToolbarActionId, ToolbarActionType } from '../../components/page-toolbar/page-toolbar';
import SelectionComponent, { SelectionItem } from '../../components/page-toolbar/selection/selection-component';
import { DimensionSelectionItemsCreator } from '../../utils/dimension-selection-items-creator';
import { ExplorerView } from './explorer.page';

class ExplorerToolbar extends Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);
        const { allDimensions } = this.props;
        const availableDimensionItems = allDimensions ? DimensionSelectionItemsCreator.create(allDimensions) : [];
        this.state = { availableDimensionItems };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { allDimensions } = this.props;

        if (allDimensions && allDimensions !== prevProps.allDimensions) {
            const availableDimensionItems = allDimensions ? DimensionSelectionItemsCreator.create(allDimensions) : [];
            this.setState({ availableDimensionItems });
        }
    }

    public render(): JSX.Element {
        const { t, selectedViewMode } = this.props;
        const { availableDimensionItems } = this.state;
        const { selectedFilters, selectedRowDimensions, selectedColumnDimensions } = this.props;
        const selectedRowItems = availableDimensionItems.filter((d) => selectedRowDimensions.includes(d.name));
        const selectedColumnItems = availableDimensionItems.filter((d) => selectedColumnDimensions.includes(d.name));

        return (
            <PageToolbar
                leftActions={[
                    {
                        uid: "ExplorerToggleTable",
                        actionId: ToolbarActionId.ExplorerToggleTable,
                        type: ToolbarActionType.toggle,
                        faIcon: faTable,
                        onClick: (): void => this.onSetViewMode(ExplorerView.table),
                        isSelected: selectedViewMode === ExplorerView.table,
                    },
                    {
                        uid: "ExplorerToggleChart",
                        actionId: ToolbarActionId.ExplorerToggleChart,
                        type: ToolbarActionType.toggle,
                        faIcon: faChartBar,
                        onClick: (): void => this.onSetViewMode(ExplorerView.chart),
                        isSelected: selectedViewMode === ExplorerView.chart,
                    },
                    {
                        uid: "ExplorerRowSelection",
                        actionId: ToolbarActionId.ExplorerRowSelection,
                        type: ToolbarActionType.component,
                        title: t('Rows'),
                        faIcon: faBars,
                        component: (
                            <SelectionComponent
                                items={availableDimensionItems}
                                selectedItems={selectedRowItems}
                                selectionChanged={(items): void => this.onRowDimensionSelectionChanged(items)}
                            />
                        ),
                    },
                    {
                        uid: "ExplorerColumnSelection",
                        actionId: ToolbarActionId.ExplorerColumnSelection,
                        type: ToolbarActionType.component,
                        title: t('Columns'),
                        faIcon: faColumns,
                        component: (
                            <SelectionComponent
                                items={availableDimensionItems}
                                selectedItems={selectedColumnItems}
                                selectionChanged={(items): void => this.onColumnDimensionSelectionChanged(items)}
                            />
                        ),
                    },
                ]}
                rightActions={[
                    {
                        uid: "ExplorerDownload",
                        actionId: ToolbarActionId.ExplorerDownload,
                        type: ToolbarActionType.button,
                        title: t('Download'),
                        faIcon: faFileDownload,
                        onClick: (): void => this.onDownloadCsv(),
                    },
                ]}
                filters={selectedFilters}
                onFiltersChanged={(changedFilters: DimensionFilter[]) => this.onFiltersChanged(changedFilters)}
            />
        );
    }

    private onRowDimensionSelectionChanged(items: SelectionItem[]): void {
        const { onRowDimensionSelectionChanged } = this.props;
        onRowDimensionSelectionChanged(items.map((i: SelectionItem) => i.name));
    }

    private onColumnDimensionSelectionChanged(items: SelectionItem[]): void {
        const { onColumnDimensionSelectionChanged } = this.props;
        onColumnDimensionSelectionChanged(items.map((i: SelectionItem) => i.name));
    }

    private onSetViewMode(viewMode: ExplorerView): void {
        const { onSetViewMode } = this.props;
        onSetViewMode(viewMode);
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        const { onFiltersChanged } = this.props;
        onFiltersChanged(filters);
    }

    private onDownloadCsv(): void {
        const { onDownloadCsv } = this.props;
        onDownloadCsv();
    }
}

interface OwnProps {
    selectedViewMode: ExplorerView;
    allDimensions: Dimension[];
    selectedRowDimensions: string[];
    selectedColumnDimensions: string[];
    selectedFilters: DimensionFilter[];
    onRowDimensionSelectionChanged: (dimensions: string[]) => void;
    onColumnDimensionSelectionChanged: (dimensions: string[]) => void;
    onFiltersChanged: (filters: DimensionFilter[]) => void;
    onDownloadCsv: () => void;
    onSetViewMode: (viewMode: ExplorerView) => void;
}

export default withTranslation()(ExplorerToolbar);

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    availableDimensionItems: SelectionItem[];
}

type AllState = OwnState;
