import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { PaymentMethod, SubscriptionAction, SubscriptionActionTypes, SubscriptionState } from './types';

export function initialState(): SubscriptionState {
    return {};
}

const reducer: Reducer<SubscriptionState, SubscriptionAction> = (state = initialState(), action): SubscriptionState => {
    switch (action.type) {
        case SubscriptionActionTypes.CREATE_SETUP_INTENT_REQUEST: {
            return {
                ...state,
                createSetupIntentRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.CREATE_SETUP_INTENT_SUCCESS: {
            return {
                ...state,
                createSetupIntentRequestStatus: new RequestStatus(RequestStatusType.Success),
                setupIntent: action.payload,
            };
        }
        case SubscriptionActionTypes.CREATE_SETUP_INTENT_FAILURE: {
            return {
                ...state,
                createSetupIntentRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.GET_PAYMENT_METHODS_REQUEST: {
            return {
                ...state,
                getPaymentMethodsRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.GET_PAYMENT_METHODS_SUCCESS: {
            return {
                ...state,
                getPaymentMethodsRequestStatus: new RequestStatus(RequestStatusType.Success),
                paymentMethods: action.payload,
            };
        }
        case SubscriptionActionTypes.GET_PAYMENT_METHODS_FAILURE: {
            return {
                ...state,
                getPaymentMethodsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_REQUEST: {
            return {
                ...state,
                setDefaultPaymentMethodRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                setDefaultPaymentMethodRequestStatus: new RequestStatus(RequestStatusType.Success),
                paymentMethods: action.payload,
            };
        }
        case SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_FAILURE: {
            return {
                ...state,
                setDefaultPaymentMethodRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.DELETE_PAYMENT_METHOD_REQUEST: {
            return {
                ...state,
                deletePaymentMethodRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.DELETE_PAYMENT_METHOD_SUCCESS: {
            const paymentMethodId = action.payload;
            const updatedArray: PaymentMethod[] = state.paymentMethods ? [...state.paymentMethods] : [];
            const index = updatedArray.findIndex((pm) => pm.id === paymentMethodId);
            if (index !== -1) updatedArray.splice(index, 1);

            return {
                ...state,
                deletePaymentMethodRequestStatus: new RequestStatus(RequestStatusType.Success),
                paymentMethods: updatedArray,
            };
        }
        case SubscriptionActionTypes.DELETE_PAYMENT_METHOD_FAILURE: {
            return {
                ...state,
                deletePaymentMethodRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.GET_CUSTOMER_REQUEST: {
            return {
                ...state,
                getCustomerRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.GET_CUSTOMER_SUCCESS: {
            return {
                ...state,
                getCustomerRequestStatus: new RequestStatus(RequestStatusType.Success),
                customer: action.payload,
            };
        }
        case SubscriptionActionTypes.GET_CUSTOMER_FAILURE: {
            return {
                ...state,
                getCustomerRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.UPDATE_CUSTOMER_REQUEST: {
            return {
                ...state,
                updateCustomerRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                updateCustomerRequestStatus: new RequestStatus(RequestStatusType.Success),
                customer: action.payload,
            };
        }
        case SubscriptionActionTypes.UPDATE_CUSTOMER_FAILURE: {
            return {
                ...state,
                updateCustomerRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.GET_SUBSCRIPTION_REQUEST: {
            return {
                ...state,
                getSubscriptionRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.GET_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                getSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Success),
                subscription: action.payload,
            };
        }
        case SubscriptionActionTypes.GET_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                getSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST: {
            return {
                ...state,
                cancelSubscriptionRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                cancelSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Success),
                subscription: action.payload,
            };
        }
        case SubscriptionActionTypes.CANCEL_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                cancelSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SubscriptionActionTypes.RENEW_SUBSCRIPTION_REQUEST: {
            return {
                ...state,
                renewSubscriptionRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case SubscriptionActionTypes.RENEW_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                renewSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Success),
                subscription: action.payload,
            };
        }
        case SubscriptionActionTypes.RENEW_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                renewSubscriptionRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return initialState();
        default:
            return state;
    }
};

export { reducer as subscriptionReducer };
export { initialState as initialSubscriptionState };
