export class CollectionUtil {
    public static groupBy<T, TKey extends keyof T>(collection: T[], key: TKey): Map<T[TKey], T[]> {
        return collection.reduce((result, item) => {
            if (result.has(item[key])) {
                result.get(item[key])!.push(item);
            } else {
                result.set(item[key], [item]);
            }
            return result;
        }, new Map<T[TKey], T[]>());
    }

    public static nestedGroupBy<T, TParentKey extends keyof T, TChildKey extends keyof T[TParentKey]>(
        collection: T[],
        parentKey: TParentKey,
        childKey: TChildKey,
    ): Map<T[TParentKey][TChildKey], T[]> {
        return collection.reduce((result, item) => {
            const nestedCollection = result.get(item[parentKey][childKey]);
            if (nestedCollection) {
                nestedCollection.push(item);
            } else {
                result.set(item[parentKey][childKey], [item]);
            }
            return result;
        }, new Map<T[TParentKey][TChildKey], T[]>());
    }

    public static onlyUnique<T>(value: T, index: number, collection: T[]): boolean {
        return collection.indexOf(value) === index;
    }

    public static removeIfExists<T>(array: T[], child: T): boolean {
        const index = array.indexOf(child);
        if (index !== -1) {
            array.splice(index, 1);
        }
        return index !== -1;
    }

    public static removeAllIfExists<T>(array: T[], children: T[]): void {
        children.forEach((c: T) => {
            CollectionUtil.removeIfExists(array, c);
        });
    }

    public static addIfNotExists<T>(array: T[], child: T): void {
        const index = array.indexOf(child);
        if (index === -1) {
            array.push(child);
        }
    }

    public static addAllIfNotExists<T>(array: T[], children: T[]): void {
        children.forEach((c) => {
            CollectionUtil.addIfNotExists(array, c);
        });
    }

    public static toggleElement<T>(array: T[], child: T): void {
        const existed = CollectionUtil.removeIfExists(array, child);
        if (!existed) {
            array.push(child);
        }
    }

    public static areEqual<T>(array1: T[], array2: T[]): boolean {
        if (array1 && array2 && array1.length === array2.length) {
            const diffs = array1.filter((el1, index) => {
                const el2 = array2[index];
                return el1 instanceof Array && el2 instanceof Array
                    ? !this.areEqual(el1, el2)
                    : array2.indexOf(el1) === -1;
            });
            return diffs.length === 0;
        } else {
            return false;
        }
    }

    public static areEqualByProperty<T>(array1: T[], array2: T[], property: string): boolean {
        return this.areEqualByProperties(array1, array2, [property]);
    }

    public static areEqualByProperties<T>(array1: T[], array2: T[], properties: string[]): boolean {
        if (array1 && array2 && array1.length === array2.length) {
            const diffs = array1.filter(
                (el1) =>
                    array2.findIndex((el2) => {
                        let equal = true;
                        properties.forEach((property: string) => {
                            if (equal) {
                                equal =
                                    el2[property] instanceof Array && el1[property] instanceof Array
                                        ? this.areEqual(el2[property], el1[property])
                                        : el2[property] === el1[property];
                            }
                        });
                        return equal;
                    }) === -1,
            );
            return diffs.length === 0;
        } else {
            return false;
        }
    }
}
