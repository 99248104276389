import { ActionType } from 'typesafe-actions';
import * as React from 'react';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';

export interface Popup {
    type: PopupType;
    content: JSX.Element | React.FunctionComponent;
    isDismissible: boolean;
}

export enum PopupType {
    WORKSPACE_SELECTION = 'WORKSPACE_SELECTION',
    CREATE_WORKSPACE = 'CREATE_WORKSPACE',
    ADD_NEW_DATA_SET = 'ADD_NEW_DATA_SET',
    INVITE_USER = 'INVITE_USER',
    SEQUENCE_DETAILS = 'SEQUENCE_DETAILS',
    UNAUTHORIZED = 'UNAUTHORIZED',
    COLUMN_CONFIGURATION = 'COLUMN_CONFIGURATION',
    ADD_MEMBER = 'ADD_MEMBER',
    CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
    DELETE_USER = 'DELETE_USER',
    QUERY_METADATA = 'QUERY_METADATA',
    LINK_DATASET = 'LINK_DATASET',
    SEARCH_SETTINGS = 'SEARCH_SETTINGS',

    MULTI_SEQUENCE_QUERY_SELECTION = 'MULTI_SEQUENCE_QUERY_SELECTION',
    DNA_SETTINGS_QUERY_SELECTION = 'DNA_SETTINGS_QUERY_SELECTION',
}

export enum PopupActionTypes {
    SHOW_POPUP = '@@popup/ShowPopup',
    CLOSE_POPUP = '@@popup/ClosePopup',
}

export type PopupAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface PopupState {
    readonly popup?: Popup;
}
