import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Popup } from '../../store/popup';
import { conditionalClassList } from '../../utils/class-helpers';
import styles from './popup-container.module.scss';

class PopupContainer extends React.Component<AllProps> {
    public render(): JSX.Element | null {
        const { popup } = this.props;

        if (popup) {
            const popupContainerClasses = conditionalClassList(styles, {
                popupContainer: true,
            });

            return (
                <div className={styles.container}>
                    <div className={styles.overlay} onClick={(): void => this.onClose(popup.isDismissible)} />
                    <div className={popupContainerClasses}>
                        {popup.isDismissible ? (
                            <FontAwesomeIcon
                                className={styles.close}
                                icon={faTimes}
                                size='lg'
                                onClick={(): void => this.onClose(popup.isDismissible)}
                            />
                        ) : null}
                        {popup.content}
                    </div>
                </div>
            );
        }
        return null;
    }

    public onClose(isDismissible: boolean): void {
        const { close } = this.props;

        if (isDismissible) {
            close();
        }
    }
}

export default PopupContainer;

interface OwnProps {
    popup?: Popup;
    close: () => void;
}

type AllProps = OwnProps;
