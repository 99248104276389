import React, { ReactElement, useCallback } from 'react';
import styles from '../../../pages/results/views/alignment-view/alignment-viewer.module.scss';
import { LabelRowProps } from '../../../pages/results/views/alignment-view/components/alignment-view-component-types';


const SelectSequenceLabelRow = (props: LabelRowProps): ReactElement => {
    const {row, onSelect, selected } = props;

//    const dispatch = useDispatch();

    const clickHandler = useCallback(() => {
        if (!onSelect) return;

        if (selected) {
            onSelect(null);
        } else {
            onSelect(row.id as string);
        }
    }, [onSelect, row.id, selected]);

    const title = row.name ? `${row.name}\n\nID: ${row.id}` : `ID: ${row.id}`;

    return (
        <div className={selected ? styles.labelRowSelected : styles.labelRow} onClick={clickHandler}
               title={title}>
            {row.label}
        </div>
    );
};

export default SelectSequenceLabelRow;
