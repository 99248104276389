import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../../../store';
import { Dataset, getDatasetsForOrganizationRequest } from '../../../../../../store/datasets';
import { AuthorizationContext, WorkspaceRole } from '../../../../../../store/global';
import { closePopup } from '../../../../../../store/popup';
import { RequestStatus } from '../../../../../../store/shared/types';
import { addToast, ToastType } from '../../../../../../store/toast';
import {
    createWorkspaceRequest,
    CreateWorkspaceRequestParameters,
    WorkspaceInfo,
} from '../../../../../../store/workspaces';
import { AdminBasePage } from '../../../../admin-base.page';
import DatasetSelection from '../../../../components/dataset-selection/dataset-selection';
import styles from './create-workspace-popup.module.scss';
import { activeDatasetSelector } from '../../../../../../store/datasets/datasets-selectors';

class CreateWorkspacePopup extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        const { authorizationContext, dispatchGetDatasetsForOrganizationRequest } = props;
        super(props);

        this.state = {
            name: '',
            showFeedback: false,
            selectedDatasetIds: [],
        };

        if (authorizationContext) {
            dispatchGetDatasetsForOrganizationRequest();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { createWorkspaceRequestStatus, dispatchClosePopup, t } = this.props;

        if (createWorkspaceRequestStatus && prevProps.createWorkspaceRequestStatus !== createWorkspaceRequestStatus) {
            if (createWorkspaceRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Workspace Successfully Created'));
                dispatchClosePopup();
            }

            if (createWorkspaceRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(createWorkspaceRequestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const {
            t,
            createWorkspaceRequestStatus,
            getDatasetsForOrganizationRequestStatus,
            organizationDatasets,
        } = this.props;
        const { showFeedback, feedbackMessage, selectedDatasetIds } = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>{t('Create Workspace')}</div>
                        <div className={styles.form} onKeyDown={(e): void => this.onKeyPressed(e)}>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Name')}</div>
                                <input
                                    className={styles.nameInput}
                                    type='text'
                                    key='NameField'
                                    autoFocus
                                    onChange={(e): void => this.onNameChange(e)}
                                />
                            </div>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Datasets')}</div>
                                <DatasetSelection
                                    availableDatasets={organizationDatasets || []}
                                    selectedDatasetIds={selectedDatasetIds}
                                    maxHeightOffset={530}
                                    onSelectionChange={(ids): void => this.updateSelectedDatasetIds(ids)}
                                />
                            </div>
                        </div>
                        <div className={styles.feedbackMessage}>{showFeedback ? feedbackMessage : ''}</div>
                        <div className={styles.buttons}>
                            <button
                                disabled={
                                    createWorkspaceRequestStatus?.isInProgress ||
                                    getDatasetsForOrganizationRequestStatus?.isInProgress ||
                                    selectedDatasetIds.length === 0
                                }
                                className={`${styles.button} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.onCreateWorkspace()}>
                                {createWorkspaceRequestStatus?.isInProgress ||
                                getDatasetsForOrganizationRequestStatus?.isInProgress ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                    t('Create Workspace')
                                )}
                            </button>
                            <button
                                disabled={
                                    createWorkspaceRequestStatus?.isInProgress ||
                                    getDatasetsForOrganizationRequestStatus?.isInProgress
                                }
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.onClose()}>
                                {t('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            default:
                return t('Workspace creation failed, please contact your administrator if this error persists.');
        }
    }

    private onKeyPressed(e): void {
        const { createWorkspaceRequestStatus } = this.props;

        if (!createWorkspaceRequestStatus?.isInProgress && e.keyCode === 13) {
            this.onCreateWorkspace();
        }
    }

    private onNameChange(e): void {
        this.setState({ name: e.target.value });
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private onCreateWorkspace(): void {
        const { dispatchCreateWorkspaceRequest, authorizationContext } = this.props;
        const { name, selectedDatasetIds } = this.state;

        if (authorizationContext && this.validateForm()) {
            dispatchCreateWorkspaceRequest({
                name,
                organizationId: authorizationContext.organization.id,
                memberships: [
                    {
                        email: authorizationContext.user.email,
                        workspaceRole: WorkspaceRole.ADMIN,
                    },
                ],
                datasetIds: selectedDatasetIds,
            });
        }
    }

    private validateForm(): boolean {
        const { name } = this.state;

        if (!name || name.length < 3) {
            this.setState({
                showFeedback: true,
                feedbackMessage: 'Name field is required and should be at least three characters',
            });
            return false;
        }

        return true;
    }

    private updateSelectedDatasetIds(ids: string[]): void {
        this.setState({ selectedDatasetIds: ids });
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    const { workspaces, global, datasets } = state;

   return  {
        authorizationContext: global.authorizationContext,
            createWorkspaceRequestStatus: workspaces.createWorkspaceRequestStatus,
        workspaces: workspaces.workspacesForOrganization,
        getDatasetsForOrganizationRequestStatus: datasets.getDatasetsForOrganizationRequestStatus,
        organizationDatasets: activeDatasetSelector(state),
    }
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchCreateWorkspaceRequest: (parameters: CreateWorkspaceRequestParameters) =>
        dispatch(createWorkspaceRequest(parameters)),
    dispatchGetDatasetsForOrganizationRequest: () =>
        dispatch(getDatasetsForOrganizationRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateWorkspacePopup));

interface OwnProps {}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    workspaces?: WorkspaceInfo[];
    organizationDatasets?: Dataset[];
    createWorkspaceRequestStatus?: RequestStatus;
    getDatasetsForOrganizationRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchCreateWorkspaceRequest: typeof createWorkspaceRequest;
    dispatchGetDatasetsForOrganizationRequest: typeof getDatasetsForOrganizationRequest;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    name: string;
    showFeedback: boolean;
    feedbackMessage?: string;
    selectedDatasetIds: string[];
}

type AllState = OwnState;
