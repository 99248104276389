import { RuntimeConfig } from '../config/runtime-config';

export enum ApiUrl {
    CurrentUser = '/user',
    Search = '/parser/sequence',
    SequencesQuery = '/sequences/query',

    EnrichQuery = '/sequences/query/enrich',

    GetSequences = '/sequences/query/{queryId}/sequences',
    DimensionAggregates = '/sequences/query/{queryId}/aggregates',
    GetFastas = '/sequences/query/{queryId}/fastas',
    Sequence = '/sequences/sequence/{sequenceId}',
    GetDimensionsForQuery = '/sequences/query/{queryId}/dimensions',
    GetTaxonomyForQuery = '/sequences/query/{queryId}/taxonomy',
    UsersForOrganization = '/workspace/organizations/{organizationId}/users',
    InviteUserToOrganization = '/workspace/organizations/{organizationId}/users/invite',
    GetWorkspacesForOrganization = '/workspace/organizations/{organizationId}/workspaces',
    GetAuthorizationContext = '/workspace/users/authorization',
    CreateOrganization = '/workspace/organizations/create',
    UpdateUser = '/workspace/organizations/{organizationId}/users/{email}/update',
    DeleteUser = '/workspace/organizations/{organizationId}/users/{email}/delete',
    CreateWorkspaceForOrganization = '/workspace/organizations/{organizationId}/workspaces/create',
    UpdateMembership = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/update',
    GetMembershipsForWorkspace = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships',
    UpdateDefaultWorkspaceForUser = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/default',
    DeleteWorkspace = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/delete',
    CreateMembership = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/create',
    DeleteMembership = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/delete',
    CreateSetupIntent = '/workspace/organizations/{organizationId}/subscription/setup',
    GetPaymentMethods = '/workspace/organizations/{organizationId}/subscription/payment-methods',
    SetDefaultPaymentMethod = '/workspace/organizations/{organizationId}/subscription/payment-methods/default',
    DeletePaymentMethod = '/workspace/organizations/{organizationId}/subscription/payment-methods/{paymentMethodId}/delete',
    GetCustomer = '/workspace/organizations/{organizationId}/subscription/customer',
    UpdateCustomer = '/workspace/organizations/{organizationId}/subscription/customer/update',
    GetSubscription = '/workspace/organizations/{organizationId}/subscription',
    CancelSubscription = '/workspace/organizations/{organizationId}/subscription/cancel',
    RenewSubscription = '/workspace/organizations/{organizationId}/subscription/renew',
    GetDatasetsForOrganization = '/workspace/organizations/datasets',
    GetDatasetsForWorkspace = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets',
    LinkDatasetsToWorkspace = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets/link',
    UnlinkDatasetsFromWorkspace = '/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets/unlink',
    CreateAPIToken = '/workspace/token',
    GetDatasetPipeline = '/pipeline/pipelines/{datasetId}',
    RunDatasetPipeline = '/pipeline/pipelines/{datasetId}/run',
}

export enum ApiType {
    Gateway,
    Iris,
}

const irisApiVersion = 'v1';

export function buildBaseUrl(apiType: ApiType): string {
    const apiUrl = (): string => {
        switch (apiType) {
            case ApiType.Gateway:
                return RuntimeConfig.getGatewayApiUrl();
            case ApiType.Iris:
                return `${RuntimeConfig.getIrisApiUrl()}/${irisApiVersion}`;
            default:
                return RuntimeConfig.getGatewayApiUrl();
        }
    };
    return apiUrl();
}
