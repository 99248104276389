import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import styles from './admin-page-header.module.scss';
import PageHeaderBase from '../page-header-base';

class AdminPageHeader extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { t } = this.props;

        return (
           <PageHeaderBase>
               <div className={styles.bottomContainer}>
                    <button
                        className={`${styles.button} ${styles.tertiary}`}
                        onClick={(): void => this.onNavigate(RouteUrl.Search)}
                        type='button'>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 10 }} />
                        {t('Back')}
                    </button>
               </div>
            </PageHeaderBase>
        );
    }

    private onNavigate(route: RouteUrl): void {
        const { dispatchNavigateTo, activeWorkspaceId } = this.props;
        dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${route}` });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ global }: ApplicationState) => ({
    activeWorkspaceId: global.activeWorkspaceId,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminPageHeader));

interface OwnProps {
    location: Location;
}

interface PropsFromState {
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps & WithTranslation;
