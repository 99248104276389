import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import logoIcon from '../../../assets/icons/biostrand_logo.png';
import logoText from '../../../assets/icons/biostrand_text.png';
import WorkspaceSelectionPopup from '../../pages/admin/components/workspace-selection-popup/workspace-selection-popup';
import { RouteUrl } from '../../routing/route-url';
import { ApplicationState } from '../../store';
import { logoutRequest } from '../../store/authentication';
import { AuthorizationContext } from '../../store/global';
import { Popup, PopupType, showPopup } from '../../store/popup';
import PageHeaderMenu from '../page-header-menu/page-header-menu';
import styles from './page-header-base.module.scss';

class PageHeaderBase extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { authorizationContext, activeWorkspaceId, children } = this.props;
        const activeMembership = authorizationContext?.memberships.find((m) => m.workspace.id === activeWorkspaceId);

        return (
            <div className={styles.headerContainer}>
                <div className={styles.topContainer}>
                    <div className={styles.logoContainer}>
                        <img src={logoIcon} className={styles.logoIcon} alt='' />
                        <img src={logoText} className={styles.logoText} alt='' />
                    </div>
                    <PageHeaderMenu
                        onShowWorkspaceSelection={() => this.onShowWorkspaceSelectionPopup()}
                        activeMembership={activeMembership}
                        authorizationContext={authorizationContext}
                        onLogout={(): void => this.onLogout()}
                        onSwitchWorkspace={(workspaceId) => this.onSwitchWorkspace(workspaceId)}
                        onNavigate={(route) => this.onNavigate(route)}
                    />
                </div>

                    {children}
            </div>
        );
    }

    private onLogout(): void {
        const { dispatchLogout } = this.props;
        dispatchLogout();
    }

    private onSwitchWorkspace(workspaceId: string): void {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: `/${workspaceId}${RouteUrl.Search}` });
    }

    private onNavigate(route: RouteUrl): void {
        const { dispatchNavigateTo, activeWorkspaceId } = this.props;
        dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${route}` });
    }

    private onShowWorkspaceSelectionPopup(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.WORKSPACE_SELECTION,
            content: <WorkspaceSelectionPopup />,
            isDismissible: false,
        });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ global }: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    activeWorkspaceId: global.activeWorkspaceId,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogout: () => dispatch(logoutRequest()),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PageHeaderBase));

interface OwnProps {
    location: Location;
}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchLogout: typeof logoutRequest;
    dispatchShowPopup: typeof showPopup;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps & WithTranslation;
