import { faFileDownload } from '@fortawesome/pro-regular-svg-icons';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DimensionFilter } from '../../../../store/search';
import PageToolbar, { ToolbarActionId, ToolbarActionType } from '../../components/page-toolbar/page-toolbar';
import { SharedMolType } from '../../../../api/generated';

class AlignmentToolbar extends Component<AllProps, AllState> {
    private onFiltersChanged(filters: DimensionFilter[]): void {
        const { onFiltersChanged } = this.props;
        onFiltersChanged(filters);
    }

    private onDownloadCsv(): void {
        const { onDownloadCsv } = this.props;
        onDownloadCsv();
    }

    private onAASelected(): void {
        const { onUseAAMolType } = this.props;
        onUseAAMolType();
    }

    private onDNASelected(): void {
        const { onUseDNAMolType } = this.props;
        onUseDNAMolType();
    }

    public render(): JSX.Element {
        const { selectedFilters, t, availableMolTypes, currentMolType, originalMolType } = this.props;

        return (
            <PageToolbar
                leftActions={ [
                    {
                        uid: SharedMolType.AA,
                        title: t('AA'),
                        isSelected: currentMolType === SharedMolType.AA,
                        onClick: (): void => this.onAASelected(),
                        type: ToolbarActionType.toggle,

                    },
                    {
                        uid: SharedMolType.DNA,
                        title: originalMolType === SharedMolType.AA ? t('DNA / MRNA, translated') : t('DNA / MRNA'),
                        isSelected: currentMolType === SharedMolType.DNA,
                        onClick: (): void => this.onDNASelected(),
                        type: ToolbarActionType.toggle,
                    }
                ].filter( ( a ) => availableMolTypes.indexOf(a.uid) > -1) }

                rightActions={[
                    {
                        uid: "AlignmentViewDownload",
                        actionId: ToolbarActionId.AlignmentViewDownload,
                        title: t('Download'),
                        faIcon: faFileDownload,
                        onClick: (): void => this.onDownloadCsv(),
                    },
                ]}
                filters={selectedFilters}
                onFiltersChanged={(changedFilters: DimensionFilter[]) => this.onFiltersChanged(changedFilters)}
            />
        );
    }
}

interface OwnProps {
    selectedFilters: DimensionFilter[];
    onUseAAMolType: () => void;
    onUseDNAMolType: () => void;
    onFiltersChanged: (filters: DimensionFilter[]) => void;
    onDownloadCsv: () => void;
    availableMolTypes: string[];
    currentMolType?: string;
    originalMolType?: string;
}

export default withTranslation()(AlignmentToolbar);

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
