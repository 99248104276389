import { ResultViewInputValidator, ValidationResult } from '../../result-view-input-validator';
import { ExplorerResultViewUrlParameters } from './explorer-url-parameters-handler';

export class ExplorerInputValidator implements ResultViewInputValidator<ExplorerResultViewUrlParameters> {
    validate(urlParameters: ExplorerResultViewUrlParameters): ValidationResult {
        if (urlParameters.activeRowSelection.length === 0) {
            return { isValid: false, reason: 'Oops, you need to select at least one row dimension.' };
        } else if (urlParameters.activeColumnSelection.length === 0) {
            return { isValid: false, reason: 'Oops, you have to select at least one column dimension.' };
        } else if (urlParameters.activeRowSelection.length > 3) {
            return { isValid: false, reason: 'Oops, you can not select more than three row dimensions.' };
        } else if (urlParameters.activeColumnSelection.length > 3) {
            return { isValid: false, reason: 'Oops, you can not select more than three column dimensions.' };
        } else {
            return { isValid: true };
        }
    }
}
