import { Location } from 'history';
import { DimensionFilter } from '../../store/search';
import { QueryParameter, QueryParameterHelper, QueryParameterMap } from '../../utils/query-parameter-helpers';
import { ResultViewUrlParameters } from './result-view-url-parameters';

export abstract class UrlParametersHandler<TUrlParameters extends ResultViewUrlParameters> {
    public abstract read(currentLocation: Location): TUrlParameters;

    protected doRead(currentLocation: Location): ResultViewUrlParameters {
        const queryParameters = new URLSearchParams(currentLocation.search);

        const rootQuery = QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.RootQuery) || '';
        const activeFilters =
            QueryParameterHelper.getEncodedObjectFromUrl<DimensionFilter[]>(
                queryParameters,
                QueryParameter.GlobalFilters,
            ) || [];
        return { rootQuery, activeFilters };
    }

    public abstract write(newUrlState: TUrlParameters): QueryParameterMap;

    protected doWrite(newUrlState: ResultViewUrlParameters): QueryParameterMap {
        const encodedFilters = btoa(unescape(encodeURIComponent(JSON.stringify(newUrlState.activeFilters))));
        const parameters = {};
        parameters[QueryParameter.RootQuery] = newUrlState.rootQuery;
        parameters[QueryParameter.GlobalFilters] = encodedFilters;
        return parameters;
    }
}
