import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { conditionalClassList } from '../../utils/class-helpers';
import styles from './dropdown.module.scss';

class Dropdown extends React.Component<AllProps, AllState> {
    private disableCloseOnBlur = false;

    constructor(props) {
        super(props);

        this.state = {
            isOptionsOpen: false,
        };
    }

    public render(): JSX.Element {
        const { options, noItemSelectedLabel, selection } = this.props;
        const { isOptionsOpen } = this.state;

        const selectionPanelClasses = conditionalClassList(styles, {
            selectionPanel: true,
            isOpen: isOptionsOpen,
        });

        const optionsPanelClasses = conditionalClassList(styles, {
            optionsPanel: true,
            isOpen: isOptionsOpen,
        });

        return (
            <div className={styles.dropdownPanel}>
                <div
                    className={selectionPanelClasses}
                    tabIndex={0}
                    onClick={(): void => this.toggleOptionsOpen(!isOptionsOpen)}
                    onBlur={(): void => this.toggleOptionsOpen(false)}>
                    <div className={styles.selectionText}>{selection ? selection.label : noItemSelectedLabel}</div>
                    <div className={styles.selectionIcon}>
                        {isOptionsOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                    </div>
                </div>
                <div className={optionsPanelClasses}>
                    {options.map((option) => (
                        <div
                            className={styles.optionItem}
                            key={option.key ? option.key : option.id}
                            onMouseDown={(): void => this.onDisableCloseOnBlur(true)}
                            onMouseUp={(): void => this.onDisableCloseOnBlur(false)}
                            onClick={(): void => this.select(option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private select(option: DropdownOption): void {
        const { onSelectionChanged } = this.props;
        this.setState({ isOptionsOpen: false });
        onSelectionChanged(option);
    }

    private toggleOptionsOpen(open: boolean): void {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({ isOptionsOpen: open });
        }
    }

    private onDisableCloseOnBlur(disableOnBlur: boolean): void {
        this.disableCloseOnBlur = disableOnBlur;
    }
}

export default withTranslation()(Dropdown);

interface OwnProps {
    options: DropdownOption[];
    onSelectionChanged: (selection: DropdownOption) => void;
    noItemSelectedLabel?: string;
    selection?: DropdownOption;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOptionsOpen: boolean;
}

type AllState = OwnState;

export interface DropdownOption {
    id: string;
    label: string;
    key?: string;
}
