import maxBy from 'lodash.maxby';
import trimStart from 'lodash.trimstart';
import {
    SharedMolType,
    ApiGatewaySearchFastasReference,
    AlignAlignedSequence,
    AlignAlignResponse,
} from '../../../../../api/generated';
import { AlignmentViewRenderData, Highlight, RowRenderData } from '../alignment-view-types';

const aminoAcidsWithoutDNALetters: string[] = 'BDEFHIJKLMNOPQRSVWXYZ'.split('');

export function getSequenceType(sequence: string): SharedMolType.DNA | SharedMolType.AA {
    for (let i = 0; i < sequence.length; i++) {
        if (aminoAcidsWithoutDNALetters.indexOf(sequence[i]) !== -1) {
            return SharedMolType.AA;
        }
    }

    return SharedMolType.DNA;
}

const generateFastaMapper = (targetMolType: SharedMolType.AA | SharedMolType.DNA) => (
    fasta: AlignAlignedSequence,
): RowRenderData => {
    const sequence: string = trimStart(fasta.sequence).toUpperCase();
    return {
        offset: fasta.offset ? fasta.offset : 0,
        sequence,
        type: targetMolType,
        residueValues: fasta.residueValues,
        label: fasta.description ? fasta.description : fasta.sequenceId,
        name: fasta.description,
        id: fasta.sequenceId,
    };
};

export function getReferenceTargetSequence(
    reference: ApiGatewaySearchFastasReference,
    targetMolType: SharedMolType.AA | SharedMolType.DNA = SharedMolType.AA,
): string | undefined {
    return targetMolType === SharedMolType.AA && reference.molType !== SharedMolType.AA
        ? reference.translatedSequence
        : reference.sequence;
}

function prepareSearchPatterns(
    references: AlignAlignedSequence[] = [],
    targetMolType: SharedMolType.AA | SharedMolType.DNA,
): RowRenderData {
    if (!references.length) {
        return {
            offset: 0,
            sequence: '',
            type: SharedMolType.AA,
            label: '',
            name: '',
            id: '',
        };
    }

    const fasta = references[0];
    const sequence: string = getReferenceTargetSequence(fasta, targetMolType) as string;

    return {
        offset: fasta.offset || 0,
        sequence: sequence ? sequence.toUpperCase() : '',
        type: targetMolType,
        residueValues: fasta.residueValues,
        label: fasta.description ? fasta.description : fasta.sequenceId,
        name: fasta.description,
        id: fasta.sequenceId,
    };
}

export function prepareAlignmentViewRenderData(
    alignmentResultViewData: AlignAlignResponse | null,
    targetMolType: SharedMolType.AA | SharedMolType.DNA,
): AlignmentViewRenderData | null {
    if (!alignmentResultViewData) return null;

    const longestFasta = maxBy(
        alignmentResultViewData?.sequences,
        (fasta: AlignAlignedSequence) => (fasta.sequence?.length || 0) + (fasta.offset || 0),
    );
    const maxSeq =
        longestFasta && longestFasta.sequence ? (longestFasta.sequence?.length || 0) + (longestFasta.offset || 0) : 0;
    const longestRefs = maxBy(alignmentResultViewData?.references, (fasta: ApiGatewaySearchFastasReference) => {
        return (getReferenceTargetSequence(fasta, targetMolType)?.length || 0) + (fasta.offset || 0);
    });

    const maxRef = longestRefs
        ? (getReferenceTargetSequence(longestRefs, targetMolType)?.length || 0) + (longestRefs.offset || 0)
        : 0;
    const maxLength = Math.max(maxSeq, maxRef);

    const mapFastaToRenderData = generateFastaMapper(targetMolType);

    const fastas = alignmentResultViewData.sequences;
    const sequenceRows = fastas?.map(mapFastaToRenderData);

    const searchPatternRow = prepareSearchPatterns(alignmentResultViewData.references, targetMolType);

    searchPatternRow.maxLength = maxLength;
    return {
        maxLength,
        sequenceRows: sequenceRows || [],
        searchOffset: searchPatternRow.offset,
        searchPattern: searchPatternRow,
    };
}

const ALLOWED_PATTERN_SYMBOL_MAP = (() => {
    const sym = {};
    'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM*?'
        .split('')
        .forEach((s) => {sym[s] = true});
    return sym;
})();


export const excludeRegex = (regexString) => `^((?!${regexString}).)*$`;

export const patternToRegexString = (pattern: string): string => {
    const cleaned = pattern
        .split('')
        .filter((s) => ALLOWED_PATTERN_SYMBOL_MAP[s])
        .join('')
        .split('?')
        .join('.')
        .split('*')
        .join('.*?')
        .toUpperCase();
    return cleaned;
}

export const highlightPattern2Regexp = (pattern: string, exclude): RegExp | null => {
    const highlight = exclude ?  excludeRegex(patternToRegexString(pattern)) : patternToRegexString(pattern);
    try {
        return new RegExp(highlight, 'ig');
    } catch (e) {
        return null;
    }
};

export const testContains = (sequence: string, pattern: string):boolean => {
    try {
        const regexp = new RegExp(patternToRegexString(pattern), 'ig');
        const m = sequence.match( regexp );
        return !!m;
    } catch (e) {
        return false;
    }
}

export const getHighlightIndices = (str: string, highlight?: Highlight) => {
    const hi: { start: number; end: number }[] = [];
    if (highlight && highlight.pattern) {
        const { pattern, exclude } = highlight;
        const regexp = highlightPattern2Regexp(pattern || '', exclude);
        if (!regexp) {
            return hi;
        }

        const matches = str.matchAll(regexp);

        // eslint-disable-next-line no-restricted-syntax
        for (const match of matches) {
            if (match) {
                const index: number = match.index || 0;
                hi.push({
                    start: index,
                    end: index + match[0].length,
                });
            }
        }
    }
    return hi;
};

export const isHighlighted = (index: number, test: { start: number; end: number }[]) => {
    return test.find((item) => index >= item.start && index < item.end);
};
