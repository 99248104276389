import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './actions-menu.module.scss';

export enum ActionType {
    regular,
    negative,
    positive,
}

class ActionsMenu extends React.Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public render(): JSX.Element | null {
        const { isOpen } = this.state;
        const { actions } = this.props;

        return (
            <>
                {actions && actions.length > 0 ? (
                    <div
                        className={styles.container}
                        tabIndex={0}
                        onClick={() => this.toggle(isOpen)}
                        onBlur={() => this.toggle(true)}>
                        <div className={styles.buttonPanel}>
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                        {isOpen ? (
                            <div className={styles.menuContainer}>
                                {actions
                                    ? actions.map((a) => {
                                          const itemClasses = conditionalClassList(styles, {
                                              menuItem: true,
                                              negative: a.type === ActionType.negative,
                                              positive: a.type === ActionType.positive,
                                          });
                                          return (
                                              <div className={itemClasses} onClick={() => a.onClick()} key={a.label}>
                                                  {a.label}
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }

    private toggle(isOpen: boolean): void {
        this.setState({ isOpen: !isOpen });
    }
}

export default withTranslation()(ActionsMenu);

interface OwnProps {
    actions: ActionItem[];
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOpen: boolean;
}

type AllState = OwnState;

export interface ActionItem {
    label: string;
    onClick: () => void;
    type?: ActionType;
}
