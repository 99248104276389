import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import React, { ReactElement, useMemo, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { ApiGatewayEnrichQueryResponse } from '../../../api/generated';
import {
    AlignmentViewRenderData,
} from '../../../pages/results/views/alignment-view/alignment-view-types';
import { closePopup } from '../../../store/popup';
import { prepareAlignmentViewRenderData } from './prepare-alignment-render-data';
import AlignmentViewer from '../../../pages/results/views/alignment-view/alignment-viewer';
import SelectSequenceLabelRow from './select-sequence-label-row';
import { sequenceSelectedAction } from './select-sequence-popup-actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonDistance: {
            marginRight: theme.spacing(2),
        },
        viewerContainer: {
            userSelect: 'none',
            display: 'flex',
            maxHeight: 500,
            justifyContent: 'flex-start',
        }
    }),
);

type Props = WithTranslation & {
    enrichData: ApiGatewayEnrichQueryResponse,
};

function SelectSequencePopup(props: Props): ReactElement {
    const { t, enrichData } = props;
    const classes = useStyles();
    const [subselection, setSubselection] = useState<string | null>(null);
    const [selectedSequence, setSelectedSequence] = useState<string | null>(null);

    const dispatch = useDispatch();

    const renderData: AlignmentViewRenderData | null = useMemo(() => prepareAlignmentViewRenderData(enrichData), [enrichData]);

    if (!renderData) {
        return <div className='alignmentContainer'>ups</div>;
    }

    const closeWindow = () => {
        dispatch(closePopup());
    };

    return (
        <Box bgcolor='#ffffff' borderRadius={12} display='flex' flexDirection='column' maxWidth={1200}
             position='relative' pl={4} pr={4} pt={2}>
            <Typography variant='h5' style={{ flex: 1, color: '#000', marginBottom: 24 }}>
                {t('Select sequence to search')}
            </Typography>
            <div style={{ flex: 1, color: '#000', marginBottom: 16 }}>
                {t('The query contains few sequences, please choose one sequence or sequence part to search.')}
            </div>

            <div className={classes.viewerContainer}>
                <AlignmentViewer
                    renderData={renderData}
                    LabelRow={SelectSequenceLabelRow}
                    onSubsequenceSelected={setSubselection}
                    onSequenceSelected={setSelectedSequence}
                    showMenu />
            </div>
            <Box mb={3} mt={4} display='flex' flexDirection='row' justifyContent='center'>
                <Button
                    onClick={() => {
                        dispatch(sequenceSelectedAction(subselection as string))
                        closeWindow();
                    }}
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={!subselection}
                    className={classes.buttonDistance}
                >
                    {t('Search by selection')}
                </Button>

                <Button
                    onClick={() => {
                        dispatch(sequenceSelectedAction(selectedSequence as string))
                        closeWindow();
                    }}
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={!selectedSequence}
                    className={classes.buttonDistance}
                >
                    {t('Search by sequence')}
                </Button>

                <Button onClick={closeWindow} variant='outlined' color='primary'>
                    {t('Cancel')}
                </Button>
            </Box>
        </Box>
    );
}

export default withTranslation()(SelectSequencePopup);
