import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { AuthorizationContext } from '../../../../store/global';
import { Popup, PopupType, showPopup } from '../../../../store/popup';
import { FailureCodes, RequestStatus } from '../../../../store/shared/types';
import { addToast, ToastType } from '../../../../store/toast';
import {
    deleteWorkspaceRequest,
    DeleteWorkspaceRequestParameters,
    getWorkspacesForOrganizationRequest,
    WorkspaceInfo,
} from '../../../../store/workspaces';
import { DateHelper } from '../../../../utils/date-helper';
import { AdminBasePage } from '../../admin-base.page';
import ActionsMenu, { ActionType } from '../../components/actions-menu/actions-menu';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getOrganizationAdminMenuConfiguration } from '../organization-admin-menu.configuration';
import CreateWorkspacePopup from './components/create-workspace-popup/create-workspace-popup';
import styles from './manage-workspaces.module.scss';

class ManageWorkspacesPage extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        const { dispatchGetWorkspacesForOrganization, authorizationContext } = props;
        super(props);

        if (authorizationContext) {
            dispatchGetWorkspacesForOrganization(authorizationContext.organization.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {
            authorizationContext,
            deleteWorkspaceRequestStatus,
            dispatchGetWorkspacesForOrganization,
            t,
        } = this.props;

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            dispatchGetWorkspacesForOrganization(authorizationContext.organization.id);
        }

        if (deleteWorkspaceRequestStatus && prevProps.deleteWorkspaceRequestStatus !== deleteWorkspaceRequestStatus) {
            if (deleteWorkspaceRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Delete Workspace Successful'));
            }
            if (deleteWorkspaceRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(deleteWorkspaceRequestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const { workspaces, requestStatus, t } = this.props;

        return (
            <div>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div className={styles.adminContainer}>
                    <AdminMenu menuItems={getOrganizationAdminMenuConfiguration()} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Workspaces')}</div>
                            {requestStatus?.isInProgress ? null : (
                                <button
                                    className={styles.button}
                                    type='button'
                                    onClick={() => this.showCreateWorkspacePopup()}>
                                    {t('Create Workspace')}
                                </button>
                            )}
                        </div>
                        {requestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin /> Loading...
                            </div>
                        ) : (
                            <div className={styles.table}>
                                <div className={`${styles.row} ${styles.header}`}>
                                    <div className={styles.cell} style={{ flexGrow: 2 }}>
                                        {t('Name')}
                                    </div>
                                    <div className={styles.cell}>{t('Members')}</div>
                                    <div className={styles.cell}>{t('Datasets')}</div>
                                    <div className={styles.cell}>{t('Created On')}</div>
                                    <div className={styles.optionsCellSpacer} />
                                </div>
                                <div className={styles.rows}>
                                    {workspaces?.map((w) => {
                                        return (
                                            <div className={styles.row} key={`${w.name}${w.createdOn}`}>
                                                <div className={styles.cell} style={{ flexGrow: 2 }}>
                                                    {w.name}
                                                </div>
                                                <div className={styles.cell}>{w.membershipCount}</div>
                                                <div className={styles.cell}>{w.datasetCount || 0}</div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(w.createdOn)}
                                                </div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu
                                                        actions={[
                                                            {
                                                                label: t('Delete workspace'),
                                                                onClick: (): void => {
                                                                    this.onDeleteWorkspace(w);
                                                                },
                                                                type: ActionType.negative,
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            case FailureCodes.WS12:
                return t('Please make sure no users have set this workspace as their default.');
            default:
                return t('Organization deletion failed, please contact your administrator if this error persists.');
        }
    }

    private showCreateWorkspacePopup(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.CREATE_WORKSPACE,
            content: <CreateWorkspacePopup />,
            isDismissible: false,
        });
    }

    private onDeleteWorkspace(workspaceInfo: WorkspaceInfo): void {
        const { dispatchDeleteWorkspaceRequest, authorizationContext } = this.props;
        dispatchDeleteWorkspaceRequest({
            organizationId: authorizationContext!.organization.id,
            workspaceId: workspaceInfo.id,
        });
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchDeleteWorkspaceRequest: (parameters: DeleteWorkspaceRequestParameters) =>
        dispatch(deleteWorkspaceRequest(parameters)),
    dispatchGetWorkspacesForOrganization: (organizationId: string) =>
        dispatch(getWorkspacesForOrganizationRequest(organizationId)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    global,
    workspaces,
}: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    currentLocation: router.location,
    workspaces: workspaces.workspacesForOrganization,
    requestStatus: workspaces.getWorkspacesForOrganizationRequestStatus,
    deleteWorkspaceRequestStatus: workspaces.deleteWorkspaceRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageWorkspacesPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    currentLocation: Location;
    workspaces?: WorkspaceInfo[];
    requestStatus?: RequestStatus;
    deleteWorkspaceRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetWorkspacesForOrganization: typeof getWorkspacesForOrganizationRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchDeleteWorkspaceRequest: typeof deleteWorkspaceRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {}

type AllState = OwnState;
