import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './explorer-selection-bar.module.scss';

class ExplorerSelectionBar extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className={styles.bar}>
                <button className={`${styles.button}`} type='button' onClick={(): void => this.onFilter()}>
                    {t('Apply Filter')}
                </button>
            </div>
        );
    }

    private onFilter(): void {
        const { applyFilter } = this.props;
        applyFilter();
    }
}

export default withTranslation()(ExplorerSelectionBar);

interface OwnProps {
    applyFilter: () => void;
}

type AllProps = OwnProps & WithTranslation;
