import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SearchPageHeader from '../../components/page-header/search-page-header/search-page-header';
import { RouteUrl } from '../../routing/route-url';
import { ApplicationState } from '../../store';
import { AuthorizationContext } from '../../store/global';
import { rootSearchRequest, SearchHistoryEntry } from '../../store/search';
import { SubscriptionHelper } from '../../store/subscription/helpers/subscription-helper';
import { updateQueryParameters } from '../../utils/query-parameter-helpers';
import SearchHistory from './components/search-history/search-history';
import styles from './search.module.scss';

class SearchPage extends Component<AllProps> {
    public render(): JSX.Element {
        const { searchHistory, authorizationContext } = this.props;

        return (
            <div className={styles.container}>
                <SearchPageHeader />
                <SearchHistory
                    history={searchHistory}
                    canRunAgain={SubscriptionHelper.hasValidSubscription(authorizationContext)}
                    onSearch={(query: string): void => this.onSearch(query)}
                />
            </div>
        );
    }

    private onSearch(query): void {
        const { dispatchNavigateTo, activeWorkspaceId } = this.props;
        dispatchNavigateTo({
            search: updateQueryParameters({ query }, ''),
            pathname: `/${activeWorkspaceId}${RouteUrl.QuickFilter}`,
        });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ search, global }: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    searchHistory: search.history,
    activeWorkspaceId: global.activeWorkspaceId,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchRootSearchRequest: (workspaceId: string, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SearchPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    searchHistory?: SearchHistoryEntry[];
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchRootSearchRequest: typeof rootSearchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
