import React, { ReactElement, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './search-page-header.module.scss';
import { addToast, ToastType } from '../../../store/toast';
import { generateUUID } from '../../../utils/uuid-helpers';

interface FileSelectButtonProps {
    onFileContent: (content: string) => void
}

const FILE_MAX_SIZE = 1024 * 32; // 32 kilobytes.

function FileSelectButton(props: FileSelectButtonProps):ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { onFileContent } = props;
    const { getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        multiple: false,
        maxSize: FILE_MAX_SIZE,
    });

    useEffect( () => {
        const rejectedFile = fileRejections[0];
        if (rejectedFile && rejectedFile.errors[0]) {
            if (rejectedFile.errors[0].code === 'file-too-large'){
                dispatch(addToast({
                    id: generateUUID(),
                    type: ToastType.Warning,
                    messages: [t('File is too big, maximum size is {{fileSize}}kb', { fileSize: FILE_MAX_SIZE/1024 })],
                    removeAfterMilliseconds: 3000,
                }))
            }
        }
    }, [fileRejections, t, dispatch])

    useEffect(() => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>):void => onFileContent(event?.target?.result as string);
            reader.readAsText(file);
        }
    }, [acceptedFiles, onFileContent]);

    return (
        <div className={styles.fileButtonContainer}
             title={t('Quick fill search form by file content\n\nMaximum file size is {{fileSize}}kb', { fileSize: FILE_MAX_SIZE/1024 })}>
            <input {...getInputProps()} />
            <button type='button' onClick={open} className={`${styles.button} ${styles.tertiary} ${styles.fileButton}`}>
                {t('Select file...')}
            </button>
        </div>
    );
}


export default FileSelectButton;
