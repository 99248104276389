import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core';
import debounce from 'lodash.debounce';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter-icon.svg';
import styles from './alignment-viewer.module.scss';
import { Highlight } from './alignment-view-types';

type AlignmentHighlightInputProps = WithTranslation & {
    highlighted: Highlight | undefined;
    onHighlightApply?: (Highlight) => void;
    onFilterApply?: (highlight:Highlight) => void;
};

function AlignmentHighlightInputComponent(props: AlignmentHighlightInputProps): ReactElement {
    const { highlighted, onHighlightApply, t, onFilterApply } = props;
    const [h, setH] = useState<Highlight | undefined>(highlighted);

    const changeHighlight = (highlight: Highlight) => {
        if (onHighlightApply) onHighlightApply(highlight);
    };

    const delayedHighlightChange = useCallback(
        debounce(changeHighlight, 700),
        [],
    );

    useEffect(() => {
        setH(highlighted);
    }, [highlighted]);

    return (
        <>
            <div style={{ flex: 1 }} />
            <TextField
                id='outlined-basic'
                label={t('Highlight pattern, ? - one symbol, * - few symbols')}
                value={h?.pattern}
                style={{ minWidth: 500 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={() => {
                                    const newH = { exclude: h?.exclude, pattern: '' };
                                    delayedHighlightChange(newH);
                                    setH(newH);
                                }}>
                                <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} size='sm' />
                            </IconButton>
                        </InputAdornment>
                    ),
                    startAdornment: <div />,
                }}
                onChange={(e) => {
                    const newH = { exclude: h?.exclude, pattern: e.target.value };
                    delayedHighlightChange(newH);
                    setH(newH);
                }}
            />
            <FormControlLabel
                style={{marginLeft: 16, marginBottom: -4}}
                control={
                    <Checkbox
                        color='primary'
                        checked={!!h?.exclude}
                        onChange={(event) => {
                            const newH = { exclude: event.target.checked, pattern: h?.pattern };
                            delayedHighlightChange(newH);
                            setH(newH);
                        }
                        } />
                }
                label='exclude pattern'
            />
            <div style={{ flex: 1, marginLeft: 8, marginBottom: -4 }}>
                {onFilterApply ? (
                    <Button
                        size='large'
                        color='primary'
                        disabled={!h?.pattern}
                        onClick={() => {
                            if (onFilterApply && h) {
                                onFilterApply(h);
                            }
                        }}
                        startIcon={<FilterIcon style={{ fill: h?.pattern ? '#00a4d0' : '#aaaaaa' }} />}>
                        Use as filter
                    </Button>
                ) : null}
            </div>
        </>
    );
}


const AlignmentHighlightInput = withTranslation()(AlignmentHighlightInputComponent);

export default AlignmentHighlightInput;
