import { Dimension, DimensionWithAllElements } from '../../../store/search';
import { SelectionItem } from '../components/page-toolbar/selection/selection-component';

export class DimensionSelectionItemsCreator {
    public static create(dimensions: Dimension[]): SelectionItem[] {
        return dimensions
            .filter((d) => (d as DimensionWithAllElements).elements && d.name !== 'sequenceId' && d.name !== 'description')
            .map((d) => {
                return { name: d.name, displayName: d.displayName };
            });
    }
}
