import React, { ReactElement } from 'react';
import styles from '../alignment-viewer.module.scss';
import { BASE_SIZE } from '../aligment-view-constants';

interface PositionProps {
    offset: number;
    total: number;
    step: number;
}

interface LabelProps {
    label: string | number;
}

const Tick = ({ label }: LabelProps): ReactElement => <span className={styles.tick}>{label}</span>;

const Zero = ({ label }: LabelProps): ReactElement => {
    return (
        <span className={styles.tick} style={{ fontWeight: 'bold', fontSize: 12, padding: 0, paddingLeft: 4 }}>
            {label}
        </span>
    );
};

const renderPositive = (pointsNumber, step): ReactElement[] => {
    const points = new Array(pointsNumber);
    points.fill(0);
    // eslint-disable-next-line react/no-array-index-key
    return points.map((xxx, index) => <Tick label={(index + 1) * step} key={(index + 1) * step} />);
};

const renderNegative = (pointsNumber, step): ReactElement[] => {
    const points = new Array(pointsNumber);
    points.fill(0);

    // eslint-disable-next-line react/no-array-index-key
    return points.map((xxx, index) => <Tick label={(index - pointsNumber) * step} key={(index + 1) * step} />);
};

const PositionAxis = (props: PositionProps): ReactElement => {
    const { offset, total, step = 3 } = props;

    const positive: number = total - offset;

    const negativeLen = Math.trunc(offset / step);
    const shift = (offset % step) * BASE_SIZE;
    const positiveLen = Math.trunc(positive / step) - 1; /* skip first because of start from index 1 */

    return (
        <div className={styles.positionRow} style={{ marginLeft: shift }}>
            {renderNegative(negativeLen, step)}
            <Zero label={0} key='0' />
            {renderPositive(positiveLen, step)}
        </div>
    );
};

export default PositionAxis;
