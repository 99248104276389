import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConfirmPasswordPage from '../pages/authentication/confirm-password/confirm-password.page';
import ConfirmationUnderwayPage from '../pages/authentication/confirmation-underway/confirmation-underway.page';
import ForgotPasswordPage from '../pages/authentication/forgot-password/forgot-password.page';
import LoginPage from '../pages/authentication/login/login.page';
import ActiveWorkspaceBoundary from './active-workspace-boundary';
import AuthenticatedRoute from './authenticated-route';
import { RouteUrl } from './route-url';

const routes: React.FunctionComponent = () => (
    <Switch>
        <Route exact path={RouteUrl.Root} component={LoginPage} />
        <Route exact path={RouteUrl.Login} component={LoginPage} />
        <Route exact path={RouteUrl.ForgotPassword} component={ForgotPasswordPage} />
        <Route exact path={RouteUrl.ConfirmPassword} component={ConfirmPasswordPage} />
        <Route exact path={RouteUrl.ConfirmationUnderway} component={ConfirmationUnderwayPage} />
        <AuthenticatedRoute path='/:workspaceId' component={ActiveWorkspaceBoundary} />
        <Route component={(): JSX.Element => <div>Not Found</div>} />
    </Switch>
);

export default routes;
