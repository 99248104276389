class UserCancelActionError extends Error {
    constructor() {
        super('User cancel the action')
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UserCancelActionError)
        }
        this.name = 'UserCancelActionError';
    }
}

export default UserCancelActionError
