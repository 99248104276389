export interface ListSorting {
    columnKey: string;
    direction: SortDirection;
}

export interface ExplorerSorting {
    axis: SortAxis;
    direction: SortDirection;
    property: SortProperty;
}

export enum SortDirection {
    Ascending = 'asc',
    Descending = 'desc',
}

export enum SortAxis {
    Row,
    Column,
}

export type SortProperty = 'name' | 'count';
