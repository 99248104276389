import { Location } from 'history';
import { SharedMolType } from '../../../../api/generated';
import { QueryParameter, QueryParameterHelper, QueryParameterMap } from '../../../../utils/query-parameter-helpers';
import { ResultViewUrlParameters } from '../../result-view-url-parameters';
import { UrlParametersHandler } from '../../url-parameters-handler';

export class AlignmentUrlParametersHandler extends UrlParametersHandler<AlignmentResultViewUrlParameters> {
    public read(currentLocation: Location): AlignmentResultViewUrlParameters {
        const base = super.doRead(currentLocation);
        const urlSearchParameters = new URLSearchParams(currentLocation.search);
        const page = QueryParameterHelper.getNumberFromUrl(urlSearchParameters, QueryParameter.AlignmentViewPage) || 1;
        const molType = QueryParameterHelper.getStringFromUrl(urlSearchParameters, QueryParameter.MolType) || undefined;

        const pageSize =
            QueryParameterHelper.getNumberFromUrl(urlSearchParameters, QueryParameter.AlignmentViewPageSize) || 50;

        return {
            ...base,
            activePage: page,
            activePageSize: pageSize,
            molType: molType as SharedMolType.AA | SharedMolType.DNA | undefined,
        };
    }

    public write(newUrlState: AlignmentResultViewUrlParameters): QueryParameterMap {
        const resultViewParameters = super.doWrite(newUrlState);
        resultViewParameters[QueryParameter.AlignmentViewPage] = newUrlState.activePage;
        resultViewParameters[QueryParameter.AlignmentViewPageSize] = newUrlState.activePageSize;
        resultViewParameters[QueryParameter.MolType] = newUrlState.molType;
        return resultViewParameters;
    }
}

export interface AlignmentResultViewUrlParameters extends ResultViewUrlParameters {
    activePage: number;
    activePageSize: number;
    molType?: SharedMolType.AA | SharedMolType.DNA;
}
