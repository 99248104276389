import { SelectionItem } from '../../../components/page-toolbar/selection/selection-component';
import { ExplorerTableRow } from '../components/explorer-table/explorer-table';

export class ExplorerViewCsvCreator {
    public static createCsv(
        selectedRowDimensionItems: SelectionItem[],
        selectedColumnDimensionItems: SelectionItem[],
        rows: ExplorerTableRow[],
        columns: string[][],
        columnCombinations: string[][],
    ): string {
        const bom = '\uFEFF';
        const newLine = '\r\n';
        const separator = ';';

        const selectedRowDimensions = selectedRowDimensionItems.map((row) => row.name);

        const csvColumns = columns.reduce((concatenatedColumnsPerRow, columnsRow) => {
            let emptyColumnsForRowKeys = '';

            for (let i = 0; i < selectedRowDimensions.length; i++) {
                emptyColumnsForRowKeys += separator;
            }

            const concatenatedColumnsRow = columnsRow.reduce((concatenatedColumns, column, index) => {
                return `${concatenatedColumns}${column}${index !== columnCombinations.length - 1 ? separator : ''}`;
            }, emptyColumnsForRowKeys);

            return `${concatenatedColumnsPerRow}${concatenatedColumnsRow}${newLine}`;
        }, '');
        const csvRows = rows.reduce((concatenatedRows, row) => {
            const csvRowKeys = row.keys.reduce((concatenatedKeys, key) => {
                return `${concatenatedKeys}${key}${separator}`;
            }, '');
            const csvRow = columnCombinations.reduce((concatenatedColumns, columnCombination, index) => {
                return `${concatenatedColumns}${row.values[columnCombination.toString()] || ''}${
                    index === columnCombinations.length - 1 ? newLine : separator
                }`;
            }, csvRowKeys);
            return concatenatedRows + csvRow;
        }, '');

        return bom + csvColumns + csvRows;
    }
}
