import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    CookieStorage,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { RuntimeConfig } from '../config/runtime-config';
import {
    AuthenticationFeedbackType,
    ConfirmPasswordParameters,
    Credentials,
    PasswordChallengeParameters,
} from '../store/authentication';
import { FailureCodes } from '../store/shared/types';

const domain =  RuntimeConfig.getRootDomain() as string;

const Storage = domain ==='localhost' ? undefined : new CookieStorage({ domain, secure:false });

const cognitoConfig = {
    IdentityPoolId: RuntimeConfig.getCognitoIdentityPoolId(),
    UserPoolId: RuntimeConfig.getCognitoUserPoolId(),
    ClientId: RuntimeConfig.getCognitoClientId(),
    AWSRegion: RuntimeConfig.getCognitoRegion(),
    Storage
};

const userPool = new CognitoUserPool(cognitoConfig);

const COGNITO_EXCEPTION_EXPIREDCODE = 'ExpiredCodeException';
const COGNITO_EXCEPTION_LIMIT_EXCEEDED = 'LimitExceededException';

class AuthenticationApi {
    public static authenticate(credentials: Credentials): Promise<AuthenticationResponse<AuthenticationFeedbackType>> {
        return new Promise((resolve, reject) => {
            const authData = {
                Username: credentials.email,
                Password: credentials.password,
            };
            const authDetails = new AuthenticationDetails(authData);
            const userData = {
                Username: credentials.email,
                Pool: userPool,
                Storage,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authDetails, {
                onSuccess(session: CognitoUserSession | any) {
                    AuthenticationApi.setAWSConfig(session);
                    resolve({ result: AuthenticationFeedbackType.Success });
                },
                newPasswordRequired(userAttributes: any, requiredAttributes: any) {
                    resolve({
                        errorCode: FailureCodes.AUTH2,
                        result: AuthenticationFeedbackType.Failure,
                    });
                },
                onFailure(err) {
                    resolve({
                        errorCode: FailureCodes.AUTH1,
                        result: AuthenticationFeedbackType.Failure,
                    });
                },
            });
        });
    }

    public static forgotPassword(email: string): Promise<AuthenticationResponse<AuthenticationFeedbackType>> {
        return new Promise((resolve, reject) => {
            const userData = {
                Username: email,
                Pool: userPool,
                Storage,
            };

            const cognitoUser = new CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess: (data: any) => {
                    resolve({ result: AuthenticationFeedbackType.Success });
                },
                onFailure: (err: Error) => {
                    resolve({
                        result: AuthenticationFeedbackType.Failure,
                        errorCode: FailureCodes.Unexpected,
                    });
                },
            });
        });
    }

    public static confirmPassword(
        parameters: ConfirmPasswordParameters,
    ): Promise<AuthenticationResponse<AuthenticationFeedbackType>> {
        return new Promise((resolve, reject) => {
            const userData = {
                Username: parameters.email,
                Pool: userPool,
                Storage,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.confirmPassword(parameters.confirmationCode, parameters.newPassword, {
                onSuccess() {
                    resolve({ result: AuthenticationFeedbackType.Success });
                },
                onFailure(err: Error) {
                    if (err.name === COGNITO_EXCEPTION_EXPIREDCODE) {
                        resolve({
                            result: AuthenticationFeedbackType.Failure,
                            errorCode: FailureCodes.AUTH3,
                        });
                    } else if (err.name === COGNITO_EXCEPTION_LIMIT_EXCEEDED) {
                        resolve({
                            result: AuthenticationFeedbackType.Failure,
                            errorCode: FailureCodes.AUTH4,
                        });
                    } else {
                        resolve({
                            result: AuthenticationFeedbackType.Failure,
                            errorCode: FailureCodes.Unexpected,
                        });
                    }
                },
            });
        });
    }

    public static completeNewPasswordChallenge(
        parameters: PasswordChallengeParameters,
    ): Promise<AuthenticationResponse<AuthenticationFeedbackType>> {
        return new Promise((resolve, reject) => {
            const authData = {
                Username: parameters.email,
                Password: parameters.temporaryPassword,
            };
            const authDetails = new AuthenticationDetails(authData);
            const userData = {
                Username: parameters.email,
                Pool: userPool,
                Storage,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authDetails, {
                onSuccess(session: CognitoUserSession | any) {
                    AuthenticationApi.setAWSConfig(session);
                    resolve({ result: AuthenticationFeedbackType.Success });
                },
                newPasswordRequired(userAttributes: any, requiredAttributes: any) {
                    // resolve({ result: AuthenticationFeedbackType.NewPasswordRequired });
                    cognitoUser.completeNewPasswordChallenge(parameters.newPassword, requiredAttributes, {
                        onSuccess(session: CognitoUserSession) {
                            AuthenticationApi.setAWSConfig(session);
                            resolve({ result: AuthenticationFeedbackType.Success });
                        },
                        onFailure(err) {
                            resolve({
                                errorCode: FailureCodes.Unexpected,
                            });
                        },
                    });
                },
                onFailure(err) {
                    resolve({
                        errorCode: FailureCodes.Unexpected,
                        result: AuthenticationFeedbackType.Failure,
                    });
                },
            });
        });
    }

    public static isAuthenticated(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const currentUser = userPool.getCurrentUser();
            if (currentUser) {
                currentUser.getSession((err, session: CognitoUserSession) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session.isValid());
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public static getBearerToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const currentUser = userPool.getCurrentUser();
            if (currentUser) {
                currentUser.getSession((err, session: CognitoUserSession) => {
                    resolve(`Bearer ${session.getIdToken().getJwtToken()}`);
                });
            } else {
                resolve('no token');
            }
        });
    }

    public static getAccessToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const currentUser = userPool.getCurrentUser();
            if (currentUser) {
                currentUser.getSession((err, session: CognitoUserSession) => {
                    resolve(session.getIdToken().getJwtToken());
                });
            } else {
                resolve('no token');
            }
        });
    }

    public static refreshSession(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const currentUser = userPool.getCurrentUser();
            if (currentUser) {
                currentUser.getSession((err, session: CognitoUserSession) => {
                    if (session.isValid()) {
                        currentUser.refreshSession(session.getRefreshToken(), (error, newSession) => {
                            AuthenticationApi.setAWSConfig(session);
                            resolve();
                        });
                    } else {
                        reject('No valid session available to conduct session refresh');
                    }
                });
            } else {
                reject('Unable to refresh session because current logged in user not found');
            }
        });
    }

    private static setAWSConfig(session: CognitoUserSession) {
        // POTENTIAL: Region needs to be set if not already set previously elsewhere.
        AWS.config.region = cognitoConfig.AWSRegion;

        const loginKey = `cognito-idp.${cognitoConfig.AWSRegion}.amazonaws.com/${cognitoConfig.UserPoolId}`;
        const logins = {};
        logins[loginKey] = session.getIdToken().getJwtToken();

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognitoConfig.IdentityPoolId, // your identity pool id here
            Logins: logins,
        });
    }

    public static logout(): void {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.signOut();
        }
    }
}

export default AuthenticationApi;

export interface AuthenticationResponse<T> {
    result?: T;
    errorCode?: FailureCodes;
}
