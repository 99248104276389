import { Slider } from '@material-ui/core';
import { scaleLinear } from 'd3-scale';
import React, { ReactElement, useEffect, useState } from 'react';
import { CollapsedItemRenderer, RegularItemRenderer } from './taxonomy-tree-item-renderers';
import {
    FlattenTx,
    TAXONOMY_ITEM_TYPE,
    TaxonomyCollapsedItems,
    TaxonomyLevelItem,
    TaxonomyRenderItem,
} from './taxonomy-tree-types';
import { collapseTaxonomy, isChildId, isMatch2Parent, MINIMAL_ITEM_SIZE } from './taxonomy-tree-utils';

interface TaxonomyTreeProps {
    taxonomyDescription?: FlattenTx;
    onSelectionChange?: (itemId?: string[]) => void;
    selected?: string[];
    height?: number;
    width?: number;
}

const isItemSelected = (selectedIds: string[], item) => {
    return !!selectedIds.find((id) => isMatch2Parent(item.id, id));
};

const isContainSelectedItem = (selectedIds: string[], item: TaxonomyCollapsedItems) => {
    return !!selectedIds.find((id) => {
        return item.id.indexOf(id) !== -1;
    });
};

const TAXONOMY_HEADER_SIZE = 40;

const clearChildren = (ids: string[]): string[] => {
    const cleanedIds = ids.filter((id) => {
        const hasParent = !!ids.find( (id2) =>  isChildId(id, id2) )
        return !hasParent;
    })

    return cleanedIds;
}

const TaxonomyTree = (props: TaxonomyTreeProps): ReactElement => {
    const { taxonomyDescription, height = 350, width = 1300, onSelectionChange, selected } = props;
    const taxonomyViewportHeight = height - TAXONOMY_HEADER_SIZE;

    const [items, setItems] = useState<TaxonomyRenderItem[]>([]);
    const [zoom, setZoom] = useState<number>(0);
    const [maxHeight, setMaxHeight] = useState<number>(taxonomyViewportHeight);
    const [canvasHeight, setCanvasHeight] = useState<number>(taxonomyViewportHeight);
    const [selectedIds, setSelectedIds] = useState<string[]>(selected || []);

    const itemWidth = (width - 50) / 8;

    useEffect(() => {
        if (taxonomyDescription) {
            const mh = taxonomyDescription.total * MINIMAL_ITEM_SIZE + 1;
            setMaxHeight(mh);
            const zoomRange = scaleLinear().domain([0, 10]).range([taxonomyViewportHeight, mh]);
            const newItems = collapseTaxonomy(taxonomyDescription, mh, zoomRange(zoom) / mh);
            setItems(newItems);
            setCanvasHeight(zoomRange(zoom));
        }
    }, [taxonomyDescription, height, zoom, taxonomyViewportHeight]);

    const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        const zzz = value as number;
        setZoom(zzz);
        if (taxonomyDescription) {
            const zoomRange = scaleLinear().domain([0, 10]).range([taxonomyViewportHeight, maxHeight]);
           // setItems(collapseTaxonomy(taxonomyDescription, maxHeight, zoomRange(zzz) / maxHeight));
            setCanvasHeight(zoomRange(zzz));
        }
    };

    const handleSelectionChange = (event, ids) => {
        let selection = ids;

        if (event.shiftKey) {
            const newSelection = ids.filter(id => selectedIds.indexOf(id) < 0);
            // const witoutParents = removePossibleParents(selectedIds, newSelection);
            const cleanedSelection = selectedIds.filter(id => ids.indexOf(id) < 0);
            selection = clearChildren(cleanedSelection.concat(newSelection))
        }

        setSelectedIds(selection);
        if (onSelectionChange) {
            onSelectionChange(selection);
        }
    };
    if (!taxonomyDescription) return <div />;

    const { levelDescriptions } = taxonomyDescription;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', height: TAXONOMY_HEADER_SIZE }}>
                    {levelDescriptions.map((levelDesc) => {
                        return (
                            <div
                                style={{
                                    width: itemWidth,
                                    marginBottom: 5,
                                    marginTop: 5,
                                    marginRight: 5,
                                    textAlign: 'center',
                                }}
                                key={levelDesc.name}>
                                {levelDesc.name}
                                <br />
                                <span style={{ fontSize: 11 }}>{levelDesc.variants} variants</span>
                            </div>
                        );
                    })}
                </div>

                <div
                    style={{
                        width,
                        height: taxonomyViewportHeight + 5,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        userSelect: 'none',
                    }}>
                    <svg width={width} height={canvasHeight}>
                        {items.map((item, index) =>
                            item.type === TAXONOMY_ITEM_TYPE.SINGLE ? (
                                <RegularItemRenderer
                                    item={item as TaxonomyLevelItem}
                                    itemWidth={itemWidth}
                                    key={item.id}
                                    selected={isItemSelected(selectedIds, item)}
                                    onClick={handleSelectionChange}
                                />
                            ) : (
                                <CollapsedItemRenderer
                                    item={item as TaxonomyCollapsedItems}
                                    itemWidth={itemWidth}
                                    selected={isContainSelectedItem(selectedIds, item as TaxonomyCollapsedItems)}
                                    onClick={handleSelectionChange}
                                    key={item.id}
                                />
                            ),
                        )}
                    </svg>
                </div>
            </div>
            <div style={{ height: 200, marginTop: TAXONOMY_HEADER_SIZE }}>
                <Slider
                    min={0}
                    max={10}
                    marks={[
                        {
                            value: 0,
                            label: '+',
                        },
                        {
                            value: 10,
                            label: '-',
                        },
                    ]}
                    value={zoom}
                    style={{ height: 200 }}
                    onChange={handleChange}
                    orientation='vertical'
                />
            </div>
        </div>
    );
};

export default TaxonomyTree;
