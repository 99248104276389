import { DatasetStatus } from './types';
import { SharedTaskStatusCode } from '../../api/generated';

export function getDatasetPipelineStatus(datasetPipeline) {
    if (datasetPipeline.runs && datasetPipeline.runs.length > 0) {
        return datasetPipeline.runs[0].status;
    }
    return 'New';
}

export function isPipelineCompleted(datasetPipeline) {
    return getDatasetPipelineStatus(datasetPipeline) === SharedTaskStatusCode.SUCCEEDED;
}

export function isDatasetReady(dataset, datasetPipeline) {
    return  dataset.status === DatasetStatus.ACTIVE && datasetPipeline && isPipelineCompleted(datasetPipeline);
}
