import { SharedMolType } from '../../../../api/generated';
import { ApplicationState } from '../../../../store';
import { DimensionWithAllElements } from '../../../../store/search';

type DimLookup = DimensionWithAllElements | undefined;

export const availableMolTypesSelector = (state: ApplicationState) => {
    const molTypes: string[] = [];
    const molTypesDimension: DimLookup = state.search.currentDimension.find((dim) => dim.name === 'molType') as DimLookup;
    let molNames = molTypesDimension?.elements.map((e) => e.name);

    const molTypeFilters = state.search.baseSearchParameters?.filters.find( df => df.dimensionName === 'molType' );

    if (molTypeFilters && molTypeFilters.includes) {
        molNames =  molNames?.filter( mt => {
            if (molTypeFilters && molTypeFilters.includes) {
               return molTypeFilters.includes.indexOf(mt) > -1;
            }
            return false;
        })
    }

    if (molNames) {
        const aa = molNames.filter(nm => nm === SharedMolType.AA);
        const notAA = molNames.filter( nm => nm !== SharedMolType.AA);

        if (aa && aa.length > 0) {
            molTypes.push(SharedMolType.AA);
        }

        if (notAA && notAA.length > 0) {
            molTypes.push(SharedMolType.DNA);
        }
        return molTypes;
    }

    return molTypes;
};

export const defaultMolTypeSelector = (state: ApplicationState) => {
    const availableTypes = availableMolTypesSelector(state);
    return availableTypes.length > 0 ? availableTypes[0] : SharedMolType.AA;
};

export const aaTotalCountSelector = (state: ApplicationState) => {
    const molTypesDimension: DimLookup = state.search.currentDimension.find((dim) => dim.name === 'molType') as DimLookup;
    if (molTypesDimension) {
        const aaElement = molTypesDimension.elements.find( e => e.name === SharedMolType.AA )
        if (aaElement) {
            return aaElement.count;
        }
    }
    return 0;
};

export const dnaTotalCountSelector = (state: ApplicationState) => {
    const totalResults = state.search.queryMatches;
    const aaRecordsCount = aaTotalCountSelector(state);
    return totalResults - aaRecordsCount;
}
