import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { WorkspaceInfo, WorkspaceMembership, WorkspacesAction, WorkspacesActionTypes, WorkspacesState } from './types';

export function initialState(): WorkspacesState {
    return {};
}

const reducer: Reducer<WorkspacesState, WorkspacesAction> = (state = initialState(), action): WorkspacesState => {
    switch (action.type) {
        case WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_REQUEST: {
            return {
                ...state,
                getWorkspacesForOrganizationRequestStatus: new RequestStatus(RequestStatusType.InProgress),
                workspacesForOrganization: undefined,
            };
        }
        case WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                getWorkspacesForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Success),
                workspacesForOrganization: action.payload,
            };
        }
        case WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_FAILURE: {
            return {
                ...state,
                getWorkspacesForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.CREATE_WORKSPACE_REQUEST: {
            return {
                ...state,
                createWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.CREATE_WORKSPACE_SUCCESS: {
            const newWorkspace = action.payload;
            const updatedArray: WorkspaceInfo[] = state.workspacesForOrganization
                ? [...state.workspacesForOrganization, newWorkspace]
                : [newWorkspace];
            return {
                ...state,
                createWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                workspacesForOrganization: updatedArray,
            };
        }
        case WorkspacesActionTypes.CREATE_WORKSPACE_FAILURE: {
            return {
                ...state,
                createWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.UPDATE_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                updateMembershipRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.UPDATE_MEMBERSHIP_SUCCESS: {
            const updatedMembership = action.payload;
            const updatedArray: WorkspaceMembership[] = state.membershipsForWorkspace
                ? [...state.membershipsForWorkspace]
                : [];
            const index = updatedArray.findIndex((u) => u.email === updatedMembership.email);
            if (index !== -1) updatedArray[index] = updatedMembership;

            return {
                ...state,
                updateMembershipRequestStatus: new RequestStatus(RequestStatusType.Success),
                membershipsForWorkspace: updatedArray,
            };
        }
        case WorkspacesActionTypes.UPDATE_MEMBERSHIP_FAILURE: {
            return {
                ...state,
                updateMembershipRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_REQUEST: {
            return {
                ...state,
                getMembershipsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_SUCCESS: {
            return {
                ...state,
                getMembershipsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                membershipsForWorkspace: action.payload,
            };
        }
        case WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_FAILURE: {
            return {
                ...state,
                getMembershipsForWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                updateDefaultMembershipRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                updateDefaultMembershipRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_FAILURE: {
            return {
                ...state,
                updateDefaultMembershipRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.DELETE_WORKSPACE_REQUEST: {
            return {
                ...state,
                deleteWorkspaceRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.DELETE_WORKSPACE_SUCCESS: {
            const workspaceId = action.payload;
            const updatedArray: WorkspaceInfo[] = state.workspacesForOrganization
                ? [...state.workspacesForOrganization]
                : [];
            const index = updatedArray.findIndex((w) => w.id === workspaceId);
            if (index !== -1) updatedArray.splice(index, 1);

            return {
                ...state,
                deleteWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Success),
                workspacesForOrganization: updatedArray,
            };
        }
        case WorkspacesActionTypes.DELETE_WORKSPACE_FAILURE: {
            return {
                ...state,
                deleteWorkspaceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.CREATE_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                createMembershipRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.CREATE_MEMBERSHIP_SUCCESS: {
            const newMembership = action.payload;
            const updatedArray: WorkspaceMembership[] = state.membershipsForWorkspace
                ? [...state.membershipsForWorkspace, newMembership]
                : [newMembership];

            return {
                ...state,
                createMembershipRequestStatus: new RequestStatus(RequestStatusType.Success),
                membershipsForWorkspace: updatedArray,
            };
        }
        case WorkspacesActionTypes.CREATE_MEMBERSHIP_FAILURE: {
            return {
                ...state,
                createMembershipRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case WorkspacesActionTypes.DELETE_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                deleteMembershipRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case WorkspacesActionTypes.DELETE_MEMBERSHIP_SUCCESS: {
            const email = action.payload;
            const updatedArray: WorkspaceMembership[] = state.membershipsForWorkspace
                ? [...state.membershipsForWorkspace]
                : [];
            const index = updatedArray.findIndex((w) => w.email === email);
            if (index !== -1) updatedArray.splice(index, 1);

            return {
                ...state,
                deleteMembershipRequestStatus: new RequestStatus(RequestStatusType.Success),
                membershipsForWorkspace: updatedArray,
            };
        }
        case WorkspacesActionTypes.DELETE_MEMBERSHIP_FAILURE: {
            return {
                ...state,
                deleteMembershipRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return initialState();
        default:
            return state;
    }
};

export { reducer as workspacesReducer };
export { initialState as initialWorkspacesState };
