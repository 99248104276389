import { faSlidersH } from '@fortawesome/pro-light-svg-icons';
import { faSearch, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { ChangeEvent, createRef, KeyboardEvent, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SearchSettingsPopup from '../../../pages/search/components/search-settings-popup/search-settings-popup';
import { ApplicationState } from '../../../store';
import { Dataset, DatasetWithLimit, getDatasetsForWorkspaceRequest } from '../../../store/datasets';
import { AuthorizationContext} from '../../../store/global';
import { Popup, PopupType, showPopup } from '../../../store/popup';
import { rootSearchRequest, runSearchRequest, SearchSettings } from '../../../store/search';
import { RequestStatus } from '../../../store/shared/types';
import { SubscriptionHelper } from '../../../store/subscription/helpers/subscription-helper';
import {
    localSearchSettingsKey,
    persistParsedToLocalStorage,
    retrieveParsedFromLocalStorage,
} from '../../../utils/local-storage-helpers';
import HelpLink from '../../help-link/help-link';
import { HelpLinkUrl } from '../../help-link/help-link-urls';
import PageHeaderBase from '../page-header-base';
import styles from './search-page-header.module.scss';
import FileSelectButton from './file-select-button';

class SearchPageHeader extends React.Component<AllProps, AllState> {
    dropzoneRef;

    constructor(props: AllProps) {
        super(props);
        const { authorizationContext, activeWorkspaceId, dispatchGetDatasetsForWorkspaceRequest } = props;

        this.state = {
            query: '',
            form: React.createRef<HTMLFormElement>(),
            availableDatasetCount: 0,
            selectedDatasetCount: 0,
            settings: null,
        };

        if (authorizationContext && activeWorkspaceId) {
            dispatchGetDatasetsForWorkspaceRequest(authorizationContext.organization.id, activeWorkspaceId);
        }

        this.dropzoneRef = createRef();
    }

    private onFileContent = (content) => {
        this.setState({ query: content });
    };

    private onKeyPressed(event: KeyboardEvent<HTMLFormElement>, isValid: boolean): void {
        if (isValid && !event.shiftKey && event.keyCode === 13) {
            this.onSearch();
        }
    }

    private onQueryChange(event: ChangeEvent<HTMLTextAreaElement>): void {
        this.setState({ query: event.target.value });
    }

    private async onSearch() {
        const { runSearch } = this.props;
        const { query } = this.state;
        runSearch(query);
    }

    private openSettings(
        organizationId: string,
        workspaceId: string,
        workspaceDatasets: Dataset[],
        storedSelectedDatasets: DatasetWithLimit[],
    ): void {
        const { dispatchShowPopup } = this.props;
        dispatchShowPopup({
            type: PopupType.SEARCH_SETTINGS,
            content: (
                <SearchSettingsPopup
                    organizationId={organizationId}
                    workspaceId={workspaceId}
                    workspaceDatasets={workspaceDatasets}
                    storedSelectedDatasets={storedSelectedDatasets}
                    onSave={(selectedDatasets): void => this.onSaveSettings(selectedDatasets)}
                />
            ),
            isDismissible: true,
        });
    }

    private onSaveSettings(selectedDatasets: DatasetWithLimit[]): void {
        const { activeWorkspaceId, workspaceDatasets } = this.props;

        if (activeWorkspaceId && workspaceDatasets) {
            const settings = retrieveParsedFromLocalStorage<SearchSettings>(localSearchSettingsKey(activeWorkspaceId));

            this.setState({
                availableDatasetCount: workspaceDatasets.length,
                selectedDatasetCount: selectedDatasets.length,
                settings: {
                    ...settings,
                    selectedDatasets,
                },
            });

            persistParsedToLocalStorage<SearchSettings>(localSearchSettingsKey(activeWorkspaceId), {
                ...settings,
                selectedDatasets,
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {
            authorizationContext,
            activeWorkspaceId,
            workspaceDatasets,
            getDatasetsForWorkspaceRequestStatus,
            dispatchGetDatasetsForWorkspaceRequest,
        } = this.props;

        if (
            authorizationContext &&
            activeWorkspaceId &&
            (authorizationContext !== prevProps.authorizationContext ||
                activeWorkspaceId !== prevProps.activeWorkspaceId) &&
            !getDatasetsForWorkspaceRequestStatus?.isInProgress
        ) {
            dispatchGetDatasetsForWorkspaceRequest(authorizationContext.organization.id, activeWorkspaceId);
        }

        if (activeWorkspaceId && workspaceDatasets && workspaceDatasets !== prevProps.workspaceDatasets) {
            const settings = retrieveParsedFromLocalStorage<SearchSettings>(localSearchSettingsKey(activeWorkspaceId));
            if (settings) {
                const updatedSettings: SearchSettings = {
                    ...settings,
                    selectedDatasets: settings.selectedDatasets.filter((s) =>
                        workspaceDatasets.find((d) => d.id === s.id),
                    ),
                };
                this.setState({
                    availableDatasetCount: workspaceDatasets.length,
                    selectedDatasetCount: updatedSettings.selectedDatasets.length,
                    settings: updatedSettings,
                });
                persistParsedToLocalStorage<SearchSettings>(localSearchSettingsKey(activeWorkspaceId), updatedSettings);
            } else {
                const defaultSettings: SearchSettings = {
                    selectedDatasets: workspaceDatasets.map((d) => {
                        return { id: d.id };
                    }),
                };
                this.setState({
                    availableDatasetCount: workspaceDatasets.length,
                    selectedDatasetCount: defaultSettings.selectedDatasets.length,
                    settings: defaultSettings,
                });
                persistParsedToLocalStorage<SearchSettings>(localSearchSettingsKey(activeWorkspaceId), defaultSettings);
            }
        }
    }

    public render(): JSX.Element {
        const { authorizationContext, activeWorkspaceId, workspaceDatasets, t, isSearchRunning } = this.props;
        const { query, form, availableDatasetCount, selectedDatasetCount, settings } = this.state;

        const isValid =
            form && form.current
                ? ([...form.current.elements] as HTMLInputElement[]).every((element) => element.validity.valid)
                : false;

        return (
            <PageHeaderBase>
                <form className={styles.form} onKeyDown={(event): void => this.onKeyPressed(event, isValid)} ref={form}>
                    <div className={styles.searchBarContainer}>
                        <div className={styles.searchAreaContainer}>
                            <textarea
                                className={styles.searchBar}
                                autoFocus
                                required
                                placeholder={t('Enter your query')}
                                value={query}
                                onChange={(event): void => this.onQueryChange(event)}
                            />
                            <div className={styles.helpLink}>
                                <HelpLink url={HelpLinkUrl.Home} size='1x' />
                            </div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            <button
                                className={`${styles.button} ${styles.secondary} ${styles.searchButton}`}
                                type='button'
                                disabled={!SubscriptionHelper.hasValidSubscription(authorizationContext) || isSearchRunning}
                                onClick={() => this.onSearch()}>
                                <div className={styles.searchButtonContent}>
                                    {isSearchRunning ?
                                        <FontAwesomeIcon className={styles.searchIcon} icon={faSpinner} spin />
                                        : <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />}
                                    {t('Search')}
                                </div>
                            </button>
                            <FileSelectButton onFileContent={this.onFileContent} />
                            {
                                authorizationContext && activeWorkspaceId && workspaceDatasets && settings ? (
                                    <div
                                        className={styles.settingsButton}
                                        onClick={(): void =>
                                            this.openSettings(
                                                authorizationContext.organization.id,
                                                activeWorkspaceId,
                                                workspaceDatasets,
                                                settings.selectedDatasets,
                                            )
                                        }>
                                        <FontAwesomeIcon className={styles.settingsIcon} icon={faSlidersH} />
                                        <span>{t('Dataset filter')}</span>
                                        {selectedDatasetCount < availableDatasetCount ? (
                                            <span className={styles.settingsSummary}>
                                                {' '}
                                                {`(${selectedDatasetCount}/${availableDatasetCount})`}
                                            </span>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className={styles.settingsButton}>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </form>
            </PageHeaderBase>
        );
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
                                                                          global,
                                                                          datasets,
                                                                          search,
                                                                      }: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    activeWorkspaceId: global.activeWorkspaceId,
    workspaceDatasets: datasets.datasetsForWorkspace,
    getDatasetsForWorkspaceRequestStatus: datasets.getDatasetsForWorkspaceRequestStatus,
    isSearchRunning: search.isSearchRunning,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    runSearch: (query: string) => dispatch(runSearchRequest(query)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchRootSearchRequest: (workspaceId: string, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchGetDatasetsForWorkspaceRequest: (organizationId, workspaceId) =>
        dispatch(getDatasetsForWorkspaceRequest(organizationId, workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchPageHeader));

interface OwnProps {
    location: Location;
}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    activeWorkspaceId?: string;
    workspaceDatasets?: Dataset[];
    getDatasetsForWorkspaceRequestStatus?: RequestStatus;
    isSearchRunning: boolean
}

interface PropsFromDispatch {
    runSearch: typeof runSearchRequest;
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchShowPopup: typeof showPopup;
    dispatchGetDatasetsForWorkspaceRequest: typeof getDatasetsForWorkspaceRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps & WithTranslation;

interface OwnState {
    query: string;
    form: RefObject<HTMLFormElement>;
    availableDatasetCount: number;
    selectedDatasetCount: number;
    settings: SearchSettings | null;
}

type AllState = OwnState;
