import { Http, WorkspaceResponse } from '../http/http';
import {
    DeleteUserRequestParameters,
    InviteUserRequestParameters,
    InviteUserResponse,
    UpdateUserRequestParameters,
    UserInfo,
} from '../store/users';
import { ApiType, ApiUrl } from './api-urls';

export class UsersApi {
    public static async getUsersForOrganization(organizationId: string): Promise<WorkspaceResponse<UserInfo[]>> {
        return Http.get<WorkspaceResponse<UserInfo[]>>(ApiType.Gateway, ApiUrl.UsersForOrganization, {
            organizationId,
        });
    }

    public static async inviteUserToOrganization(
        parameters: InviteUserRequestParameters,
    ): Promise<WorkspaceResponse<InviteUserResponse>> {
        return Http.post<InviteUserRequestParameters, WorkspaceResponse<InviteUserResponse>>(
            ApiType.Gateway,
            ApiUrl.InviteUserToOrganization,
            parameters,
            {
                organizationId: parameters.organizationId,
            },
        );
    }

    public static async updateUser(parameters: UpdateUserRequestParameters): Promise<WorkspaceResponse<UserInfo>> {
        return Http.put<UpdateUserRequestParameters, WorkspaceResponse<UserInfo>>(
            ApiType.Gateway,
            ApiUrl.UpdateUser,
            parameters,
            {
                organizationId: parameters.organizationId,
                email: parameters.email,
            },
        );
    }

    public static async deleteUser(parameters: DeleteUserRequestParameters): Promise<WorkspaceResponse<void>> {
        return Http.delete<WorkspaceResponse<void>>(ApiType.Gateway, ApiUrl.DeleteUser, {
            organizationId: parameters.organizationId,
            email: parameters.email,
        });
    }
}
