import { faFileDownload } from '@fortawesome/pro-regular-svg-icons';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DimensionFilter } from '../../../../store/search';
import PageToolbar, { ToolbarActionId } from '../../components/page-toolbar/page-toolbar';
import { ReactComponent as ColumnsIcon } from '../../../../../assets/icons/columns-icon.svg';
import styles from '../../components/page-toolbar/page-toolbar-button.module.scss';

class ListToolbarComponent extends Component<AllProps, AllState> {
    private onFiltersChanged(filters: DimensionFilter[]): void {
        const { onFiltersChanged } = this.props;
        onFiltersChanged(filters);
    }

    private onDownloadCsv(): void {
        const { onDownloadCsv } = this.props;
        onDownloadCsv();
    }

    private onShowColumnConfiguration(): void {
        const { onShowColumnConfiguration } = this.props;
        onShowColumnConfiguration();
    }

    public render(): JSX.Element {
        const { selectedFilters, t } = this.props;

        return (
            <PageToolbar
                leftActions={[
                    {
                        uid: 'ListViewFields',
                        actionId: ToolbarActionId.ListViewFields,
                        title: t('Fields'),
                        onClick: (): void => this.onShowColumnConfiguration(),
                        customIcon: <ColumnsIcon  className={styles.buttonIcon}  />,
                    },
                ]}
                rightActions={[
                    {
                        uid: 'ListViewDownload',
                        actionId: ToolbarActionId.ListViewDownload,
                        title: t('Download'),
                        faIcon: faFileDownload,
                        onClick: (): void => this.onDownloadCsv(),
                    },
                ]}
                filters={selectedFilters}
                onFiltersChanged={(changedFilters: DimensionFilter[]) => this.onFiltersChanged(changedFilters)}
            />
        );
    }

}

interface OwnProps {
    selectedFilters: DimensionFilter[];
    onFiltersChanged: (filters: DimensionFilter[]) => void;
    onDownloadCsv: () => void;
    onShowColumnConfiguration: () => void;
}

const ListToolbar = withTranslation()(ListToolbarComponent);

export default ListToolbar;

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
