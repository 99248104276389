import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../routing/route-url';
import { ApplicationState } from '../../store';
import { closePopup } from '../../store/popup';
import styles from './unauthorized-popup.module.scss';

class UnauthorizedPopup extends Component<AllProps> {
    public render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}>{t('Session expired')}</div>
                <div className={styles.popupBody}>
                    <div className={styles.popupBodyText}>{t('You will be redirected and asked to log back in.')}</div>
                    <button
                        className={`${styles.button} ${styles.secondary} ${styles.logout}`}
                        type='button'
                        onClick={(): void => this.onOkay()}>
                        {t('Okay')}
                    </button>
                </div>
            </div>
        );
    }

    private onOkay(): void {
        const { currentLocation, dispatchNavigateTo, dispatchClosePopup } = this.props;

        const returnUrl = currentLocation
            ? currentLocation.search
                ? `${currentLocation.pathname}${currentLocation.search}`
                : currentLocation.pathname
            : '';
        const returnUrlEncoded = encodeURIComponent(returnUrl);
        const queryParameters = new URLSearchParams({ returnUrl: returnUrlEncoded }).toString();
        const newLocation =
            returnUrl !== '' ? { pathname: RouteUrl.Login, search: queryParameters } : { pathname: RouteUrl.Login };

        dispatchClosePopup();
        dispatchNavigateTo(newLocation);
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ router }: ApplicationState) => ({
    currentLocation: router.location,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UnauthorizedPopup));

interface PropsFromState {
    currentLocation: Location;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchClosePopup: typeof closePopup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
