import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './invalid-config-panel.module.scss';

class InvalidConfigPanel extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { style, t, message } = this.props;

        return (
            <div className={styles.container} style={style}>
                {message ? (
                    <div className={styles.title}>{message}</div>
                ) : (
                    <div className={styles.title}>{t('Your current configuration is not supported (yet).')}</div>
                )}
                <div className={styles.subtitle}>{t('Please change your parameters and try again.')}</div>
            </div>
        );
    }
}

export default withTranslation()(InvalidConfigPanel);

interface OwnProps {
    message?: string;
    style?: {};
}

type AllProps = OwnProps & WithTranslation;
