import { AxiosError } from 'axios';
import React from 'react';
import { put } from 'redux-saga/effects';
import UnauthorizedPopup from '../components/unauthorized-popup/unauthorized-popup';
import { generateUUID } from '../utils/uuid-helpers';
import { PopupType, showPopup } from './popup';
import { addToast, ToastType } from './toast';


export function* handleUnexpectedErrorWithToast(error: AxiosError) {
    if (error.response) {

        if (error.response.status === 401) {
            yield put(
                showPopup({
                    type: PopupType.UNAUTHORIZED,
                    content: <UnauthorizedPopup />,
                    isDismissible: false,
                }),
            );
        } else {

            const [id, message] = error.response.data.message ? error.response.data.message.split('+') : ['????', 'No error message'];

            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Error,
                    messages: [
                        'Something went wrong',
                        `Error [code: ${error.response.data.code}, id: ${id}]`,
                        message
                    ],
                }),
            );
        }
    } else {
        yield put(
            addToast({
                id: generateUUID(),
                type: ToastType.Error,
                messages: ['Something went wrong', 'Please try again or contact your admin', error && error.toString()],
            }),
        );
    }
}

export function* handleUnexpectedError(error: AxiosError) {
    if (error.response) {
        console.log(error.response);
        if (error.response.status === 401) {
            yield put(
                showPopup({
                    type: PopupType.UNAUTHORIZED,
                    content: <UnauthorizedPopup />,
                    isDismissible: false,
                }),
            );
        }
    } else {
        console.log(error);
    }
}
