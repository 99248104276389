import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchAndRelateSequenceMetaData } from '../../../api/generated';
import { closePopup } from '../../../store/popup';
import { TRANSLATION_TABLE } from '../../../types/translation-table';
import { dnaSettingsApplied } from './select-dna-frame-and-table-popup-actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 350,
            maxWidth: 350,
            textAlign: 'left',
        },

        selectEmpty: {
            marginTop: theme.spacing(4),
        },
        settings: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonDistance: {
            marginRight: theme.spacing(2),
        },
    }),
);

const FRAMES = [-3, -2, -1, 1, 2, 3];

type Props = WithTranslation & {
    sequenceInfo: SearchAndRelateSequenceMetaData;
};

function SelectDNAFrameAndTablePopupComponent(props: Props): ReactElement | null {
    const { t, sequenceInfo } = props;
    const classes = useStyles();
    const { translationFrame = '1', translationTable = '1', molType, sequence } = sequenceInfo;

    const [frame, setFrame] = useState<string | number>(translationFrame);

    const [tableValue, setTableValue] = useState(translationTable);
    const dispatch = useDispatch();

    useEffect(() => {
        setTableValue(translationTable);
    }, [translationTable]);

    const handleFrameChange = (event: React.ChangeEvent<{ value }>) => {
        setFrame(event.target.value as string);
    };

    const handleTableChange = (event: React.ChangeEvent<{ value }>) => {
        setTableValue(event.target.value);
    };

    const onSearch = () => {
        const query = `>${molType}|${frame}|${tableValue}|${sequence}`;
        dispatch(dnaSettingsApplied(query));
        dispatch(closePopup());
    };

    const closeWindow = () => {
        dispatch(closePopup());
    };

    return (
        <Box
            bgcolor='#ffffff'
            borderRadius={12}
            display='flex'
            flexDirection='column'
            maxWidth={1200}
            position='relative'
            pl={4}
            pr={4}
            pt={2}>
            <Typography variant='h5' style={{ flex: 1, color: '#000', marginBottom: 24 }}>
                {t('Translation settings for DNA')}
            </Typography>
            <div style={{ flex: 1, color: '#000', marginBottom: 16 }}>
                {t('The simple DNA sequence is detected, please specify the translation table and frame.')}
                <br />
                {t('1 | 1 are used by default')}
            </div>

            <div className={classes.settings}>
                <FormControl className={classes.formControl}>
                    <InputLabel>{t('Translation frame')}</InputLabel>
                    <Select value={frame.toString()} displayEmpty onChange={handleFrameChange}>
                        {FRAMES.map((f) => (
                            <MenuItem key={f.toString()} value={f}>
                                {f}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel>{t('Translation table')}</InputLabel>
                    <Select label={t('Translation table')} displayEmpty value={tableValue} onChange={handleTableChange}>
                        {TRANSLATION_TABLE.map((table) => (
                            <MenuItem key={table.value} value={table.value}>
                                {table.value}:&nbsp;{table.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Box mb={3} mt={4} display='flex' flexDirection='row' justifyContent='center'>
                <Button
                    onClick={onSearch}
                    variant='contained'
                    color='primary'
                    size='large'
                    className={classes.buttonDistance}>
                    {t('Search')}
                </Button>

                <Button onClick={closeWindow} variant='outlined' color='primary'>
                    {t('Cancel')}
                </Button>
            </Box>
        </Box>
    );
}

const SelectDNAFrameAndTablePopup = withTranslation()(SelectDNAFrameAndTablePopupComponent);

export default SelectDNAFrameAndTablePopup;
