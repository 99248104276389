import * as React from 'react';
import { UIEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../../store';
import { Dimension, GetSequencesResultSequenceMap, ListResultViewData } from '../../../../../../store/search';
import { ListSorting } from '../../../../../../types/sorting';
import { ColumnConfig } from './column-config';
import ResultsListHeader, { ColumnReferenceMap, ColumnWidthMap } from './results-list-header';
import ResultsListPaging from './results-list-paging';
import ResultsListRow, { RowColumnReferenceMap } from './results-list-row';
import styles from './results-list.module.scss';

class ResultsList extends React.Component<AllProps, AllState> {
    // Necessary to avoid setState which causes lag when resizing columns
    // It's fine because they don't need to trigger redraws
    private headerReferences: ColumnReferenceMap = {};
    private rowReferences: RowColumnReferenceMap = {};
    private scrollLeft = 0;

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isResizing: false,
            resizingColumnKey: '',
            resizeStartX: 0,
        };
    }

    public render(): JSX.Element {
        const {
            sortedData,
            columns,
            onShowSequenceDetails,
            sorting,
            isToolbarOpen,
            dimensions,
            page,
            onSequenceSelectionToggle,
            pageSize,
            sequenceSelection,
        } = this.props;
        const { isResizing, resizingColumnKey, resizeStartX } = this.state;

        return (
            <div className={styles.listContainer}>
                <ResultsListHeader
                    columns={columns}
                    sorting={sorting}
                    isToolbarOpen={isToolbarOpen}
                    dimensions={dimensions}
                    isResizing={isResizing}
                    resizingColumnKey={resizingColumnKey}
                    resizeStartX={resizeStartX}
                    onOpenFilter={(dimensionName: string): void => this.onOpenFilter(dimensionName)}
                    onChangeSorting={(newSorting): void => this.onChangeSorting(newSorting)}
                    onChangeWidths={(newWidths): void => this.onChangeWidths(newWidths)}
                    onChangeResizing={(newIsResizing, newResizingColumnKey, newResizeStartX): void =>
                        this.onChangeResizing(newIsResizing, newResizingColumnKey, newResizeStartX)
                    }
                    onChangeReferences={(newReferences): void => this.onChangeHeaderReferences(newReferences)}
                />
                <div
                    className={styles.listRows}
                    onScroll={(event): void => {
                        this.onScroll(event);
                    }}>
                    {sortedData.sequences.map((row, index) => {
                        return (
                            <ResultsListRow
                                key={row.id + index}
                                columns={columns}
                                data={row}
                                isSelected={sequenceSelection.includes(row.id)}
                                onSequenceSelectionToggle={(sequenceId: string): void =>
                                    onSequenceSelectionToggle(sequenceId)
                                }
                                onShowSequenceDetails={(sequence): void => onShowSequenceDetails(sequence)}
                                onChangeReferences={(rowKey, references): void =>
                                    this.onChangeRowReferences(rowKey, references)
                                }
                            />
                        );
                    })}
                    <div style={{ marginLeft: 41 }}>
                        <ResultsListPaging
                            page={page}
                            pageSize={pageSize}
                            total={sortedData.totalCount || 0}
                            onChangePage={(newPage): void => this.onChangePage(newPage)}
                        />
                    </div>
                </div>

            </div>
        );
    }

    private onScroll(event: UIEvent<HTMLDivElement>): void {
        const headerContainer = this.headerReferences.container;

        if (headerContainer && headerContainer.current) {
            headerContainer.current.scrollLeft = event.currentTarget.scrollLeft;
        }

        this.scrollLeft = event.currentTarget.scrollLeft;
    }

    private onChangeSorting(sorting: ListSorting): void {
        const { onChangeSorting } = this.props;
        onChangeSorting(sorting);
    }

    private onOpenFilter(dimensionName: string): void {
        const { onOpenFilter } = this.props;
        onOpenFilter(dimensionName);
    }

    private onChangeHeaderReferences(references: ColumnReferenceMap): void {
        this.headerReferences = references;
    }

    private onChangeRowReferences(rowKey: string, references: ColumnReferenceMap): void {
        this.rowReferences = { ...this.rowReferences, [rowKey]: references };
    }

    private onChangeWidths(widths: ColumnWidthMap): void {
        const { columns } = this.props;

        this.applyColumnWidths(columns, widths);
    }

    private onChangeResizing(isResizing: boolean, resizingColumnKey: string, resizeStartX: number): void {
        Object.keys(this.headerReferences).forEach((key) => {
            if (key === `${resizingColumnKey}ResizeHandle`) {
                const resizeHandleElement = this.headerReferences[key];

                if (resizeHandleElement && resizeHandleElement.current) {
                    resizeHandleElement.current.style.left = isResizing ? `${this.scrollLeft}px` : 'initial';
                }
            }
        });

        this.setState({ isResizing, resizingColumnKey, resizeStartX });
    }

    private onChangePage(newPage: number): void {
        const { onChangePage } = this.props;

        onChangePage(newPage);
    }

    private applyColumnWidths(columns: ColumnConfig[], widths: ColumnWidthMap): void {
        const { headerReferences, rowReferences } = this;

        columns.forEach((column) => {
            const width = `${widths[column.key]}px`;

            if (headerReferences[column.key]) {
                const headerElement = headerReferences[column.key].current;

                if (headerElement) {
                    headerElement.style.width = width;
                    headerElement.style.minWidth = width;
                }
            }

            Object.keys(rowReferences).forEach((rowKey) => {
                Object.keys(rowReferences[rowKey])
                    .filter((columnKey) => {
                        return columnKey.includes(column.key);
                    })
                    .forEach((columnKey) => {
                        const rowElement = rowReferences[rowKey][columnKey].current;

                        if (rowElement) {
                            rowElement.style.width = width;
                            rowElement.style.minWidth = width;
                        }
                    });
            });
        });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ search }: ApplicationState) => ({
    dimensions: search.dimensions,
});

export default connect(mapStateToProps, null)(withTranslation()(ResultsList));

interface PropsFromState {
    dimensions: Dimension[];
}

interface OwnProps {
    isToolbarOpen: boolean;
    columns: ColumnConfig[];
    sortedData: ListResultViewData;
    sorting: ListSorting;
    page: number;
    pageSize: number;
    sequenceSelection: string[];
    onOpenFilter: (dimensionName: string) => void;
    onChangeSorting: (sorting: ListSorting) => void;
    onShowSequenceDetails: (sequence: GetSequencesResultSequenceMap) => void;
    onChangePage: (newPage: number) => void;
    onSequenceSelectionToggle: (sequenceId: string) => void;
}

type AllProps = OwnProps & PropsFromState & WithTranslation;

interface OwnState {
    isResizing: boolean;
    resizingColumnKey: string;
    resizeStartX: number;
}

type AllState = OwnState;

export const resultListWidthPerUnit = 180;
