import { Http, WorkspaceResponse } from '../http/http';
import { CreateApiTokenRequestParameters, CreateOrganizationRequestParameters } from '../store/global';
import { ApiType, ApiUrl } from './api-urls';
import { WorkspaceCreateNewApiTokenResponse } from './generated';

class OrganizationsApi {
    public static async createOrganization(
        parameters: CreateOrganizationRequestParameters,
    ): Promise<WorkspaceResponse<any>> {
        return Http.post<CreateOrganizationRequestParameters, WorkspaceResponse<any>>(
            ApiType.Gateway,
            ApiUrl.CreateOrganization,
            parameters,
        );
    }

    public static async createNewApiToken(
        parameters: CreateApiTokenRequestParameters,
    ): Promise<WorkspaceResponse<WorkspaceCreateNewApiTokenResponse>> {
        return Http.post<CreateApiTokenRequestParameters, WorkspaceResponse<WorkspaceCreateNewApiTokenResponse>>(
            ApiType.Gateway,
            ApiUrl.CreateAPIToken,
            parameters,
        );
    }
}

export default OrganizationsApi;
