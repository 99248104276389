import { Reducer } from 'redux';
import { SharedActionTypes } from '../shared/types';
import { PopupAction, PopupActionTypes, PopupState } from './types';

function initialState(): PopupState {
    return {
        popup: undefined,
    };
}

const reducer: Reducer<PopupState, PopupAction> = (state = initialState(), action): PopupState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case PopupActionTypes.SHOW_POPUP: {
            return { ...state, popup: action.payload };
        }
        case PopupActionTypes.CLOSE_POPUP: {
            return { ...state, popup: undefined };
        }
        default: {
            return state;
        }
    }
};

export { reducer as popupReducer };
export { initialState as initialPopupState };
