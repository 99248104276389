import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { ApplicationAction, AuthorizationContext, GlobalActionTypes, GlobalState, InstanceType } from './types';

function initialState(): GlobalState {
    return {
        instanceType: InstanceType.Public,
        authorizationInProgress: false,
        initialAuthorizationFlowHasFinished: false,
    };
}

const reducer: Reducer<GlobalState, ApplicationAction> = (state = initialState(), action): GlobalState => {
    switch (action.type) {
        case GlobalActionTypes.SET_INSTANCE_TYPE:
            return { ...state, instanceType: action.payload };
        case GlobalActionTypes.GET_AUTHORIZATION_CONTEXT: {
            return { ...state, authorizationInProgress: true };
        }
        case GlobalActionTypes.GET_AUTHORIZATION_CONTEXT_SUCCESS: {
            const context: AuthorizationContext = action.payload;
            context.memberships.sort((a, b) => (a.workspace.name > b.workspace.name ? 1 : -1));
            return { ...state, authorizationInProgress: false, authorizationContext: context };
        }
        case GlobalActionTypes.GET_AUTHORIZATION_CONTEXT_FAILURE: {
            return { ...state };
        }
        case GlobalActionTypes.SET_ACTIVE_WORKSPACE: {
            return { ...state, activeWorkspaceId: action.payload };
        }
        case GlobalActionTypes.CREATE_ORGANIZATION_REQUEST: {
            return {
                ...state,
                createOrganizationRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case GlobalActionTypes.CREATE_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                createOrganizationRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case GlobalActionTypes.CREATE_ORGANIZATION_FAILURE: {
            return {
                ...state,
                createOrganizationRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case GlobalActionTypes.MARK_INITIAL_AUTHORIZATION_FLOW_AS_FINISHED: {
            if (!state.initialAuthorizationFlowHasFinished) {
                return { ...state, initialAuthorizationFlowHasFinished: true };
            } else {
                return state;
            }
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return { ...initialState(), initialAuthorizationFlowHasFinished: true };
        default: {
            return state;
        }
    }
};

export { reducer as globalReducer };
export { initialState as initialGlobalState };
