import { SearchAggregatesResultSummary } from '../types';

export function getDistinctElementNamesForSingleDimensionInSummaries(
    summaries: SearchAggregatesResultSummary[],
    dimensionName: string,
): string[] {
    const result: string[] = [];
    if (summaries) {
        summaries.forEach((s) => {
            const values: string[] = s[dimensionName];
            if (values) {
                values.forEach((value) => {
                    if (!result.includes(value)) {
                        result.push(value);
                    }
                });
            }
        });
    }
    return result;
}
