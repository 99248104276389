import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dataset } from '../../../../store/datasets';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './dataset-selection.module.scss';

class DatasetSelection extends React.Component<AllProps, AllState> {
    public render(): JSX.Element | null {
        const { availableDatasets, selectedDatasetIds, emptyMessage, maxHeightOffset, t } = this.props;

        return availableDatasets.length === 0 && emptyMessage ? (
            <div style={{ maxHeight: `calc(100vh - ${maxHeightOffset}px)` }}>{emptyMessage}</div>
        ) : (
            <div style={{ maxHeight: `calc(100vh - ${maxHeightOffset}px)` }}>
                <div className={styles.elementsToolbar}>
                    <div onClick={(): void => this.toggleAllNone(true)} className={styles.link}>
                        {t('All')}
                    </div>
                    |
                    <div className={styles.link} onClick={(): void => this.toggleAllNone(false)}>
                        {t('None')}
                    </div>
                </div>
                <div
                    className={styles.elementsContainer}
                    style={{ maxHeight: `calc(100vh - ${maxHeightOffset + 30}px)` }}>
                    {availableDatasets.map((d) => {
                        const isIncluded = selectedDatasetIds.includes(d.id);
                        const indicatorClasses = conditionalClassList(styles, {
                            indicator: true,
                            included: isIncluded,
                        });
                        const elementClasses = conditionalClassList(styles, {
                            elementItem: true,
                            included: isIncluded,
                        });
                        return (
                            <div
                                key={d.id}
                                className={elementClasses}
                                onClick={(): void => this.toggleDataset(!isIncluded, d)}>
                                <div className={indicatorClasses}>
                                    {isIncluded ? <FontAwesomeIcon icon={faCheck} /> : null}
                                </div>
                                <div className={styles.caption}>{d.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    private toggleDataset(include: boolean, dataset: Dataset): void {
        const { selectedDatasetIds, onSelectionChange } = this.props;

        if (include) {
            onSelectionChange(selectedDatasetIds.concat(dataset.id));
        } else {
            onSelectionChange(selectedDatasetIds.filter((id) => id !== dataset.id));
        }
    }

    private toggleAllNone(include: boolean): void {
        const { availableDatasets, onSelectionChange } = this.props;

        if (include) {
            onSelectionChange(availableDatasets.map((d) => d.id));
        } else {
            onSelectionChange([]);
        }
    }
}

export default withTranslation()(DatasetSelection);

interface OwnProps {
    availableDatasets: Dataset[];
    selectedDatasetIds: string[];
    emptyMessage?: string;
    maxHeightOffset: number;
    onSelectionChange: (selectedDatasetIds: string[]) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
