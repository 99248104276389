import { push } from 'connected-react-router';
import * as FileSaver from 'file-saver';
import { Location, LocationDescriptorObject } from 'history';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AlignAlignResponse, SharedMolType } from '../../../../api/generated';
import EmptyPanel from '../../../../components/empty-panel/empty-panel';
import { HelpLinkUrl } from '../../../../components/help-link/help-link-urls';
import LoadingPanel from '../../../../components/loading/loading-panel';
import ResultsPageHeader from '../../../../components/page-header/results-page-header/results-page-header';
import TooManyResults from '../../../../components/wrong-page/too-many-results';
import SomethingWentWrong from '../../../../components/wrong-page/wrong-page';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import {
    AlignmentSearchParameters,
    alignmentSearchRequest,
    ALIGNMENT_RESULT_LIMIT,
    BaseSearchParameters,
    baseSearchRequest,
    closeToolbarRequest,
    DimensionFilter,
    rootSearchRequest,
} from '../../../../store/search';
import { updateFilters } from '../../../../store/search/filter-actions';
import { DimensionFilterHelper } from '../../../../store/search/helpers/dimension-filter-helper';
import { queryMolTypeSelector } from '../../../../store/search/metadata-selectors';
import { getDownloadFileName } from '../../../../utils/get-file-name-util';
import ResultsNavigationBar from '../../components/results-navigation-bar/results-navigation-bar';
import ResultsPageOverlay from '../../components/results-page-overlay/results-page-overlay';
import { SEQUENCE_DIMENSION_NAME } from '../../constants/dimension-constants';
import { ResultViewBasePage, ResultViewStatus } from '../../result-view-base.page';
import { DimensionFiltersComparator } from '../../utils/dimension-filters-comparator';
import ResultsListPaging from '../list-view/components/results-list/results-list-paging';
import { AlignmentInputValidator } from './alignment-input-validator';
import {
    aaTotalCountSelector,
    availableMolTypesSelector,
    defaultMolTypeSelector,
    dnaTotalCountSelector,
} from './alignment-page-selectors';
import AlignmentViewer from './alignment-page-viewer';
import AlignmentToolbar from './alignment-toolbar';
import { AlignmentResultViewUrlParameters, AlignmentUrlParametersHandler } from './alignment-url-parameters-handler';
import { Highlight } from './alignment-view-types';
import styles from './alignment.module.scss';
import { AlignmentCsvCreator } from './utils/alignment-csv-creator';

class AlignmentPage extends ResultViewBasePage<AllProps, AllState, AlignmentResultViewUrlParameters> {
    constructor(props: AllProps) {
        super(props, new AlignmentUrlParametersHandler(), new AlignmentInputValidator());
        this.state = {
            selectedFilters: [],
            isFilterChanged: false,
        };

        // Make sure defaults are applied to url state
        this.updateUrl(this.urlParameters);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        super.componentDidUpdate(prevProps, prevState);
        const { alignmentResultViewData, alignmentTooManyResults } = this.props;
        const { selectedFilters } = this.state;

        const dataChanged = alignmentResultViewData !== prevProps.alignmentResultViewData;

        // Execute new search when URL state is different from last executed search
        if (this.needsNewSearch(this.urlParameters)) {
            this.executeAlignmentViewSearch();
        } else if ((alignmentResultViewData && dataChanged) || alignmentTooManyResults) {
            if (!DimensionFiltersComparator.equals(selectedFilters, this.urlParameters.activeFilters)) {
                this.setState({ selectedFilters: this.urlParameters.activeFilters });
            }
        }
    }

    private onChangePage(newPage: number): void {
        const { dispatchAlignmentSearchRequest, baseQueryId, availableMolTypes, defaultMolType } = this.props;

        const { rootQuery, activeFilters, activePageSize, molType } = this.urlParameters;

        const mt = (availableMolTypes.indexOf(molType as string) > -1 ? molType : defaultMolType) as
            | SharedMolType.AA
            | SharedMolType.DNA;

        this.updateUrl({
            activePageSize,
            rootQuery,
            activeFilters,
            molType: mt,
            activePage: newPage,
        });

        dispatchAlignmentSearchRequest({
            rootQuery,
            filters: activeFilters,
            baseQueryId,
            page: newPage,
            pageSize: activePageSize,
            molType: mt,
        });
    }

    public render(): JSX.Element {
        const { location, isToolbarOpen, availableMolTypes, defaultMolType, queryMolType, totalResults } = this.props;
        const { selectedFilters } = this.state;
        const { activeFilters, molType = defaultMolType } = this.urlParameters;

        const viewStatus = this.determineViewStatus();
        const mt = availableMolTypes.indexOf(molType as string) > -1 ? molType : defaultMolType;

        return (
            <div className={styles.container}>
                <ResultsPageHeader filters={activeFilters} />
                <ResultsNavigationBar
                    isNavigationDisabled={viewStatus === ResultViewStatus.inProgress}
                    location={location}
                    navigateTo={(url: RouteUrl, search) => this.navigateTo(url, search)}
                    helpUrl={HelpLinkUrl.AlignmentView}
                    totalResults={totalResults}
                />
                <AlignmentToolbar
                    selectedFilters={selectedFilters}
                    onFiltersChanged={(filters: DimensionFilter[]) => this.onFiltersChanged(filters)}
                    onDownloadCsv={() => this.onDownloadCsv()}
                    onUseAAMolType={() => this.onUseAAMolType()}
                    onUseDNAMolType={() => this.onUseDNAMolType()}
                    currentMolType={mt}
                    originalMolType={queryMolType}
                    availableMolTypes={availableMolTypes}
                />

                <div className={styles.pageContentContainer}>
                    <div className={styles.pageContent}>
                        <ResultsPageOverlay show={isToolbarOpen} onClick={() => this.overlayClicked()} />
                        {this.renderViewContent(viewStatus)}
                    </div>
                </div>
            </div>
        );
    }

    onMotifFilterApply = (h: Highlight) => {
        const { selectedFilters } = this.state;
        const { dispatchUpdateFilters } = this.props;
        // FIX FILTER FOR HIGHLIGHT!
        const updatedFilters = DimensionFilterHelper.cloneFilters(selectedFilters);
        DimensionFilterHelper.updatePlaceholderFilter(
            updatedFilters,
            SEQUENCE_DIMENSION_NAME,
            h.pattern || '',
            h.exclude,
        );
        this.setState({ selectedFilters: updatedFilters, highlighted: h });
        dispatchUpdateFilters(updatedFilters);
    };

    protected renderViewContent(viewStatus: ResultViewStatus): JSX.Element {
        const {
            alignmentResultViewData,
            t,
            availableMolTypes,
            defaultMolType,
            aaResults,
            dnaResults,
            queryMolType,
            totalResults,
            location,
        } = this.props;
        const { highlighted } = this.state;
        const { molType, activePage, activePageSize } = this.urlParameters;
        const mt = (availableMolTypes.indexOf(molType as string) > -1 ? molType : defaultMolType) as
            | SharedMolType.AA
            | SharedMolType.DNA;

        const total = mt === SharedMolType.AA ? aaResults : dnaResults;

        const targetMolType = queryMolType === SharedMolType.AA ? SharedMolType.AA : mt;

        switch (viewStatus) {
            case ResultViewStatus.tooManyResults:
                return (
                    <TooManyResults
                        location={location}
                        message={`The Alignment View is not shown as you have more than ${ALIGNMENT_RESULT_LIMIT} results/matches. Please use the Quick Filter to bring the number of matches down to below ${ALIGNMENT_RESULT_LIMIT} to see the list`}
                    />
                );

            case ResultViewStatus.inProgress:
                return (
                    <LoadingPanel
                        text={t(
                            totalResults > 1000
                                ? 'A lot of sequences were found. It can take up to a few minutes to align them...'
                                : 'Aligning the sequences...',
                        )}
                    />
                );
            case ResultViewStatus.error:
                return <SomethingWentWrong />;
            case ResultViewStatus.emptyResult:
                return <EmptyPanel />;
            default:
                return (
                    <div className={styles.viewerContainer}>
                        <AlignmentViewer
                            alignmentResultViewData={alignmentResultViewData}
                            targetMolType={targetMolType}
                            onFilterApply={this.onMotifFilterApply}
                            highlighted={highlighted}
                        />

                        <ResultsListPaging
                            page={activePage}
                            pageSize={activePageSize}
                            total={total}
                            onChangePage={(newPage) => this.onChangePage(newPage)}
                        />
                    </div>
                );
        }
    }

    public determineViewStatus(): ResultViewStatus {
        const {
            rootSearchRequestInProgress,
            alignmentRequestInProgress,
            baseSearchRequestInProgress,
            alignmentResultViewData,
            alignmentRequestInError,
            alignmentTooManyResults
        } = this.props;

        if (alignmentRequestInError) return ResultViewStatus.error;

        if (alignmentTooManyResults) {
            return ResultViewStatus.tooManyResults;
        }
        else if (rootSearchRequestInProgress || alignmentRequestInProgress || baseSearchRequestInProgress) {
            return ResultViewStatus.inProgress;
        } else if (
            !alignmentResultViewData ||
            !alignmentResultViewData.sequences ||
            alignmentResultViewData.sequences.length === 0
        ) {
            return ResultViewStatus.emptyResult;
        } else {
            return ResultViewStatus.ready;
        }
    }

    private executeAlignmentViewSearch(): void {
        const {
            dispatchBaseSearchRequest,
            dispatchAlignmentSearchRequest,
            baseSearchParameters,
            baseQueryId,
            defaultMolType,
            availableMolTypes,
        } = this.props;

        if (!baseSearchParameters || this.baseParametersOutOfSync(this.urlParameters, baseSearchParameters)) {
            dispatchBaseSearchRequest({
                rootQuery: this.urlParameters.rootQuery,
                filters: this.urlParameters.activeFilters,
            });
        } else {
            const { rootQuery, activeFilters, activePage, activePageSize, molType } = this.urlParameters;

            dispatchAlignmentSearchRequest({
                rootQuery,
                filters: activeFilters,
                baseQueryId,
                page: activePage,
                pageSize: activePageSize,
                molType: (availableMolTypes.indexOf(molType as string) > -1 ? molType : defaultMolType) as
                    | SharedMolType.AA
                    | SharedMolType.DNA,
            });
        }
    }

    private needsNewSearch(urlParameters: AlignmentResultViewUrlParameters): boolean {
        const {
            alignmentSearchParameters,
            alignmentRequestInProgress,
            baseSearchRequestInProgress,
            alignmentRequestInError,
            alignmentTooManyResults
        } = this.props;

        // api calls already in progress or in error
        if (
            this.initializingRootQuery ||
            alignmentRequestInProgress ||
            baseSearchRequestInProgress ||
            alignmentRequestInError ||
            alignmentTooManyResults
        ) {
            return false;
        }

        if (!alignmentSearchParameters) {
            return true;
        }

        return this.baseParametersOutOfSync(urlParameters, alignmentSearchParameters);
    }

    protected overlayClicked(): void {
        super.overlayClicked();
        const { dispatchUpdateFilters } = this.props;
        const { selectedFilters, isFilterChanged } = this.state;

        if (isFilterChanged) dispatchUpdateFilters(selectedFilters);

        this.setState({ isFilterChanged: false });
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        this.setState({ selectedFilters: filters, isFilterChanged: true });
    }

    private onDownloadCsv(): void {
        const { alignmentResultViewData, rootQuery, t } = this.props;

        if (alignmentResultViewData && alignmentResultViewData.sequences) {
            const csv = AlignmentCsvCreator.createCsvString(alignmentResultViewData.sequences, t);
            const fileName = `BioStrand-${getDownloadFileName(rootQuery)}-alignment data.csv`;
            const blob = new Blob([csv], { type: 'text/csv' });

            FileSaver.saveAs(blob, fileName);
        }
    }

    private onUseAAMolType(): void {
        const { dispatchAlignmentSearchRequest, baseQueryId } = this.props;

        const { rootQuery, activeFilters, activePageSize } = this.urlParameters;
        this.updateUrl({
            rootQuery,
            activeFilters,
            activePageSize,
            activePage: 1,
            molType: SharedMolType.AA,
        });

        dispatchAlignmentSearchRequest({
            rootQuery,
            filters: activeFilters,
            baseQueryId,
            page: 1,
            pageSize: activePageSize,
            molType: SharedMolType.AA,
        });
    }

    private onUseDNAMolType(): void {
        const { dispatchAlignmentSearchRequest, baseQueryId } = this.props;

        const { rootQuery, activeFilters, activePageSize } = this.urlParameters;
        this.updateUrl({
            rootQuery,
            activeFilters,
            activePage: 1,
            activePageSize,
            molType: SharedMolType.DNA,
        });
        dispatchAlignmentSearchRequest({
            rootQuery,
            filters: activeFilters,
            baseQueryId,
            pageSize: activePageSize,
            page: 1,
            molType: SharedMolType.DNA,
        });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = (state: ApplicationState) => {
    const { search, router, global } = state;
    return {
        baseQueryId: search.baseQueryId,
        rootQuery: search.rootQuery,
        currentLocation: router.location,
        isToolbarOpen: search.isToolbarOpen,
        rootSearchRequestInProgress: search.rootSearchRequestInProgress,
        alignmentResultViewData: search.alignmentResultViewData,
        alignmentRequestInProgress: search.alignmentRequestInProgress,
        alignmentRequestInError: search.alignmentRequestInError,
        alignmentSearchParameters: search.alignmentSearchParameters,
        alignmentTooManyResults: search.alignmentTooManyResults,
        baseSearchParameters: search.baseSearchParameters,
        baseSearchRequestInProgress: search.baseSearchRequestInProgress,
        activeWorkspaceId: global.activeWorkspaceId,
        availableMolTypes: availableMolTypesSelector(state),
        defaultMolType: defaultMolTypeSelector(state),
        totalResults: search.queryMatches,
        aaResults: aaTotalCountSelector(state),
        dnaResults: dnaTotalCountSelector(state),
        queryMolType: queryMolTypeSelector(state),
    };
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchRootSearchRequest: (workspaceId, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchCloseToolbar: () => dispatch(closeToolbarRequest()),
    dispatchAlignmentSearchRequest: (parameters: AlignmentSearchParameters) =>
        dispatch(alignmentSearchRequest(parameters)),
    dispatchBaseSearchRequest: (parameters: BaseSearchParameters) => dispatch(baseSearchRequest(parameters)),
    dispatchUpdateFilters: (parameters: DimensionFilter[]) => dispatch(updateFilters(parameters)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AlignmentPage));

interface PropsFromState {
    baseQueryId: string;
    rootQuery: string;
    currentLocation: Location;
    isToolbarOpen: boolean;
    rootSearchRequestInProgress: boolean;
    alignmentResultViewData?: AlignAlignResponse;
    alignmentRequestInProgress: boolean;
    alignmentRequestInError: boolean;
    alignmentSearchParameters?: AlignmentSearchParameters;
    alignmentTooManyResults?: boolean;
    baseSearchParameters?: BaseSearchParameters;
    baseSearchRequestInProgress: boolean;
    activeWorkspaceId?: string;
    availableMolTypes: string[];
    defaultMolType: string;
    queryMolType?: SharedMolType.AA | SharedMolType.DNA;
    totalResults: number;
    aaResults: number;
    dnaResults: number;
}

interface PropsFromDispatch {
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchCloseToolbar: typeof closeToolbarRequest;
    dispatchAlignmentSearchRequest: typeof alignmentSearchRequest;
    dispatchBaseSearchRequest: typeof baseSearchRequest;
    dispatchUpdateFilters: typeof updateFilters;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedFilters: DimensionFilter[];
    isFilterChanged: boolean;
    highlighted?: Highlight;
}

type AllState = OwnState;
