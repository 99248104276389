import { CollectionUtil } from '../../../utils/collection-util';
import { Dimension, DimensionFilter, DimensionWithAllElements } from '../types';

export class DimensionFilterHelper {
    public static hasFilterOnDimension(filters: DimensionFilter[], dimension: string): boolean {
        const index = filters.findIndex((f) => dimension === f.dimensionName);

        if (index === -1) {
            return false;
        } else {
            const filter = filters[index];
            return (
                filter.excludesAll || !!filter.elementNamesContains || (!!filter.includes && filter.includes.length > 0)
            );
        }
    }

    public static getExcludedElements(
        filters: DimensionFilter[],
        dimensionName: string,
        allElements: string[],
    ): string[] {
        const filter = filters.find((f) => dimensionName === f.dimensionName);
        return filter ? allElements.filter((e) => !filter.includes?.includes(e)) : [];
    }

    public static removeFilter(filters: DimensionFilter[], dimensionName: string): void {
        const filter = filters.find((f) => f.dimensionName === dimensionName);
        CollectionUtil.removeIfExists(filters, filter);
    }

    public static updatePlaceholderFilter(
        filters: DimensionFilter[],
        dimensionName: string,
        elementNamesContain: string | string[],
        excludeAll = false
    ): void {
        const value = elementNamesContain.toString().trim();
        const filter = filters.find((f) => f.dimensionName === dimensionName);

        if (value.length > 0) {
            if (filter) {
                filter.elementNamesContains = value;
                filter.excludesAll = excludeAll
            } else {
                filters.push({
                    elementNamesContains: value,
                    dimensionName,
                    excludesAll: excludeAll,
                });
            }
        } else {
            CollectionUtil.removeIfExists(filters, filter);
        }
    }

    public static updateMergeElementsFilter(
        filters: DimensionFilter[],
        include: boolean,
        dimensionName: string,
        elementNames: string[],
        allElements: string[],
    ): void {
        const filter = filters.find((f) => f.dimensionName === dimensionName);

        if (filter) {
            if (filter.includes && filter.includes.length > 0) {
                if (include) {
                    CollectionUtil.addAllIfNotExists(filter.includes, elementNames);
                    if (filter.includes.length === allElements.length) {
                        CollectionUtil.removeIfExists(filters, filter);
                    }
                } else {
                    CollectionUtil.removeAllIfExists(filter.includes, elementNames);
                    if (filter.includes.length === 0) {
                        CollectionUtil.removeIfExists(filters, filter);
                        filters.push({
                            dimensionName,
                            excludesAll: true,
                        });
                    }
                }
            } else if (filter.excludesAll) {
                this.updateReplaceElementsFilter(filters, include, dimensionName, elementNames, allElements);
            }
        } else {
            this.updateReplaceElementsFilter(filters, include, dimensionName, elementNames, allElements);
        }
    }

    public static updateReplaceElementsFilter(
        filters: DimensionFilter[],
        include: boolean,
        dimensionName: string,
        elementNames: string[],
        allElements: string[],
    ): void {
        const filter = filters.find((f) => f.dimensionName === dimensionName);
        CollectionUtil.removeIfExists(filters, filter);
        if (include) {
            filters.push({
                dimensionName,
                includes: elementNames,
                excludesAll: false,
            });
        } else if (elementNames.length === allElements.length) {
            filters.push({
                dimensionName,
                excludesAll: true,
            });
        } else {
            filters.push({
                dimensionName,
                includes: allElements.filter((e) => !elementNames.includes(e)),
                excludesAll: false,
            });
        }
    }

    public static cloneFilter(filter: DimensionFilter): DimensionFilter {
        return { ...filter };
    }

    public static cloneFilters(filters: DimensionFilter[]): DimensionFilter[] {
        return filters.map((f) => DimensionFilterHelper.cloneFilter(f));
    }

    public static isElementIncluded(filter: DimensionFilter, element: string): boolean {
        if (filter.includes && filter.includes.length > 0) {
            return filter.includes.includes(element);
        } else {
            return !filter.excludesAll;
        }
    }

    public static updateContextualFilters(
        selectedFilters: DimensionFilter[],
        selectedRowElements: string[][],
        selectedColumnElements: string[][],
        activeRowSelection: string[],
        activeColumnSelection: string[],
        dimensions: Dimension[],
    ): DimensionFilter[] {
        const updatedFilters = DimensionFilterHelper.cloneFilters(selectedFilters);

        if (selectedRowElements.length > 0) {
            this.updateContextualFilter(
                updatedFilters,
                selectedFilters,
                selectedRowElements,
                activeRowSelection,
                dimensions,
            );
        }

        if (selectedColumnElements.length > 0) {
            this.updateContextualFilter(
                updatedFilters,
                selectedFilters,
                selectedColumnElements,
                activeColumnSelection,
                dimensions,
            );
        }

        return updatedFilters;
    }

    public static updateContextualFilter(
        updatedFilters: DimensionFilter[],
        selectedFilters: DimensionFilter[],
        selectedElements: string[][],
        activeSelection: string[],
        dimensions: Dimension[],
    ): void {
        activeSelection.forEach((activeDimension, index) => {
            const elementsToInclude = selectedElements.map((el) => el[index]);
            DimensionFilterHelper.updateReplaceElementsFilter(
                updatedFilters,
                true,
                activeDimension,
                elementsToInclude,
                this.getAllElementsForDimension(dimensions, activeDimension),
            );
        });
    }

    public static getAllElementsForDimension(dimensions: Dimension[], dimensionName: string): string[] {
        const dimension = dimensions.find((d) => d.name === dimensionName);
        return dimension && (dimension as DimensionWithAllElements).elements ? (dimension as DimensionWithAllElements).elements.map((e) => e.name) : [];
    }
}
