import { action } from 'typesafe-actions';
import { FilterActionTypes } from './filter-types';
import { DimensionFilter } from './types';


export const updateFilters = (parameters: DimensionFilter[]) =>
    action(FilterActionTypes.UPDATE_FILTERS, parameters);

export const removeFilterItem = (name: string) =>
    action(FilterActionTypes.REMOVE_FILTER_ITEM, name);
