import { ActionType } from 'typesafe-actions';
import { User } from '../authentication';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import { SubscriptionStatus } from '../subscription';
import * as actions from './actions';

export enum GlobalActionTypes {
    SET_INSTANCE_TYPE = '@@global/SET_INSTANCE_TYPE',
    SET_ACTIVE_WORKSPACE = '@@global/SET_ACTIVE_WORKSPACE',
    MARK_INITIAL_AUTHORIZATION_FLOW_AS_FINISHED = '@@global/MARK_AUTHORIZATION_FLOW_AS_FINISHED',

    GET_AUTHORIZATION_CONTEXT = '@@global/GET_AUTHORIZATION_CONTEXT',
    GET_AUTHORIZATION_CONTEXT_SUCCESS = '@@global/GET_AUTHORIZATION_CONTEXT_SUCCESS',
    GET_AUTHORIZATION_CONTEXT_FAILURE = '@@global/GET_AUTHORIZATION_CONTEXT_FAILURE',

    CREATE_ORGANIZATION_REQUEST = '@@global/CREATE_ORGANIZATION_REQUEST',
    CREATE_ORGANIZATION_SUCCESS = '@@global/CREATE_ORGANIZATION_SUCCESS',
    CREATE_ORGANIZATION_FAILURE = '@@global/CREATE_ORGANIZATION_FAILURE',
}

export type ApplicationAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export enum InstanceType {
    Public,
    Private,
}

export interface GlobalState {
    // this flag indicates that the system is still trying to restore previous session
    readonly authorizationInProgress: boolean;
    readonly instanceType: InstanceType;
    readonly authorizationContext?: AuthorizationContext;
    readonly activeWorkspaceId?: string;
    readonly initialAuthorizationFlowHasFinished: boolean;
    readonly createOrganizationRequestStatus?: RequestStatus;
}

export interface AuthorizationContext {
    user: User;
    organization: Organization;
    memberships: Membership[];
    subscription: SubscriptionStatus;
}

export interface Organization {
    id: string;
    name: string;
}

export interface Membership {
    workspace: Workspace;
    workspaceRole: WorkspaceRole;
    isDefault: boolean;
}

export interface Workspace {
    id: string;
    name: string;
    organizationId: string;
}

export interface CreateOrganizationRequestParameters {
    email: string;
    organizationName: string;
    organizationRole: OrganizationRole;
}

export interface CreateApiTokenRequestParameters {
    organizationId: string;
}

export enum OrganizationRole {
    REGULAR = 'REGULAR',
    ADMIN = 'ADMIN',
}

export enum WorkspaceRole {
    REGULAR = 'REGULAR',
    ADMIN = 'ADMIN',
}

export enum Feature {
    ComputedFields = 'computedFields',
}
