export const TRANSLATION_TABLE = [
    {
        value: '1',
        label: 'Standard',
    },
    {
        value: '2',
        label: 'Vertebrate mitochondrial',
    },
    {
        value: '3',
        label: 'Yeast mitochondrial',
    },
    {
        value: '4',
        label: 'Mold, protozoan, and coelenterate mitochondrial code and the mycoplasma/spiroplasma',
    },
    {
        value: '5',
        label: 'Invertebrate mitochondrial',
    },
    {
        value: '6',
        label: 'Ciliate, dasycladacean and hexamita nuclear',
    },
    // {
    //     value: '7',
    //     label: 'The kinetoplast code; cf. table 4.',
    // },
    // {
    //     value: '8',
    //     label: 'cf. table 1.',
    // },
    {
        value: '9',
        label: 'Echinoderm and flatworm mitochondrial',
    },
    {
        value: '10',
        label: 'Euplotid nuclear',
    },
    {
        value: '11',
        label: 'Bacterial, archaeal and plant plastid',
    },
    {
        value: '12',
        label: 'Alternative yeast nuclear',
    },
    {
        value: '13',
        label: 'Ascidian mitochondrial',
    },
    {
        value: '14',
        label: 'Alternative flatworm mitochondrial',
    },
    {
        value: '15',
        label: 'Blepharisma nuclear',
    },
    {
        value: '16',
        label: 'Chlorophycean mitochondrial',
    },
    {
        value: '21',
        label: 'Trematode mitochondrial',
    },
    {
        value: '22',
        label: 'Scenedesmus obliquus mitochondrial',
    },
    {
        value: '23',
        label: 'Thraustochytrium mitochondrial',
    },
    {
        value: '24',
        label: 'Pterobranchia mitochondrial',
    },
    {
        value: '25',
        label: 'Candidate division SR1 and gracilibacteria',
    },
    {
        value: '26',
        label: 'Pachysolen tannophilus nuclear',
    },
    {
        value: '27',
        label: 'Karyorelict nuclear',
    },
    {
        value: '28',
        label: 'Condylostoma nuclear',
    },
    {
        value: '29',
        label: 'Mesodinium nuclear',
    },
    {
        value: '30',
        label: 'Peritrich nuclear',
    },
    {
        value: '31',
        label: 'Blastocrithidia nuclear',
    },
    {
        value: '32',
        label: 'Balanophoraceae plastid',
    },
    {
        value: '33',
        label: 'Cephalodiscidae mitochondrial',
    },
];
