import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import AdminMenuItemComponent from './admin-menu-item.component';
import styles from './admin-menu.module.scss';

class AdminMenu extends React.Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);
    }

    public render(): JSX.Element {
        const { t, menuItems } = this.props;

        return (
            <div className={styles.menuContainer}>
                {menuItems.map((item) => {
                    return (
                        <AdminMenuItemComponent
                            key={item.label}
                            caption={t(item.label)}
                            isActive={this.isActive(item.url)}
                            onClick={() => this.navigateTo(item.url)}
                        />
                    );
                })}
            </div>
        );
    }

    private isActive(url: RouteUrl): boolean {
        const { currentLocation } = this.props;
        return currentLocation.pathname.includes(url);
    }

    private navigateTo(route: RouteUrl): void {
        const { dispatchNavigateTo, activeWorkspaceId, currentLocation } = this.props;
        dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${route}`, search: currentLocation.search });
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ router, global }: ApplicationState) => ({
    currentLocation: router.location,
    activeWorkspaceId: global.activeWorkspaceId,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminMenu));

interface PropsFromState {
    currentLocation: Location;
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

interface OwnProps {
    menuItems: MenuItem[];
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {}

type AllState = OwnState;

export interface MenuItem {
    label: string;
    url: RouteUrl;
}
