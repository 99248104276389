import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import {
    AuthenticationActionTypes,
    ConfirmPasswordParameters,
    Credentials,
    PasswordChallengeParameters,
} from './types';

export const loginRequest = (credentials: Credentials) => action(AuthenticationActionTypes.LOGIN_REQUEST, credentials);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginFailure = (errorCode?: FailureCodes) => action(AuthenticationActionTypes.LOGIN_FAILURE, errorCode);
export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);

export const forgotPasswordRequest = (email: string) =>
    action(AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST, email);
export const forgotPasswordSuccess = () => action(AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordFailure = (errorCode?: FailureCodes) =>
    action(AuthenticationActionTypes.FORGOT_PASSWORD_FAILURE, errorCode);

export const completePasswordChallengeRequest = (parameters: PasswordChallengeParameters) =>
    action(AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_REQUEST, parameters);
export const completePasswordChallengeSuccess = () =>
    action(AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_SUCCESS);
export const completePasswordChallengeFailure = (errorCode?: FailureCodes) =>
    action(AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_FAILURE, errorCode);

export const confirmNewPasswordRequest = (parameters: ConfirmPasswordParameters) =>
    action(AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_REQUEST, parameters);
export const confirmNewPasswordSuccess = () => action(AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_SUCCESS);
export const confirmNewPasswordFailure = (errorCode?: FailureCodes) =>
    action(AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_FAILURE, errorCode);
