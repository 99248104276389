import * as React from 'react';
import { addToast, defaultRemoveAfterMilliseconds, ToastType } from '../../store/toast';
import { generateUUID } from '../../utils/uuid-helpers';

export abstract class AdminBasePage<TProps extends AdminBasePageProps, TState> extends React.Component<TProps, TState> {
    protected showToast(type: ToastType, message: string): void {
        const { dispatchAddToast } = this.props;

        dispatchAddToast({
            id: generateUUID(),
            messages: [message],
            type,
            removeAfterMilliseconds: type === ToastType.Success ? defaultRemoveAfterMilliseconds : undefined,
        });
    }
}

export interface AdminBasePageProps {
    dispatchAddToast: typeof addToast;
}
