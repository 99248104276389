import { AlignAlignedSequence } from '../../../../../api/generated';


export class AlignmentCsvCreator {
    public static createCsvString(fastas:  AlignAlignedSequence[], t: any): string {
        const bom = '\uFEFF';
        const newLine = '\r\n';
        const separator = ';';

        const csvColumns = `${t('Description')}${separator}${t('Sequence')}${separator}${t('Sequence ID')}${newLine}`;
        const csvRows = fastas.reduce((concatenatedRows, fasta) => {
            return `${concatenatedRows}${fasta.description}${separator}${fasta.sequence}${separator}${fasta.sequenceId}${newLine}`;
        }, '');

        return bom + csvColumns + csvRows;
    }
}
