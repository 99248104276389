import { Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { Location, LocationDescriptorObject } from 'history';
import intersection from 'lodash.intersection';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import EmptyPanel from '../../../../components/empty-panel/empty-panel';
import { HelpLinkUrl } from '../../../../components/help-link/help-link-urls';
import InvalidConfigPanel from '../../../../components/invalid-config-panel/invalid-config-panel';
import LoadingPanel from '../../../../components/loading/loading-panel';
import ResultsPageHeader from '../../../../components/page-header/results-page-header/results-page-header';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import {
    BaseSearchParameters,
    baseSearchRequest,
    closeToolbarRequest,
    Dimension,
    DimensionFilter,
    DimensionWithAllElements,
    QuickFilterResultViewData,
    QuickFilterSearchParameters,
    quickFilterSearchRequest,
    rootSearchRequest,
    TAXONOMY_TREE_DIMENSION,
} from '../../../../store/search';
import { updateFilters } from '../../../../store/search/filter-actions';
import { CollectionUtil } from '../../../../utils/collection-util';
import ResultsNavigationBar from '../../components/results-navigation-bar/results-navigation-bar';
import ResultsPageOverlay from '../../components/results-page-overlay/results-page-overlay';
import { defaultSelectedDimensionNames } from '../../constants/dimension-constants';
import { ResultViewBasePage, ResultViewStatus } from '../../result-view-base.page';
import { ValidationResult } from '../../result-view-input-validator';
import { DimensionFiltersComparator } from '../../utils/dimension-filters-comparator';
import QuickFilterItemComponent from './components/quick-filter-item.component';
import QuickFilterTaxonomyTree from './components/quick-filter-taxonomy-tree';
import { QuickFilterInputValidator } from './quick-filter-input-validator';
import QuickFilterToolbar from './quick-filter-toolbar';
import {
    QuickFilterResultViewUrlParameters,
    QuickFilterUrlParametersHandler,
} from './quick-filter-url-parameters-handler';
import styles from './quick-filter.module.scss';
import { QuickFilterElement, QuickFilterGroup } from './types/quick-filter-model';
import { getAllUids, prepareQuickViewData } from './utils/quick-filter-helper';
import SomethingWentWrong from '../../../../components/wrong-page/wrong-page';
import { queryErrorSelector } from '../../../../store/search/metadata-selectors';

class QuickFilterPage extends ResultViewBasePage<AllProps, AllState, QuickFilterResultViewUrlParameters> {
    constructor(props: AllProps) {
        super(props, new QuickFilterUrlParametersHandler(), new QuickFilterInputValidator());

        const viewData = prepareQuickViewData(
            props.currentDimensions,
            this.urlParameters.activeFilters,
            props.matches || 0,
        );
        const selectedItemsUIDs = getAllUids(viewData);
        const filters = this.urlParameters.activeFilters || [];

        this.state = {
            selectedFilters: filters,
            viewData,
            selectedItemsUIDs,
        };

        // Make sure defaults are applied to url state
        this.updateUrl(this.urlParameters);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        super.componentDidUpdate(prevProps, prevState);
        const { baseSearchRequestInProgress, matches, currentDimensions } = this.props;

        if (prevProps.currentDimensions !== currentDimensions || prevProps.matches !== matches) {
            const viewData = prepareQuickViewData(
                currentDimensions as DimensionWithAllElements[],
                this.urlParameters.activeFilters,
                matches || 0,
            );
            const selectedItemsUIDs = getAllUids(viewData);

            this.setState({
                viewData,
                selectedItemsUIDs,
            });
        }
        // Execute new search when URL state is different from last executed search
        if (this.needsNewSearch(this.urlParameters)) {
            if (!baseSearchRequestInProgress) {
                // Validate input parameters before launching new search
                const validationResult = this.inputValidator.validate(this.urlParameters);
                if (validationResult.isValid) {
                    this.executeQuickFilterSearch();
                }
            } else if (
                !DimensionFiltersComparator.equals(this.urlParameters.activeFilters || [], this.state.selectedFilters)
            ) {
                this.setState({
                    selectedFilters: this.urlParameters.activeFilters,
                });
            }
        }
    }

    checkDirty = () => {
        const { viewData, selectedItemsUIDs, selectedTaxonomyBranches } = this.state;
        const all = getAllUids(viewData);
        return all.length !== selectedItemsUIDs.length || !!selectedTaxonomyBranches;
    };

    checkHasEmptyGroup = () => {
        const { viewData, selectedItemsUIDs } = this.state;
        const groupUidsMap = {};
        viewData
            .filter(g => g.uid !== TAXONOMY_TREE_DIMENSION)
            .forEach((group) => {
                if (group.summary) return;
                groupUidsMap[group.uid] = false;
            });

        selectedItemsUIDs.forEach((itemUid) => {
            const [itemGroupUid] = itemUid.split('::');
            groupUidsMap[itemGroupUid] = true;
        });

        const values = Object.values(groupUidsMap);
        return !values.every((value) => value);
    };

    public render(): JSX.Element {
        const { location, isToolbarOpen, totalResults } = this.props;
        const { selectedFilters } = this.state;
        const { activeFilters } = this.urlParameters;
        // const pageContentClasses = conditionalClassList(styles, {
        //     isToolbarOpen,
        //     pageContent: true,
        // });

        const viewStatus = this.determineViewStatus();

        return (
            <div className={styles.container}>
                <ResultsPageHeader filters={activeFilters} />
                <ResultsNavigationBar
                    isNavigationDisabled={viewStatus === ResultViewStatus.inProgress}
                    location={location}
                    navigateTo={(url: RouteUrl, search) => this.navigateTo(url, search)}
                    helpUrl={HelpLinkUrl.QuickFilterView}
                    totalResults={totalResults}
                />
                <QuickFilterToolbar
                    selectedFilters={selectedFilters}
                    onFiltersChanged={(filters: DimensionFilter[]) => this.onFiltersChanged(filters)}
                />
                <div className={styles.pageContentContainer}>
                    <ResultsPageOverlay show={isToolbarOpen} onClick={() => this.overlayClicked()} />
                    <div className={styles.pageContent}>
                        {this.renderViewContent(viewStatus)}
                    </div>
                </div>
            </div>
        );
    }

    onBranchSelected = (branchIds?: string[]) => {
        this.setState({ selectedTaxonomyBranches: branchIds });
    };

    onElementClick(uid) {
        const { selectedItemsUIDs } = this.state;
        if (selectedItemsUIDs.indexOf(uid) < 0) {
            this.setState({ selectedItemsUIDs: selectedItemsUIDs.concat([uid]) });
        } else {
            this.setState({ selectedItemsUIDs: selectedItemsUIDs.filter((item) => item !== uid) });
        }
    }

    onElementDoubleClick(uid) {
        const { selectedItemsUIDs } = this.state;

        const [groupUid] = uid.split('::');
        const newSelection = selectedItemsUIDs.filter((itemUid) => {
            const [itemGroupUid] = itemUid.split('::');
            return groupUid !== itemGroupUid;
        });

        newSelection.push(uid);

        this.setState({ selectedItemsUIDs: newSelection });
    }

    protected renderViewContent(viewStatus: ResultViewStatus): JSX.Element {
        const { t, isToolbarOpen } = this.props;
        const { viewData, selectedItemsUIDs, selectedTaxonomyBranches } = this.state;

        const isDirty = this.checkDirty();
        const hasEmptyGroup = this.checkHasEmptyGroup();

        switch (viewStatus) {
            case ResultViewStatus.inProgress:
                return <div style={{ width: '100%', flex: 1 }}><LoadingPanel text={t('Searching our records...')} />
                </div>;
            case ResultViewStatus.invalid:
                // eslint-disable-next-line no-case-declarations
                const reason = this.inputValidator.validate(this.urlParameters).reason || '';
                return <InvalidConfigPanel message={t(reason)} />;
            case ResultViewStatus.error:
                // eslint-disable-next-line no-case-declarations
                return <SomethingWentWrong />;
            case ResultViewStatus.emptyResult:
                return <EmptyPanel />;
            default:
                return (
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        {viewData ? (
                            <div className={styles.buttons}>
                                <button
                                    type='button'
                                    className={`${styles.button}`}
                                    onClick={(): void => this.onApplyButtonClicked()}
                                    disabled={!isDirty || hasEmptyGroup}>
                                    {t('Apply Filter')}
                                </button>
                                {isDirty ? (
                                    <button
                                        type='button'
                                        className={`${styles.button} ${styles.negativePrimary}`}
                                        onClick={(): void => this.onReset()}>
                                        {t('Reset')}
                                    </button>
                                ) : null}
                            </div>
                        ) : null}
                        <div style={{ flex: 1, overflowY: isToolbarOpen ? 'hidden' : 'auto' }}>
                            {viewData &&
                            viewData.map((section, index) => {
                                const color = schemeCategory10[index];
                                return (
                                    <div
                                        key={section.title}
                                        style={{ display: 'flex', flexDirection: 'column', paddingTop: 24 }}>
                                        <Typography variant='h6'>{section.title.toUpperCase()}</Typography>
                                        {section.uid === TAXONOMY_TREE_DIMENSION ? (
                                            <div>
                                                <QuickFilterTaxonomyTree
                                                    key={section.uid}
                                                    selectedBranchesId={selectedTaxonomyBranches}
                                                    onBranchSelected={this.onBranchSelected}
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
                                                {section.elements &&
                                                section.elements.map((element) => (
                                                    <QuickFilterItemComponent
                                                        key={element.uid}
                                                        quickFilterItem={element}
                                                        selected={selectedItemsUIDs.indexOf(element.uid) > -1}
                                                        rowColor={color}
                                                        onClick={(uid) => this.onElementClick(uid)}
                                                        onDoubleClick={(uid) => this.onElementDoubleClick(uid)}
                                                    />
                                                ))}
                                                {section.summary && (
                                                    <div>
                                                        There are more than <strong>{section.summary}</strong> different
                                                        values in this dimension.
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
        }
    }

    public determineViewStatus(): ResultViewStatus {
        const {
            rootSearchRequestInProgress,
            quickFilterRequestInProgress,
            queryInError,
            baseSearchRequestInProgress,
        } = this.props;
        const { viewData } = this.state;
        if (queryInError) return ResultViewStatus.error;

        if (!this.inputValidator.validate(this.urlParameters).isValid) {
            return ResultViewStatus.invalid;
        } else if (rootSearchRequestInProgress || quickFilterRequestInProgress || baseSearchRequestInProgress) {
            return ResultViewStatus.inProgress;
        } else if (!viewData || viewData.length === 0) {
            return ResultViewStatus.emptyResult;
        } else {
            return ResultViewStatus.ready;
        }
    }

    private executeQuickFilterSearch(): void {
        const {
            baseSearchParameters,
            dispatchBaseSearchRequest,
            dispatchQuickFilterSearchRequest,
            baseQueryId,
        } = this.props;

        if (!baseSearchParameters || this.baseParametersOutOfSync(this.urlParameters, baseSearchParameters)) {
            dispatchBaseSearchRequest({
                rootQuery: this.urlParameters.rootQuery,
                filters: this.urlParameters.activeFilters,
            });
        } else {
            const { rootQuery, activeFilters } = this.urlParameters;
            dispatchQuickFilterSearchRequest({
                rootQuery,
                filters: activeFilters,
                baseQueryId,
                dimensions: defaultSelectedDimensionNames,
            });
        }
    }

    // Need new search when url state is different from the last executed query
    private needsNewSearch(urlParameters: QuickFilterResultViewUrlParameters): boolean {
        const { quickFilterSearchParameters, quickFilterRequestInProgress, queryInError } = this.props;

        // api calls already in progress = no search required
        if (this.initializingRootQuery || quickFilterRequestInProgress || queryInError) {
            return false;
        }

        // no previous data available = search required
        if (!quickFilterSearchParameters) {
            return true;
        }

        // base parameters are different = search required
        if (this.baseParametersOutOfSync(urlParameters, quickFilterSearchParameters)) {
            return true;
        } else {
            const dimensionsEqual = CollectionUtil.areEqual(
                defaultSelectedDimensionNames || [],
                quickFilterSearchParameters.dimensions,
            );

            if (!dimensionsEqual) {
                return true;
            }

            return false;
        }
    }

    protected overlayClicked(): void {
        super.overlayClicked();
        const { dispatchUpdateFilters } = this.props;
        const { selectedFilters } = this.state;
        dispatchUpdateFilters(selectedFilters);

        this.updateUrl({
            rootQuery: this.urlParameters.rootQuery,
            activeFilters: selectedFilters || [],
        });
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        this.setState({ selectedFilters: filters });
    }

    public onApplyButtonClicked(): void {
        const filters = this.updateFilters();
        const { dispatchUpdateFilters } = this.props;

        dispatchUpdateFilters(filters);
        this.updateUrl({
            rootQuery: this.urlParameters.rootQuery,
            activeFilters: filters,
        });

        this.setState({ selectedTaxonomyBranches: undefined });
    }

    public onReset(): void {
        const { viewData } = this.state;

        this.setState({ selectedItemsUIDs: getAllUids(viewData), selectedTaxonomyBranches: undefined });
    }

    private mergeFilters(currentFilter: DimensionFilter[], newFilters: DimensionFilter[]): DimensionFilter[] {
        const filterByDimensionName = {};
        const groupByDimName = (f: DimensionFilter) => {
            const filter = filterByDimensionName[f.dimensionName];
            if (filter) {
                filter.includes = intersection(filter.includes, f.includes);
                return;
            }
            filterByDimensionName[f.dimensionName] = f;
        };

        currentFilter.forEach(groupByDimName);
        newFilters.forEach(groupByDimName);

        return Object.values<DimensionFilter>(filterByDimensionName);
    }

    private updateFilters(): DimensionFilter[] {
        const { selectedItemsUIDs, viewData, selectedTaxonomyBranches } = this.state;
        const { activeFilters } = this.urlParameters;

        const newFilters: DimensionFilter[] = [];

        const selectedByGroupId = {};
        selectedItemsUIDs.forEach((itemUid) => {
            const [itemGroupUid, itemName] = itemUid.split('::');
            if (selectedByGroupId[itemGroupUid]) {
                selectedByGroupId[itemGroupUid].push(itemName);
            } else {
                selectedByGroupId[itemGroupUid] = [itemName];
            }
        });

        viewData.forEach((group) => {
            const selectedItems = selectedByGroupId[group.uid];

            const reduceIncludes = (acc, include) => {
                if (include === 'Other') {
                    const other: QuickFilterElement | undefined = group.elements.find(
                        (el) => el.uid === `${group.uid}::Other`,
                    );
                    other!.relatedElements?.forEach((element) => acc.push(element.uid.split('::')[1]));
                } else {
                    acc.push(include);
                }
                return acc;
            };

            if (selectedItems && selectedItems.length !== group.elements.length) {
                const includes: string[] = selectedItems.reduce(reduceIncludes, []);
                const filter: DimensionFilter = {
                    dimensionName: group.uid,
                    includes,
                    excludesAll: false,
                };

                newFilters.push(filter);
            }
        });

        let filters = this.mergeFilters(activeFilters, newFilters);

        filters = filters.filter((f) => f.dimensionName !== TAXONOMY_TREE_DIMENSION);

        if (selectedTaxonomyBranches) {
            filters.push({
                dimensionName: TAXONOMY_TREE_DIMENSION,
                includes: selectedTaxonomyBranches,
                excludesAll: false,
            });
        }
        return filters;
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = (state: ApplicationState) => {

    const {
        search,
        router,
        global,
    } = state;
    return {
        baseQueryId: search.baseQueryId,
        rootQuery: search.rootQuery,
        currentLocation: router.location,
        isToolbarOpen: search.isToolbarOpen,
        dimensions: search.currentDimension,
        currentDimensions: search.currentDimension,
        matches: search.queryMatches,
        rootSearchRequestInProgress: search.rootSearchRequestInProgress,
        quickFilterResultViewData: search.quickFilterResultViewData,
        quickFilterRequestInProgress: search.quickFilterRequestInProgress,
        queryInError: queryErrorSelector(state),
        baseSearchRequestInProgress: search.baseSearchRequestInProgress,
        quickFilterSearchParameters: search.quickFilterSearchParameters,
        activeWorkspaceId: global.activeWorkspaceId,
        baseSearchParameters: search.baseSearchParameters,
        totalResults: search.queryMatches,
    };
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchRootSearchRequest: (workspaceId: string, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchCloseToolbar: () => dispatch(closeToolbarRequest()),
    dispatchQuickFilterSearchRequest: (parameters: QuickFilterSearchParameters) =>
        dispatch(quickFilterSearchRequest(parameters)),
    dispatchBaseSearchRequest: (parameters: BaseSearchParameters) => dispatch(baseSearchRequest(parameters)),
    dispatchUpdateFilters: (parameters: DimensionFilter[]) => dispatch(updateFilters(parameters)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QuickFilterPage));

interface PropsFromState {
    baseQueryId: string;
    rootQuery: string;
    currentLocation: Location;
    isToolbarOpen: boolean;
    matches?: number;
    dimensions: Dimension[];
    currentDimensions: Dimension[];
    baseSearchRequestInProgress: boolean;
    rootSearchRequestInProgress: boolean;
    quickFilterResultViewData?: QuickFilterResultViewData;
    quickFilterRequestInProgress: boolean;
    queryInError: boolean;
    quickFilterSearchParameters?: QuickFilterSearchParameters;
    activeWorkspaceId?: string;
    baseSearchParameters?: BaseSearchParameters;
    totalResults: number;
}

interface PropsFromDispatch {
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchCloseToolbar: typeof closeToolbarRequest;
    dispatchQuickFilterSearchRequest: typeof quickFilterSearchRequest;
    dispatchBaseSearchRequest: typeof baseSearchRequest;
    dispatchUpdateFilters: typeof updateFilters;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedFilters: DimensionFilter[];
    validationResult?: ValidationResult;
    selectedItemsUIDs: string[];
    viewData: QuickFilterGroup[];
    selectedTaxonomyBranches?: string[];
}

type AllState = OwnState;
