export class DateHelper {
    public static Iso8601ToLocalizedDateTime(date: string): string {
        const nativeDate = new Date(date);
        return `${nativeDate.toLocaleDateString()} ${nativeDate.toLocaleTimeString().slice(0, -3)}`;
    }

    public static Iso8601ToLocalizedDate(date: string): string {
        return new Date(date).toLocaleDateString();
    }

    public static UnixTimestampToLocalizedDateTime(date: number): string {
        const nativeDate = new Date(date);
        return `${nativeDate.toLocaleDateString()} ${nativeDate.toLocaleTimeString().slice(0, -3)}`;
    }
}
