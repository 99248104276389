import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ApplicationState, rootReducer, rootSaga } from '.';
import { initialAuthenticationState } from './authentication';
import { initialDatasetsState } from './datasets';
import { initialGlobalState } from './global';
import { initialPopupState } from './popup';
import { initialSearchState } from './search';
import { initialSubscriptionState } from './subscription';
import { initialToastState } from './toast';
import { initialUsersState } from './users';
import { initialWorkspacesState } from './workspaces';
import { initialUIState } from './uistate';

const initState: ApplicationState = {
    router: {
        location: {
            key: undefined,
            hash: '',
            pathname: '',
            search: '',
            state: '',
        },
        action: 'PUSH',
    },
    global: initialGlobalState(),
    toast: initialToastState(),
    popup: initialPopupState(),
    search: initialSearchState(),
    authentication: initialAuthenticationState(),
    users: initialUsersState(),
    workspaces: initialWorkspacesState(),
    subscription: initialSubscriptionState(),
    datasets: initialDatasetsState(),
    uistate: initialUIState(),
};

export default function configureStore(
    history: History,
    initialState: ApplicationState = initState,
): Store<ApplicationState> {
    const composeEnhancers = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
}
