import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import { History, Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AuthenticationApi from './api/authentication-api';
import PopupContainer from './components/popup-container/popup-container';
import ToastContainer from './components/toast-container/toast-container';
import { RouteUrl } from './routing/route-url';
import Routes from './routing/routes';
import { ApplicationState } from './store';
import {
    AuthorizationContext,
    getAuthorizationContext,
    InstanceType,
    markInitialAuthorizationFlowAsFinshed,
    setInstanceType,
} from './store/global';
import { closePopup, Popup } from './store/popup';
import { SearchHistoryEntry } from './store/search';
import { localSearchHistoryKey, retrieveParsedFromLocalStorage } from './utils/local-storage-helpers';
import { updateQueryParameters } from './utils/query-parameter-helpers';
import { setSearchHistory } from './components/page-header/search-page-header/search-history-actions';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 16,
                border: 2,

            },
            label: {
                textTransform: 'none',
                fontWeight: 'bold',
                marginLeft: 16,
                marginRight: 16,
            },
            containedPrimary: {
                color: 'white'
            }
        }
    },
    palette: {
        primary: {
            main: '#00a4d0',
        },
        secondary: {
            main: '#ffa403',
        },
    },
});

class App extends React.Component<AllProps> {
    constructor(props) {
        super(props);
        const { history, dispatchNavigateTo } = this.props;

        dispatchNavigateTo(history.location);
        this.setInstanceType();
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { authorizationContext, authorizationInProgress, currentLocation } = this.props;
        const {
            dispatchGetAuthorizationContext,
            activeWorkspaceId,
            dispatchMarkAuthorizationFlowAsFinished,
        } = this.props;

        AuthenticationApi.isAuthenticated()
            .then((authenticated) => {
                if (authenticated) {
                    if (
                        !authorizationContext &&
                        !authorizationInProgress &&
                        currentLocation &&
                        currentLocation !== prevProps.currentLocation
                    ) {
                        if (this.isNonRestrictedPage(currentLocation)) {
                            dispatchMarkAuthorizationFlowAsFinished();
                        } else {
                            dispatchGetAuthorizationContext();
                        }
                    }
                } else {
                    dispatchMarkAuthorizationFlowAsFinished();
                }
            })
            .catch((e) => {
                dispatchMarkAuthorizationFlowAsFinished();
            });

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            const defaultMembership = authorizationContext?.memberships.find((m) => m.isDefault);
            const defaultWorkspaceId = defaultMembership?.workspace.id;
            if (defaultWorkspaceId) {
                this.navigateAfterLogin(defaultWorkspaceId, currentLocation);
                dispatchMarkAuthorizationFlowAsFinished();
            } else {
                // TODO @Sam fix this invalid configuration
            }
        }

        // TODO @Sam why is this not part of the search page?
        if (activeWorkspaceId && activeWorkspaceId !== prevProps.activeWorkspaceId) {
            this.retrieveSearchHistory(activeWorkspaceId);
        }
    }

    public render(): JSX.Element {
        const { popup } = this.props;

        return (
            <div className='root'>
                <ThemeProvider theme={theme}>
                    <div className='appWrapper'>
                        <PopupContainer popup={popup} close={(): void => this.onClosePopup()} />
                        <ToastContainer />
                        <div className='pageContent'>
                            <Routes />
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        );
    }

    private onClosePopup(): void {
        const { currentLocation, dispatchClosePopup, dispatchNavigateTo } = this.props;
        const queryParameters = updateQueryParameters(
            {
                popup: undefined,
            },
            currentLocation.search,
        );
        dispatchClosePopup();
        dispatchNavigateTo({ search: queryParameters });
    }

    private setInstanceType(): void {
        const { dispatchSetInstanceType } = this.props;

        if (window.location.hostname.includes('localhost') || window.location.hostname.includes('private')) {
            dispatchSetInstanceType(InstanceType.Private);
        } else {
            dispatchSetInstanceType(InstanceType.Public);
        }
    }

    private navigateAfterLogin(workspaceId: string, location: Location): void {
        const { dispatchNavigateTo } = this.props;
        if (this.needsForwardToSearch(location)) {
            // TODO@Sam restore deeplinking with returnURL
            dispatchNavigateTo({ pathname: `/${workspaceId}${RouteUrl.Search}` });
        }
    }

    private retrieveSearchHistory(workspaceId: string): void {
        const { dispatchSetSearchHistory } = this.props;
        const searchHistory = retrieveParsedFromLocalStorage<SearchHistoryEntry[]>(localSearchHistoryKey(workspaceId));
        dispatchSetSearchHistory(searchHistory || undefined);
    }

    private isNonRestrictedPage(currentLocation: Location): boolean {
        return (
            this.isLoginPage(currentLocation) ||
            currentLocation.pathname.includes(RouteUrl.CreateOrganizationPage) ||
            currentLocation.pathname.includes(RouteUrl.ConfirmPassword) ||
            currentLocation.pathname.includes(RouteUrl.ForgotPassword) ||
            currentLocation.pathname.includes(RouteUrl.ConfirmationUnderway)
        );
    }

    private needsForwardToSearch(currentLocation: Location): boolean {
        return this.isLoginPage(currentLocation) || currentLocation.pathname.includes(RouteUrl.ConfirmPassword);
    }

    private isLoginPage(currentLocation: Location): boolean {
        return (
            currentLocation.pathname === RouteUrl.Login ||
            currentLocation.pathname === '' ||
            currentLocation.pathname === '/'
        );
    }

    // private buildReturnUrlFromQueryParameters(queryParameters: URLSearchParams): LocationDescriptorObject {
    //     const decodedReturnUrl = decodeURIComponent(queryParameters.get(QueryParameter.ReturnUrl)!);
    //     const splitReturnUrl = decodedReturnUrl.split('?');
    //     const pathname = splitReturnUrl[0];
    //     const search = splitReturnUrl.length === 2 ? splitReturnUrl[1] : '';
    //     return { pathname, search };
    // }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    global,
    popup,
}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    authorizationInProgress: global.authorizationInProgress,
    authorizationContext: global.authorizationContext,
    popup: popup.popup,
    activeWorkspaceId: global.activeWorkspaceId,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchSetSearchHistory: (history) => dispatch(setSearchHistory(history)),
    dispatchSetInstanceType: (type: InstanceType) => dispatch(setInstanceType(type)),
    dispatchGetAuthorizationContext: () => dispatch(getAuthorizationContext()),
    dispatchMarkAuthorizationFlowAsFinished: () => dispatch(markInitialAuthorizationFlowAsFinshed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

interface PropsFromState {
    authorizationInProgress: boolean;
    currentLocation: Location;
    authorizationContext?: AuthorizationContext;
    popup?: Popup;
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchGetAuthorizationContext: typeof getAuthorizationContext;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchClosePopup: typeof closePopup;
    dispatchSetSearchHistory: typeof setSearchHistory;
    dispatchSetInstanceType: typeof setInstanceType;
    dispatchMarkAuthorizationFlowAsFinished: typeof markInitialAuthorizationFlowAsFinshed;
}

interface OwnProps {
    history: History;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;
