// eslint-disable-next-line max-classes-per-file
import { ActionType } from 'typesafe-actions';
import {
    AlignAlignResponse,
    SearchAndRelateDimensionCounts,
    SearchAndRelateDimensionPlaceholder,
    SearchAndRelateQueryMetaData,
    SearchAndRelateTaxonomyResponse,
    SharedMolType,
} from '../../api/generated';
import { ToolbarContext } from '../../pages/results/components/page-toolbar/page-toolbar';
import { ColumnConfig } from '../../pages/results/views/list-view/components/results-list/column-config';
import { SortDirection } from '../../types/sorting';
import { DatasetWithLimit } from '../datasets';
import * as sharedActions from '../shared/actions';
import * as actions from './search-actions';

export const TAXONOMY_TREE_DIMENSION = 'taxonomyTree';

export const ALIGNMENT_RESULT_LIMIT = 3500;
export const LIST_RESULT_LIMIT = 10000;


export enum SearchActionTypes {
    TOGGLE_TOOLBAR = '@@search/TOGGLE_TOOLBAR',
    CLOSE_TOOLBAR = '@@search/CLOSE_TOOLBAR',
    OPEN_TOOLBAR = '@@search/OPEN_TOOLBAR',

    SET_ROOT_QUERY_TAXONOMY = '@@search/SET_ROOT_QUERY_TAXONOMY',
    SET_CURRENT_QUERY_TAXONOMY = '@@search/SET_CURRENT_QUERY_TAXONOMY',

    SET_DIMENSIONS = '@@search/SET_DIMENSIONS',
    SET_CURRENT_DIMENSIONS = '@@search/SET_CURRENT_DIMENSIONS',

    ROOT_SEARCH_REQUEST = '@@search/ROOT_SEARCH_REQUEST',
    ROOT_SEARCH_SUCCESS = '@@search/ROOT_SEARCH_REQUEST_SUCCESS',
    ROOT_SEARCH_REQUEST_ERROR = '@@search/ROOT_SEARCH_REQUEST_ERROR',

    RUN_SEARCH_REQUEST = '@@search/RUN_SEARCH_REQUEST',
    RUN_SEARCH_REQUEST_SUCCESS = '@@search/RUN_SEARCH_REQUEST_SUCCESS',
    SEARCH_IS_RUNNING = '@@search/SEARCH_IS_RUNNING',
    BASE_SEARCH_REQUEST_ERROR = '@@search/BASE_SEARCH_REQUEST_ERROR',

    QUICK_FILTER_SEARCH_REQUEST = '@@search/QUICK_FILTER_SEARCH_REQUEST',
    QUICK_FILTER_SEARCH_SUCCESS = '@@search/QUICK_FILTER_SEARCH_SUCCESS',

    ALIGNMENT_SEARCH_REQUEST = '@@search/ALIGNMENT_SEARCH_REQUEST',
    ALIGNMENT_SEARCH_SUCCESS = '@@search/ALIGNMENT_SEARCH_SUCCESS',
    ALIGNMENT_SEARCH_ERROR = '@@search/ALIGNMENT_SEARCH_ERROR',
    ALIGNMENT_SEARCH_CANCELED = '@@search/ALIGNMENT_SEARCH_CANCELED',
    ALIGNMENT_SEARCH_TOO_MANY_RESULTS = '@@search/ALIGNMENT_SEARCH_TOO_MANY_RESULTS',

    LIST_SEARCH_REQUEST = '@@search/LIST_SEARCH_REQUEST',
    LIST_SEARCH_SUCCESS = '@@search/LIST_SEARCH_SUCCESS',
    LIST_SEARCH_ERROR = '@@search/LIST_SEARCH_ERROR',
    LIST_SEARCH_CANCELED = '@@search/LIST_SEARCH_CANCELED',
    LIST_SEARCH_TOO_MANY_RESULTS = '@@search/LIST_SEARCH_TOO_MANY_RESULTS',


    EXPLORER_SEARCH_REQUEST = '@@search/EXPLORER_SEARCH_REQUEST',
    EXPLORER_SEARCH_SUCCESS = '@@search/EXPLORER_SEARCH_SUCCESS',
    EXPLORER_SEARCH_ERROR = '@@search/EXPLORER_SEARCH_ERROR',
    EXPLORER_SEARCH_CANCELED = '@@search/EXPLORER_SEARCH_CANCELED',

    BASE_SEARCH_REQUEST = '@@search/BASE_SEARCH_REQUEST',
    BASE_SEARCH_SUCCESS = '@@search/BASE_SEARCH_SUCCESS',

    CLEAR_QUERY = '@@search/CLEAR_QUERY',
}

export interface SearchState {
    readonly rootTaxonomy?: SearchAndRelateTaxonomyResponse;
    readonly currentTaxonomy?: SearchAndRelateTaxonomyResponse;

    readonly dimensions: Dimension[];
    readonly currentDimension: Dimension[];

    readonly quickFilterSearchParameters?: QuickFilterSearchParameters;
    readonly quickFilterResultViewData?: QuickFilterResultViewData;
    readonly quickFilterRequestInProgress: boolean;

    readonly alignmentSearchParameters?: AlignmentSearchParameters;
    readonly alignmentResultViewData?: AlignAlignResponse;
    readonly alignmentRequestInProgress: boolean;
    readonly alignmentRequestInError: boolean;
    readonly alignmentTooManyResults?: boolean;


    readonly listSearchParameters?: ListSearchParameters;
    readonly listResultViewData?: ListResultViewData;
    readonly listAvailableColumns?: ColumnConfig[];
    readonly listRequestInProgress: boolean;
    readonly listRequestInError: boolean;
    readonly listTooManyResults: boolean;
    readonly listErrorMessage?: string;

    readonly explorerSearchParameters?: ExplorerSearchParameters;
    readonly explorerResultViewData?: ExplorerResultViewData;
    readonly explorerRequestInProgress: boolean;
    readonly explorerRequestInError: boolean;

    readonly toolbarContext?: ToolbarContext;
    readonly isToolbarOpen: boolean;

    readonly rootQuery: string;
    readonly rootSearchRequestInProgress: boolean;
    readonly rootSearchRequestInError?: boolean;

    readonly baseSearchRequestInProgress: boolean;
    readonly baseQueryId: string;
    readonly baseSearchParameters?: BaseSearchParameters;
    readonly baseSearchRequestInError?: boolean;

    readonly queryMatches: number;
    readonly queryMetadata?: SearchAndRelateQueryMetaData;
    readonly history?: SearchHistoryEntry[];

    readonly isSearchRunning: boolean;
}

export type SearchAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface QueryMetadata {
    type: string;
    molType?: string;
    translationTable?: number;
    translationFrame?: number;
    translatedSequence?: string;
    translationErrorCode?: number;
}

export interface GetDimensionsResult {
    queryId: string;
    dimensions?: SearchAndRelateDimensionCounts[];
    dimensionPlaceholders?: SearchAndRelateDimensionPlaceholder[];
}

export interface DimensionAggregatesResult {
    queryId: string;
    aggregates: DimensionAggregate[];
}

export interface DimensionAggregate {
    dimensionMatches: QuickFilterDimensionMatch[];
    count: number;
}

export interface QuickFilterDimensionMatch {
    dimension: string;
    elements: string[];
}

export interface QuickFilterResultViewData {
    queryId: string;
    summaries: SearchAggregatesResultSummary[];
}

export interface ExplorerResultViewData {
    queryId: string;
    summaries: SearchAggregatesResultSummary[];
}

export type SearchAggregatesResultSummary = {
    // Dynamic attributes will exist
    count: number;
} & any;

export interface SearchAggregatesDimensionElement {
    name: string;
    count: number;
}

export interface ListResultViewData {
    sequences: GetSequencesResultSequenceMap[];
    totalCount: number;
}

export interface GetSequencesResult {
    sequences: GetSequencesResultSequence[];
    totalCount: number;
}

export interface GetSequencesResultSequence {
    attributes: GetSequencesResultSequenceField[];
}

export interface GetSequencesResultSequenceMap {
    id: string;
    // fields converted to dynamic properties
}

export interface GetSequencesResultSequenceField {
    name: string;
    displayName: string;
    value: string[];
}

export interface BaseSearchParameters {
    readonly rootQuery: string;
    readonly filters: DimensionFilter[];
}

export interface ListSearchParameters extends BaseSearchParameters {
    readonly baseQueryId: string;
    readonly page: number;
    readonly pageSize: number;
    readonly sortingColumnKey: string;
    readonly sortingDirection: SortDirection;
    readonly fields?: string[];
}

export interface AlignmentSearchParameters extends BaseSearchParameters {
    readonly baseQueryId: string;
    readonly page: number;
    readonly pageSize: number;
    readonly molType: SharedMolType;
}

export interface QuickFilterSearchParameters extends BaseSearchParameters {
    readonly dimensions: string[];
    readonly baseQueryId: string;
}

export interface QueryTaxonomyParameters {
    readonly queryId: string;
}

export interface ExplorerSearchParameters extends BaseSearchParameters {
    readonly baseQueryId: string;
    readonly rowSelection: string[];
    readonly columnSelection: string[];
}

export interface DimensionFilter {
    readonly dimensionName: string;
    includes?: string[];
    excludesAll: boolean;
    elementNamesContains?: string;
    match?: string;
}

export interface Dimension {
    name: string;
    displayName: string;
}

export interface DimensionElement {
    name: string;
    count: number;
}

export type DimensionWithAllElements = Dimension & {
    elements: DimensionElement[];
};

export interface DimensionPlaceholder {
    type: string;
    count: number;
}

export type DimensionWithPlaceholder = Dimension & { placehold: DimensionPlaceholder };

export interface SearchHistoryEntry {
    readonly query: string;
    readonly date: number;
    readonly totalCount: number;
}

export interface SearchSettings {
    readonly selectedDatasets: DatasetWithLimit[];
    readonly hideSearchExamples?: boolean;
}

export enum SequenceFields {
    sequenceId = 'sequenceId',
    url = 'url',
}
