import { Dimension, DimensionWithAllElements, DimensionWithPlaceholder, TAXONOMY_TREE_DIMENSION } from '../types';
import { SEQUENCE_DIMENSION_NAME } from '../../../pages/results/constants/dimension-constants';
import { SearchAndRelateDimensionCounts, SearchAndRelateDimensionPlaceholder } from '../../../api/generated';

export class DimensionBuilder {
    public static buildDimensions(
        dimensionsWithElements?: SearchAndRelateDimensionCounts[],
        dimensionsWithPlaceholders?: SearchAndRelateDimensionPlaceholder[],
    ): Dimension[] {
        const dimWithElements = DimensionBuilder.buildDimensionsWithElements(dimensionsWithElements);
        const dimWithPlaceholders = DimensionBuilder.buildDimensionsWithPlaceholder(dimensionsWithPlaceholders);

        // inject artificial filter for sequences. Sequences is not a part of regular dimensions,
        // inject artificial filter for taxonomy tree. It is not a part of regular dimensions,
        // but sequences filter can be managed by the same mechanic as the regular dimension filter.

        const dwp: DimensionWithPlaceholder = {
            name: SEQUENCE_DIMENSION_NAME,
            displayName: 'Sequence motif',
            placehold: { count: 0, type: 'string' },
        };

        const dim: Dimension[] = [dwp];

        if (dimWithElements.length > 0) {
            const dwl: DimensionWithAllElements = {
                name: TAXONOMY_TREE_DIMENSION,
                displayName: 'Taxonomy',
                elements: [],
            };

            dim.push(dwl);
        }


        // FIXME remove filter for the taxonomy when it removed on BE
        return dim.concat(dimWithElements, dimWithPlaceholders).filter(d => d.name !== 'taxonomy');
    }

    private static buildDimensionsWithElements(
        dimensionsWithElements?: SearchAndRelateDimensionCounts[],
    ): Dimension[] {
        return dimensionsWithElements
            ? dimensionsWithElements.map((dim: SearchAndRelateDimensionCounts) => {

                const dwl: DimensionWithAllElements = {
                    name: dim.name || '',
                    displayName: dim.displayName || '',
                    elements: dim.values ? dim.values.map((el) => {
                        return {
                            name: el.name || '',
                            count: el.count || 0,
                        };
                    }) : [],
                };

                return dwl;
            })
            : [];
    }

    private static buildDimensionsWithPlaceholder(
        dimensionsWithPlaceholder?: SearchAndRelateDimensionPlaceholder[],
    ): Dimension[] {
        return dimensionsWithPlaceholder
            ? dimensionsWithPlaceholder.map((dim: SearchAndRelateDimensionPlaceholder) => {

                const dwp:DimensionWithPlaceholder = {
                    name: dim.name || '',
                    displayName: dim.displayName || '',
                    placehold: {
                        count: dim.placeholder?.count || 0,
                        type: dim.placeholder?.type || '',
                    }
                }

                return dwp;
            })
            : [];
    }
}
