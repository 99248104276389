import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import { TFunction } from 'i18next';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ConfirmationPopup from '../../../../components/confirmation-popup/confirmation-popup';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { AuthorizationContext, OrganizationRole } from '../../../../store/global';
import { closePopup, Popup, PopupType, showPopup } from '../../../../store/popup';
import { FailureCodes, RequestStatus } from '../../../../store/shared/types';
import { addToast, ToastType } from '../../../../store/toast';
import {
    deleteUserRequest,
    DeleteUserRequestParameters,
    getUsersForOrganizationRequest,
    updateUserRequest,
    UpdateUserRequestParameters,
    UserInfo,
    UserStatus,
} from '../../../../store/users';
import { DateHelper } from '../../../../utils/date-helper';
import { AdminBasePage } from '../../admin-base.page';
import ActionsMenu, { ActionItem, ActionType } from '../../components/actions-menu/actions-menu';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getOrganizationAdminMenuConfiguration } from '../organization-admin-menu.configuration';
import InviteUserPopup from './components/invite-user-popup/invite-user-popup';
import styles from './manage-users.module.scss';

class ManageUsersPage extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        const { dispatchGetUsersForOrganization, authorizationContext } = props;
        super(props);

        if (authorizationContext) {
            dispatchGetUsersForOrganization(authorizationContext.organization.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {
            updateUserRequestStatus,
            deleteUserRequestStatus,
            t,
            authorizationContext,
            dispatchGetUsersForOrganization,
        } = this.props;

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            dispatchGetUsersForOrganization(authorizationContext.organization.id);
        }

        if (updateUserRequestStatus && prevProps.updateUserRequestStatus !== updateUserRequestStatus) {
            if (updateUserRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('User Successfully Updated'));
            }
            if (updateUserRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(updateUserRequestStatus));
            }
        }

        if (deleteUserRequestStatus && prevProps.deleteUserRequestStatus !== deleteUserRequestStatus) {
            if (deleteUserRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('User Successfully Deleted'));
            }

            if (deleteUserRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(deleteUserRequestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const { users, getUsersRequestStatus, t } = this.props;

        return (
            <div>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div className={styles.adminContainer}>
                    <AdminMenu menuItems={getOrganizationAdminMenuConfiguration()} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Users')}</div>
                            {getUsersRequestStatus?.isInProgress ? null : (
                                <button
                                    className={styles.button}
                                    type='button'
                                    onClick={() => this.showInviteUserPopup()}>
                                    {t('Invite User')}
                                </button>
                            )}
                        </div>
                        {getUsersRequestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin /> Loading...
                            </div>
                        ) : (
                            <div className={styles.table}>
                                <div className={`${styles.row} ${styles.header}`}>
                                    <div className={styles.cell} style={{ flexGrow: 2 }}>
                                        {t('Email')}
                                    </div>
                                    <div className={styles.cell}>{t('Role')}</div>
                                    <div className={styles.cell}>{t('Billing Status')}</div>
                                    <div className={styles.cell}>{t('Last Login')}</div>
                                    <div className={styles.optionsCellSpacer} />
                                </div>
                                <div className={styles.rows}>
                                    {users?.map((u) => {
                                        return (
                                            <div className={styles.row} key={u.email}>
                                                <div className={styles.cell} style={{ flexGrow: 2 }}>
                                                    {u.email}
                                                </div>
                                                <div className={styles.cell}>{u.organizationRole}</div>
                                                <div className={styles.cell}>{u.status}</div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(u.lastLoggedIn)}
                                                </div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu actions={this.getActionsForUser(u, t)} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            case FailureCodes.WS25: {
                return t('Failed to delete user, please contact your administrator if this error persists');
            }
            default:
                return t('Update user failed, please contact your administrator if this error persists.');
        }
    }

    private getActionsForUser(user: UserInfo, t: TFunction): ActionItem[] {
        const { authorizationContext } = this.props;

        const actions: ActionItem[] = [];
        const isCurrentUser = authorizationContext?.user.email === user.email;

        if (!isCurrentUser) {
            actions.push(
                user.organizationRole === OrganizationRole.ADMIN
                    ? {
                          label: t('Remove Admin rights'),
                          onClick: (): void => this.updateRole(user, OrganizationRole.REGULAR),
                      }
                    : {
                          label: t('Promote to Admin'),
                          onClick: (): void => this.updateRole(user, OrganizationRole.ADMIN),
                      },
            );
            if (user.status === UserStatus.DEACTIVATED) {
                actions.push({
                    label: t('Activate account'),
                    onClick: (): void => this.updateStatus(user, UserStatus.ACTIVE),
                    type: ActionType.positive,
                });
            }
            if (user.status === UserStatus.ACTIVE) {
                actions.push({
                    label: t('Deactivate account'),
                    onClick: (): void => this.updateStatus(user, UserStatus.DEACTIVATED),
                    type: ActionType.negative,
                });
            }
            if (user.status === UserStatus.DEACTIVATED || user.status === UserStatus.PENDING) {
                actions.push({
                    label: t('Delete account'),
                    onClick: (): void => this.deleteUser(user),
                    type: ActionType.negative,
                });
            }
        }

        return actions;
    }

    private updateRole(user: UserInfo, organizationRole: OrganizationRole): void {
        const { dispatchUpdateUser, authorizationContext } = this.props;
        dispatchUpdateUser({
            email: user.email,
            organizationRole,
            status: user.status,
            organizationId: authorizationContext!.organization.id,
        });
    }

    private updateStatus(user: UserInfo, status: UserStatus): void {
        const { dispatchUpdateUser, authorizationContext } = this.props;
        dispatchUpdateUser({
            email: user.email,
            organizationRole: user.organizationRole,
            status,
            organizationId: authorizationContext!.organization.id,
        });
    }

    private showInviteUserPopup(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.INVITE_USER,
            content: <InviteUserPopup />,
            isDismissible: false,
        });
    }

    private deleteUser(user: UserInfo): void {
        const { dispatchShowPopup, t } = this.props;

        dispatchShowPopup({
            type: PopupType.DELETE_USER,
            content: (
                <ConfirmationPopup
                    header={t('Delete account?')}
                    messages={[t('Are you sure you want to delete'), `${user.email}?`]}
                    onConfirm={(): void => this.confirmDeleteUser(user)}
                    onCancel={(): void => this.cancelDeleteUser()}
                />
            ),
            isDismissible: true,
        });
    }

    private confirmDeleteUser(user: UserInfo): void {
        const { dispatchDeleteUser, dispatchClosePopup, authorizationContext } = this.props;

        if (authorizationContext) {
            dispatchDeleteUser({
                organizationId: authorizationContext.organization.id,
                email: user.email,
            });
            dispatchClosePopup();
        }
    }

    private cancelDeleteUser(): void {
        const { dispatchClosePopup } = this.props;

        dispatchClosePopup();
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchGetUsersForOrganization: (organizationId: string) =>
        dispatch(getUsersForOrganizationRequest(organizationId)),
    dispatchUpdateUser: (parameters: UpdateUserRequestParameters) => dispatch(updateUserRequest(parameters)),
    dispatchDeleteUser: (parameters: DeleteUserRequestParameters) => dispatch(deleteUserRequest(parameters)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ router, users, global }: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    currentLocation: router.location,
    users: users.usersForOrganization,
    getUsersRequestStatus: users.getUsersForOrganizationRequestStatus,
    updateUserRequestStatus: users.updateUserRequestStatus,
    deleteUserRequestStatus: users.deleteUserRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageUsersPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    currentLocation: Location;
    users?: UserInfo[];
    getUsersRequestStatus?: RequestStatus;
    updateUserRequestStatus?: RequestStatus;
    deleteUserRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetUsersForOrganization: typeof getUsersForOrganizationRequest;
    dispatchUpdateUser: typeof updateUserRequest;
    dispatchDeleteUser: typeof deleteUserRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchAddToast: typeof addToast;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {}

type AllState = OwnState;
