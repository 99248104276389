import { DimensionFilter } from '../../../store/search';
import { CollectionUtil } from '../../../utils/collection-util';

export class DimensionFiltersComparator {
    public static equals(filters1: DimensionFilter[], filters2: DimensionFilter[]): boolean {
        if (filters1.length !== filters2.length) {
            return false;
        }

        const dimensionFields1 = filters1.map((f) => f.dimensionName);
        const dimensionFields2 = filters2.map((f) => f.dimensionName);
        if (!CollectionUtil.areEqual(dimensionFields1, dimensionFields2)) {
            return false;
        }

        return filters1.every((f1) => {
            const f2 = filters2.find((f) => f.dimensionName === f1.dimensionName);
            if (!f2) {
                return false;
            }
            if (f1.excludesAll !== f2.excludesAll) {
                return false;
            }

            if ((f1.includes && !f2.includes) || (f2.includes && !f1.includes)) {
                return false;
            }
            if (
                (f1.elementNamesContains && !f2.elementNamesContains) ||
                (f2.elementNamesContains && !f1.elementNamesContains)
            ) {
                return false;
            }
            if (f1.elementNamesContains && f1.elementNamesContains !== f2.elementNamesContains) {
                return false;
            }

            if (f1.includes && !CollectionUtil.areEqual(f1.includes, f2.includes || [])) {
                return false;
            }

            return true;
        });

    }
}
