import { RouteUrl } from '../../../routing/route-url';
import { AuthorizationContext, OrganizationRole } from '../../../store/global';
import { MenuItem } from '../components/admin-menu/admin-menu';

export function getWorkspaceAdminMenuConfiguration(
    authorizationContext?: AuthorizationContext,
    workspaceId?: string,
): MenuItem[] {

    return authorizationContext &&
        workspaceId &&
        authorizationContext.user.organizationRole === OrganizationRole.ADMIN &&
        authorizationContext.memberships.find((m) => m.workspace.id === workspaceId)?.workspace.organizationId ===
            authorizationContext.organization.id
        ? [
              { label: 'Members', url: RouteUrl.ManageMembers },
              { label: 'Datasets', url: RouteUrl.ManageWorkspaceDatasets },
          ]
        : [{ label: 'Members', url: RouteUrl.ManageMembers }];
}
