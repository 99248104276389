import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import { TFunction } from 'i18next';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { AuthorizationContext, WorkspaceRole } from '../../../../store/global';
import { Popup, PopupType, showPopup } from '../../../../store/popup';
import { FailureCodes, RequestStatus } from '../../../../store/shared/types';
import { addToast, ToastType } from '../../../../store/toast';
import {
    deleteMembershipForWorkspaceRequest,
    DeleteMembershipRequestParameters,
    getMembershipsForWorkspaceRequest,
    updateMembershipRequest,
    UpdateMembershipRequestParameters,
    WorkspaceMembership,
} from '../../../../store/workspaces';
import { QueryParameter, QueryParameterHelper } from '../../../../utils/query-parameter-helpers';
import { AdminBasePage } from '../../admin-base.page';
import ActionsMenu, { ActionItem, ActionType } from '../../components/actions-menu/actions-menu';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getWorkspaceAdminMenuConfiguration } from '../workspace-admin-menu.configuration';
import AddMemberPopup from './components/add-member-popup';
import styles from './manage-members.module.scss';

class ManageMembersPage extends AdminBasePage<AllProps, AllState> {
    private workspaceId;

    public constructor(props) {
        super(props);
        const { currentLocation, authorizationContext } = this.props;

        if (currentLocation && authorizationContext) {
            this.updateMembershipList();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {
            currentLocation,
            authorizationContext,
            updateMembershipRequestStatus,
            deleteMembershipRequestStatus,
            t,
        } = this.props;

        if (currentLocation && currentLocation !== prevProps.currentLocation) {
            this.updateMembershipList();
        }

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            this.updateMembershipList();
        }

        if (
            updateMembershipRequestStatus &&
            prevProps.updateMembershipRequestStatus !== updateMembershipRequestStatus
        ) {
            if (updateMembershipRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Membership Successfully Updated'));
            }
            if (updateMembershipRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForUpdateFailureCode(updateMembershipRequestStatus));
            }
        }

        if (
            deleteMembershipRequestStatus &&
            prevProps.deleteMembershipRequestStatus !== deleteMembershipRequestStatus
        ) {
            if (deleteMembershipRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Membership Successfully Deleted'));
            }
            if (deleteMembershipRequestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForDeletionFailureCode(deleteMembershipRequestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const { t, getMembershipsRequestStatus, memberships, authorizationContext } = this.props;

        return (
            <div>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div className={styles.adminContainer}>
                    <AdminMenu menuItems={getWorkspaceAdminMenuConfiguration(authorizationContext, this.workspaceId)} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Members')}</div>
                            {getMembershipsRequestStatus?.isInProgress ? null : (
                                <button
                                    className={styles.button}
                                    type='button'
                                    onClick={() => {
                                        this.showAddMemberPopup();
                                    }}>
                                    {t('Add Member')}
                                </button>
                            )}
                        </div>
                        {getMembershipsRequestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin /> Loading...
                            </div>
                        ) : (
                            <div className={styles.table}>
                                <div className={`${styles.row} ${styles.header}`}>
                                    <div className={styles.cell} style={{ flexGrow: 2 }}>
                                        {t('Email')}
                                    </div>
                                    <div className={styles.cell}>{t('Role')}</div>
                                    <div className={styles.optionsCellSpacer} />
                                </div>
                                <div className={styles.rows}>
                                    {memberships?.map((m) => {
                                        return (
                                            <div className={styles.row} key={m.email}>
                                                <div className={styles.cell} style={{ flexGrow: 2 }}>
                                                    {m.email}
                                                </div>
                                                <div className={styles.cell}>{m.workspaceRole}</div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu actions={this.getActionsForMember(m, t)} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForDeletionFailureCode(requestStatus): string {
        const { t } = this.props;

        switch (requestStatus.errorCode) {
            default:
                return t('Membership deletion failed, please contact your administrator if this error persists.');
        }
    }

    private getMessageForUpdateFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            case FailureCodes.WS13:
                return t('Cannot delete membership because it is set as default by the user.');
            default:
                return t('Membership update failed, please contact your administrator if this error persists.');
        }
    }

    private getActionsForMember(membership: WorkspaceMembership, t: TFunction): ActionItem[] {
        const { authorizationContext } = this.props;

        const actions: ActionItem[] = [];
        const isCurrentUser = authorizationContext?.user.email === membership.email;

        if (!isCurrentUser) {
            actions.push(
                membership.workspaceRole === WorkspaceRole.ADMIN
                    ? {
                          label: t('Revoke Admin Rights'),
                          onClick: (): void => this.updateRole(membership, WorkspaceRole.REGULAR),
                      }
                    : {
                          label: t('Promote to Admin'),
                          onClick: (): void => this.updateRole(membership, WorkspaceRole.ADMIN),
                      },
            );
            actions.push({
                label: t('Delete Membership'),
                onClick: (): void => this.deleteMembership(membership),
                type: ActionType.negative,
            });
        }

        return actions;
    }

    private updateRole(membership: WorkspaceMembership, workspaceRole: WorkspaceRole): void {
        const { dispatchUpdateMembershipRequest, authorizationContext } = this.props;
        dispatchUpdateMembershipRequest({
            email: membership.email,
            workspaceRole,
            workspaceId: this.workspaceId,
            organizationId: authorizationContext!.organization.id,
        });
    }

    private deleteMembership(membership: WorkspaceMembership): void {
        const { dispatchDeleteMembershipForWorkspaceRequest, authorizationContext } = this.props;
        dispatchDeleteMembershipForWorkspaceRequest({
            email: membership.email,
            workspaceId: this.workspaceId,
            organizationId: authorizationContext!.organization.id,
        });
    }

    private getWorkspaceFromUrl(): string {
        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        return QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.WorkspaceId) || '';
    }

    private updateMembershipList(): void {
        const { authorizationContext, dispatchGetMembershipsForWorkspaceRequest } = this.props;

        this.workspaceId = this.getWorkspaceFromUrl();
        const organizationId = authorizationContext?.organization.id;
        if (this.workspaceId && organizationId) {
            dispatchGetMembershipsForWorkspaceRequest(organizationId, this.workspaceId);
        }
    }

    private showAddMemberPopup(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.ADD_MEMBER,
            content: <AddMemberPopup workspaceId={this.workspaceId} />,
            isDismissible: false,
        });
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchDeleteMembershipForWorkspaceRequest: (parameters: DeleteMembershipRequestParameters) =>
        dispatch(deleteMembershipForWorkspaceRequest(parameters)),
    dispatchUpdateMembershipRequest: (parameters: UpdateMembershipRequestParameters) =>
        dispatch(updateMembershipRequest(parameters)),
    dispatchGetMembershipsForWorkspaceRequest: (organizationId: string, workspaceId: string) =>
        dispatch(getMembershipsForWorkspaceRequest(organizationId, workspaceId)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    workspaces,
    global,
}: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    currentLocation: router.location,
    memberships: workspaces.membershipsForWorkspace,
    getMembershipsRequestStatus: workspaces.getWorkspacesForOrganizationRequestStatus,
    updateMembershipRequestStatus: workspaces.updateMembershipRequestStatus,
    deleteMembershipRequestStatus: workspaces.deleteMembershipRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageMembersPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    currentLocation: Location;
    memberships?: WorkspaceMembership[];
    getMembershipsRequestStatus?: RequestStatus;
    updateMembershipRequestStatus?: RequestStatus;
    deleteMembershipRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetMembershipsForWorkspaceRequest: typeof getMembershipsForWorkspaceRequest;
    dispatchDeleteMembershipForWorkspaceRequest: typeof deleteMembershipForWorkspaceRequest;
    dispatchUpdateMembershipRequest: typeof updateMembershipRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchAddToast: typeof addToast;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {}

type AllState = OwnState;
