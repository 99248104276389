import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { BASE_SIZE } from './aligment-view-constants';
import AlignmentHighlightInput from './alignment-highlight-input';
import { AlignmentViewRenderData, Highlight, NViewport, RowRenderData } from './alignment-view-types';
import styles from './alignment-viewer.module.scss';
import ChartSelector from './components/alignment-view-chart-selector';
import AlignmentViewHeatmapNavigation from './components/alignment-view-heatmap-navigation';
import AlignmentViewLabelRow from './components/alignment-view-label-row';
import PositionAxis from './components/alignment-view-position-axis';
import Row from './components/alignment-view-row';

interface RVProps {
    renderData: AlignmentViewRenderData | null;
    LabelRow?: any;
    onSubsequenceSelected?: (selection: string | null) => void;
    onSequenceSelected?: (selectedSequence: string | null) => void;
    onFilterApply?: (highlight: Highlight) => void;
    showMenu?: boolean;
    allowSearch?: boolean;
    highlighted?: Highlight;
}

function AlignmentViewer(props: RVProps): ReactElement {
    const {
        renderData,
        LabelRow = AlignmentViewLabelRow,
        onSubsequenceSelected,
        onSequenceSelected,
        showMenu,
        onFilterApply,
        highlighted,
        allowSearch,
    } = props;

    const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
    const [highlight, setHighlight] = useState<Highlight | undefined>(highlighted);

    const [normalizedViewport] = useState<NViewport>({ nX: 0, nY: 0, nWidth: 0, nHeight: 0 });
    const navRef = useRef<any>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const labelsRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const updateNavigatorPosition = () => {
        const content = contentRef?.current;
        if (content && navRef && navRef.current) {
            navRef.current.updateVP({
                nX: content.scrollLeft / content.scrollWidth,
                nY: content.scrollTop / content.scrollHeight,
                nWidth: content.clientWidth / content.scrollWidth,
                nHeight: content.clientHeight / content.scrollHeight,
            });
        }
    };

    useLayoutEffect(() => {
        if (renderData && contentRef && contentRef.current) {
            contentRef?.current?.scrollTo((renderData.searchOffset - 3.5) * BASE_SIZE, 0);
            updateNavigatorPosition();
        }
        return () => {};
    }, [contentRef, renderData]);

    if (!renderData) {
        return <div className='alignmentContainer'>ups</div>;
    }

    const { searchPattern, sequenceRows } = renderData;

    const onLabelSelect = (id: string | null): void => {
        setSelectedRowId(id);
        if (onSequenceSelected) {
            const row = sequenceRows.find((r) => r.id === id);
            if (row) {
                onSequenceSelected(row.sequence);
            } else {
                onSequenceSelected(null);
            }
        }
    };

    const handleLabelsScroll = (): void => {
        requestAnimationFrame(() => {
            contentRef?.current?.scrollTo(
                contentRef?.current?.scrollLeft,
                labelsRef.current?.scrollTop ? labelsRef.current?.scrollTop : 0,
            );
            updateNavigatorPosition();
        });
    };

    const handleSubsequenceSelection = (selection: string | null): void => {
        if (onSubsequenceSelected) onSubsequenceSelected(selection);
    };

    const handleContentScroll = (): void => {
        requestAnimationFrame(() => {
            labelsRef?.current?.scrollTo(0, contentRef.current?.scrollTop ? contentRef.current?.scrollTop : 0);
            headerRef?.current?.scrollTo(
                contentRef.current?.scrollLeft ? contentRef.current?.scrollLeft : 0,
                contentRef.current?.scrollTop ? contentRef.current?.scrollTop : 0,
            );

            updateNavigatorPosition();
        });
    };

    const handlePositionChange = (nv: NViewport) => {
        const content = contentRef?.current;
        if (content) {
            content.scrollTo(content.scrollWidth * nv.nX, content.scrollHeight * nv.nY);
        }
    };

    return (
        <div className={styles.alignmentViewer}>
            <div className={styles.highlightContainer}>
                <AlignmentHighlightInput
                    highlighted={highlight}
                    onHighlightApply={(h) => setHighlight(h)}
                    onFilterApply={
                        onFilterApply
                            ? (hh: Highlight) => {
                                  if (onFilterApply) {
                                      onFilterApply(hh);
                                  }
                              }
                            : undefined
                    }
                />
            </div>
            <div className={styles.heatmapContainer}>
                <AlignmentViewHeatmapNavigation
                    ref={navRef}
                    highlight={highlight}
                    renderData={renderData}
                    normalizedViewport={normalizedViewport}
                    onPositionChange={handlePositionChange}
                />
            </div>

            <div className={styles.header} ref={headerRef}>
                <PositionAxis offset={renderData.searchOffset} total={renderData.maxLength} step={3} />
                <Row row={searchPattern} key={searchPattern.id} highlight={highlight} />
            </div>
            <div className={styles.viewerTable}>
                <div className={styles.labelsContainer} ref={labelsRef} onScroll={handleLabelsScroll}>
                    {sequenceRows.map((row: RowRenderData) => {
                        return (
                            <LabelRow
                                row={row}
                                key={`l-${row.id}`}
                                selected={selectedRowId === row.id}
                                onSelect={onLabelSelect}
                            />
                        );
                    })}
                </div>
                <div className={styles.alignmentContainer} ref={contentRef} onScroll={handleContentScroll}>
                    <div style={{ pointerEvents: 'none' }}>
                        {sequenceRows.map((row: RowRenderData) => {
                            return <Row row={row} key={`r-${row.id}`} highlight={highlight} />;
                        })}
                    </div>
                    <ChartSelector
                        onSelect={onLabelSelect}
                        selectedRowId={selectedRowId}
                        renderData={renderData}
                        onSubsequenceSelection={handleSubsequenceSelection}
                        showMenu={showMenu}
                        isSearchAllowed={allowSearch}
                        onHighlight={(text) => {
                            // if (highlight) {
                            //     setHighlight({ ...highlight, pattern: text })
                            // } else {
                                setHighlight({ pattern: text, exclude:false })
                            // }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default AlignmentViewer;
