import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';
import { Dataset, DatasetWithLimit, getDatasetsForWorkspaceRequest } from '../../../../store/datasets';
import { closePopup } from '../../../../store/popup';
import { addToast } from '../../../../store/toast';
import DatasetLimitSelection from '../dataset-limit-selection/dataset-limit-selection';
import styles from './search-settings-popup.module.scss';

class SearchSettingsPopup extends React.Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);

        this.state = {
            selectedDatasets: [...props.storedSelectedDatasets],
        };
    }

    public render(): JSX.Element {
        const { t, workspaceDatasets } = this.props;
        const { selectedDatasets } = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>{t('Dataset filter')}</div>
                        <DatasetLimitSelection
                            availableDatasets={workspaceDatasets || []}
                            selectedDatasets={selectedDatasets}
                            maxHeightOffset={400}
                            onSelectionChange={(ids): void => this.updateSelectedDatasets(ids)}
                        />
                        <div className={styles.buttons}>
                            <button
                                disabled={selectedDatasets.length === 0}
                                className={`${styles.button} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.save()}>
                                {t('Save')}
                            </button>
                            <button
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.onClose()}>
                                {t('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private updateSelectedDatasets(datasets: DatasetWithLimit[]): void {
        this.setState({ selectedDatasets: datasets });
    }

    private save(): void {
        const { dispatchClosePopup, onSave } = this.props;
        const { selectedDatasets } = this.state;

        onSave(selectedDatasets);
        dispatchClosePopup();
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchGetDatasetsForWorkspaceRequest: (organizationId, workspaceId) =>
        dispatch(getDatasetsForWorkspaceRequest(organizationId, workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchSettingsPopup));

interface OwnProps {
    organizationId: string;
    workspaceId: string;
    workspaceDatasets: Dataset[];
    storedSelectedDatasets: DatasetWithLimit[];
    onSave: (selectedDatasets: DatasetWithLimit[]) => void;
}

interface PropsFromState {}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchGetDatasetsForWorkspaceRequest: typeof getDatasetsForWorkspaceRequest;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedDatasets: DatasetWithLimit[];
}

type AllState = OwnState;
