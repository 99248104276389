import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { AuthenticationAction, AuthenticationActionTypes, AuthenticationState } from './types';

export function initialState(): AuthenticationState {
    return {};
}

const reducer: Reducer<AuthenticationState, AuthenticationAction> = (
    state = initialState(),
    action,
): AuthenticationState => {
    switch (action.type) {
        case AuthenticationActionTypes.LOGIN_REQUEST: {
            return { ...state, loginRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case AuthenticationActionTypes.LOGIN_SUCCESS: {
            return { ...state, loginRequestStatus: new RequestStatus(RequestStatusType.Success) };
        }
        case AuthenticationActionTypes.LOGIN_FAILURE: {
            return { ...state, loginRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        case AuthenticationActionTypes.LOGOUT_REQUEST: {
            return { ...state };
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST: {
            return { ...state, forgotPasswordRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS: {
            return { ...state, forgotPasswordRequestStatus: new RequestStatus(RequestStatusType.Success) };
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_FAILURE: {
            return {
                ...state,
                forgotPasswordRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_REQUEST: {
            return { ...state, confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_SUCCESS: {
            return { ...state, confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.Success) };
        }
        case AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_FAILURE: {
            return {
                ...state,
                confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_REQUEST: {
            return { ...state, confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_SUCCESS: {
            return { ...state, confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.Success) };
        }
        case AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_FAILURE: {
            return {
                ...state,
                confirmPasswordRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return { ...initialState() };
        }
        default: {
            return state;
        }
    }
};

export { reducer as authenticationReducer };
export { initialState as initialAuthenticationState };
