import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { Dataset, getDatasetsForWorkspaceRequest, unlinkDatasetsToWorkspaceRequest } from '../../../../store/datasets';
import { AuthorizationContext } from '../../../../store/global';
import { Popup, PopupType, showPopup } from '../../../../store/popup';
import { RequestStatus } from '../../../../store/shared/types';
import { addToast, ToastType } from '../../../../store/toast';
import { DateHelper } from '../../../../utils/date-helper';
import { QueryParameter, QueryParameterHelper } from '../../../../utils/query-parameter-helpers';
import { AdminBasePage } from '../../admin-base.page';
import ActionsMenu, { ActionItem } from '../../components/actions-menu/actions-menu';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getWorkspaceAdminMenuConfiguration } from '../workspace-admin-menu.configuration';
import LinkDatasetPopup from './components/link-dataset-popup/link-dataset-popup';
import styles from './manage-workspace-datasets.module.scss';

class ManageWorkspaceDatasetsPage extends AdminBasePage<AllProps, AllState> {
    private workspaceId;

    public constructor(props) {
        super(props);
        const { currentLocation, authorizationContext } = this.props;

        if (currentLocation && authorizationContext) {
            this.updateDatasetsList();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { currentLocation, authorizationContext, unlinkDatasetsRequestStatus, t } = this.props;

        if (currentLocation && currentLocation !== prevProps.currentLocation) {
            this.updateDatasetsList();
        }

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            this.updateDatasetsList();
        }

        if (unlinkDatasetsRequestStatus && prevProps.unlinkDatasetsRequestStatus !== unlinkDatasetsRequestStatus) {
            if (unlinkDatasetsRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Dataset Successfully Unlinked'));
            }
            if (unlinkDatasetsRequestStatus.isFailed) {
                this.showToast(
                    ToastType.Error,
                    t('Unlinking dataset failed, please contact your administrator if this error persists.'),
                );
            }
        }
    }

    public render(): JSX.Element {
        const { t, getDatasetsRequestStatus, datasets, authorizationContext } = this.props;

        return (
            <div>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div className={styles.adminContainer}>
                    <AdminMenu menuItems={getWorkspaceAdminMenuConfiguration(authorizationContext, this.workspaceId)} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Datasets')}</div>
                            {getDatasetsRequestStatus?.isInProgress ? null : (
                                <button
                                    className={styles.button}
                                    type='button'
                                    onClick={(): void => {
                                        this.showLinkDatasetPopup();
                                    }}>
                                    {t('Link Datasets')}
                                </button>
                            )}
                        </div>
                        {getDatasetsRequestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin /> Loading...
                            </div>
                        ) : (
                            <div className={styles.table}>
                                <div className={`${styles.row} ${styles.header}`}>
                                    <div className={styles.cell} style={{ flexGrow: 2 }}>
                                        {t('Name')}
                                    </div>
                                    <div className={styles.cell}>{t('Owner')}</div>
                                    <div className={styles.cell}>{t('Created On')}</div>
                                    <div className={styles.cell}>{t('Last Updated On')}</div>
                                    <div className={styles.optionsCellSpacer} />
                                </div>
                                <div className={styles.rows}>
                                    {datasets?.map((d) => {
                                        return (
                                            <div className={styles.row} key={`${d.name}${d.createdOn}`}>
                                                <div className={styles.cell} style={{ flexGrow: 2 }}>
                                                    {d.name}
                                                </div>
                                                <div className={styles.cell}>{d.owner || t('n/a')}</div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(d.createdOn)}
                                                </div>
                                                <div className={styles.cell}>
                                                    {DateHelper.Iso8601ToLocalizedDateTime(d.lastUpdatedOn)}
                                                </div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu actions={this.getActionsForDataset(d)} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getActionsForDataset(dataset: Dataset): ActionItem[] {
        const { t } = this.props;

        return [
            {
                label: t('Unlink'),
                onClick: (): void => this.unlink(dataset),
            },
        ];
    }

    private getWorkspaceFromUrl(): string {
        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        return QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.WorkspaceId) || '';
    }

    private updateDatasetsList(): void {
        const { authorizationContext, dispatchGetDatasetsForWorkspaceRequest } = this.props;

        this.workspaceId = this.getWorkspaceFromUrl();
        const organizationId = authorizationContext?.organization.id;
        if (this.workspaceId && organizationId) {
            dispatchGetDatasetsForWorkspaceRequest(organizationId, this.workspaceId);
        }
    }

    private showLinkDatasetPopup(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.LINK_DATASET,
            content: <LinkDatasetPopup workspaceId={this.workspaceId} />,
            isDismissible: true,
        });
    }

    private unlink(dataset: Dataset): void {
        const { authorizationContext, dispatchUnlinkDatasetsFromWorkspaceRequest } = this.props;

        if (this.workspaceId && authorizationContext) {
            dispatchUnlinkDatasetsFromWorkspaceRequest({
                organizationId: authorizationContext.organization.id,
                workspaceId: this.workspaceId,
                datasetIds: [dataset.id],
            });
        }
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchGetDatasetsForWorkspaceRequest: (organizationId: string, workspaceId: string) =>
        dispatch(getDatasetsForWorkspaceRequest(organizationId, workspaceId)),
    dispatchUnlinkDatasetsFromWorkspaceRequest: (parameters) => dispatch(unlinkDatasetsToWorkspaceRequest(parameters)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    datasets,
    global,
    router,
}: ApplicationState) => ({
    authorizationContext: global.authorizationContext,
    currentLocation: router.location,
    datasets: datasets.datasetsForWorkspace,
    getDatasetsRequestStatus: datasets.getDatasetsForWorkspaceRequestStatus,
    unlinkDatasetsRequestStatus: datasets.unlinkDatasetsFromWorkspaceRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageWorkspaceDatasetsPage));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    currentLocation: Location;
    datasets?: Dataset[];
    getDatasetsRequestStatus?: RequestStatus;
    unlinkDatasetsRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetDatasetsForWorkspaceRequest: typeof getDatasetsForWorkspaceRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchAddToast: typeof addToast;
    dispatchUnlinkDatasetsFromWorkspaceRequest: typeof unlinkDatasetsToWorkspaceRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {}

type AllState = OwnState;
