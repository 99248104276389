import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import { QueryParameter, QueryParameterHelper } from '../../../utils/query-parameter-helpers';
import styles from './confirmation-underway.module.scss';
import { LeftPanel } from '../left-panel';

enum ViewMode {
    ForgotPassword,
    IsOnboarding,
}

class ConfirmationUnderwayPage extends React.Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);

        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        const email = QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.Email) || '';
        const isOnboarding = QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.IsOnboarding) || '';
        const viewMode = isOnboarding ? ViewMode.IsOnboarding : ViewMode.ForgotPassword;

        this.state = {
            email,
            viewMode,
        };
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const { email, viewMode } = this.state;

        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.contentPanel}>
                        <LeftPanel />
                        <div className={styles.rightPanel}>
                            <div className={styles.rightTopPanel}>
                                <Link to={RouteUrl.Login}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> {t('Back to Login')}
                                </Link>
                            </div>
                            <div className={styles.rightMiddlePanel}>
                                <div className={styles.header}>
                                    {viewMode === ViewMode.ForgotPassword ? (
                                        <>
                                            <div className={styles.titleOne}>{t('Please check your inbox')}</div>
                                            <div className={styles.titleTwo}>{t('Email has been sent to')}</div>
                                            <div>{email}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={styles.titleOne}>{t('Welcome to BioStrand!')}</div>
                                            <div className={styles.titleTwo}>{t('Please, check your inbox')}</div>
                                            <div>{t('An activation email was sent to {{email}}', { email })}</div>
                                        </>
                                    )}
                                </div>
                                <div className={styles.buttons}>
                                    <button
                                        type='button'
                                        className={styles.button}
                                        onClick={() => this.onBackToLogin()}>
                                        {t('Okay, take me back to login')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public onBackToLogin() {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: RouteUrl.Login });
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ router }: ApplicationState) => ({
    currentLocation: router.location,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmationUnderwayPage));

interface PropsFromState {
    currentLocation: Location;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    viewMode: ViewMode;
}

type AllState = OwnState;
