import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Dropdown, { DropdownOption } from '../../../../../components/dropdown/dropdown';
import { ApplicationState } from '../../../../../store';
import { AuthorizationContext, WorkspaceRole } from '../../../../../store/global';
import { closePopup } from '../../../../../store/popup';
import { FailureCodes, RequestStatus } from '../../../../../store/shared/types';
import { addToast, ToastType } from '../../../../../store/toast';
import {
    createMembershipForWorkspaceRequest,
    CreateMembershipRequestParameters,
    WorkspaceInfo,
} from '../../../../../store/workspaces';
import { AdminBasePage } from '../../../admin-base.page';
import styles from './add-member-popup.module.scss';

class AddMemberPopup extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);
        const { t } = this.props;
        const availableRoles: DropdownOption[] = [
            { id: WorkspaceRole.REGULAR, label: t('Regular User') },
            { id: WorkspaceRole.ADMIN, label: t('Administrator') },
        ];

        this.state = {
            email: '',
            showFeedback: false,
            availableRoles,
            selectedRole: availableRoles[0],
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const { requestStatus, dispatchClosePopup, t } = this.props;

        if (requestStatus && prevProps.requestStatus !== requestStatus) {
            if (requestStatus.isSuccess) {
                dispatchClosePopup();
                this.showToast(ToastType.Success, t('Member Successfully Added'));
            }
            if (requestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(requestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const { t, requestStatus } = this.props;
        const { showFeedback, feedbackMessage, availableRoles, selectedRole } = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>{t('Add Member')}</div>
                        <div className={styles.form} onKeyDown={(e) => this.onKeyPressed(e)}>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Email')}</div>
                                <input
                                    className={styles.emailInput}
                                    type='text'
                                    key='EmailField'
                                    autoFocus
                                    placeholder={t('james@organization.com')}
                                    onChange={(e) => this.onEmailChange(e)}
                                />
                            </div>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Role')}</div>
                                <Dropdown
                                    selection={selectedRole}
                                    options={availableRoles}
                                    onSelectionChanged={(option: DropdownOption) => this.onRoleSelectionChanged(option)}
                                />
                            </div>
                        </div>
                        <div className={styles.feedbackMessage}>{showFeedback ? feedbackMessage : ''}</div>
                        <div className={styles.buttons}>
                            <button
                                disabled={requestStatus?.isInProgress}
                                className={`${styles.button} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onAddMember()}>
                                {requestStatus?.isInProgress ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                    t('Add Member')
                                )}
                            </button>
                            <button
                                disabled={requestStatus?.isInProgress}
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onClose()}>
                                {t('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;
        const { email } = this.state;

        switch (requestStatus.failureCode) {
            case FailureCodes.WS7:
                return t('Membership already exists.');
            case FailureCodes.WS10:
                return t('User {{email}} not found, please make sure this user exists.', { email });
            default:
                return t('Membership creation failed, please contact your administrator if this error persists.');
        }
    }

    private onKeyPressed(e): void {
        const { requestStatus } = this.props;

        if (!requestStatus?.isInProgress && e.keyCode === 13) {
            this.onAddMember();
        }
    }

    private onRoleSelectionChanged(selectedRole: DropdownOption): void {
        this.setState({ selectedRole });
    }

    private onEmailChange(e): void {
        this.setState({ email: e.target.value });
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private onAddMember(): void {
        const { dispatchCreateMembershipForWorkspaceRequest, authorizationContext, workspaceId } = this.props;
        const { email, selectedRole } = this.state;

        if (this.validateForm() && selectedRole) {
            dispatchCreateMembershipForWorkspaceRequest({
                email,
                workspaceId,
                organizationId: authorizationContext!.organization.id,
                workspaceRole: WorkspaceRole[selectedRole.id],
            });
        }
    }

    private validateForm(): boolean {
        const { email } = this.state;

        if (!email || email.length < 6) {
            this.setState({
                showFeedback: true,
                feedbackMessage: 'Email field is required',
            });
            return false;
        }

        return true;
    }
}

const mapStateToProps = ({ users, workspaces, global }: ApplicationState): PropsFromState => ({
    authorizationContext: global.authorizationContext,
    workspaces: workspaces.workspacesForOrganization,
    requestStatus: workspaces.createMembershipRequestStatus,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchCreateMembershipForWorkspaceRequest: (parameters: CreateMembershipRequestParameters) =>
        dispatch(createMembershipForWorkspaceRequest(parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddMemberPopup));

interface OwnProps {
    workspaceId: string;
}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    workspaces?: WorkspaceInfo[];
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchCreateMembershipForWorkspaceRequest: typeof createMembershipForWorkspaceRequest;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    availableRoles: DropdownOption[];
    selectedRole: DropdownOption;
    showFeedback: boolean;
    feedbackMessage?: string;
}

type AllState = OwnState;
