import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { UsersApi } from '../../api/users-api';
import { WorkspaceResponse } from '../../http/http';
import { handleUnexpectedError, handleUnexpectedErrorWithToast } from '../http-error-handler';
import { determineApiErrorCode } from '../shared/util';
import {
    deleteUserFailure,
    deleteUserRequest,
    deleteUserSuccess,
    getUsersForOrganizationFailure,
    getUsersForOrganizationRequest,
    getUsersForOrganizationSuccess,
    inviteUserFailure,
    inviteUserRequest,
    inviteUserSuccess,
    updateUserFailure,
    updateUserRequest,
    updateUserSuccess,
} from './actions';
import {
    DeleteUserRequestParameters,
    InviteUserRequestParameters,
    InviteUserResponse,
    UpdateUserRequestParameters,
    UserInfo,
    UsersActionTypes,
} from './types';

function* handleGetUsersForOrganizationRequest(action: ActionType<typeof getUsersForOrganizationRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<UserInfo[]> = yield call(UsersApi.getUsersForOrganization, organizationId);
        yield put(getUsersForOrganizationSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getUsersForOrganizationFailure(err));
    }
}

function* handleUpdateUserRequest(action: ActionType<typeof updateUserRequest>) {
    try {
        const parameters: UpdateUserRequestParameters = action.payload;
        const response: WorkspaceResponse<UserInfo> = yield call(UsersApi.updateUser, parameters);
        yield put(updateUserSuccess(response.data));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(updateUserFailure(failureCode));
    }
}

function* handleInviteUserRequest(action: ActionType<typeof inviteUserRequest>) {
    try {
        const parameters: InviteUserRequestParameters = action.payload;
        const response: WorkspaceResponse<InviteUserResponse> = yield call(
            UsersApi.inviteUserToOrganization,
            parameters,
        );
        yield put(inviteUserSuccess(response.data.user));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield put(inviteUserFailure(failureCode));
        yield call(handleUnexpectedError, err);
    }
}

function* handleDeleteUserRequest(action: ActionType<typeof deleteUserRequest>) {
    try {
        const parameters: DeleteUserRequestParameters = action.payload;

        yield call(UsersApi.deleteUser, parameters);
        yield put(deleteUserSuccess(parameters.email));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(deleteUserFailure(failureCode));
    }
}

function* watchRequests() {
    yield takeEvery(UsersActionTypes.GET_USERS_FOR_ORGANIZATION_REQUEST, handleGetUsersForOrganizationRequest);
    yield takeEvery(UsersActionTypes.UPDATE_USER_REQUEST, handleUpdateUserRequest);
    yield takeEvery(UsersActionTypes.INVITE_USER_REQUEST, handleInviteUserRequest);
    yield takeEvery(UsersActionTypes.DELETE_USER_REQUEST, handleDeleteUserRequest);
}

function* usersSaga() {
    yield all([fork(watchRequests)]);
}

export default usersSaga;
