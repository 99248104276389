import Color from 'color';
import { interpolateGnBu, interpolateGreys, interpolateOrRd } from 'd3-scale-chromatic';

interface ColorMap {
    [key: string]: string;
}

//
// const colorMap: any = {
//     A: "#5858a7",
//     R: "#6b6b94",
//     N: "#64649b",
//     D: "#2121de",
//     C: "#9d9d62",
//     Q: "#8c8c73",
//     E: "#0000ff",
//     G: "#4949b6",
//     H: "#60609f",
//     I: "#ecec13",
//     L: "#b2b24d",
//     K: "#4747b8",
//     M: "#82827d",
//     F: "#c2c23d",
//     P: "#2323dc",
//     S: "#4949b6",
//     T: "#9d9d62",
//     W: "#c0c03f",
//     Y: "#d3d32c",
//     V: "#ffff00",
//     B: "#4343bc",
//     X: "#797986",
//     Z: "#4747b8"
// };

const taylorColorMap: ColorMap = {
    A: '#ccff00',
    R: '#0000ff',
    N: '#cc00ff',
    D: '#ff0000',
    C: '#ffff00',
    Q: '#ff00cc',
    E: '#ff0066',
    G: '#ff9900',
    H: '#0066ff',
    I: '#66ff00',
    L: '#33ff00',
    K: '#6600ff',
    M: '#00ff00',
    F: '#00ff66',
    P: '#ffcc00',
    S: '#ff3300',
    T: '#ff6600',
    W: '#00ccff',
    Y: '#00ffcc',
    V: '#99ff00',
    B: '#ffffff',
    X: '#ffffff',
    Z: '#ffffff',
};
//
// const clustal2ColorMap: ColorMap = {
//     A: "#80a0f0",
//     R: "#f01505",
//     N: "#00ff00",
//     D: "#c048c0",
//     C: "#f08080",
//     Q: "#00ff00",
//     E: "#c048c0",
//     G: "#f09048",
//     H: "#15a4a4",
//     I: "#80a0f0",
//     L: "#80a0f0",
//     K: "#f01505",
//     M: "#80a0f0",
//     F: "#80a0f0",
//     P: "#ffff00",
//     S: "#00ff00",
//     T: "#00ff00",
//     W: "#80a0f0",
//     Y: "#15a4a4",
//     V: "#80a0f0",
//     B: "#ffffff",
//     X: "#ffffff",
//     Z: "#ffffff"
// }

export function pastelColor(color:string, light):string {
    const colorObj = new Color(color);
    return colorObj.lightness(light).hex();
}

const makePastelColors = (cmap: ColorMap, light): ColorMap => {
    const newMap: ColorMap = {};
    Object.keys(cmap).forEach((key: string) => {
        newMap[key] = pastelColor(cmap[key], light);
    });
    return newMap;
}

const aaMap: ColorMap = makePastelColors(taylorColorMap, 80);

const aaBrightMap: ColorMap = makePastelColors(taylorColorMap, 93);

const getAAColor = (key: string): string => {
    return aaMap[key] as string;
};

const getAABrightColor = (key: string): string => {
    return aaBrightMap[key] as string;
};

const nucleotideColors: ColorMap = {
    A: '#64F73F',
    C: '#FFB340',
    G: '#EB413C',
    T: '#3C88EE',
    U: '#3C88EE',
};

const dnaMap: ColorMap = makePastelColors(nucleotideColors, 80);
const dnaBrightMap: ColorMap = makePastelColors(nucleotideColors, 93);

const getDNAColor = (key: string): string => {
    return dnaMap[key] as string;
};

const getDNABrightColor = (key: string): string => {
    return dnaBrightMap[key] as string;
};

const getHeatmapGrayColor = ( value: number  /* 1..100 */ | undefined ): string => {
    return (value !== undefined) ? interpolateGreys((value+100) / 350 - 0.20 ) : '#ffffff';
}

const getHeatmapHighlightColor = ( value: number  /* 1..100 */ | undefined ): string => {
    return (value !== undefined) ? interpolateOrRd((value+50) / 175 + 0.1) : '#ffffff';
}

const getHeatmapColor = ( value: number  /* 1..100 */ | undefined ): string => {
    return (value !== undefined) ? interpolateGnBu(value / 125 + 0.06) : '#ffffff';
}

const HEATMAP_SELECTION_COLOR = '#d14f65';

export { getAAColor, getDNAColor, getHeatmapColor, getHeatmapGrayColor, getHeatmapHighlightColor, getAABrightColor, getDNABrightColor, HEATMAP_SELECTION_COLOR };


