import { Feature } from '../store/global';

export class RuntimeConfig {
    public static getGatewayApiUrl(): string {
        return this.getRuntimeConfig('gatewayApiUrl');
    }

    public static getIrisApiUrl(): string {
        return this.getRuntimeConfig('irisApiUrl');
    }

    public static getCognitoUserPoolId(): string {
        return this.getRuntimeConfig('cognitoUserPoolId');
    }

    public static getCognitoRegion(): string {
        return this.getRuntimeConfig('cognitoRegion');
    }

    public static getCognitoIdentityPoolId(): string {
        return this.getRuntimeConfig('cognitoIdentityPoolId');
    }

    public static getCognitoClientId(): string {
        return this.getRuntimeConfig('cognitoClientId');
    }

    public static getStripePublishableKey(): string {
        return this.getRuntimeConfig('stripePublishableKey');
    }

    public static getRootDomain(): string {
        return this.getRuntimeConfig('rootUrl');
    }

    private static getFeatures(): string[] {
        return this.getRuntimeConfig('features');
    }

    public static isSupported(featureName: Feature): boolean {
        return RuntimeConfig.getFeatures() && RuntimeConfig.getFeatures().includes(featureName);
    }

    private static getRuntimeConfig<T>(configProp: string): T {
        return (window as Window & typeof globalThis & { runConfig: { [key: string]: T } }).runConfig[configProp];
    }
}
