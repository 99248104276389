import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import AuthorizationApi from '../../api/authorization-api';
import OrganizationsApi from '../../api/organizations-api';
import { WorkspaceResponse } from '../../http/http';
import { RouteUrl } from '../../routing/route-url';
import { updateQueryParameters } from '../../utils/query-parameter-helpers';
import { generateUUID } from '../../utils/uuid-helpers';
import { loginFailure, loginSuccess } from '../authentication';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { FailureCodes } from '../shared/types';
import { determineApiErrorCode } from '../shared/util';
import { SubscriptionHelper } from '../subscription/helpers/subscription-helper';
import { addToast, ToastType } from '../toast';
import {
    createOrganizationFailure,
    createOrganizationRequest,
    createOrganizationSuccess,
    getAuthorizationContext,
    getAuthorizationContextFailure,
    getAuthorizationContextSuccess,
} from './actions';
import { AuthorizationContext, CreateOrganizationRequestParameters, GlobalActionTypes } from './types';

export function* handleGetAuthorizationContext(action: ActionType<typeof getAuthorizationContext>) {
    try {
        const response: WorkspaceResponse<AuthorizationContext> = yield call(AuthorizationApi.getAuthorizationContext);
        const context = response.data;
        yield put(getAuthorizationContextSuccess(context));

        const defaultMembership = context.memberships.find((m) => m.isDefault);
        if (!defaultMembership) {
            throw new Error(
                'There seems to be something wrong with the user configuration, no default membership was found.',
            );
        }

        if (!SubscriptionHelper.hasValidSubscription(context)) {
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Info,
                    messages: ["You don't have an active subscription", 'Click this message to go to the billing page'],
                    navigateTo: `/${defaultMembership.workspace.id}${RouteUrl.ManageBilling}`,
                }),
            );
        }

        if (SubscriptionHelper.shouldBeReminded(context)) {
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Warning,
                    messages: ['Your billing details are incomplete.', 'Click this message to go to the billing page'],
                    navigateTo: `/${defaultMembership.workspace.id}${RouteUrl.ManageBilling}`,
                }),
            );
        }

        yield put(loginSuccess());
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getAuthorizationContextFailure(FailureCodes.Unexpected));
        yield put(loginFailure(FailureCodes.Unexpected));
    }
}

function* handleCreateOrganizationRequest(action: ActionType<typeof createOrganizationRequest>) {
    try {
        const parameters: CreateOrganizationRequestParameters = action.payload;
        yield call(OrganizationsApi.createOrganization, parameters);
        const location: LocationDescriptorObject = {
            pathname: RouteUrl.ConfirmationUnderway,
            search: updateQueryParameters({ email: parameters.email, onboarding: 'true' }, ''),
        };
        yield put(createOrganizationSuccess());
        yield put(push(location));
    } catch (err) {
        yield put(createOrganizationFailure(determineApiErrorCode(err)));
    }
}

function* watchRequests() {
    yield takeEvery(GlobalActionTypes.GET_AUTHORIZATION_CONTEXT, handleGetAuthorizationContext);
    yield takeEvery(GlobalActionTypes.CREATE_ORGANIZATION_REQUEST, handleCreateOrganizationRequest);
}

function* globalSaga() {
    yield all([fork(watchRequests)]);
}

export default globalSaga;
