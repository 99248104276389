import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import { logoutRequest } from '../../../store/authentication';
import { Popup, showPopup } from '../../../store/popup';
import { clearQuery, Dimension, DimensionFilter } from '../../../store/search';
import PageHeaderBase from '../page-header-base';
import FilterSummaryBar from './components/filter-summary/filter-summary-bar';
import QueryStringSummary from './components/query-string-summary/query-string-summary';
import { FilterSummaryCreator } from './helpers/filter-summary-creator';
import styles from './results-page-header.module.scss';

class ResultsPageHeader extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { t, dimensions, filters } = this.props;
        const filterSummaries = FilterSummaryCreator.createSummaries(filters, dimensions, t);
        return (
            <PageHeaderBase>
                <div className={styles.bottomContainer}>
                    <button
                        className={`${styles.button} ${styles.tertiary}`}
                        onClick={(): void => this.back()}
                        type='button'>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 10 }} />
                        {t('New Search')}
                    </button>
                    <div className={styles.summaryContainer}>
                        <QueryStringSummary />
                        <FilterSummaryBar filterSummaries={filterSummaries} />
                    </div>
                </div>
            </PageHeaderBase>
        );
    }

    private back(): void {
        const { dispatchNavigateTo, dispatchClearQuery, activeWorkspaceId } = this.props;
        dispatchClearQuery();
        dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${RouteUrl.Search}` });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = (state: ApplicationState) => {
    const { global, search } = state;
    return {
        activeWorkspaceId: global.activeWorkspaceId,
       dimensions: search.dimensions,
}};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogout: () => dispatch(logoutRequest()),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClearQuery: () => dispatch(clearQuery()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResultsPageHeader));

interface OwnProps {
    location: Location;
    filters: DimensionFilter[];
}

interface PropsFromState {
    activeWorkspaceId?: string;
    dimensions: Dimension[];
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchLogout: typeof logoutRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchClearQuery: typeof clearQuery;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps & WithTranslation;
