import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import React from 'react';
import { all, call, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import biostrandApi from '../../api/biostrand-api';
import {
    AlignAlignResponse,
    ApiGatewayEnrichQueryResponse,
    ApiGatewayQueryTaskObject,
    SearchAndRelateCountCube,
    SearchAndRelateDimensionCounts,
    SearchAndRelateFirstOrderCounts,
    SearchAndRelateQueryFilter,
    SearchAndRelateTaxonomyResponse,
    SharedMolType,
    SharedQueryType,
    SharedTaskStatusCode,
} from '../../api/generated';
import {
    clearSearchHistory,
    SearchHistoryActionTypes,
    setSearchHistory,
} from '../../components/page-header/search-page-header/search-history-actions';
import SelectDNAFrameAndTablePopup from '../../components/page-header/search-page-header/select-dna-frame-and-table-popup';
import { DNA_SETTINGS_APPLIED } from '../../components/page-header/search-page-header/select-dna-frame-and-table-popup-actions';
import SelectSequencePopup from '../../components/page-header/search-page-header/select-sequence-popoup';
import { SEQUENCE_SELECTED } from '../../components/page-header/search-page-header/select-sequence-popup-actions';
import {
    defaultRootQueryDimensionNames,
    SEQUENCE_DIMENSION_NAME,
    sortColumns,
} from '../../pages/results/constants/dimension-constants';
import {
    excludeRegex,
    patternToRegexString,
} from '../../pages/results/views/alignment-view/utils/prepare-alignment-view-render-data';
import { ColumnConfig } from '../../pages/results/views/list-view/components/results-list/column-config';
import { listFieldsToColumns } from '../../pages/results/views/list-view/utils/columns-create-helpers';
import { RouteUrl } from '../../routing/route-url';
import { SortDirection } from '../../types/sorting';
import {
    localSearchHistoryKey,
    localSearchSettingsKey,
    persistParsedToLocalStorage,
    retrieveParsedFromLocalStorage,
    updateSearchHistory,
} from '../../utils/local-storage-helpers';
import { updateQueryParameters } from '../../utils/query-parameter-helpers';
import UserCancelActionError from '../../utils/UserCancelActionError';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { ApplicationState } from '../index';
import { PopupActionTypes, PopupType, showPopup } from '../popup';
import { SubscriptionHelper } from '../subscription/helpers/subscription-helper';
import filterSaga from './filter-sagas';
import {
    convertDimensionAggregates,
    createFakeDimensionAggregates,
    createFakeDimensionAggregatesFromDimensions,
} from './helpers/dimension-aggregate-converter';
import { DimensionBuilder } from './helpers/dimensions-builder';
import { populateSequenceFields } from './helpers/sequence-fields-populator';
import {
    alignmentSearchCanceled,
    alignmentSearchError,
    alignmentSearchRequest,
    alignmentSearchRequestSuccess,
    baseSearchRequesSuccess,
    baseSearchRequest, baseSearchRequestError, explorerSearchCanceled, explorerSearchError,
    explorerSearchRequest,
    explorerSearchRequestSuccess, listSearchCanceled, listSearchError,
    listSearchRequest,
    listSearchRequestSuccess,
    quickFilterSearchRequest,
    quickFilterSearchRequestSuccess,
    rootSearchRequest,
    rootSearchRequestSuccess,
    runSearchRequest,
    setCurrentDimensions,
    setCurrentTaxonomy,
    setDimensions,
    setRootQueryTaxonomy,
    setSearchRunning, rootSearchRequestError, listTooManyResults, alignmentTooManyResults,
} from './search-actions';
import {
    ALIGNMENT_RESULT_LIMIT,
    BaseSearchParameters,
    Dimension,
    DimensionFilter,
    DimensionWithAllElements, LIST_RESULT_LIMIT,
    ListResultViewData,
    QuickFilterSearchParameters,
    SearchActionTypes,
    SearchAggregatesResultSummary,
    SearchHistoryEntry,
    SearchSettings,
} from './types';

enum TaskResult {
    CANCELED = 'CANCELED',
    READY = 'READY',
}

const getAllDimensions = () => select((state: ApplicationState) => state.search.dimensions);
const getActiveWorkspaceId = () => select((state: ApplicationState) => state.global.activeWorkspaceId);

const isDNAQuery = (result: ApiGatewayEnrichQueryResponse) => {
    if (result.type === SharedQueryType.SIMPLE_SEQUENCE || result.type === SharedQueryType.SINGLE_FASTA) {
        const sequenceInfo = result.data && result.data[0] && result.data[0].sequenceInfo;

        if (!sequenceInfo) return false;

        return sequenceInfo.molType === SharedMolType.DNA || sequenceInfo.molType === SharedMolType.MRNA;
    }
    return false;
};

function translateToApiFilter(filter: DimensionFilter, dimension: Dimension): SearchAndRelateQueryFilter {
    // sequence filter expect match property for the filtering.
    if (filter.dimensionName === SEQUENCE_DIMENSION_NAME) {
        const s = patternToRegexString(filter.elementNamesContains as string);

        return {
            dimensionField: filter.dimensionName,
            search: [filter.excludesAll ? excludeRegex(s) : s],
        };
    }

    const newFilter: SearchAndRelateQueryFilter = {
        dimensionField: filter.dimensionName,
        includes: filter.includes,
        elementNamesContains: filter.elementNamesContains,
    };

    if (filter.excludesAll) {
        if ((dimension as DimensionWithAllElements).elements) {
            newFilter.excludes = (dimension as DimensionWithAllElements).elements.map((e) => e.name);
        } else {
            throw new Error('Excluding all elements from a Lazy dimension is not supported');
        }
    }

    return newFilter;
}

function* addSearchHistoryEntry(workspaceId: string, query: string, resultCount: number) {
    const key = localSearchHistoryKey(workspaceId);
    const history = retrieveParsedFromLocalStorage<SearchHistoryEntry[]>(key);

    const entry: SearchHistoryEntry = {
        query,
        date: Date.now(),
        totalCount: resultCount || 0,
    };

    if (history && history[0] && history[0].query === query) {
        history.shift(); // remove last result because it contains the same query;
    }

    const updatedHistory = history ? [entry].concat(history) : [entry];
    yield put(setSearchHistory(updatedHistory));
    persistParsedToLocalStorage<SearchHistoryEntry[]>(key, updatedHistory);
}

function* watchTaskResult(queryId: string, taskId: string) {
    while (true) {
        const response: AxiosResponse<ApiGatewayQueryTaskObject> = yield call(
            [biostrandApi, 'biostrandGatewayGetQueryTaskById'],
            queryId,
            taskId,
        );

        const currentQueryID = yield select( (state: ApplicationState) => state.search.baseQueryId);
        if (currentQueryID !== queryId) {
            return TaskResult.CANCELED;
        }
        if (response.data.status?.code === SharedTaskStatusCode.SUCCEEDED) {
            return TaskResult.READY;
        }

        if (response.data.status?.code === SharedTaskStatusCode.FAILED) {
            throw new Error('Task failed');
        }
        yield delay(2000);
    }
}

function* watchQueryTaskResult(queryId: string, taskId: string) {
    while (true) {
        const response: AxiosResponse<ApiGatewayQueryTaskObject> = yield call(
            [biostrandApi, 'biostrandGatewayGetQueryTaskById'],
            queryId,
            taskId,
        );

        if (response.data.status?.code === SharedTaskStatusCode.SUCCEEDED) {
            return TaskResult.READY;
        }

        if (response.data.status?.code === SharedTaskStatusCode.FAILED) {
            throw new Error('Task failed');
        }
        yield delay(2000);
    }
}

function* handleRootSearchRequest(action: ActionType<typeof rootSearchRequest>) {
    try {
        const workspaceId: string = action.payload[0];
        const query: string = action.payload[1];
        const searchSettings = retrieveParsedFromLocalStorage<SearchSettings>(localSearchSettingsKey(workspaceId));
        const taskResult = yield call([biostrandApi, 'biostrandGatewayRunQuery'], {
            query,
            dimensions: defaultRootQueryDimensionNames,
            datasets: searchSettings?.selectedDatasets || [],
        });

        yield watchQueryTaskResult(taskResult.data.queryId, taskResult.data.queryId);

        const response = yield call(
            [biostrandApi, 'biostrandGatewayGetQueryResult'],
            taskResult.data.queryId || '',
        );

        const { data } = response;

        const uniqueSequencesCount = data?.result?.totalCount || 0;

        const allDimensions = DimensionBuilder.buildDimensions(
            data?.result?.dimensions,
            data?.result?.dimensionPlaceholders,
        );

        yield put(setDimensions(query, allDimensions));
        yield put(setCurrentDimensions(data.queryId as string, allDimensions));

        const txResponse = yield call([biostrandApi, 'biostrandGatewayGetTaxonomy'], data.queryId  as string);
        const taxonomy = txResponse.data;

        yield put(setRootQueryTaxonomy(taxonomy));
        yield put(setCurrentTaxonomy(taxonomy));

        yield addSearchHistoryEntry(workspaceId, query, uniqueSequencesCount);
        yield put(rootSearchRequestSuccess(query, uniqueSequencesCount, data.queryId as string, data.metaData));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(rootSearchRequestError());
    }
}

function* handleBaseSearchRequest(action: ActionType<typeof baseSearchRequest>) {
    try {
        const parameters: BaseSearchParameters = action.payload;
        const allDimensions = yield getAllDimensions();
        const activeWorkspaceId = yield getActiveWorkspaceId();
        const searchSettings = retrieveParsedFromLocalStorage<SearchSettings>(
            localSearchSettingsKey(activeWorkspaceId),
        );

        const taskResult = yield call([biostrandApi, 'biostrandGatewayRunQuery'], {
            query: parameters.rootQuery,
            dimensions: defaultRootQueryDimensionNames,
            datasets: searchSettings?.selectedDatasets || [],
            filters: parameters.filters.map((f) =>
                translateToApiFilter(
                    f,
                    allDimensions.find((d) => d.name === f.dimensionName),
                ),
            ),
        });

        yield watchQueryTaskResult(taskResult.data.queryId, taskResult.data.queryId);
        const response = yield call(
            [biostrandApi, 'biostrandGatewayGetQueryResult'],
            taskResult.data.queryId || '',
        );

        const { data } = response;
        const uniqueSequencesCount = data.result.totalCount || 0;

        const rdResponce: AxiosResponse<SearchAndRelateFirstOrderCounts> = yield call(
            [biostrandApi, 'biostrandGatewayGetDimensions'],
            taskResult.data.queryId as string,
        );
        const rootDimensions = rdResponce.data;

        const currentDimensions = DimensionBuilder.buildDimensions(
            rootDimensions.dimensions,
            rootDimensions.dimensionPlaceholders,
        );
        yield put(setCurrentDimensions(data.queryId as string, currentDimensions));

        const taxonomy: AxiosResponse<SearchAndRelateTaxonomyResponse> = yield call(
            [biostrandApi, 'biostrandGatewayGetTaxonomy'],
            data.queryId as string,
        );

        yield put(setCurrentTaxonomy(taxonomy.data));

        yield put(baseSearchRequesSuccess(parameters, data.queryId as string, uniqueSequencesCount, data.metaData));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(baseSearchRequestError());
    }
}

function* handleQuickFilterSearchRequest(action: ActionType<typeof quickFilterSearchRequest>) {
    try {
        const parameters: QuickFilterSearchParameters = action.payload;

        const dimensionNames = parameters.dimensions;
        let fakeSummaries: SearchAggregatesResultSummary[] = [];
        // todo@Sam: we should only fetch relevant dimensions ???
        if (parameters.filters !== null && parameters.filters.length > 0) {
            const rdResponce: AxiosResponse<SearchAndRelateFirstOrderCounts> = yield call(
                [biostrandApi, 'biostrandGatewayGetDimensions'],
                parameters.baseQueryId as string,
            );

            const rootDimensions = rdResponce.data;

            const relevantDimensions: SearchAndRelateDimensionCounts[] = rootDimensions.dimensions
                ? rootDimensions.dimensions.filter((d) => dimensionNames.includes(d.name || ''))
                : [];
            fakeSummaries = createFakeDimensionAggregates(relevantDimensions);
        } else {
            const allDimensions: Dimension[] = yield getAllDimensions();
            const relevantDimensions: Dimension[] = allDimensions.filter(
                (d: any) => dimensionNames.includes(d.name) && d.elements,
            );
            fakeSummaries = createFakeDimensionAggregatesFromDimensions(
                relevantDimensions as DimensionWithAllElements[],
            );
        }
        yield put(
            quickFilterSearchRequestSuccess(parameters, {
                queryId: parameters.baseQueryId,
                summaries: fakeSummaries,
            }),
        );
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* handleExplorerSearchRequest(action: ActionType<typeof explorerSearchRequest>) {
    try {
        const parameters = action.payload;
        const queryId = parameters.baseQueryId;
        const dimensions = parameters.rowSelection.concat(parameters.columnSelection);
        const taskRequest = yield call([biostrandApi, 'biostrandGatewayRunCountCubeTask'], queryId, {
            queryId,
            dimensions,
        });
        const {
            data: { taskId },
        } = taskRequest;

        const tr = yield watchTaskResult(queryId, taskId);
        if (tr === TaskResult.CANCELED) {
            yield put(explorerSearchCanceled());
            return;
        }

        const taskResult: AxiosResponse<SearchAndRelateCountCube> = yield call(
            [biostrandApi, 'biostrandGatewayGetCountCube'],
            queryId || '',
            taskId,
        );
        const summaries = convertDimensionAggregates(taskResult.data.buckets || []);
        yield put(explorerSearchRequestSuccess(parameters, { queryId: taskResult.data.queryId || '', summaries }));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(explorerSearchError());
    }
}

function* handleAlignmentSearchRequest(action: ActionType<typeof alignmentSearchRequest>) {
    const totalResults = yield select( (state:ApplicationState) => state.search.queryMatches);

    if (totalResults > ALIGNMENT_RESULT_LIMIT) {
        yield put(alignmentTooManyResults());
        return;
    }

    try {
        const parameters = action.payload;

        const { baseQueryId, molType, page, pageSize } = parameters;

        const taskRequest = yield call([biostrandApi, 'biostrandGatewayRunAlignmentTaskForQuery'], baseQueryId || '', {
            queryId: baseQueryId,
            molType,
        });
        const {
            data: { taskId },
        } = taskRequest;
        const tr = yield watchTaskResult(parameters.baseQueryId, taskId);
        if (tr === TaskResult.CANCELED)  {
            yield put(alignmentSearchCanceled());
            return;
        }
        const taskResult: AxiosResponse<AlignAlignResponse> = yield call(
            [biostrandApi, 'biostrandGatewayGetAlignmentForQuery'],
            baseQueryId || '',
            taskId,
            page,
            pageSize,
        );

        yield put(alignmentSearchRequestSuccess(parameters, taskResult.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(alignmentSearchError());
    }
}

function* handleListSearchRequest(action: ActionType<typeof listSearchRequest>) {
    try {
        const totalResults = yield select( (state:ApplicationState) => state.search.queryMatches);

        if (totalResults > LIST_RESULT_LIMIT) {
            yield put(listTooManyResults());
            return;
        }

        const parameters = action.payload;

        const result = yield call([biostrandApi, 'biostrandGatewayRunListTask'], parameters.baseQueryId, parameters);
        const { taskId } = result.data;

        const tr = yield watchTaskResult(parameters.baseQueryId, taskId);
        if (tr === TaskResult.CANCELED) {
            yield put(listSearchCanceled());
            return;
        }

        const listResponse = yield call(
            [biostrandApi, 'biostrandGatewayGetList'],
            parameters.baseQueryId,
            taskId || '',
            parameters.page,
            parameters.pageSize,
            parameters.sortingColumnKey,
            parameters.sortingDirection === SortDirection.Descending,
            parameters.fields,
        );

        const resultViewData: ListResultViewData = {
            sequences: listResponse.data.sequences
                ? listResponse.data.sequences.map(populateSequenceFields as any)
                : [],
            totalCount: listResponse.data.totalCount || 0,
        };

        let availableColumns: ColumnConfig[] = [];

        if (listResponse.data.fields && listResponse.data.fields.length > 0) {
            availableColumns = sortColumns(listFieldsToColumns(listResponse.data.fields));
        }

        yield put(listSearchRequestSuccess(parameters, resultViewData, availableColumns));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(listSearchError());
    }
}

function* showDNASettingsQueryPopup(enrichedQuery: ApiGatewayEnrichQueryResponse) {
    const sequenceInfo = enrichedQuery.data && enrichedQuery.data[0] && enrichedQuery.data[0].sequenceInfo;

    if (sequenceInfo) {
        yield put(
            showPopup({
                type: PopupType.DNA_SETTINGS_QUERY_SELECTION,
                isDismissible: false,
                content: React.createElement(SelectDNAFrameAndTablePopup, { sequenceInfo }),
            }),
        );
    }
}

function* showQuerySequenceSelectionPopup(enrichedQuery: ApiGatewayEnrichQueryResponse) {
    yield put(
        showPopup({
            type: PopupType.MULTI_SEQUENCE_QUERY_SELECTION,
            content: React.createElement(SelectSequencePopup, { enrichData: enrichedQuery }),
            isDismissible: false,
        }),
    );
}

function* clarifyQuery(originalQuery: string, enrichedQuery: ApiGatewayEnrichQueryResponse) {
    if (enrichedQuery.type === SharedQueryType.MULTI_FASTA) {
        yield showQuerySequenceSelectionPopup(enrichedQuery);
        const { selectedSequenceAction, cancel } = yield race({
            selectedSequenceAction: take(SEQUENCE_SELECTED),
            cancel: take(PopupActionTypes.CLOSE_POPUP),
        });
        if (cancel) throw new UserCancelActionError();
        if (selectedSequenceAction) return selectedSequenceAction.payload;
    }

    if (isDNAQuery(enrichedQuery)) {
        yield showDNASettingsQueryPopup(enrichedQuery);
        const { dnaResultAction, cancel } = yield race({
            dnaResultAction: take(DNA_SETTINGS_APPLIED),
            cancel: take(PopupActionTypes.CLOSE_POPUP),
        });
        if (cancel) throw new UserCancelActionError();

        return dnaResultAction.payload;
    }

    return originalQuery;
}

function* handleRunSearchRequest(action: ActionType<typeof runSearchRequest>) {
    const [query] = action.payload;
    const authorizationContext = yield select((state: ApplicationState) => state.global.authorizationContext);
    const activeWorkspaceId = yield select((state: ApplicationState) => state.global.activeWorkspaceId);

    if (SubscriptionHelper.hasValidSubscription(authorizationContext)) {
        yield put(setSearchRunning(true));
        let enrichedQuery: ApiGatewayEnrichQueryResponse;
        try {
            const result = yield call([biostrandApi, 'biostrandGatewayEnrichQuery'], { query });
            enrichedQuery = result.data;
        } catch (e) {
            yield call(handleUnexpectedErrorWithToast, e);
            yield put(setSearchRunning(false));
            return;
        }

        if (!enrichedQuery) {
            yield put(setSearchRunning(false));
            return;
        }

        try {
            const queryString: string = yield clarifyQuery(query, enrichedQuery);
            yield put(
                push({
                    search: updateQueryParameters({ query: queryString }, ''),
                    pathname: `/${activeWorkspaceId}${RouteUrl.QuickFilter}`,
                }),
            );
        } catch (e) {
            if (e instanceof UserCancelActionError) {
                return;
            }

            yield call(handleUnexpectedErrorWithToast, e);
        } finally {
            yield put(setSearchRunning(false));
        }
    }
}

function* handleClearSearchHistory(action: ActionType<typeof clearSearchHistory>) {
    yield put(setSearchHistory([]));
    const activeWorkspaceId = yield select((state: ApplicationState) => state.global.activeWorkspaceId);
    yield updateSearchHistory(activeWorkspaceId, []);
}

function* watchRequests() {
    yield takeEvery(SearchHistoryActionTypes.CLEAR_SEARCH_HISTORY, handleClearSearchHistory);

    yield takeEvery(SearchActionTypes.RUN_SEARCH_REQUEST, handleRunSearchRequest);
    yield takeEvery(SearchActionTypes.ROOT_SEARCH_REQUEST, handleRootSearchRequest);
    yield takeEvery(SearchActionTypes.BASE_SEARCH_REQUEST, handleBaseSearchRequest);
    yield takeEvery(SearchActionTypes.QUICK_FILTER_SEARCH_REQUEST, handleQuickFilterSearchRequest);
    yield takeEvery(SearchActionTypes.ALIGNMENT_SEARCH_REQUEST, handleAlignmentSearchRequest);
    yield takeEvery(SearchActionTypes.LIST_SEARCH_REQUEST, handleListSearchRequest);
    yield takeEvery(SearchActionTypes.EXPLORER_SEARCH_REQUEST, handleExplorerSearchRequest);
}

function* searchSaga() {
    yield all([fork(watchRequests), fork(filterSaga)]);
}

export default searchSaga;
