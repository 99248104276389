import {
    DimensionWithAllElements,
    SearchAggregatesResultSummary,
} from '../types';
import { SearchAndRelateBucket, SearchAndRelateDimensionCounts } from '../../../api/generated';

export function convertDimensionAggregates(aggregates: SearchAndRelateBucket[]): SearchAggregatesResultSummary[] {
    return aggregates? aggregates.map(aggregate => {
        const summary = {
            count: aggregate.count
        }
        aggregate.bucketDimensions?.forEach(match => {
            summary[match.name || ''] = match.value
        });
        return summary;
    }): [];
}

export function createFakeDimensionAggregates(dimensions: SearchAndRelateDimensionCounts[]): SearchAggregatesResultSummary[] {
    let summaries: SearchAggregatesResultSummary[] = [];
    let countHack = 0;
    summaries = dimensions.flatMap((dim) => {
        const otherDimensions = dimensions.filter(d=> d.name !== dim.name).map(d => d.name);

        return dim.values ? dim.values.map( element => {
            const summary = {
                count: element.count
            }
            summary[dim.name || ''] = [element.name];
            otherDimensions.forEach((otherDimension = '') => {
                summary[otherDimension] = [`EXCLUDE ${countHack++}`];
            });
            return summary;
        }) : []
    })

    return summaries;
}

export function createFakeDimensionAggregatesFromDimensions(dimensions: DimensionWithAllElements[]): SearchAggregatesResultSummary[] {
    let summaries: SearchAggregatesResultSummary[] = [];
    let countHack = 0;
    summaries = dimensions.flatMap((dim) => {
        const otherDimensions = dimensions.filter(d=> d.name !== dim.name).map(d => d.name);
        return dim.elements.map( element => {
            const summary = {
                count: element.count
            }
            summary[dim.name] = [element.name];
            otherDimensions.forEach((otherDimension) => {
                summary[otherDimension] = [`EXCLUDE ${countHack++}`];
            });
            return summary;
        })
    })

    return summaries;
}
