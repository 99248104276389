import { Location } from 'history';
import { QueryParameterMap } from '../../../../utils/query-parameter-helpers';
import { ResultViewUrlParameters } from '../../result-view-url-parameters';
import { UrlParametersHandler } from '../../url-parameters-handler';

export class QuickFilterUrlParametersHandler extends UrlParametersHandler<QuickFilterResultViewUrlParameters> {
    public read(currentLocation: Location): QuickFilterResultViewUrlParameters {
        return super.doRead(currentLocation);
    }

    public write(newUrlState: QuickFilterResultViewUrlParameters): QueryParameterMap {
        return super.doWrite(newUrlState);
    }
}

export interface QuickFilterResultViewUrlParameters extends ResultViewUrlParameters {
}
