import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import AuthenticationApi, { AuthenticationResponse } from '../../api/authentication-api';
import { RouteUrl } from '../../routing/route-url';
import { updateQueryParameters } from '../../utils/query-parameter-helpers';
import { getAuthorizationContext } from '../global';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { logoutSuccess } from '../shared/actions';
import { addToast, defaultRemoveAfterMilliseconds, ToastType } from '../toast';
import {
    completePasswordChallengeFailure,
    completePasswordChallengeRequest,
    completePasswordChallengeSuccess,
    confirmNewPasswordFailure,
    confirmNewPasswordRequest,
    confirmNewPasswordSuccess,
    forgotPasswordFailure,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    loginFailure,
    loginRequest,
    logoutRequest,
} from './actions';
import {
    AuthenticationActionTypes,
    AuthenticationFeedbackType,
    ConfirmPasswordParameters,
    Credentials,
    PasswordChallengeParameters,
} from './types';

function* handleLogout(action: ActionType<typeof logoutRequest>) {
    try {
        yield call(AuthenticationApi.logout);
        yield all([put(push({ pathname: RouteUrl.Login })), put(logoutSuccess())]);
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleLogin(action: ActionType<typeof loginRequest>) {
    try {
        const credentials: Credentials = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            AuthenticationApi.authenticate,
            credentials,
        );

        if (result.errorCode) {
            yield put(loginFailure(result.errorCode));
        } else if (result.result === AuthenticationFeedbackType.Success) {
            // LoginSuccess will be called by authorization sages after context was retrieved
            yield put(getAuthorizationContext());
            // todo @Sam why do we need this?
        } else if (result.result === AuthenticationFeedbackType.NewPasswordRequired) {
            const location: LocationDescriptorObject = {
                pathname: RouteUrl.ConfirmationUnderway,
                search: updateQueryParameters({ email: credentials.email, accessCode: credentials.password }, ''),
            };
            yield put(push(location));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(loginFailure());
    }
}

export function* handleConfirmPassword(action: ActionType<typeof confirmNewPasswordRequest>) {
    try {
        const parameters: ConfirmPasswordParameters = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            AuthenticationApi.confirmPassword,
            parameters,
        );

        if (result.errorCode) {
            yield put(confirmNewPasswordFailure(result.errorCode));
        } else if (result.result === AuthenticationFeedbackType.Success) {
            yield put(confirmNewPasswordSuccess());
            // Note: setting this authorization context will forward the user to the search page
            yield put(push({ pathname: RouteUrl.Login }));
            yield put(
                // todo @Sam fix translation
                addToast({
                    id: '',
                    messages: ['Password changed successfully'],
                    type: ToastType.Success,
                    removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                }),
            );
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(confirmNewPasswordFailure(err));
    }
}

export function* handleCompletePasswordChallenge(action: ActionType<typeof completePasswordChallengeRequest>) {
    try {
        const parameters: PasswordChallengeParameters = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            AuthenticationApi.completeNewPasswordChallenge,
            parameters,
        );

        if (result.errorCode) {
            yield put(completePasswordChallengeFailure(result.errorCode));
        } else if (result.result === AuthenticationFeedbackType.Success) {
            yield put(completePasswordChallengeSuccess());
            // Note: setting this authorization context will forward the user to the search page
            yield put(getAuthorizationContext());
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(completePasswordChallengeFailure(err));
    }
}

export function* handleForgotPassword(action: ActionType<typeof forgotPasswordRequest>) {
    try {
        const email: string = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            AuthenticationApi.forgotPassword,
            email,
        );

        if (result.errorCode) {
            yield put(forgotPasswordFailure(result.errorCode));
        } else if (result.result === AuthenticationFeedbackType.Success) {
            const location: LocationDescriptorObject = {
                pathname: RouteUrl.ConfirmationUnderway,
                search: updateQueryParameters({ email }, ''),
            };

            yield put(forgotPasswordSuccess());
            yield put(push(location));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(forgotPasswordFailure(err));
    }
}

function* watchRequests() {
    yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
    yield takeEvery(AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
    yield takeEvery(AuthenticationActionTypes.CONFIRM_NEW_PASSWORD_REQUEST, handleConfirmPassword);
    yield takeEvery(AuthenticationActionTypes.COMPLETE_PASSWORD_CHALLENGE_REQUEST, handleCompletePasswordChallenge);
}

function* authenticationSaga() {
    yield all([fork(watchRequests)]);
}

export default authenticationSaga;
