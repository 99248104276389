import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { UserInfo, UsersAction, UsersActionTypes, UsersState } from './types';

export function initialState(): UsersState {
    return {};
}

const reducer: Reducer<UsersState, UsersAction> = (state = initialState(), action): UsersState => {
    switch (action.type) {
        case UsersActionTypes.GET_USERS_FOR_ORGANIZATION_REQUEST: {
            return {
                ...state,
                getUsersForOrganizationRequestStatus: new RequestStatus(RequestStatusType.InProgress),
                usersForOrganization: undefined,
            };
        }
        case UsersActionTypes.GET_USERS_FOR_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                getUsersForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Success),
                usersForOrganization: action.payload,
            };
        }
        case UsersActionTypes.GET_USERS_FOR_ORGANIZATION_FAILURE: {
            return {
                ...state,
                getUsersForOrganizationRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case UsersActionTypes.INVITE_USER_REQUEST: {
            return {
                ...state,
                inviteUserRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case UsersActionTypes.INVITE_USER_SUCCESS: {
            const newUser = action.payload;
            const updatedArray: UserInfo[] = state.usersForOrganization ? [...state.usersForOrganization, newUser] : [];
            return {
                ...state,
                inviteUserRequestStatus: new RequestStatus(RequestStatusType.Success),
                usersForOrganization: updatedArray,
            };
        }
        case UsersActionTypes.INVITE_USER_FAILURE: {
            return {
                ...state,
                inviteUserRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case UsersActionTypes.UPDATE_USER_REQUEST: {
            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case UsersActionTypes.UPDATE_USER_SUCCESS: {
            const updatedUser = action.payload;
            const updatedArray: UserInfo[] = state.usersForOrganization ? [...state.usersForOrganization] : [];
            const index = updatedArray.findIndex((u) => u.email === updatedUser.email);
            if (index !== -1) updatedArray[index] = updatedUser;

            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.Success),
                usersForOrganization: updatedArray,
            };
        }
        case UsersActionTypes.UPDATE_USER_FAILURE: {
            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case UsersActionTypes.DELETE_USER_REQUEST: {
            return {
                ...state,
                deleteUserRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case UsersActionTypes.DELETE_USER_SUCCESS: {
            const email = action.payload;
            const updatedArray: UserInfo[] = state.usersForOrganization ? [...state.usersForOrganization] : [];
            const index = updatedArray.findIndex((w) => w.email === email);
            if (index !== -1) updatedArray.splice(index, 1);

            return {
                ...state,
                deleteUserRequestStatus: new RequestStatus(RequestStatusType.Success),
                usersForOrganization: updatedArray,
            };
        }
        case UsersActionTypes.DELETE_USER_FAILURE: {
            return {
                ...state,
                deleteUserRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case SharedActionTypes.LOGOUT_SUCCESS:
            return initialState();
        default: {
            return state;
        }
    }
};

export { reducer as usersReducer };
export { initialState as initialUsersState };
