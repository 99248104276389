import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import ManageBillingPage from '../pages/admin/organization/manage-billing/manage-billing.page';
import ManageOrganizationDatasetsPage from '../pages/admin/organization/manage-datasets/manage-organization-datasets.page';
import ManageUsersPage from '../pages/admin/organization/manage-users/manage-users.page';
import ManageWorkspacesPage from '../pages/admin/organization/manage-workspaces/manage-workspaces.page';
import ManageWorkspaceDatasetsPage from '../pages/admin/workspace/manage-datasets/manage-workspace-datasets.page';
import ManageMembersPage from '../pages/admin/workspace/manage-members/manage-members.page';
import AlignmentPage from '../pages/results/views/alignment-view/alignment.page';
import ExplorerPage from '../pages/results/views/explorer-view/explorer.page';
import ListPage from '../pages/results/views/list-view/list.page';
import QuickFilterPage from '../pages/results/views/quick-filter-view/quick-filter.page';
import SearchPage from '../pages/search/search.page';
import { ApplicationState } from '../store';
import { AuthorizationContext, setActiveWorkspace } from '../store/global';
import { addToast } from '../store/toast';
import AuthenticatedRoute from './authenticated-route';
import { RouteUrl } from './route-url';
import GetAPITokenPage from '../pages/admin/organization/get-api-token/get-api-token-page';

class ActiveWorkspaceBoundary extends Component<AllProps> {
    public constructor(props) {
        super(props);
        const { match, authorizationContext } = this.props;

        if (match && authorizationContext) {
            this.setActiveMembership();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { match } = this.props;

        if (match) {
            this.setActiveMembership();
        }
    }

    public render(): JSX.Element {
        const { match } = this.props;
        return (
            <Switch>
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.Search}`} component={SearchPage} />
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.Alignment}`} component={AlignmentPage} />
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.QuickFilter}`} component={QuickFilterPage} />
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.List}`} component={ListPage} />
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.Explorer}`} component={ExplorerPage} />
                <AuthenticatedRoute exact path={`${match.path}${RouteUrl.ManageUsers}`} component={ManageUsersPage} />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.ManageWorkspaces}`}
                    component={ManageWorkspacesPage}
                />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.ManageOrganizationDatasets}`}
                    component={ManageOrganizationDatasetsPage}
                />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.ManageBilling}`}
                    component={ManageBillingPage}
                />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.ManageMembers}`}
                    component={ManageMembersPage}
                />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.GetAPIToken}`}
                    component={GetAPITokenPage}
                />
                <AuthenticatedRoute
                    exact
                    path={`${match.path}${RouteUrl.ManageWorkspaceDatasets}`}
                    component={ManageWorkspaceDatasetsPage}
                />
                <Route component={(): JSX.Element => <div>Not Found</div>} />
            </Switch>
        );
    }

    private setActiveMembership(): void {
        const { match, dispatchSetActiveWorkspace, activeWorkspaceId } = this.props;
        const { workspaceId } = match.params;

        if (activeWorkspaceId !== workspaceId) {
            dispatchSetActiveWorkspace(workspaceId);
        }
    }
}

const mapStateToProps = ({ global }: ApplicationState): PropsFromState => ({
    authorizationContext: global.authorizationContext,
    activeWorkspaceId: global.activeWorkspaceId,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchSetActiveWorkspace: (workspaceId: string) => dispatch(setActiveWorkspace(workspaceId)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ActiveWorkspaceBoundary));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    activeWorkspaceId?: string;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchAddToast: typeof addToast;
    dispatchSetActiveWorkspace: typeof setActiveWorkspace;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<WorkspaceMatchParameters> & WithTranslation;

export interface WorkspaceMatchParameters {
    workspaceId: string;
}
