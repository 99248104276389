import { BiostrandGatewayApi, Configuration } from './generated';
import AuthenticationApi from './authentication-api';

const configureApi = ():BiostrandGatewayApi => {
    const config = new Configuration({
        apiKey: AuthenticationApi.getBearerToken,
        basePath: '' || window.location.origin,
    });

    return new BiostrandGatewayApi(config);
}

const biostrandApi = configureApi();

export default biostrandApi;
