import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../../../store';
import { AuthorizationContext } from '../../../../../../store/global';
import { closePopup } from '../../../../../../store/popup';
import { RequestStatus } from '../../../../../../store/shared/types';
import { cancelSubscriptionRequest } from '../../../../../../store/subscription';
import styles from './cancel-subscription-popup.module.scss';

class CancelSubscriptionPopup extends Component<AllProps> {
    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { requestStatus, dispatchClosePopup } = this.props;

        if (requestStatus && prevProps.requestStatus !== requestStatus && requestStatus.isSuccess) {
            dispatchClosePopup();
        }
    }

    public render(): JSX.Element {
        const { requestStatus, t } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.header}>{t('Cancel subscription?')}</div>
                    <div className={styles.messages}>
                        <div className={styles.message}>{t('Are you sure you want to cancel your subscription?')}</div>
                        <div className={styles.message}>
                            {t('You will stop being charged after your current period ends.')}
                        </div>
                        <div className={styles.message}>
                            {t("You'll be able to continue using the tool until then.")}
                        </div>
                    </div>
                    <div>
                        <button
                            className={`${styles.button} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onCancel()}>
                            {requestStatus?.isInProgress ? (
                                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                            ) : (
                                t('Cancel')
                            )}
                        </button>
                        <button
                            className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onKeep()}>
                            {t('Keep')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onCancel(): void {
        const { authorizationContext, dispatchCancelSubscriptionRequest } = this.props;

        if (authorizationContext) {
            dispatchCancelSubscriptionRequest(authorizationContext.organization.id);
        }
    }

    private onKeep(): void {
        const { dispatchClosePopup } = this.props;

        dispatchClosePopup();
    }
}

const mapStateToProps = ({ global, subscription }: ApplicationState): PropsFromState => ({
    authorizationContext: global.authorizationContext,
    requestStatus: subscription.cancelSubscriptionRequestStatus,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchCancelSubscriptionRequest: (organizationId: string) => dispatch(cancelSubscriptionRequest(organizationId)),
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CancelSubscriptionPopup));

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchCancelSubscriptionRequest: typeof cancelSubscriptionRequest;
    dispatchClosePopup: typeof closePopup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
