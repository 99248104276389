import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { i18n } from 'i18next';
import * as React from 'react';
import { Suspense } from 'react';
import App from './app';
import ErrorBoundary from './components/error-boundary/error-boundary';
import { RuntimeConfig } from './config/runtime-config';

const stripe = loadStripe(RuntimeConfig.getStripePublishableKey());

class Main extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { history } = this.props;

        return (
            <Suspense fallback=''>
                <ConnectedRouter history={history}>
                    <ErrorBoundary>
                        <Elements stripe={stripe}>
                            <App history={history} />
                        </Elements>
                    </ErrorBoundary>
                </ConnectedRouter>
            </Suspense>
        );
    }
}

export default Main;

interface OwnProps {
    history: History;
    i18n: i18n;
}

type AllProps = OwnProps;
