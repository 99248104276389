import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { SubscriptionApi } from '../../api/subscription-api';
import { WorkspaceResponse } from '../../http/http';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    cancelSubscriptionFailure,
    cancelSubscriptionRequest,
    cancelSubscriptionSuccess,
    createSetupIntentFailure,
    createSetupIntentRequest,
    createSetupIntentSuccess,
    deletePaymentMethodFailure,
    deletePaymentMethodRequest,
    deletePaymentMethodSuccess,
    getCustomerFailure,
    getCustomerRequest,
    getCustomerSuccess,
    getPaymentMethodsFailure,
    getPaymentMethodsRequest,
    getPaymentMethodsSuccess,
    getSubscriptionFailure,
    getSubscriptionRequest,
    getSubscriptionSuccess,
    renewSubscriptionFailure,
    renewSubscriptionRequest,
    renewSubscriptionSuccess,
    setDefaultPaymentMethodFailure,
    setDefaultPaymentMethodRequest,
    setDefaultPaymentMethodSuccess,
    updateCustomerFailure,
    updateCustomerRequest,
    updateCustomerSuccess,
} from './actions';
import {
    CreateSetupIntentRequestParameters,
    Customer,
    DeletePaymentMethodRequestParameters,
    PaymentMethod,
    SetDefaultPaymentMethodRequestParameters,
    SetupIntent,
    SubscriptionActionTypes,
    SubscriptionOverview,
    UpdateCustomerRequestParameters,
} from './types';

function* handleCreateSetupIntentRequest(action: ActionType<typeof createSetupIntentRequest>) {
    try {
        const parameters: CreateSetupIntentRequestParameters = action.payload;
        const response: WorkspaceResponse<SetupIntent> = yield call(SubscriptionApi.createSetupIntent, parameters);
        yield put(createSetupIntentSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(createSetupIntentFailure(err));
    }
}

function* handleGetPaymentMethodsRequest(action: ActionType<typeof getPaymentMethodsRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<PaymentMethod[]> = yield call(
            SubscriptionApi.getPaymentMethods,
            organizationId,
        );
        yield put(getPaymentMethodsSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getPaymentMethodsFailure(err));
    }
}

function* handleSetDefaultPaymentMethodRequest(action: ActionType<typeof setDefaultPaymentMethodRequest>) {
    try {
        const parameters: SetDefaultPaymentMethodRequestParameters = action.payload;
        const response: WorkspaceResponse<PaymentMethod[]> = yield call(
            SubscriptionApi.setDefaultPaymentMethod,
            parameters,
        );
        yield put(setDefaultPaymentMethodSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(setDefaultPaymentMethodFailure(err));
    }
}

function* handleDeletePaymentMethodRequest(action: ActionType<typeof deletePaymentMethodRequest>) {
    try {
        const parameters: DeletePaymentMethodRequestParameters = action.payload;
        yield call(SubscriptionApi.deletePaymentMethod, parameters);
        yield put(deletePaymentMethodSuccess(parameters.paymentMethodId));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(deletePaymentMethodFailure(err));
    }
}

function* handleGetCustomerRequest(action: ActionType<typeof getCustomerRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<Customer> = yield call(SubscriptionApi.getCustomer, organizationId);
        yield put(getCustomerSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getCustomerFailure(err));
    }
}

function* handleUpdateCustomerRequest(action: ActionType<typeof updateCustomerRequest>) {
    try {
        const parameters: UpdateCustomerRequestParameters = action.payload;
        const response: WorkspaceResponse<Customer> = yield call(SubscriptionApi.updateCustomer, parameters);
        yield put(updateCustomerSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(updateCustomerFailure(err));
    }
}

function* handleGetSubscriptionRequest(action: ActionType<typeof getSubscriptionRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<SubscriptionOverview> = yield call(
            SubscriptionApi.getSubscription,
            organizationId,
        );
        yield put(getSubscriptionSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getSubscriptionFailure(err));
    }
}

function* handleCancelSubscriptionRequest(action: ActionType<typeof cancelSubscriptionRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<SubscriptionOverview> = yield call(
            SubscriptionApi.cancelSubscription,
            organizationId,
        );
        yield put(cancelSubscriptionSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(cancelSubscriptionFailure(err));
    }
}

function* handleRenewSubscriptionRequest(action: ActionType<typeof renewSubscriptionRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<SubscriptionOverview> = yield call(
            SubscriptionApi.renewSubscription,
            organizationId,
        );
        yield put(renewSubscriptionSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(renewSubscriptionFailure(err));
    }
}

function* watchRequests() {
    yield takeEvery(SubscriptionActionTypes.CREATE_SETUP_INTENT_REQUEST, handleCreateSetupIntentRequest);
    yield takeEvery(SubscriptionActionTypes.GET_PAYMENT_METHODS_REQUEST, handleGetPaymentMethodsRequest);
    yield takeEvery(SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_REQUEST, handleSetDefaultPaymentMethodRequest);
    yield takeEvery(SubscriptionActionTypes.DELETE_PAYMENT_METHOD_REQUEST, handleDeletePaymentMethodRequest);
    yield takeEvery(SubscriptionActionTypes.GET_CUSTOMER_REQUEST, handleGetCustomerRequest);
    yield takeEvery(SubscriptionActionTypes.UPDATE_CUSTOMER_REQUEST, handleUpdateCustomerRequest);
    yield takeEvery(SubscriptionActionTypes.GET_SUBSCRIPTION_REQUEST, handleGetSubscriptionRequest);
    yield takeEvery(SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST, handleCancelSubscriptionRequest);
    yield takeEvery(SubscriptionActionTypes.RENEW_SUBSCRIPTION_REQUEST, handleRenewSubscriptionRequest);
}

function* subscriptionSaga() {
    yield all([fork(watchRequests)]);
}

export default subscriptionSaga;
