import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';

export interface Toast {
    id: string;
    messages: string[];
    type: ToastType;
    removeAfterMilliseconds?: number;
    navigateTo?: string;
}

export enum ToastType {
    Success,
    Error,
    Warning,
    Info,
}

export enum ToastActionTypes {
    ADD_TOAST = '@@toast/AddToast',
    REMOVE_TOAST = '@@toast/RemoveToast',
}

export type ToastAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export const defaultRemoveAfterMilliseconds = 3400;

export interface ToastState {
    readonly toasts: Toast[];
}
