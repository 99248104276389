import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import {
    CreateSetupIntentRequestParameters,
    Customer,
    DeletePaymentMethodRequestParameters,
    PaymentMethod,
    SetDefaultPaymentMethodRequestParameters,
    SetupIntent,
    SubscriptionActionTypes,
    SubscriptionOverview,
    UpdateCustomerRequestParameters,
} from './types';

export const createSetupIntentRequest = (parameters: CreateSetupIntentRequestParameters) =>
    action(SubscriptionActionTypes.CREATE_SETUP_INTENT_REQUEST, parameters);
export const createSetupIntentSuccess = (setupIntent: SetupIntent) =>
    action(SubscriptionActionTypes.CREATE_SETUP_INTENT_SUCCESS, setupIntent);
export const createSetupIntentFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.CREATE_SETUP_INTENT_FAILURE, failureCode);

export const getPaymentMethodsRequest = (organizationId: string) =>
    action(SubscriptionActionTypes.GET_PAYMENT_METHODS_REQUEST, organizationId);
export const getPaymentMethodsSuccess = (paymentMethods: PaymentMethod[]) =>
    action(SubscriptionActionTypes.GET_PAYMENT_METHODS_SUCCESS, paymentMethods);
export const getPaymentMethodsFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.GET_PAYMENT_METHODS_FAILURE, failureCode);

export const setDefaultPaymentMethodRequest = (parameters: SetDefaultPaymentMethodRequestParameters) =>
    action(SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_REQUEST, parameters);
export const setDefaultPaymentMethodSuccess = (paymentMethods: PaymentMethod[]) =>
    action(SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_SUCCESS, paymentMethods);
export const setDefaultPaymentMethodFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_FAILURE, failureCode);

export const deletePaymentMethodRequest = (parameters: DeletePaymentMethodRequestParameters) =>
    action(SubscriptionActionTypes.DELETE_PAYMENT_METHOD_REQUEST, parameters);
export const deletePaymentMethodSuccess = (paymentMethodId: string) =>
    action(SubscriptionActionTypes.DELETE_PAYMENT_METHOD_SUCCESS, paymentMethodId);
export const deletePaymentMethodFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.DELETE_PAYMENT_METHOD_FAILURE, failureCode);

export const getCustomerRequest = (organizationId: string) =>
    action(SubscriptionActionTypes.GET_CUSTOMER_REQUEST, organizationId);
export const getCustomerSuccess = (customer: Customer) =>
    action(SubscriptionActionTypes.GET_CUSTOMER_SUCCESS, customer);
export const getCustomerFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.GET_CUSTOMER_FAILURE, failureCode);

export const updateCustomerRequest = (parameters: UpdateCustomerRequestParameters) =>
    action(SubscriptionActionTypes.UPDATE_CUSTOMER_REQUEST, parameters);
export const updateCustomerSuccess = (customer: Customer) =>
    action(SubscriptionActionTypes.UPDATE_CUSTOMER_SUCCESS, customer);
export const updateCustomerFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.UPDATE_CUSTOMER_FAILURE, failureCode);

export const getSubscriptionRequest = (organizationId: string) =>
    action(SubscriptionActionTypes.GET_SUBSCRIPTION_REQUEST, organizationId);
export const getSubscriptionSuccess = (subscription: SubscriptionOverview) =>
    action(SubscriptionActionTypes.GET_SUBSCRIPTION_SUCCESS, subscription);
export const getSubscriptionFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.GET_SUBSCRIPTION_FAILURE, failureCode);

export const cancelSubscriptionRequest = (organizationId: string) =>
    action(SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST, organizationId);
export const cancelSubscriptionSuccess = (subscription: SubscriptionOverview) =>
    action(SubscriptionActionTypes.CANCEL_SUBSCRIPTION_SUCCESS, subscription);
export const cancelSubscriptionFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.CANCEL_SUBSCRIPTION_FAILURE, failureCode);

export const renewSubscriptionRequest = (organizationId: string) =>
    action(SubscriptionActionTypes.RENEW_SUBSCRIPTION_REQUEST, organizationId);
export const renewSubscriptionSuccess = (subscription: SubscriptionOverview) =>
    action(SubscriptionActionTypes.RENEW_SUBSCRIPTION_SUCCESS, subscription);
export const renewSubscriptionFailure = (failureCode?: FailureCodes) =>
    action(SubscriptionActionTypes.RENEW_SUBSCRIPTION_FAILURE, failureCode);
