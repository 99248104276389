import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReactComponent as AlignmentViewIcon } from '../../../../../assets/icons/alignment-view-icon.svg';
import { ReactComponent as ExplorerViewIcon } from '../../../../../assets/icons/explorer-view-icon.svg';
import { ReactComponent as ListViewIcon } from '../../../../../assets/icons/list-view-icon.svg';
import { ReactComponent as QuickFilterIcon } from '../../../../../assets/icons/quick-filter-icon.svg';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './results-navigation-item.module.scss';

export enum NavItemTypes {
    quickFilter,
    explorerView,
    listView,
    alignmentView,
}

class ResultsNavigationItem extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { caption, active, type, disabled, info } = this.props;

        const itemClasses = conditionalClassList(styles, {
            active,
            disabled: !!(disabled || info),
            navigationItemContainer: true,
        });

        return (
            <>
                <div className={itemClasses} onClick={() => this.onNavigate()}>
                    {this.renderIcon(type)}
                    {caption}
                    {info && (
                        <Tooltip title={info} className={styles.questionIcon} >
                            <div>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </>
        );
    }

    private onNavigate(): void {
        const { onClick, disabled, info } = this.props;
        if (disabled || info) {
            return;
        }
        onClick();
    }

    private renderIcon(type: NavItemTypes) {
        switch (type) {
            case NavItemTypes.quickFilter:
                return <QuickFilterIcon className={styles.icon} />;
            case NavItemTypes.explorerView:
                return <ExplorerViewIcon className={styles.icon} />;
            case NavItemTypes.alignmentView:
                return <AlignmentViewIcon className={styles.icon} />;
            case NavItemTypes.listView:
                return <ListViewIcon className={styles.icon} />;
            default:
                return <div />;
        }
    }
}

export default connect()(withTranslation()(ResultsNavigationItem));

interface OwnProps {
    type: NavItemTypes;
    caption: string;
    active: boolean;
    disabled: boolean;
    onClick: () => {};
    info: string;
}

type AllProps = OwnProps & WithTranslation;
