export enum HelpLinkUrl {
    Intro = 'https://howto.biostrand.be/knowledge/retrieve-and-relate-application',
    Login = 'https://howto.biostrand.be/knowledge',
    QuickFilterView = 'https://howto.biostrand.be/knowledge/what-is-the-quick-filter-view',
    ListView = 'https://howto.biostrand.be/knowledge/what-is-list-view',
    ExplorerView = 'https://howto.biostrand.be/knowledge/retrieve-and-relate-application#explorer-view',
    AlignmentView = 'https://howto.biostrand.be/knowledge/what-is-the-alignment-view',
    Faq = 'https://howto.biostrand.be/knowledge/retrieve-and-relate-application',
    Home = 'https://howto.biostrand.be/knowledge/retrieve-and-relate-application',
}
