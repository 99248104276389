import { Reducer } from 'redux';
import { SharedActionTypes } from '../shared/types';
import { ListStateActionTypes, UIState, UIStateActions } from './ui-state-types';

function initialState(): UIState {
    return {
        listsSettings: {},
    };
}

const uiStateReducer: Reducer<UIState, UIStateActions> = (state = initialState(), action): UIState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case ListStateActionTypes.UPDATE_COLUMN_SETTINGS: {
            const [listId, selectedColumns] = action.payload;
            const { listsSettings } = state;
            const listState = listsSettings[listId] || {};
            listState.selectedColumns = selectedColumns;
            const newListsSettings = { ...listsSettings, [listId]: listState };

            return { ...state, listsSettings: newListsSettings };
        }
        default: {
            return state;
        }
    }
};

export { uiStateReducer };
export { initialState as initialUIState };
