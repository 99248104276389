export class CartesianProductBuilder {
    public static cartesianProduct<T>(...allEntries: T[][]): T[][] {
        return allEntries.reduce<T[][]>(
            (results, entries) =>
                results
                .map(result => entries.map(entry => result.concat([entry])))
                .reduce((subResults, result) => subResults.concat(result), []),
            [[]]
        )
    }
}
