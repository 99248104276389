import { PopupType } from '../store/popup';

export enum QueryParameter {
    Popup = 'popup',
    ReturnUrl = 'returnUrl',
    Email = 'email',
    Context = 'context',
    GlobalFilters = 'filters',
    RootQuery = 'query',
    WorkspaceId = 'workspaceId',
    QuickFilterDimensions = 'quickFilterDimensions',
    AlignmentViewPage = 'alignmentViewPage',
    AlignmentViewPageSize = 'alignmentViewPageSize',
    ListViewPage = 'listViewPage',
    ListViewPageSize = 'listViewPageSize',
    ListViewSortingColumnKey = 'listViewSortingKeyColumn',
    ListViewSortingDirection = 'listViewSortingDirection',
    ExplorerRow = 'explorerRowDimensions',
    ExplorerColumn = 'explorerColumnDimensions',
    ConfirmationCode = 'accessCode',
    TemporaryPassword = 'temporaryPassword',
    IsOnboarding = 'onboarding',
    MolType = 'molType'
}

export interface QueryParameterMap {
    popup?: PopupType;
    returnUrl?: string;
    filters?: string;
    email?: string;
    query?: string;
    quickFilterDimensions?: string;
    accessCode?: string;
    workspaceId?: string;
    onboarding?: string;
}

export function updateQueryParameters(parameters: QueryParameterMap, search: string): string {
    const queryParameters = new URLSearchParams(search);

    Object.keys(parameters).forEach((k) => {
        if (parameters[k] !== undefined) {
            queryParameters.set(k, parameters[k]);
        } else {
            queryParameters.delete(k);
        }
    });

    return queryParameters.toString();
}

export class QueryParameterHelper {
    public static getEncodedObjectFromUrl<T>(queryParameters: URLSearchParams, param: QueryParameter): T | null {
        const encodedParam = queryParameters.get(param);
        if (encodedParam) {
            const decodedJSON = atob(encodedParam);
            return JSON.parse(decodedJSON) as T;
        }
        return null;
    }

    public static getStringFromUrl(queryParameters: URLSearchParams, param: QueryParameter): string | null {
        return queryParameters.get(param);
    }

    public static getNumberFromUrl(queryParameters: URLSearchParams, param: QueryParameter): number | null {
        const paramString = queryParameters.get(param);
        if (paramString) {
            return parseInt(paramString, 10);
        }
        return null;
    }

    public static getEnumFromUrl<T>(queryParameters: URLSearchParams, param: QueryParameter): T | null {
        const paramString = queryParameters.get(param);
        if (paramString) {
            // TODO @Sam this can be done much more elegantly
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return paramString as T;
        }
        return null;
    }
}
