import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { MembershipsApi } from '../../api/memberships-api';
import { WorkspacesApi } from '../../api/workspaces-api';
import { WorkspaceResponse } from '../../http/http';
import { getAuthorizationContext } from '../global';
import { handleUnexpectedError, handleUnexpectedErrorWithToast } from '../http-error-handler';
import { determineApiErrorCode } from '../shared/util';
import {
    createMembershipForWorkspaceFailure,
    createMembershipForWorkspaceRequest,
    createMembershipForWorkspaceSuccess,
    createWorkspaceFailure,
    createWorkspaceRequest,
    createWorkspaceSuccess,
    deleteMembershipForWorkspaceFailure,
    deleteMembershipForWorkspaceRequest,
    deleteMembershipForWorkspaceSuccess,
    deleteWorkspaceFailure,
    deleteWorkspaceRequest,
    deleteWorkspaceSuccess,
    getMembershipsForWorkspaceFailure,
    getMembershipsForWorkspaceRequest,
    getMembershipsForWorkspaceSuccess,
    getWorkspacesForOrganizationFailure,
    getWorkspacesForOrganizationRequest,
    getWorkspacesForOrganizationSuccess,
    updateDefaultMembershipFailure,
    updateDefaultMembershipRequest,
    updateDefaultMembershipSuccess,
    updateMembershipFailure,
    updateMembershipRequest,
    updateMembershipSuccess,
} from './actions';
import {
    CreateMembershipRequestParameters,
    CreateWorkspaceRequestParameters,
    DeleteMembershipRequestParameters,
    DeleteWorkspaceRequestParameters,
    UpdateDefaultMembershipRequestParameters,
    UpdateMembershipRequestParameters,
    WorkspaceInfo,
    WorkspaceMembership,
    WorkspacesActionTypes,
} from './types';

function* handleGetWorkspacesForOrganizationRequest(action: ActionType<typeof getWorkspacesForOrganizationRequest>) {
    try {
        const organizationId: string = action.payload;
        const response: WorkspaceResponse<WorkspaceInfo[]> = yield call(
            WorkspacesApi.getWorkspacesForOrganization,
            organizationId,
        );
        if (response.data) {
            yield put(getWorkspacesForOrganizationSuccess(response.data));
        } else {
            yield put(getWorkspacesForOrganizationSuccess([]));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getWorkspacesForOrganizationFailure(err));
    }
}

function* handleCreateWorkspaceForOrganizationRequest(action: ActionType<typeof createWorkspaceRequest>) {
    try {
        const parameters: CreateWorkspaceRequestParameters = action.payload;
        const response: WorkspaceResponse<WorkspaceInfo> = yield call(
            WorkspacesApi.createWorkspaceForOrganization,
            parameters,
        );
        yield put(createWorkspaceSuccess(response.data));
        yield put(getAuthorizationContext());
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(createWorkspaceFailure(failureCode));
    }
}

function* handleUpdateMembershipRequest(action: ActionType<typeof updateMembershipRequest>) {
    try {
        const parameters: UpdateMembershipRequestParameters = action.payload;
        const response: WorkspaceResponse<WorkspaceMembership> = yield call(
            MembershipsApi.updateMembership,
            parameters,
        );
        yield put(updateMembershipSuccess(response.data));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(updateMembershipFailure(failureCode));
    }
}

function* handleGetMembershipsForWorkspaceRequest(action: ActionType<typeof getMembershipsForWorkspaceRequest>) {
    try {
        const organizationId: string = action.payload[0];
        const workspaceId: string = action.payload[1];
        const response: WorkspaceResponse<WorkspaceMembership[]> = yield call(
            MembershipsApi.getMembershipsForWorkspace,
            organizationId,
            workspaceId,
        );
        if (response.data) {
            yield put(getMembershipsForWorkspaceSuccess(response.data));
        } else {
            yield put(getMembershipsForWorkspaceSuccess([]));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getMembershipsForWorkspaceFailure(err));
    }
}

function* handleUpdateDefaultMembershipsRequest(action: ActionType<typeof updateDefaultMembershipRequest>) {
    try {
        const parameters: UpdateDefaultMembershipRequestParameters = action.payload;

        const response: WorkspaceResponse<any> = yield call(MembershipsApi.updateDefaultMembership, parameters);
        if (response.data) {
            yield put(updateDefaultMembershipSuccess());
            yield put(getAuthorizationContext());
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(updateDefaultMembershipFailure(err));
    }
}

function* handleDeleteWorkspaceRequest(action: ActionType<typeof deleteWorkspaceRequest>) {
    try {
        const parameters: DeleteWorkspaceRequestParameters = action.payload;

        yield call(MembershipsApi.deleteWorkspace, parameters);
        yield put(deleteWorkspaceSuccess(parameters.workspaceId));
        yield put(getAuthorizationContext());
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(deleteWorkspaceFailure(failureCode));
    }
}

function* handleCreateMembershipRequest(action: ActionType<typeof createMembershipForWorkspaceRequest>) {
    try {
        const parameters: CreateMembershipRequestParameters = action.payload;
        const response: WorkspaceResponse<WorkspaceMembership> = yield call(
            MembershipsApi.createMembership,
            parameters,
        );
        yield put(createMembershipForWorkspaceSuccess(response.data));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(createMembershipForWorkspaceFailure(failureCode));
    }
}

function* handleDeleteMembershipRequest(action: ActionType<typeof deleteMembershipForWorkspaceRequest>) {
    try {
        const parameters: DeleteMembershipRequestParameters = action.payload;
        yield call(MembershipsApi.deleteMembership, parameters);
        yield put(deleteMembershipForWorkspaceSuccess(parameters.email));
    } catch (err) {
        const failureCode = determineApiErrorCode(err);
        yield call(handleUnexpectedError, err);
        yield put(deleteMembershipForWorkspaceFailure(failureCode));
    }
}

function* watchRequests() {
    yield takeEvery(
        WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_REQUEST,
        handleGetWorkspacesForOrganizationRequest,
    );
    yield takeEvery(WorkspacesActionTypes.CREATE_WORKSPACE_REQUEST, handleCreateWorkspaceForOrganizationRequest);
    yield takeEvery(WorkspacesActionTypes.UPDATE_MEMBERSHIP_REQUEST, handleUpdateMembershipRequest);
    yield takeEvery(
        WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_REQUEST,
        handleGetMembershipsForWorkspaceRequest,
    );
    yield takeEvery(WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_REQUEST, handleUpdateDefaultMembershipsRequest);
    yield takeEvery(WorkspacesActionTypes.DELETE_WORKSPACE_REQUEST, handleDeleteWorkspaceRequest);
    yield takeEvery(WorkspacesActionTypes.CREATE_MEMBERSHIP_REQUEST, handleCreateMembershipRequest);
    yield takeEvery(WorkspacesActionTypes.DELETE_MEMBERSHIP_REQUEST, handleDeleteMembershipRequest);
}

function* workspacesSaga() {
    yield all([fork(watchRequests)]);
}

export default workspacesSaga;
