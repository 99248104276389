import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import AuthenticationApi from '../api/authentication-api';
import { RouteUrl } from './route-url';

class AuthenticatedRoute extends React.Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isAuthenticated: false,
        };
    }

    public componentDidMount(): void {
        AuthenticationApi.isAuthenticated().then((isAuthenticated) => {
            this.setState({
                isAuthenticated,
                loading: false,
            });
        });
    }

    public render() {
        const { loading, isAuthenticated } = this.state;

        // todo @ Sam please test this before moving it to production.
        // ?
        // const { location } = this.props;
        // const returnUrl = location!.search ? `${location!.pathname}${location!.search}` : location!.pathname;
        // const returnUrlEncoded = encodeURIComponent(returnUrl);
        // const redirectUrl = `${RouteUrl.Login}?${new URLSearchParams({ returnUrl: returnUrlEncoded }).toString()}`;
        const redirectUrl = `${RouteUrl.Login}`;

        if (loading) {
            return <div />;
        }

        return isAuthenticated ? <Route {...this.props} /> : <Redirect to={redirectUrl} />;
    }
}

export default AuthenticatedRoute;

type AllProps = RouteProps;

interface OwnState {
    loading: boolean;
    isAuthenticated: boolean;
}

type AllState = OwnState;
