import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { RouteUrl } from '../../routing/route-url';
import ErrorPanelBase from '../empty-panel/error-panel-base';

interface TooManyResultsProps {
    message?: string;
    location: any;
}

const TooManyResults = (props: TooManyResultsProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activeWorkspaceId = useSelector((state: ApplicationState) => state.global.activeWorkspaceId);

    const { message, location } = props;

    const onGoToQuickFilterClick = () => {
        dispatch(push({ pathname: `/${activeWorkspaceId}${RouteUrl.QuickFilter}`, search: location.search }));
    };

    return <ErrorPanelBase title={t('Too many results to show')}
                           subtitle={message || t('Something went wrong, please try to reload the page or change the search request')}>
        <Button style={{ width: 145, paddingLeft: 0, paddingRight: 0 }} onClick={onGoToQuickFilterClick}
                variant='contained' color='primary'>{t('Quick Filter')}</Button>
    </ErrorPanelBase>;
};

export default TooManyResults;
