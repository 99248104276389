import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { SearchHistoryEntry } from '../../../store/search';
import styles from '../../../pages/search/components/search-history/search-history.module.scss';
import { DateHelper } from '../../../utils/date-helper';


interface OwnProps {
    entry: SearchHistoryEntry,
    enabled: boolean,
    onClick: () => void
}

type Props = OwnProps & WithTranslation;


function SearchHistoryCardComponent(props: Props): JSX.Element {

    const { entry, enabled, onClick, t } = props;

    return <div className={styles.entry}>
        <div className={styles.query}>{entry.query}</div>
        <div className={styles.label}>{t('Matches found')}</div>
        <div>{entry.totalCount}</div>
        <div className={styles.label}>{t('Executed on')}</div>
        <div>
            {DateHelper.UnixTimestampToLocalizedDateTime(entry.date)}
        </div>
        <button
            className={`${styles.button} ${styles.secondary}`}
            style={{margin: 8}}
            type='button'
            disabled={!enabled}
            onClick={onClick}>
            <FontAwesomeIcon className={styles.icon} icon={faSearch} />
            <span>{t('Run again')}</span>
        </button>
    </div>;
}

const SearchHistoryCard = withTranslation()(SearchHistoryCardComponent);

export default SearchHistoryCard;
