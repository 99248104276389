import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './search-history.module.scss';
import { runSearchRequest } from '../../../../store/search';

interface ExampleSearch {
    name: string;
    query: string;
    description: string;
    multiline?: boolean;
}

const EXAMPLE_LIST: ExampleSearch[] = [
    {
        name: 'Text based search',
        description: 'To search on text precede with “similar”',
        query: 'similar insulin',

    },
    {
        name: 'Protein sequence search',
        description: 'Copy paste your protein sequence in the search bar',
        query: 'LAAAMKRHGLDNYRGYSLGNWVC',
    },
    {
        name: 'Nucleotide sequence search',
        query:
            '>DNA|1|1|ATGTTTTTGATACTTTTAATTTCCTTACCAATGGCTTTTGCTGTTATAGGAGATTTAAAGTGTACTTCAGATACGAGTTATATTAATGATGTTGACACCGGTGTTCCTCCTATAAGTACTGATACTGTTGATGTTACTAATGGTTTGGGTACTTATTATGTTTTAGATCGTGTGTATTTAAATACTACTTTGTTTCTTAATGGTTACTATCCTACTTCAGGTTCCACATATCGTAATATGGCACTGAAGGGAAGTGTACTGTTGAGCACACTATGGTTTAAACCACCATTTCTTTCTGATTTTATTAATGGTATTTTTGCTAAGGTCAAAAATACCAAGGTTATTAAAGATGATGTACTGTACAGTGAGTTCCCTGCTATAACTATAGGTAGTACTTTTGTAAATACATCCTATAGTGTGGTAGTACAACCACGTACAATCAATTTAGATAATAAATTACAAGGTCTTTTAGAGGTCTCTGTTTGCCAGTATAATATGTGCGAGTACCCACATACGATTTGTCATCCTAATTTGGGTAATCATCGCACAGAACTATGGCATTTGGATACAGGTGTTGTTTCCTGTTTATATAAGCGTAATTTCACATATGATGTGAATGCTGATTATTTGTATTTTCATTTTTATCAAGAAGGTGGTACTTTTTATGCATATTTTACGGACACTGGTGTTGTTACTAAGTTTTTGTTTAATGTTTATTTAGGCATGGCGCTTTCACACTATTATGTCATGCCTCTGACTTGTAATAGTAAGCGTAAGAATGGTTTTACTTTAGAATATTGGGTCACACCTCTCACTTCTAGACAATATTTACTCGCTTTCAATCAAGATGGTATTATTTTTAATGCTGTTGATTGCATGAGTGATTTTATGAGTGAGATTAAGTGTAAAACACAATCTATAGCACCACCTACTGGTGTTTATGAATTAAATGGTTACACTGTTCAGCCAATTGCAGATGTTTACCGACGTAAACCTAATCTTCCCAATTGTAATATAGAAGCTTGGCTTAATGATAAGTCGGTACCATCTCCATTAAATTGGGAACGTAAGACATTTTCAAATTGTAATTTTAATATGAGCAGCCTGATGTCCTTTATTCAGGCAGACTCATTTACTTGTAATAATATTGATGCTGCTAAGATATATGGTATGTGTTTTTCCAGCATAACTATAGATAAGTTTGCTATACCCAATGGCAGGAAGGTTGACCTACAATTGGGTAATTTGGGCTATTTGCAGTCATTTAACTATAGAATTGATACTACTGCAACAAGTTGTCAGTTGTATTATAATTTACCTGCTGCTAATGTTTCTGTTAGCAGGTTTAATCCTTCTACTTGGAATAAGAGATTTGGTTTTATAGAAAATTCTGTTTTTAAGCCTCAACCTGCAGGTGTTTTTACCAACCATGATGTAGTTTATGCCC\n',
        description: 'Paste nucleotide sequence in the search bar, press search ',
        multiline: true,
    },
    {
        name: 'Alternate Codon lookup',
        query:
            'GTGGTGTTTTTTGACTTAGATGGAACATTGCATCAGCAGGATATGTTCGGCAGTTTTCTGCGCTATTTACTACGTCGCCAACCGCTGAATGCGTTACTTGTCCTGCCGTTGTTACCGATTATAGCCATTGCGTTATTGATAAAAGGTCGTGCGGCACGCTGGCCGATGAGTCTGCTTCTGTGGGGGTGCACTTTTGGTCACAGCGAAGCACGTTTACAGACGTTGCAGGCCGATTTCGTGCGCTGGTTTCGCGACAATGTTACCGCCTTTCCGCTGGTTCAGGAGCGATTAACCACCTACCTGTTAAGTTCCGATGCTGATATCTGGTTGATTACCGGCTCTCCGCAGCCGCTGGTTGAAGCGGTTTATTTCGATACGCCCTGGCTGCCGCGGGTTAATCTTATCGCCAGCCAAATTCAGCGTGGCTATGGTGGTTGGGTATTGACGATGCGTTGTCTGGGACATGAAAAGGTCGCACAACTGGAGCGCAAAATCGGCACTCCGCTGCGGCTGTACAGTGGCTATAGCGACAGTAATCAGGACAATCCGCTGCTTTATTTCTGTCAGCATCGTTGGCGAGTAACCCCGCGCGGTGAACTCCAGCAACTGGAATAG',
        description: 'Paste nucleotide sequence, select desired Translation Table in the pop-up (for this example its 11)',
    },
    {
        name: 'Deviating reading frame lookup',
        query:
            'CGTGGTGTTTTTTGACTTAGATGGAACATTGCATCAGCAGGATATGTTCGGCAGTTTTCTGCGCTATTTACTACGTCGCCAACCGCTGAATGCGTTACTTGTCCTGCCGTTGTTACCGATTATAGCCATTGCGTTATTGATAAAAGGTCGTGCGGCACGCTGGCCGATGAGTCTGCTTCTGTGGGGGTGCACTTTTGGTCACAGCGAAGCACGTTTACAGACGTTGCAGGCCGATTTCGTGCGCTGGTTTCGCGACAATGTTACCGCCTTTCCGCTGGTTCAGGAGCGATTAACCACCTACCTGTTAAGTTCCGATGCTGATATCTGGTTGATTACCGGCTCTCCGCAGCCGCTGGTTGAAGCGGTTTATTTCGATACGCCCTGGCTGCCGCGGGTTAATCTTATCGCCAGCCAAATTCAGCGTGGCTATGGTGGTTGGGTATTGACGATGCGTTGTCTGGGACATGAAAAGGTCGCACAACTGGAGCGCAAAATCGGCACTCCGCTGCGGCTGTACAGTGGCTATAGCGACAGTAATCAGGACAATCCGCTGCTTTATTTCTGTCAGCATCGTTGGCGAGTAACCCCGCGCGGTGAACTCCAGCAACTGGAATAG',
        description: 'Paste nucleotide sequence, select desired Translation Frame in the pop-up (for this example its 2)',
    },
    {
        name: 'Multiple sequence input',
        query: `>1DKJ_1|Chain A|LYSOZYME|Colinus virginianus (9014)
KVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSRWWCNDGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL
    >1DKJ_2|Chain A|LYSOZYME|Colinus virginianus (9014)
    KVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSRWWCNDGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL
    >1DKJ_3|Chain A|LYSOZYME|Colinus virginianus (9014)
    KVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSRWWCNDGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGGMNAWVAWRNRCKGTDVQAWIRGCRL
    >1DKJ_4|Chain A|LYSOZYME|Colinus virginianus (9014)
    KVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL
    >1DKJ_5|Chain A|LYSOZYME|Colinus virginianus (9014)
    KVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSRWWCNDGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL
    >1DKJ_6|Chain A|LYSOZYME|Colinus virginianus (9014)
    KRHGLDNYRGYSLGNWVCAAKFESNFNSQATNRNTDGSTDYGVLQINSRWWCNDGKTPGSRNLCNIPCSALLSSDITATVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL`,
        description: 'From the Select file button, upload your fasta file and press search. \n' +
            'In the pop-up select the desired sequence, or by holding shift a part of a sequence',
        multiline: true,
    },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: 1200,
        },
        exampleCard: {
            display: 'flex',
            flexDirection: 'column',

            width: 382,
            maxHeight: 300,
            border: '1px solid #cccccc',
            margin: theme.spacing(1),
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
        },
        description: {
            color: '#999',
        },
        query: {
            fontFamily: 'monospace',
            fontSize: 12,
            overflow: 'auto',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(1),
            backgroundColor: '#f6f6f6',
            wordBreak: 'break-all',
        },
        queryMultiline: {
            fontFamily: 'monospace',
            fontSize: 12,
            overflow: 'auto',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(1),
            backgroundColor: '#f6f6f6',
        },
        spacer: {
            flex: 1,
        },
        button: {
            alignSelf: 'center',
        },
    }),
);

const SearchExamplesComponent = (props: AllProps): JSX.Element => {
    const { t, canRunAgain } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const search = (entry: ExampleSearch) => {
        dispatch(runSearchRequest(entry.query));
    };

    return (
        <div className={classes.container}>
            {EXAMPLE_LIST.map((entry) => {
                return (
                    <div className={classes.exampleCard} key={entry.name}>
                        <Typography variant='h6'>{entry.name}</Typography>
                        <div className={classes.description}>{entry.description}</div>
                        <div className={entry.multiline ? classes.queryMultiline : classes.query}>{entry.query}</div>
                        <div className={classes.spacer} />
                        {canRunAgain ? (
                            <button
                                className={`${styles.button} ${styles.secondary} ${classes.button}`}
                                type='button'
                                onClick={(): void => search(entry)}>
                                <FontAwesomeIcon className={styles.icon} icon={faSearch} />
                                <span>{t('Try it')}</span>
                            </button>
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

interface OwnProps {
    canRunAgain: boolean;
    onSearch: (query: string) => void;
}

type AllProps = OwnProps & WithTranslation;

const SearchExamples = withTranslation()(SearchExamplesComponent)

export default SearchExamples;
