export function classListToString(styles: { [key: string]: string }, classList: string[]): string {
    return classList.reduce((classes, currentClass, index) => {
        if (index === 0) {
            return classes + styles[currentClass];
        } else {
            return `${classes} ${styles[currentClass]}`;
        }
    }, '');
}

export function conditionalClassList(styles: { [key: string]: string }, conditionalStyles: { [key: string]: boolean }): string {
    const classList = Object.getOwnPropertyNames(conditionalStyles).map((conditionalStyleKey) => {
        return conditionalStyles[conditionalStyleKey] ? conditionalStyleKey : '';
    });

    return classListToString(styles, classList);
}
