import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FilterSummary } from '../../helpers/filter-summary-creator';
import styles from './filter-summary-bar.module.scss';
import { removeFilterItem } from '../../../../../store/search/filter-actions';

function FilterSummaryBar(props: AllProps): JSX.Element {
    const { filterSummaries } = props;
    const dispatch = useDispatch();

    return (filterSummaries && filterSummaries.length) > 0 ? (
        <div className={styles.summaryFilters}>
            <span className={styles.summaryItemIcon}>
                <FontAwesomeIcon icon={faFilter} />
            </span>
            {filterSummaries.map((summary) => {
                return (
                    <div
                        title={summary.tooltip}
                        className={styles.summaryItem}
                        key={summary.displayName}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: 300,
                            minWidth: 50,
                            paddingRight: 4,
                            alignItems: 'center',
                        }}>
                        <div className={styles.summaryItemText}>
                            {summary.displayName} {summary.operationLabel} <strong>{summary.elementsLabel}</strong>
                        </div>
                        <IconButton
                            onClick={(): void => dispatch(removeFilterItem(summary.name))}
                            size='small'
                            title='Remove filter'
                            style={{ width: 24, height: 24 }}>
                            <FontAwesomeIcon icon={faTimes} size='sm' color='#ffffff' />
                        </IconButton>
                    </div>
                );
            })}
        </div>
    ) : (
        <></>
    );
}

export default withTranslation()(FilterSummaryBar);

interface OwnProps {
    filterSummaries: FilterSummary[];
}

type AllProps = OwnProps & WithTranslation;
