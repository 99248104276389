/* tslint:disable */
/* eslint-disable */
/**
 * Biostrand API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';


export interface AlignAlignResponse {
    sequences?: Array<AlignAlignedSequence>;
    totalCount?: number;
    references?: Array<AlignAlignedSequence>;
}

export interface AlignAlignedSequence {
    sequenceId?: string;
    originalSequence?: string;
    molType?: SharedMolType;
    description?: string;
    residueValues?: Array<number>;
    offset?: number;
    sequence?: string;
    alignmentScore?: number;
    translatedSequence?: string;
    translationFrame?: number;
}

export interface AlignRunAlignmentTaskForQueryRequest {
    queryId?: string;
    algorithm?: SharedAlignAlgorithmType;
    molType?: SharedMolType;
}

export interface ApiGatewayAlignmentInfo {
    offset?: number;
    residueValues?: Array<number>;
}

export interface ApiGatewayCreateDatasetRequest {
    datasetName?: string;
    config?: ApiGatewayDatasetConfig;
}

export interface ApiGatewayCreatePipelineApiRequest {
    datasetName?: string;
    config?: PipelinePipelineConfig;
}

export interface ApiGatewayDatasetConfig {
    path?: string;
    filter?: string;
    ftp?: PipelineFTPStorage;
    http?: PipelineHTTPStorage;
}

export interface ApiGatewayDimensionAggregate {
    dimensionMatches?: Array<ApiGatewayDimensionMatch>;
    count?: number;
}

export interface ApiGatewayDimensionMatch {
    dimension?: string;
    elements?: Array<string>;
}

export interface ApiGatewayEnrichQueryResponse {
    type?: SharedQueryType;
    data?: Array<ApiGatewayEnrichedData>;
}

export interface ApiGatewayEnrichedData {
    sequenceInfo?: SearchAndRelateSequenceMetaData;
    alignmentInfo?: ApiGatewayAlignmentInfo;
}

export interface ApiGatewayGetDimensionAggregatesRequest {
    queryId?: string;
    dimensions?: Array<string>;
}

export interface ApiGatewayGetDimensionAggregatesResponse {
    queryId?: string;
    aggregates?: Array<ApiGatewayDimensionAggregate>;
}

export interface ApiGatewayGetSequenceField {
    name?: string;
    displayName?: string;
    value?: Array<string>;
}

export interface ApiGatewayGetSequenceResponse {
    fields?: Array<ApiGatewayGetSequenceField>;
    sequence?: string;
}

export interface ApiGatewayLaunchWorkflowRequest {
    workflowName?: string;
}

export interface ApiGatewayLaunchWorkflowResponse {
    jobId?: string;
}

export interface ApiGatewayListRequest {
    queryId?: string;
    pageSize?: number;
    page?: number;
    sortDimension?: string;
    sortDirection?: string;
    fields?: Array<string>;
}

export interface ApiGatewayQueryObject {
    queryId?: string;
    link?: ApiGatewayResourceLink;
    status?: SearchAndRelateQueryTaskStatus;
    result?: SearchAndRelateFirstOrderCounts;
    metaData?: SearchAndRelateQueryMetaData;
}

export interface ApiGatewayQueryTaskObject {
    taskId?: string;
    queryId?: string;
    resultLink?: ApiGatewayResourceLink;
    statusLink?: ApiGatewayResourceLink;
    status?: SearchAndRelateQueryTaskStatus;
}

export interface ApiGatewayQueryTasks {
    tasks?: Array<ApiGatewayQueryTaskObject>;
}

export interface ApiGatewayResourceLink {
    url?: string;
    verb?: SharedHttpVerb;
}

export interface ApiGatewayRunPipelineApiRequest {
    datasetId?: string;
}

export interface ApiGatewayRunQueryRequest {
    query?: string;
    filters?: Array<SearchAndRelateQueryFilter>;
    datasets?: Array<SearchAndRelateQueryDataset>;
    dimensions?: Array<string>;
}

export interface ApiGatewaySearchFastasReference {
    sequence?: string;
    molType?: string;
    id?: string;
    description?: string;
    translatedSequence?: string;
    residueValues?: Array<number>;
    offset?: number;
}

export interface ApiGatewaySearchFastasResponse {
    sequences?: Array<ApiGatewaySearchFastasResponseFasta>;
    totalCount?: number;
    references?: Array<ApiGatewaySearchFastasReference>;
}

export interface ApiGatewaySearchFastasResponseFasta {
    id?: string;
    description?: string;
    sequence?: string;
    molType?: string;
    residueValues?: Array<number>;
    offset?: number;
}

export interface ApiGatewaySearchRequest {
    query?: string;
    filters?: Array<SearchAndRelateQueryFilter>;
    dimensions?: Array<string>;
    datasets?: Array<SearchAndRelateQueryDataset>;
}

export interface ApiGatewaySearchResponse {
    queryId?: string;
    uniqueSequencesCount?: number;
    metaData?: SearchAndRelateQueryMetaData;
}

export interface ApiGatewayUpdatePipelineApiRequest {
    datasetId?: string;
    datasetName?: string;
    config?: PipelinePipelineConfig;
}

export interface ApiGatewayUploadRequest {
    datasetId?: string;
}

export interface ApiGatewayUserResponse {
    email?: string;
    firstName?: string;
    lastName?: string;
}

export interface PipelineExplorePipelineDataRequest {
    config?: PipelinePipelineConfig;
}

export interface PipelineExplorePipelineDataResponse {
    items?: Array<PipelinePipelineData>;
}

export interface PipelineFTPStorage {
    host?: string;
    user?: string;
    pass?: string;
}

export interface PipelineHTTPStorage {
    urls?: Array<string>;
}

export interface PipelinePipeline {
    datasetId?: string;
    datasetName?: string;
    config?: PipelinePipelineConfig;
    runs?: Array<PipelinePipelineRun>;
}

export interface PipelinePipelineConfig {
    path?: string;
    filter?: string;
    fileType?: PipelinePipelineFileType;
    molType?: SharedMolType;
    translationTable?: number;
    ftp?: PipelineFTPStorage;
    http?: PipelineHTTPStorage;
}

export interface PipelinePipelineData {
    name?: string;
    size?: string;
    isDir?: boolean;
}

export enum PipelinePipelineFileType {
    UNKNOWN_FILETYPE = 'UNKNOWN_FILETYPE',
    FASTA = 'FASTA',
    FASTQ = 'FASTQ',
    GENBANKFLATFILE = 'GENBANKFLATFILE'
}


export interface PipelinePipelineRun {
    datasetId?: string;
    status?: SharedTaskStatusCode;
    startTime?: string;
    endTime?: string;
    statusMessage?: string;
}

export interface PipelineTestPipelineConnectionRequest {
    config?: PipelinePipelineConfig;
}

export interface PipelineTestPipelineConnectionResponse {
    success?: boolean;
    message?: string;
}

export interface ProtobufAny {
    typeUrl?: string;
    value?: string;
}

export interface RpcStatus {
    code?: number;
    message?: string;
    details?: Array<ProtobufAny>;
}

export interface SearchAndRelateBucket {
    bucketDimensions?: Array<SearchAndRelateBucketDimension>;
    count?: number;
}

export interface SearchAndRelateBucketDimension {
    name?: string;
    value?: Array<string>;
}

export interface SearchAndRelateCountCube {
    queryId?: string;
    buckets?: Array<SearchAndRelateBucket>;
}

export interface SearchAndRelateDimensionCounts {
    name?: string;
    displayName?: string;
    values?: Array<SearchAndRelateValueCount>;
}

export interface SearchAndRelateDimensionPlaceholder {
    name?: string;
    displayName?: string;
    placeholder?: SearchAndRelatePlaceholderCount;
}

export interface SearchAndRelateFirstOrderCounts {
    queryId?: string;
    dimensions?: Array<SearchAndRelateDimensionCounts>;
    dimensionPlaceholders?: Array<SearchAndRelateDimensionPlaceholder>;
    totalCount?: number;
}

export interface SearchAndRelateListField {
    name?: string;
    displayName?: string;
    _class?: SharedAttributeClass;
}

export interface SearchAndRelateListResponse {
    sequences?: Array<SearchAndRelateListSequence>;
    totalCount?: number;
    fields?: Array<SearchAndRelateListField>;
}

export interface SearchAndRelateListSequence {
    attributes?: Array<SearchAndRelateListSequenceAttribute>;
}

export interface SearchAndRelateListSequenceAttribute {
    name?: string;
    displayName?: string;
    value?: Array<string>;
    _class?: SharedAttributeClass;
}

export interface SearchAndRelatePlaceholderCount {
    type?: string;
    count?: number;
}

export interface SearchAndRelateQueryDataset {
    id?: string;
    limit?: number;
}

export interface SearchAndRelateQueryFilter {
    dimensionField?: string;
    includes?: Array<string>;
    excludes?: Array<string>;
    elementNamesContains?: string;
    match?: Array<string>;
    search?: Array<string>;
}

export interface SearchAndRelateQueryMetaData {
    type?: SharedQueryType;
    query?: string;
    data?: Array<SearchAndRelateSequenceMetaData>;
}

export interface SearchAndRelateQueryTaskStatus {
    code?: SharedTaskStatusCode;
    message?: string;
}

export interface SearchAndRelateRunCountCubeTaskRequest {
    queryId?: string;
    dimensions?: Array<string>;
}

export interface SearchAndRelateRunListTaskRequest {
    queryId?: string;
    fields?: Array<string>;
}

export interface SearchAndRelateSequenceMetaData {
    translatedSequence?: string;
    translationTable?: number;
    translationFrame?: number;
    translationErrorCode?: number;
    sequence?: string;
    molType?: SharedMolType;
    description?: string;
}

export interface SearchAndRelateTaxonomyLevel {
    type?: string;
    value?: string;
    count?: number;
    subitems?: Array<SearchAndRelateTaxonomyLevel>;
}

export interface SearchAndRelateTaxonomyResponse {
    taxonomy?: SearchAndRelateTaxonomyLevel;
    countUnknown?: number;
}

export interface SearchAndRelateValueCount {
    name?: string;
    count?: number;
}

export enum SharedAlignAlgorithmType {
    UNGAPPED_HYFT_BASED = 'GLOBAL_UNGAPPED_HYFT_BASED',
    GAPPED_AA_BASED = 'GLOBAL_GAPPED_AA_BASED',
    GAPPED_DNA_BASED = 'GLOBAL_GAPPED_DNA_BASED'
}


export enum SharedAttributeClass {
    BASIC = 'BASIC',
    COMPUTED = 'COMPUTED'
}


export enum SharedHttpVerb {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}


export enum SharedMolType {
    UNKNOWN = 'UNKNOWN',
    AA = 'AA',
    DNA = 'DNA',
    MRNA = 'MRNA',
    RNA = 'RNA',
    CRNA = 'CRNA'
}


export enum SharedQueryType {
    QUERY_TYPE_UNKNOWN = 'QUERY_TYPE_UNKNOWN',
    SIMPLE_SEQUENCE = 'SIMPLE_SEQUENCE',
    SINGLE_FASTA = 'SINGLE_FASTA',
    MULTI_FASTA = 'MULTI_FASTA',
    SEQUENCEID = 'SEQUENCEID',
    NLP = 'NLP',
    HYFTS = 'HYFTS'
}


export enum SharedTaskStatusCode {
    DEFAULT_TASK_STATUS = 'DEFAULT_TASK_STATUS',
    RUNNING = 'RUNNING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    SUBMITTED = 'SUBMITTED'
}


export interface WorkspaceAddressModel {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
}

export interface WorkspaceAuthorizationContextEntity {
    user?: WorkspaceUserEntity;
    organization?: WorkspaceOrganizationEntity;
    memberships?: Array<WorkspaceMembershipWithWorkspaceEntity>;
    subscription?: WorkspaceSubscriptionStatusModel;
}

export interface WorkspaceBillingDetailsModel {
    address?: WorkspaceAddressModel;
    email?: string;
    name?: string;
    phone?: string;
}

export interface WorkspaceCancelSubscriptionRequest {
    organizationId?: string;
}

export interface WorkspaceCancelSubscriptionResponse {
    data?: WorkspaceSubscriptionOverviewModel;
}

export interface WorkspaceCardModel {
    expirationMonth?: number;
    expirationYear?: number;
    endingIn?: string;
    isDefault?: boolean;
}

export interface WorkspaceCreateMembershipRequest {
    organizationId?: string;
    workspaceId?: string;
    email?: string;
    workspaceRole?: string;
}

export interface WorkspaceCreateMembershipResponse {
    data?: WorkspaceMembershipEntity;
}

export interface WorkspaceCreateNewApiTokenResponse {
    clientId?: string;
    clientSecret?: string;
}

export interface WorkspaceCreateOrganizationRequest {
    organizationName?: string;
    email?: string;
    organizationRole?: string;
}

export interface WorkspaceCreateOrganizationResponse {
    data?: WorkspaceCreateOrganizationResponseData;
}

export interface WorkspaceCreateOrganizationResponseData {
    organization?: WorkspaceOrganizationEntity;
    user?: WorkspaceUserEntity;
    workspace?: WorkspaceWorkspaceEntity;
    membership?: WorkspaceMembershipEntity;
}

export interface WorkspaceCreateSetupIntentRequest {
    organizationId?: string;
}

export interface WorkspaceCreateSetupIntentResponse {
    data?: WorkspaceSetupIntentModel;
}

export interface WorkspaceCreateWorkspaceRequest {
    organizationId?: string;
    name?: string;
    memberships?: Array<WorkspaceCreateWorkspaceRequestMembership>;
    datasetIds?: Array<string>;
}

export interface WorkspaceCreateWorkspaceRequestMembership {
    email?: string;
    workspaceRole?: string;
}

export interface WorkspaceCreateWorkspaceResponse {
    data?: WorkspaceWorkspaceWithMembershipsDatasetsEntity;
}

export interface WorkspaceCustomerModel {
    id?: string;
    address?: WorkspaceAddressModel;
    name?: string;
    taxId?: WorkspaceTaxIdModel;
}

export interface WorkspaceDatasetWithOwnerEntity {
    id?: string;
    owner?: string;
    name?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
    status?: string;
    type?: string;
    jobId?: string;
}

export interface WorkspaceGetAllDatasetsOfOrganizationResponse {
    data?: Array<WorkspaceDatasetWithOwnerEntity>;
}

export interface WorkspaceGetAllDatasetsOfWorkspaceResponse {
    data?: Array<WorkspaceDatasetWithOwnerEntity>;
}

export interface WorkspaceGetAllMembershipsOfWorkspaceResponse {
    data?: Array<WorkspaceMembershipEntity>;
}

export interface WorkspaceGetAllUsersOfOrganizationResponse {
    data?: Array<WorkspaceUserEntity>;
}

export interface WorkspaceGetAllWorkspacesOfOrganizationResponse {
    data?: Array<WorkspaceWorkspaceWithMembershipsDatasetsEntity>;
}

export interface WorkspaceGetAuthorizationContextResponse {
    data?: WorkspaceAuthorizationContextEntity;
}

export interface WorkspaceGetCustomerResponse {
    data?: WorkspaceCustomerModel;
}

export interface WorkspaceGetPaymentMethodsResponse {
    data?: Array<WorkspacePaymentMethodModel>;
}

export interface WorkspaceGetSubscriptionResponse {
    data?: WorkspaceSubscriptionOverviewModel;
}

export interface WorkspaceInviteUserRequest {
    organizationId?: string;
    email?: string;
    organizationRole?: string;
    workspaceId?: string;
}

export interface WorkspaceInviteUserResponse {
    data?: WorkspaceInviteUserResponseData;
}

export interface WorkspaceInviteUserResponseData {
    user?: WorkspaceUserEntity;
    membership?: WorkspaceMembershipEntity;
}

export interface WorkspaceInvoiceModel {
    amountDue?: number;
    amountPaid?: number;
    currency?: string;
    hostedInvoiceUrl?: string;
    invoicePdfUrl?: string;
    total?: number;
    nextPaymentAttempt?: string;
    paid?: boolean;
    periodStart?: string;
    periodEnd?: string;
}

export interface WorkspaceLinkDatasetsToWorkspaceRequest {
    organizationId?: string;
    workspaceId?: string;
    datasetIds?: Array<string>;
}

export interface WorkspaceLinkDatasetsToWorkspaceResponse {
    data?: Array<WorkspaceDatasetWithOwnerEntity>;
}

export interface WorkspaceMembershipEntity {
    email?: string;
    workspaceId?: string;
    workspaceRole?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
    isDefault?: boolean;
}

export interface WorkspaceMembershipWithWorkspaceEntity {
    email?: string;
    workspace?: WorkspaceWorkspaceEntity;
    workspaceRole?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
    isDefault?: boolean;
}

export interface WorkspaceOrganizationEntity {
    id?: string;
    name?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
}

export interface WorkspacePaymentMethodModel {
    id?: string;
    billingDetails?: WorkspaceBillingDetailsModel;
    card?: WorkspaceCardModel;
    createdOn?: string;
}

export interface WorkspacePriceModel {
    product?: WorkspaceProductModel;
    recurring?: WorkspaceRecurringModel;
}

export interface WorkspaceProductModel {
    name?: string;
}

export interface WorkspaceRecurringModel {
    interval?: string;
    intervalCount?: number;
}

export interface WorkspaceRenewSubscriptionRequest {
    organizationId?: string;
}

export interface WorkspaceRenewSubscriptionResponse {
    data?: WorkspaceSubscriptionOverviewModel;
}

export interface WorkspaceSetDefaultMembershipRequest {
    organizationId?: string;
    workspaceId?: string;
    email?: string;
}

export interface WorkspaceSetDefaultMembershipResponse {
    data?: Array<WorkspaceMembershipEntity>;
}

export interface WorkspaceSetDefaultPaymentMethodRequest {
    organizationId?: string;
    paymentMethodId?: string;
    isDefault?: boolean;
}

export interface WorkspaceSetDefaultPaymentMethodResponse {
    data?: Array<WorkspacePaymentMethodModel>;
}

export interface WorkspaceSetupIntentModel {
    clientSecret?: string;
}

export interface WorkspaceSubscriptionItemModel {
    price?: WorkspacePriceModel;
    quantity?: number;
}

export interface WorkspaceSubscriptionModel {
    id?: string;
    cancelAt?: string;
    cancelAtPeriodEnd?: boolean;
    canceledAt?: string;
    created?: string;
    currentPeriodEnd?: string;
    currentPeriodStart?: string;
    daysUntilDue?: string;
    endedAt?: string;
    startDate?: string;
    status?: string;
    trialEnd?: string;
    trialStart?: string;
    items?: Array<WorkspaceSubscriptionItemModel>;
}

export interface WorkspaceSubscriptionOverviewModel {
    subscription?: WorkspaceSubscriptionModel;
    invoices?: Array<WorkspaceInvoiceModel>;
}

export interface WorkspaceSubscriptionStatusModel {
    status?: string;
    hasDefaultPaymentMethod?: boolean;
    areBillingDetailsComplete?: boolean;
    remainingTrialDays?: number;
    totalTrialDays?: number;
}

export interface WorkspaceTaxIdModel {
    type?: string;
    value?: string;
}

export interface WorkspaceUnlinkDatasetsFromWorkspaceRequest {
    organizationId?: string;
    workspaceId?: string;
    datasetIds?: Array<string>;
}

export interface WorkspaceUpdateCustomerRequest {
    organizationId?: string;
    customerId?: string;
    name?: string;
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
    taxIdValue?: string;
    taxIdType?: string;
}

export interface WorkspaceUpdateCustomerResponse {
    data?: WorkspaceCustomerModel;
}

export interface WorkspaceUpdateMembershipRequest {
    organizationId?: string;
    workspaceId?: string;
    email?: string;
    workspaceRole?: string;
}

export interface WorkspaceUpdateMembershipResponse {
    data?: WorkspaceMembershipEntity;
}

export interface WorkspaceUpdateOrganizationRequest {
    organizationId?: string;
    name?: string;
}

export interface WorkspaceUpdateOrganizationResponse {
    data?: WorkspaceOrganizationEntity;
}

export interface WorkspaceUpdateUserRequest {
    organizationId?: string;
    email?: string;
    organizationRole?: string;
    status?: string;
}

export interface WorkspaceUpdateUserResponse {
    data?: WorkspaceUserEntity;
}

export interface WorkspaceUpdateWorkspaceRequest {
    organizationId?: string;
    workspaceId?: string;
    name?: string;
}

export interface WorkspaceUpdateWorkspaceResponse {
    data?: WorkspaceWorkspaceEntity;
}

export interface WorkspaceUserEntity {
    email?: string;
    organizationId?: string;
    organizationRole?: string;
    status?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
    lastLoggedIn?: string;
    applications?: Array<string>;
}

export interface WorkspaceWorkspaceEntity {
    id?: string;
    name?: string;
    organizationId?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
}

export interface WorkspaceWorkspaceWithMembershipsDatasetsEntity {
    id?: string;
    name?: string;
    organizationId?: string;
    membershipCount?: number;
    datasetCount?: number;
    createdOn?: string;
    lastUpdatedOn?: string;
}


export const BiostrandGatewayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {

        biostrandGatewayCancelSubscription: async (organizationId: string, body: WorkspaceCancelSubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayCancelSubscription', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCancelSubscription', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/cancel`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateApiTokenOfOrganization: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateApiTokenOfOrganization', 'body', body)
            const localVarPath = `/api/workspace/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateDataset: async (body: ApiGatewayCreateDatasetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateDataset', 'body', body)
            const localVarPath = `/api/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateMembership: async (organizationId: string, workspaceId: string, body: WorkspaceCreateMembershipRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayCreateMembership', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayCreateMembership', 'workspaceId', workspaceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateMembership', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/create`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateOrganization: async (body: WorkspaceCreateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateOrganization', 'body', body)
            const localVarPath = `/api/workspace/organizations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreatePipeline: async (body: ApiGatewayCreatePipelineApiRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreatePipeline', 'body', body)
            const localVarPath = `/api/pipeline/pipelines/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateSetupIntent: async (organizationId: string, body: WorkspaceCreateSetupIntentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayCreateSetupIntent', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateSetupIntent', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/setup`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayCreateWorkspace: async (organizationId: string, body: WorkspaceCreateWorkspaceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayCreateWorkspace', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayCreateWorkspace', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/create`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeleteDataset: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('biostrandGatewayDeleteDataset', 'id', id)
            const localVarPath = `/api/datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeleteMembership: async (organizationId: string, workspaceId: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayDeleteMembership', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayDeleteMembership', 'workspaceId', workspaceId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('biostrandGatewayDeleteMembership', 'email', email)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/delete`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeletePaymentMethod: async (organizationId: string, paymentMethodId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayDeletePaymentMethod', 'organizationId', organizationId)
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('biostrandGatewayDeletePaymentMethod', 'paymentMethodId', paymentMethodId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/payment-methods/{paymentMethodId}/delete`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeletePipeline: async (datasetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('biostrandGatewayDeletePipeline', 'datasetId', datasetId)
            const localVarPath = `/api/pipeline/pipelines/{datasetId}/delete`
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeleteUser: async (organizationId: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayDeleteUser', 'organizationId', organizationId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('biostrandGatewayDeleteUser', 'email', email)
            const localVarPath = `/api/workspace/organizations/{organizationId}/users/{email}/delete`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayDeleteWorkspace: async (organizationId: string, workspaceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayDeleteWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayDeleteWorkspace', 'workspaceId', workspaceId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/delete`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayEnrichQuery: async (body: ApiGatewaySearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayEnrichQuery', 'body', body)
            const localVarPath = `/api/iris/v1/sequences/query/enrich`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayExplorePipelineData: async (body: PipelineExplorePipelineDataRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayExplorePipelineData', 'body', body)
            const localVarPath = `/api/pipeline/pipelines/explore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAlignmentForQuery: async (queryId: string, taskId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetAlignmentForQuery', 'queryId', queryId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('biostrandGatewayGetAlignmentForQuery', 'taskId', taskId)
            const localVarPath = `/api/sr/v1/query/{queryId}/alignment/{taskId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllDatasetsOfOrganization: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workspace/organizations/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllDatasetsOfOrganization2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllDatasetsOfWorkspace: async (organizationId: string, workspaceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllDatasetsOfWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllDatasetsOfWorkspace', 'workspaceId', workspaceId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllMembershipsOfWorkspace: async (organizationId: string, workspaceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllMembershipsOfWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllMembershipsOfWorkspace', 'workspaceId', workspaceId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllUsersOfOrganization: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllUsersOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAllWorkspacesOfOrganization: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetAllWorkspacesOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAttributes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sr/v1/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetAuthorizationContext: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workspace/users/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetCountCube: async (queryId: string, taskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetCountCube', 'queryId', queryId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('biostrandGatewayGetCountCube', 'taskId', taskId)
            const localVarPath = `/api/sr/v1/query/{queryId}/aggregation/cube/counts/{taskId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetCustomer: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetCustomer', 'organizationId', organizationId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/customer`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetDataset: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('biostrandGatewayGetDataset', 'id', id)
            const localVarPath = `/api/datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetDimensionAggregates: async (queryId: string, body: ApiGatewayGetDimensionAggregatesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetDimensionAggregates', 'queryId', queryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayGetDimensionAggregates', 'body', body)
            const localVarPath = `/api/iris/v1/sequences/query/{queryId}/aggregates`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetDimensions: async (queryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetDimensions', 'queryId', queryId)
            const localVarPath = `/api/iris/v1/sequences/query/{queryId}/dimensions`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetList: async (queryId: string, taskId: string, page?: number, pageSize?: number, sortDimension?: string, descending?: boolean, fields?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetList', 'queryId', queryId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('biostrandGatewayGetList', 'taskId', taskId)
            const localVarPath = `/api/sr/v1/query/{queryId}/list/{taskId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDimension !== undefined) {
                localVarQueryParameter['sortDimension'] = sortDimension;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetPaymentMethods: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetPaymentMethods', 'organizationId', organizationId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/payment-methods`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetPipeline: async (datasetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('biostrandGatewayGetPipeline', 'datasetId', datasetId)
            const localVarPath = `/api/pipeline/pipelines/{datasetId}`
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetQueryResult: async (queryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetQueryResult', 'queryId', queryId)
            const localVarPath = `/api/sr/v1/query/{queryId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetQueryTaskById: async (queryId: string, taskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetQueryTaskById', 'queryId', queryId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('biostrandGatewayGetQueryTaskById', 'taskId', taskId)
            const localVarPath = `/api/sr/v1/query/{queryId}/tasks/{taskId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetQueryTasks: async (queryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetQueryTasks', 'queryId', queryId)
            const localVarPath = `/api/sr/v1/query/{queryId}/tasks`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetSequence: async (sequenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceId' is not null or undefined
            assertParamExists('biostrandGatewayGetSequence', 'sequenceId', sequenceId)
            const localVarPath = `/api/iris/v1/sequences/sequence/{sequenceId}`
                .replace(`{${"sequenceId"}}`, encodeURIComponent(String(sequenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetSequences: async (queryId: string, body: ApiGatewayListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetSequences', 'queryId', queryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayGetSequences', 'body', body)
            const localVarPath = `/api/iris/v1/sequences/query/{queryId}/sequences`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetSubscription: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayGetSubscription', 'organizationId', organizationId)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayGetTaxonomy: async (queryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayGetTaxonomy', 'queryId', queryId)
            const localVarPath = `/api/iris/v1/sequences/query/{queryId}/taxonomy`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayInitializeDatabase: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workspace/database/initialize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayInviteUser: async (organizationId: string, body: WorkspaceInviteUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayInviteUser', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayInviteUser', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/users/invite`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayLaunchWorkflow: async (workflowName: string, body: ApiGatewayLaunchWorkflowRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowName' is not null or undefined
            assertParamExists('biostrandGatewayLaunchWorkflow', 'workflowName', workflowName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayLaunchWorkflow', 'body', body)
            const localVarPath = `/api/jobs/{workflowName}`
                .replace(`{${"workflowName"}}`, encodeURIComponent(String(workflowName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayLinkDatasetsToWorkspace: async (organizationId: string, workspaceId: string, body: WorkspaceLinkDatasetsToWorkspaceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayLinkDatasetsToWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayLinkDatasetsToWorkspace', 'workspaceId', workspaceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayLinkDatasetsToWorkspace', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets/link`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayMigrateDatabase: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workspace/database/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRegisterDataset: async (body: ApiGatewayCreateDatasetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRegisterDataset', 'body', body)
            const localVarPath = `/api/datasets/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRenewSubscription: async (organizationId: string, body: WorkspaceRenewSubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayRenewSubscription', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRenewSubscription', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/renew`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRunAlignmentTaskForQuery: async (queryId: string, body: AlignRunAlignmentTaskForQueryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayRunAlignmentTaskForQuery', 'queryId', queryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRunAlignmentTaskForQuery', 'body', body)
            const localVarPath = `/api/sr/v1/query/{queryId}/alignment`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRunCountCubeTask: async (queryId: string, body: SearchAndRelateRunCountCubeTaskRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayRunCountCubeTask', 'queryId', queryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRunCountCubeTask', 'body', body)
            const localVarPath = `/api/sr/v1/query/{queryId}/aggregation/cube/counts`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRunListTask: async (queryId: string, body: SearchAndRelateRunListTaskRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewayRunListTask', 'queryId', queryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRunListTask', 'body', body)
            const localVarPath = `/api/sr/v1/query/{queryId}/list`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRunPipeline: async (datasetId: string, body: ApiGatewayRunPipelineApiRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('biostrandGatewayRunPipeline', 'datasetId', datasetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRunPipeline', 'body', body)
            const localVarPath = `/api/pipeline/pipelines/{datasetId}/run`
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayRunQuery: async (body: ApiGatewayRunQueryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayRunQuery', 'body', body)
            const localVarPath = `/api/sr/v1/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewaySearchSequence: async (body: ApiGatewaySearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewaySearchSequence', 'body', body)
            const localVarPath = `/api/iris/v1/sequences/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewaySearchSequenceFastas: async (queryId: string, align?: boolean, page?: number, pageSize?: number, molType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('biostrandGatewaySearchSequenceFastas', 'queryId', queryId)
            const localVarPath = `/api/iris/v1/sequences/query/{queryId}/fastas`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (align !== undefined) {
                localVarQueryParameter['align'] = align;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (molType !== undefined) {
                localVarQueryParameter['molType'] = molType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewaySeedDatabase: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workspace/database/seed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewaySetDefaultMembership: async (organizationId: string, workspaceId: string, email: string, body: WorkspaceSetDefaultMembershipRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultMembership', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultMembership', 'workspaceId', workspaceId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultMembership', 'email', email)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultMembership', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/default`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewaySetDefaultPaymentMethod: async (organizationId: string, body: WorkspaceSetDefaultPaymentMethodRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultPaymentMethod', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewaySetDefaultPaymentMethod', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/payment-methods/default`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayTestPipelineConnection: async (body: PipelineTestPipelineConnectionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayTestPipelineConnection', 'body', body)
            const localVarPath = `/api/pipeline/pipelines/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUnlinkDatasetsFromWorkspace: async (organizationId: string, workspaceId: string, body: WorkspaceUnlinkDatasetsFromWorkspaceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUnlinkDatasetsFromWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayUnlinkDatasetsFromWorkspace', 'workspaceId', workspaceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUnlinkDatasetsFromWorkspace', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/datasets/unlink`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdateCustomer: async (organizationId: string, body: WorkspaceUpdateCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateCustomer', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdateCustomer', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/subscription/customer/update`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdateMembership: async (organizationId: string, workspaceId: string, email: string, body: WorkspaceUpdateMembershipRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateMembership', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateMembership', 'workspaceId', workspaceId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('biostrandGatewayUpdateMembership', 'email', email)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdateMembership', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/memberships/{email}/update`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdateOrganization: async (organizationId: string, body: WorkspaceUpdateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateOrganization', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdateOrganization', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/update`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdatePipeline: async (datasetId: string, body: ApiGatewayUpdatePipelineApiRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('biostrandGatewayUpdatePipeline', 'datasetId', datasetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdatePipeline', 'body', body)
            const localVarPath = `/api/pipeline/pipelines/{datasetId}/update`
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdateUser: async (organizationId: string, email: string, body: WorkspaceUpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateUser', 'organizationId', organizationId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('biostrandGatewayUpdateUser', 'email', email)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdateUser', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/users/{email}/update`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUpdateWorkspace: async (organizationId: string, workspaceId: string, body: WorkspaceUpdateWorkspaceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateWorkspace', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('biostrandGatewayUpdateWorkspace', 'workspaceId', workspaceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUpdateWorkspace', 'body', body)
            const localVarPath = `/api/workspace/organizations/{organizationId}/workspaces/{workspaceId}/update`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUploadDataset: async (body: ApiGatewayUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('biostrandGatewayUploadDataset', 'body', body)
            const localVarPath = `/api/jobs/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        biostrandGatewayUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

export const BiostrandGatewayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiostrandGatewayApiAxiosParamCreator(configuration)
    return {

        async biostrandGatewayCancelSubscription(organizationId: string, body: WorkspaceCancelSubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCancelSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCancelSubscription(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateApiTokenOfOrganization(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCreateNewApiTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateApiTokenOfOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateDataset(body: ApiGatewayCreateDatasetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDatasetWithOwnerEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateDataset(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateMembership(organizationId: string, workspaceId: string, body: WorkspaceCreateMembershipRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCreateMembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateMembership(organizationId, workspaceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateOrganization(body: WorkspaceCreateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCreateOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreatePipeline(body: ApiGatewayCreatePipelineApiRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelinePipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreatePipeline(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateSetupIntent(organizationId: string, body: WorkspaceCreateSetupIntentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCreateSetupIntentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateSetupIntent(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayCreateWorkspace(organizationId: string, body: WorkspaceCreateWorkspaceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceCreateWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayCreateWorkspace(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeleteDataset(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDatasetWithOwnerEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeleteDataset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeleteMembership(organizationId: string, workspaceId: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeleteMembership(organizationId, workspaceId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeletePaymentMethod(organizationId: string, paymentMethodId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeletePaymentMethod(organizationId, paymentMethodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeletePipeline(datasetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelinePipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeletePipeline(datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeleteUser(organizationId: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeleteUser(organizationId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayDeleteWorkspace(organizationId: string, workspaceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayDeleteWorkspace(organizationId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayEnrichQuery(body: ApiGatewaySearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayEnrichQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayEnrichQuery(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayExplorePipelineData(body: PipelineExplorePipelineDataRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineExplorePipelineDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayExplorePipelineData(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAlignmentForQuery(queryId: string, taskId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlignAlignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAlignmentForQuery(queryId, taskId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllDatasetsOfOrganization(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllDatasetsOfOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllDatasetsOfOrganization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllDatasetsOfOrganization2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllDatasetsOfOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllDatasetsOfOrganization2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllDatasetsOfWorkspace(organizationId: string, workspaceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllDatasetsOfWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllDatasetsOfWorkspace(organizationId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllMembershipsOfWorkspace(organizationId: string, workspaceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllMembershipsOfWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllMembershipsOfWorkspace(organizationId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllUsersOfOrganization(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllUsersOfOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllUsersOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAllWorkspacesOfOrganization(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAllWorkspacesOfOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAllWorkspacesOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAttributes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateListField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAttributes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetAuthorizationContext(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetAuthorizationContextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetAuthorizationContext(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetCountCube(queryId: string, taskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateCountCube>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetCountCube(queryId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetCustomer(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetCustomer(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetDataset(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDatasetWithOwnerEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetDataset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetDimensionAggregates(queryId: string, body: ApiGatewayGetDimensionAggregatesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayGetDimensionAggregatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetDimensionAggregates(queryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetDimensions(queryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateFirstOrderCounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetDimensions(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetList(queryId: string, taskId: string, page?: number, pageSize?: number, sortDimension?: string, descending?: boolean, fields?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetList(queryId, taskId, page, pageSize, sortDimension, descending, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetPaymentMethods(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetPaymentMethodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetPaymentMethods(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetPipeline(datasetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelinePipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetPipeline(datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetQueryResult(queryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetQueryResult(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetQueryTaskById(queryId: string, taskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryTaskObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetQueryTaskById(queryId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetQueryTasks(queryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryTasks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetQueryTasks(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetSequence(sequenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayGetSequenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetSequence(sequenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetSequences(queryId: string, body: ApiGatewayListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetSequences(queryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetSubscription(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceGetSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetSubscription(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayGetTaxonomy(queryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAndRelateTaxonomyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayGetTaxonomy(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayInitializeDatabase(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayInitializeDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayInviteUser(organizationId: string, body: WorkspaceInviteUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceInviteUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayInviteUser(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayLaunchWorkflow(workflowName: string, body: ApiGatewayLaunchWorkflowRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayLaunchWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayLaunchWorkflow(workflowName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayLinkDatasetsToWorkspace(organizationId: string, workspaceId: string, body: WorkspaceLinkDatasetsToWorkspaceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceLinkDatasetsToWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayLinkDatasetsToWorkspace(organizationId, workspaceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayMigrateDatabase(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayMigrateDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRegisterDataset(body: ApiGatewayCreateDatasetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDatasetWithOwnerEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRegisterDataset(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRenewSubscription(organizationId: string, body: WorkspaceRenewSubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceRenewSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRenewSubscription(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRunAlignmentTaskForQuery(queryId: string, body: AlignRunAlignmentTaskForQueryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryTaskObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRunAlignmentTaskForQuery(queryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRunCountCubeTask(queryId: string, body: SearchAndRelateRunCountCubeTaskRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryTaskObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRunCountCubeTask(queryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRunListTask(queryId: string, body: SearchAndRelateRunListTaskRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryTaskObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRunListTask(queryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRunPipeline(datasetId: string, body: ApiGatewayRunPipelineApiRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelinePipelineRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRunPipeline(datasetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayRunQuery(body: ApiGatewayRunQueryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayQueryObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayRunQuery(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewaySearchSequence(body: ApiGatewaySearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewaySearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewaySearchSequence(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewaySearchSequenceFastas(queryId: string, align?: boolean, page?: number, pageSize?: number, molType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewaySearchFastasResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewaySearchSequenceFastas(queryId, align, page, pageSize, molType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewaySeedDatabase(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewaySeedDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewaySetDefaultMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceSetDefaultMembershipRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSetDefaultMembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewaySetDefaultMembership(organizationId, workspaceId, email, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewaySetDefaultPaymentMethod(organizationId: string, body: WorkspaceSetDefaultPaymentMethodRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSetDefaultPaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewaySetDefaultPaymentMethod(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayTestPipelineConnection(body: PipelineTestPipelineConnectionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTestPipelineConnectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayTestPipelineConnection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUnlinkDatasetsFromWorkspaceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId, workspaceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdateCustomer(organizationId: string, body: WorkspaceUpdateCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUpdateCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdateCustomer(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdateMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceUpdateMembershipRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUpdateMembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdateMembership(organizationId, workspaceId, email, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdateOrganization(organizationId: string, body: WorkspaceUpdateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUpdateOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdateOrganization(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdatePipeline(datasetId: string, body: ApiGatewayUpdatePipelineApiRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelinePipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdatePipeline(datasetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdateUser(organizationId: string, email: string, body: WorkspaceUpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUpdateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdateUser(organizationId, email, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUpdateWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUpdateWorkspaceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUpdateWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUpdateWorkspace(organizationId, workspaceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUploadDataset(body: ApiGatewayUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDatasetWithOwnerEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUploadDataset(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        async biostrandGatewayUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGatewayUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biostrandGatewayUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

export const BiostrandGatewayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiostrandGatewayApiFp(configuration)
    return {

        biostrandGatewayCancelSubscription(organizationId: string, body: WorkspaceCancelSubscriptionRequest, options?: any): AxiosPromise<WorkspaceCancelSubscriptionResponse> {
            return localVarFp.biostrandGatewayCancelSubscription(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateApiTokenOfOrganization(body: object, options?: any): AxiosPromise<WorkspaceCreateNewApiTokenResponse> {
            return localVarFp.biostrandGatewayCreateApiTokenOfOrganization(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateDataset(body: ApiGatewayCreateDatasetRequest, options?: any): AxiosPromise<WorkspaceDatasetWithOwnerEntity> {
            return localVarFp.biostrandGatewayCreateDataset(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateMembership(organizationId: string, workspaceId: string, body: WorkspaceCreateMembershipRequest, options?: any): AxiosPromise<WorkspaceCreateMembershipResponse> {
            return localVarFp.biostrandGatewayCreateMembership(organizationId, workspaceId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateOrganization(body: WorkspaceCreateOrganizationRequest, options?: any): AxiosPromise<WorkspaceCreateOrganizationResponse> {
            return localVarFp.biostrandGatewayCreateOrganization(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreatePipeline(body: ApiGatewayCreatePipelineApiRequest, options?: any): AxiosPromise<PipelinePipeline> {
            return localVarFp.biostrandGatewayCreatePipeline(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateSetupIntent(organizationId: string, body: WorkspaceCreateSetupIntentRequest, options?: any): AxiosPromise<WorkspaceCreateSetupIntentResponse> {
            return localVarFp.biostrandGatewayCreateSetupIntent(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayCreateWorkspace(organizationId: string, body: WorkspaceCreateWorkspaceRequest, options?: any): AxiosPromise<WorkspaceCreateWorkspaceResponse> {
            return localVarFp.biostrandGatewayCreateWorkspace(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeleteDataset(id: string, options?: any): AxiosPromise<WorkspaceDatasetWithOwnerEntity> {
            return localVarFp.biostrandGatewayDeleteDataset(id, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeleteMembership(organizationId: string, workspaceId: string, email: string, options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayDeleteMembership(organizationId, workspaceId, email, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeletePaymentMethod(organizationId: string, paymentMethodId: string, options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayDeletePaymentMethod(organizationId, paymentMethodId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeletePipeline(datasetId: string, options?: any): AxiosPromise<PipelinePipeline> {
            return localVarFp.biostrandGatewayDeletePipeline(datasetId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeleteUser(organizationId: string, email: string, options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayDeleteUser(organizationId, email, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayDeleteWorkspace(organizationId: string, workspaceId: string, options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayDeleteWorkspace(organizationId, workspaceId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayEnrichQuery(body: ApiGatewaySearchRequest, options?: any): AxiosPromise<ApiGatewayEnrichQueryResponse> {
            return localVarFp.biostrandGatewayEnrichQuery(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayExplorePipelineData(body: PipelineExplorePipelineDataRequest, options?: any): AxiosPromise<PipelineExplorePipelineDataResponse> {
            return localVarFp.biostrandGatewayExplorePipelineData(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAlignmentForQuery(queryId: string, taskId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<AlignAlignResponse> {
            return localVarFp.biostrandGatewayGetAlignmentForQuery(queryId, taskId, page, pageSize, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllDatasetsOfOrganization(options?: any): AxiosPromise<WorkspaceGetAllDatasetsOfOrganizationResponse> {
            return localVarFp.biostrandGatewayGetAllDatasetsOfOrganization(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllDatasetsOfOrganization2(options?: any): AxiosPromise<WorkspaceGetAllDatasetsOfOrganizationResponse> {
            return localVarFp.biostrandGatewayGetAllDatasetsOfOrganization2(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllDatasetsOfWorkspace(organizationId: string, workspaceId: string, options?: any): AxiosPromise<WorkspaceGetAllDatasetsOfWorkspaceResponse> {
            return localVarFp.biostrandGatewayGetAllDatasetsOfWorkspace(organizationId, workspaceId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllMembershipsOfWorkspace(organizationId: string, workspaceId: string, options?: any): AxiosPromise<WorkspaceGetAllMembershipsOfWorkspaceResponse> {
            return localVarFp.biostrandGatewayGetAllMembershipsOfWorkspace(organizationId, workspaceId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllUsersOfOrganization(organizationId: string, options?: any): AxiosPromise<WorkspaceGetAllUsersOfOrganizationResponse> {
            return localVarFp.biostrandGatewayGetAllUsersOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAllWorkspacesOfOrganization(organizationId: string, options?: any): AxiosPromise<WorkspaceGetAllWorkspacesOfOrganizationResponse> {
            return localVarFp.biostrandGatewayGetAllWorkspacesOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAttributes(options?: any): AxiosPromise<SearchAndRelateListField> {
            return localVarFp.biostrandGatewayGetAttributes(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetAuthorizationContext(options?: any): AxiosPromise<WorkspaceGetAuthorizationContextResponse> {
            return localVarFp.biostrandGatewayGetAuthorizationContext(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetCountCube(queryId: string, taskId: string, options?: any): AxiosPromise<SearchAndRelateCountCube> {
            return localVarFp.biostrandGatewayGetCountCube(queryId, taskId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetCustomer(organizationId: string, options?: any): AxiosPromise<WorkspaceGetCustomerResponse> {
            return localVarFp.biostrandGatewayGetCustomer(organizationId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetDataset(id: string, options?: any): AxiosPromise<WorkspaceDatasetWithOwnerEntity> {
            return localVarFp.biostrandGatewayGetDataset(id, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetDimensionAggregates(queryId: string, body: ApiGatewayGetDimensionAggregatesRequest, options?: any): AxiosPromise<ApiGatewayGetDimensionAggregatesResponse> {
            return localVarFp.biostrandGatewayGetDimensionAggregates(queryId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetDimensions(queryId: string, options?: any): AxiosPromise<SearchAndRelateFirstOrderCounts> {
            return localVarFp.biostrandGatewayGetDimensions(queryId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetList(queryId: string, taskId: string, page?: number, pageSize?: number, sortDimension?: string, descending?: boolean, fields?: Array<string>, options?: any): AxiosPromise<SearchAndRelateListResponse> {
            return localVarFp.biostrandGatewayGetList(queryId, taskId, page, pageSize, sortDimension, descending, fields, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetPaymentMethods(organizationId: string, options?: any): AxiosPromise<WorkspaceGetPaymentMethodsResponse> {
            return localVarFp.biostrandGatewayGetPaymentMethods(organizationId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetPipeline(datasetId: string, options?: any): AxiosPromise<PipelinePipeline> {
            return localVarFp.biostrandGatewayGetPipeline(datasetId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetQueryResult(queryId: string, options?: any): AxiosPromise<ApiGatewayQueryObject> {
            return localVarFp.biostrandGatewayGetQueryResult(queryId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetQueryTaskById(queryId: string, taskId: string, options?: any): AxiosPromise<ApiGatewayQueryTaskObject> {
            return localVarFp.biostrandGatewayGetQueryTaskById(queryId, taskId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetQueryTasks(queryId: string, options?: any): AxiosPromise<ApiGatewayQueryTasks> {
            return localVarFp.biostrandGatewayGetQueryTasks(queryId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetSequence(sequenceId: string, options?: any): AxiosPromise<ApiGatewayGetSequenceResponse> {
            return localVarFp.biostrandGatewayGetSequence(sequenceId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetSequences(queryId: string, body: ApiGatewayListRequest, options?: any): AxiosPromise<SearchAndRelateListResponse> {
            return localVarFp.biostrandGatewayGetSequences(queryId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetSubscription(organizationId: string, options?: any): AxiosPromise<WorkspaceGetSubscriptionResponse> {
            return localVarFp.biostrandGatewayGetSubscription(organizationId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayGetTaxonomy(queryId: string, options?: any): AxiosPromise<SearchAndRelateTaxonomyResponse> {
            return localVarFp.biostrandGatewayGetTaxonomy(queryId, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayHealth(options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayHealth(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayInitializeDatabase(options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayInitializeDatabase(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayInviteUser(organizationId: string, body: WorkspaceInviteUserRequest, options?: any): AxiosPromise<WorkspaceInviteUserResponse> {
            return localVarFp.biostrandGatewayInviteUser(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayLaunchWorkflow(workflowName: string, body: ApiGatewayLaunchWorkflowRequest, options?: any): AxiosPromise<ApiGatewayLaunchWorkflowResponse> {
            return localVarFp.biostrandGatewayLaunchWorkflow(workflowName, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayLinkDatasetsToWorkspace(organizationId: string, workspaceId: string, body: WorkspaceLinkDatasetsToWorkspaceRequest, options?: any): AxiosPromise<WorkspaceLinkDatasetsToWorkspaceResponse> {
            return localVarFp.biostrandGatewayLinkDatasetsToWorkspace(organizationId, workspaceId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayMigrateDatabase(options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayMigrateDatabase(options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRegisterDataset(body: ApiGatewayCreateDatasetRequest, options?: any): AxiosPromise<WorkspaceDatasetWithOwnerEntity> {
            return localVarFp.biostrandGatewayRegisterDataset(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRenewSubscription(organizationId: string, body: WorkspaceRenewSubscriptionRequest, options?: any): AxiosPromise<WorkspaceRenewSubscriptionResponse> {
            return localVarFp.biostrandGatewayRenewSubscription(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRunAlignmentTaskForQuery(queryId: string, body: AlignRunAlignmentTaskForQueryRequest, options?: any): AxiosPromise<ApiGatewayQueryTaskObject> {
            return localVarFp.biostrandGatewayRunAlignmentTaskForQuery(queryId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRunCountCubeTask(queryId: string, body: SearchAndRelateRunCountCubeTaskRequest, options?: any): AxiosPromise<ApiGatewayQueryTaskObject> {
            return localVarFp.biostrandGatewayRunCountCubeTask(queryId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRunListTask(queryId: string, body: SearchAndRelateRunListTaskRequest, options?: any): AxiosPromise<ApiGatewayQueryTaskObject> {
            return localVarFp.biostrandGatewayRunListTask(queryId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRunPipeline(datasetId: string, body: ApiGatewayRunPipelineApiRequest, options?: any): AxiosPromise<PipelinePipelineRun> {
            return localVarFp.biostrandGatewayRunPipeline(datasetId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayRunQuery(body: ApiGatewayRunQueryRequest, options?: any): AxiosPromise<ApiGatewayQueryObject> {
            return localVarFp.biostrandGatewayRunQuery(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewaySearchSequence(body: ApiGatewaySearchRequest, options?: any): AxiosPromise<ApiGatewaySearchResponse> {
            return localVarFp.biostrandGatewaySearchSequence(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewaySearchSequenceFastas(queryId: string, align?: boolean, page?: number, pageSize?: number, molType?: string, options?: any): AxiosPromise<ApiGatewaySearchFastasResponse> {
            return localVarFp.biostrandGatewaySearchSequenceFastas(queryId, align, page, pageSize, molType, options).then((request) => request(axios, basePath));
        },

        biostrandGatewaySeedDatabase(options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewaySeedDatabase(options).then((request) => request(axios, basePath));
        },

        biostrandGatewaySetDefaultMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceSetDefaultMembershipRequest, options?: any): AxiosPromise<WorkspaceSetDefaultMembershipResponse> {
            return localVarFp.biostrandGatewaySetDefaultMembership(organizationId, workspaceId, email, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewaySetDefaultPaymentMethod(organizationId: string, body: WorkspaceSetDefaultPaymentMethodRequest, options?: any): AxiosPromise<WorkspaceSetDefaultPaymentMethodResponse> {
            return localVarFp.biostrandGatewaySetDefaultPaymentMethod(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayTestPipelineConnection(body: PipelineTestPipelineConnectionRequest, options?: any): AxiosPromise<PipelineTestPipelineConnectionResponse> {
            return localVarFp.biostrandGatewayTestPipelineConnection(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUnlinkDatasetsFromWorkspaceRequest, options?: any): AxiosPromise<object> {
            return localVarFp.biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId, workspaceId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdateCustomer(organizationId: string, body: WorkspaceUpdateCustomerRequest, options?: any): AxiosPromise<WorkspaceUpdateCustomerResponse> {
            return localVarFp.biostrandGatewayUpdateCustomer(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdateMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceUpdateMembershipRequest, options?: any): AxiosPromise<WorkspaceUpdateMembershipResponse> {
            return localVarFp.biostrandGatewayUpdateMembership(organizationId, workspaceId, email, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdateOrganization(organizationId: string, body: WorkspaceUpdateOrganizationRequest, options?: any): AxiosPromise<WorkspaceUpdateOrganizationResponse> {
            return localVarFp.biostrandGatewayUpdateOrganization(organizationId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdatePipeline(datasetId: string, body: ApiGatewayUpdatePipelineApiRequest, options?: any): AxiosPromise<PipelinePipeline> {
            return localVarFp.biostrandGatewayUpdatePipeline(datasetId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdateUser(organizationId: string, email: string, body: WorkspaceUpdateUserRequest, options?: any): AxiosPromise<WorkspaceUpdateUserResponse> {
            return localVarFp.biostrandGatewayUpdateUser(organizationId, email, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUpdateWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUpdateWorkspaceRequest, options?: any): AxiosPromise<WorkspaceUpdateWorkspaceResponse> {
            return localVarFp.biostrandGatewayUpdateWorkspace(organizationId, workspaceId, body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUploadDataset(body: ApiGatewayUploadRequest, options?: any): AxiosPromise<WorkspaceDatasetWithOwnerEntity> {
            return localVarFp.biostrandGatewayUploadDataset(body, options).then((request) => request(axios, basePath));
        },

        biostrandGatewayUser(options?: any): AxiosPromise<ApiGatewayUserResponse> {
            return localVarFp.biostrandGatewayUser(options).then((request) => request(axios, basePath));
        },
    };
};


export class BiostrandGatewayApi extends BaseAPI {

    public biostrandGatewayCancelSubscription(organizationId: string, body: WorkspaceCancelSubscriptionRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCancelSubscription(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateApiTokenOfOrganization(body: object, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateApiTokenOfOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateDataset(body: ApiGatewayCreateDatasetRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateDataset(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateMembership(organizationId: string, workspaceId: string, body: WorkspaceCreateMembershipRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateMembership(organizationId, workspaceId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateOrganization(body: WorkspaceCreateOrganizationRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreatePipeline(body: ApiGatewayCreatePipelineApiRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreatePipeline(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateSetupIntent(organizationId: string, body: WorkspaceCreateSetupIntentRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateSetupIntent(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayCreateWorkspace(organizationId: string, body: WorkspaceCreateWorkspaceRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayCreateWorkspace(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeleteDataset(id: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeleteDataset(id, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeleteMembership(organizationId: string, workspaceId: string, email: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeleteMembership(organizationId, workspaceId, email, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeletePaymentMethod(organizationId: string, paymentMethodId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeletePaymentMethod(organizationId, paymentMethodId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeletePipeline(datasetId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeletePipeline(datasetId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeleteUser(organizationId: string, email: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeleteUser(organizationId, email, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayDeleteWorkspace(organizationId: string, workspaceId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayDeleteWorkspace(organizationId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayEnrichQuery(body: ApiGatewaySearchRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayEnrichQuery(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayExplorePipelineData(body: PipelineExplorePipelineDataRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayExplorePipelineData(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAlignmentForQuery(queryId: string, taskId: string, page?: number, pageSize?: number, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAlignmentForQuery(queryId, taskId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllDatasetsOfOrganization(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllDatasetsOfOrganization(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllDatasetsOfOrganization2(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllDatasetsOfOrganization2(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllDatasetsOfWorkspace(organizationId: string, workspaceId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllDatasetsOfWorkspace(organizationId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllMembershipsOfWorkspace(organizationId: string, workspaceId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllMembershipsOfWorkspace(organizationId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllUsersOfOrganization(organizationId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllUsersOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAllWorkspacesOfOrganization(organizationId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAllWorkspacesOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAttributes(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAttributes(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetAuthorizationContext(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetAuthorizationContext(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetCountCube(queryId: string, taskId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetCountCube(queryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetCustomer(organizationId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetCustomer(organizationId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetDataset(id: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetDataset(id, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetDimensionAggregates(queryId: string, body: ApiGatewayGetDimensionAggregatesRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetDimensionAggregates(queryId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetDimensions(queryId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetDimensions(queryId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetList(queryId: string, taskId: string, page?: number, pageSize?: number, sortDimension?: string, descending?: boolean, fields?: Array<string>, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetList(queryId, taskId, page, pageSize, sortDimension, descending, fields, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetPaymentMethods(organizationId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetPaymentMethods(organizationId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetPipeline(datasetId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetPipeline(datasetId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetQueryResult(queryId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetQueryResult(queryId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetQueryTaskById(queryId: string, taskId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetQueryTaskById(queryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetQueryTasks(queryId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetQueryTasks(queryId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetSequence(sequenceId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetSequence(sequenceId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetSequences(queryId: string, body: ApiGatewayListRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetSequences(queryId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetSubscription(organizationId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetSubscription(organizationId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayGetTaxonomy(queryId: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayGetTaxonomy(queryId, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayHealth(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayHealth(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayInitializeDatabase(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayInitializeDatabase(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayInviteUser(organizationId: string, body: WorkspaceInviteUserRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayInviteUser(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayLaunchWorkflow(workflowName: string, body: ApiGatewayLaunchWorkflowRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayLaunchWorkflow(workflowName, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayLinkDatasetsToWorkspace(organizationId: string, workspaceId: string, body: WorkspaceLinkDatasetsToWorkspaceRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayLinkDatasetsToWorkspace(organizationId, workspaceId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayMigrateDatabase(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayMigrateDatabase(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRegisterDataset(body: ApiGatewayCreateDatasetRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRegisterDataset(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRenewSubscription(organizationId: string, body: WorkspaceRenewSubscriptionRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRenewSubscription(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRunAlignmentTaskForQuery(queryId: string, body: AlignRunAlignmentTaskForQueryRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRunAlignmentTaskForQuery(queryId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRunCountCubeTask(queryId: string, body: SearchAndRelateRunCountCubeTaskRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRunCountCubeTask(queryId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRunListTask(queryId: string, body: SearchAndRelateRunListTaskRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRunListTask(queryId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRunPipeline(datasetId: string, body: ApiGatewayRunPipelineApiRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRunPipeline(datasetId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayRunQuery(body: ApiGatewayRunQueryRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayRunQuery(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewaySearchSequence(body: ApiGatewaySearchRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewaySearchSequence(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewaySearchSequenceFastas(queryId: string, align?: boolean, page?: number, pageSize?: number, molType?: string, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewaySearchSequenceFastas(queryId, align, page, pageSize, molType, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewaySeedDatabase(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewaySeedDatabase(options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewaySetDefaultMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceSetDefaultMembershipRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewaySetDefaultMembership(organizationId, workspaceId, email, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewaySetDefaultPaymentMethod(organizationId: string, body: WorkspaceSetDefaultPaymentMethodRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewaySetDefaultPaymentMethod(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayTestPipelineConnection(body: PipelineTestPipelineConnectionRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayTestPipelineConnection(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUnlinkDatasetsFromWorkspaceRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUnlinkDatasetsFromWorkspace(organizationId, workspaceId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdateCustomer(organizationId: string, body: WorkspaceUpdateCustomerRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdateCustomer(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdateMembership(organizationId: string, workspaceId: string, email: string, body: WorkspaceUpdateMembershipRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdateMembership(organizationId, workspaceId, email, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdateOrganization(organizationId: string, body: WorkspaceUpdateOrganizationRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdateOrganization(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdatePipeline(datasetId: string, body: ApiGatewayUpdatePipelineApiRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdatePipeline(datasetId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdateUser(organizationId: string, email: string, body: WorkspaceUpdateUserRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdateUser(organizationId, email, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUpdateWorkspace(organizationId: string, workspaceId: string, body: WorkspaceUpdateWorkspaceRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUpdateWorkspace(organizationId, workspaceId, body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUploadDataset(body: ApiGatewayUploadRequest, options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUploadDataset(body, options).then((request) => request(this.axios, this.basePath));
    }


    public biostrandGatewayUser(options?: any) {
        return BiostrandGatewayApiFp(this.configuration).biostrandGatewayUser(options).then((request) => request(this.axios, this.basePath));
    }
}


