import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';

import styles from './query-string-summary.module.scss';

const QueryStringSummary = (props: AllProps) => {
    const rootQuery = useSelector((state: ApplicationState) => state.search.rootQuery);

    return (
        <div className={styles.summaryContainer}>
            <div className={`${styles.summaryQuery} ${styles.summaryItem}`}>
                <span className={styles.summaryItemIcon}>
                    <FontAwesomeIcon icon={faSearch} />
                </span>
                <span className={`${styles.summaryItemText} ${styles.summaryItemTextWithIcon}`}>{rootQuery}</span>
            </div>
        </div>
    );
};

export default withTranslation()(QueryStringSummary);

interface OwnProps {}

type AllProps = OwnProps & WithTranslation;
