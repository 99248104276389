import { Location, LocationDescriptorObject, Search } from 'history';
import * as React from 'react';
import { RouteUrl } from '../../routing/route-url';
import { BaseSearchParameters, closeToolbarRequest, DimensionFilter, rootSearchRequest } from '../../store/search';
import { updateQueryParameters } from '../../utils/query-parameter-helpers';
import { ResultViewInputValidator } from './result-view-input-validator';
import { ResultViewUrlParameters } from './result-view-url-parameters';
import { UrlParametersHandler } from './url-parameters-handler';
import { DimensionFiltersComparator } from './utils/dimension-filters-comparator';

export enum ResultViewStatus {
    inProgress,
    emptyResult,
    invalid,
    ready,
    error,
    tooManyResults
}

export abstract class ResultViewBasePage<
    TProps extends ResultViewPageProps,
    TState extends ResultViewPageState,
    TUrlParameters extends ResultViewUrlParameters
> extends React.Component<TProps, TState> {
    protected initializingRootQuery = true;
    protected urlParameters: TUrlParameters;
    protected urlHandler: UrlParametersHandler<TUrlParameters>;
    protected inputValidator: ResultViewInputValidator<TUrlParameters>;

    public constructor(
        props: TProps,
        urlHandler: UrlParametersHandler<TUrlParameters>,
        inputValidator: ResultViewInputValidator<TUrlParameters>,
    ) {
        super(props);
        const {
            rootQuery,
            activeWorkspaceId,
            dispatchRootSearchRequest,
            dispatchNavigateTo,
            currentLocation,
        } = this.props;
        this.inputValidator = inputValidator;
        this.urlHandler = urlHandler;
        this.urlParameters = urlHandler.read(currentLocation);

        if (!this.urlParameters.rootQuery) {
            dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${RouteUrl.Search}` });
        } else if (this.urlParameters.rootQuery !== rootQuery) {
            dispatchRootSearchRequest(activeWorkspaceId!, this.urlParameters.rootQuery);
        } else {
            this.initializingRootQuery = false;
        }
    }

    protected baseParametersOutOfSync(urlState: ResultViewUrlParameters, parameters: BaseSearchParameters): boolean {
        const rootQueryEqual = urlState.rootQuery === parameters.rootQuery;
        if (!rootQueryEqual) {
            return true;
        }

        const filtersEqual = DimensionFiltersComparator.equals(urlState.activeFilters || [], parameters.filters);
        if (!filtersEqual) {
            return true;
        }

        return false;
    }

    public componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
        const { rootQuery, currentLocation } = this.props;
        this.urlParameters = this.urlHandler.read(currentLocation);

        if (this.urlParameters.rootQuery === rootQuery) {
            this.initializingRootQuery = false;
        }
    }

    protected navigateTo(route: RouteUrl, search: Search): void {
        const { dispatchNavigateTo, activeWorkspaceId } = this.props;
        dispatchNavigateTo({ pathname: `/${activeWorkspaceId}${route}`, search });
    }

    protected abstract renderViewContent(viewStatus: ResultViewStatus): JSX.Element;
    protected abstract determineViewStatus(): ResultViewStatus;

    protected overlayClicked(): void {
        const { dispatchCloseToolbar } = this.props;
        dispatchCloseToolbar();
    }

    protected updateUrl(newUrlState: TUrlParameters): void {
        const { dispatchNavigateTo, currentLocation } = this.props;
        const parameters = this.urlHandler.write(newUrlState);
        dispatchNavigateTo({
            search: updateQueryParameters(parameters, currentLocation.search),
        });
    }
}

export interface ResultViewPageProps {
    activeWorkspaceId?: string;
    currentLocation: Location;
    rootQuery: string;
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchCloseToolbar: typeof closeToolbarRequest;
}

export interface ResultViewPageState {
    selectedFilters: DimensionFilter[];
}
