import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DimensionFilter, DimensionWithAllElements } from '../../../../../store/search';
import { DimensionFilterHelper } from '../../../../../store/search/helpers/dimension-filter-helper';
import { conditionalClassList } from '../../../../../utils/class-helpers';
import styles from './filter-dimension-elements-panel.module.scss';

class FilterDimensionWithElementsPanel extends React.Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        this.state = { searchText: '' };
    }

    public render(): JSX.Element {
        const { t, dimension, filterOnSelectedDimension } = this.props;
        const { searchText } = this.state;

        let visibleElements: string[] = [];
        if (dimension) {
            visibleElements =
                searchText.trim().length > 0
                    ? dimension.elements
                          .filter((e) => e.name.toLowerCase().includes(searchText.toLowerCase()))
                          .map((e) => e.name)
                    : dimension.elements.map((e) => e.name);
        }

        return (
            <div className={styles.elementsContainer}>
                <div className={styles.elementsToolbar}>
                    {t('Select')}
                    <div onClick={(): void => this.toggleAllNone(true, dimension.name)} className={styles.link}>
                        {t('All')}
                    </div>
                    |
                    <div className={styles.link} onClick={(): void => this.toggleAllNone(false, dimension.name)}>
                        {t('None')}
                    </div>
                    <div>
                        <input
                            className={styles.search}
                            placeholder={t('Search by name')}
                            value={searchText}
                            onChange={(event): void => this.onFilter(event.currentTarget.value)}
                        />
                    </div>
                </div>
                {visibleElements.map((el: string) => {
                    const isIncluded = filterOnSelectedDimension
                        ? DimensionFilterHelper.isElementIncluded(filterOnSelectedDimension, el)
                        : true;
                    const indicatorClasses = conditionalClassList(styles, {
                        indicator: true,
                        included: isIncluded,
                    });
                    const elementClasses = conditionalClassList(styles, {
                        elementItem: true,
                        included: isIncluded,
                    });
                    return (
                        <div
                            key={el}
                            className={elementClasses}
                            onDoubleClick={(): void => this.selectExclusively(dimension.name, el)}
                            onClick={(): void => this.toggleFilter(!isIncluded, dimension.name, el)}>
                            <div className={indicatorClasses}>
                                {isIncluded ? <FontAwesomeIcon icon={faCheck} /> : null}
                            </div>
                            <div className={styles.caption}>{el}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    private toggleFilter(include: boolean, dimensionName: string, elementName: string): void {
        const { toggleFilters } = this.props;
        toggleFilters(include, dimensionName, [elementName]);
    }

    private selectExclusively(dimensionName: string, elemetName: string): void {
        const { selectExclusively } = this.props;
        selectExclusively(dimensionName, elemetName);
    }

    private toggleAllNone(include: boolean, dimensionName: string): void {
        const { toggleAllNone, toggleFilters, dimension } = this.props;
        const { searchText } = this.state;
        if (searchText.trim() === '') {
            toggleAllNone(include, dimensionName);
        } else {
            const visibleElements =
                searchText.trim().length > 0
                    ? dimension.elements.filter((e) => e.name.toLowerCase().includes(searchText.toLowerCase()))
                    : dimension.elements;
            toggleFilters(
                include,
                dimensionName,
                visibleElements.map((e) => e.name),
            );
        }
    }

    private onFilter(text: string): void {
        this.setState({ searchText: text });
    }
}

export default withTranslation()(FilterDimensionWithElementsPanel);

interface OwnProps {
    dimension: DimensionWithAllElements;
    filterOnSelectedDimension?: DimensionFilter;
    toggleFilters: (include: boolean, dimensionName: string, elementNames: string[]) => void;
    selectExclusively: (dimensionName, elementName) => void;
    toggleAllNone: (include, dimensionName) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    searchText: string;
}

type AllState = OwnState;
