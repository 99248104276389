import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { authenticationReducer, AuthenticationState } from './authentication';
import authenticationSaga from './authentication/sagas';
import { datasetsReducer, DatasetsState } from './datasets';
import datasetsSaga from './datasets/sagas';
import { globalReducer, GlobalState } from './global';
import globalSaga from './global/sagas';
import { popupReducer, PopupState } from './popup';
import { searchReducer, SearchState } from './search';
import searchSaga from './search/search-sagas';
import { subscriptionReducer, SubscriptionState } from './subscription';
import subscriptionSaga from './subscription/sagas';
import { toastReducer, ToastState } from './toast';
import { usersReducer, UsersState } from './users';
import usersSaga from './users/sagas';
import { workspacesReducer, WorkspacesState } from './workspaces';
import workspacesSaga from './workspaces/sagas';
import { UIState, uiStateReducer } from './uistate';

export interface ApplicationState {
    global: GlobalState;
    router: RouterState;
    toast: ToastState;
    popup: PopupState;
    search: SearchState;
    authentication: AuthenticationState;
    users: UsersState;
    workspaces: WorkspacesState;
    subscription: SubscriptionState;
    datasets: DatasetsState;
    uistate: UIState
}

export const rootReducer = (history) =>
    combineReducers<ApplicationState>({
        global: globalReducer,
        router: connectRouter(history),
        toast: toastReducer,
        popup: popupReducer,
        search: searchReducer,
        authentication: authenticationReducer,
        users: usersReducer,
        workspaces: workspacesReducer,
        subscription: subscriptionReducer,
        datasets: datasetsReducer,
        uistate: uiStateReducer,
    });

export function* rootSaga() {
    yield all([
        fork(searchSaga),
        fork(authenticationSaga),
        fork(globalSaga),
        fork(usersSaga),
        fork(workspacesSaga),
        fork(subscriptionSaga),
        fork(datasetsSaga),
    ]);
}
