import maxBy from 'lodash.maxby';
import trimStart from 'lodash.trimstart';
import { ApiGatewayEnrichedData, ApiGatewayEnrichQueryResponse, SharedMolType } from '../../../api/generated';
import {
    AlignmentViewRenderData,
    RowRenderData,
} from '../../../pages/results/views/alignment-view/alignment-view-types';

const aminoAcidsWithoutDNALetters: string[] = 'BDEFHIJKLMNOPQRSVWXYZ'.split('');

export function getSequenceType(sequence: string): SharedMolType.DNA | SharedMolType.AA {
    for (let i = 0; i < sequence.length; i++) {
        if (aminoAcidsWithoutDNALetters.indexOf(sequence[i]) !== -1) {
            return SharedMolType.AA;
        }
    }

    return SharedMolType.DNA;
}

const mapFastaToRenderData = (
    queryData: ApiGatewayEnrichedData,
    index: number,
): RowRenderData => {
    const {alignmentInfo = {}, sequenceInfo = {}} = queryData

    const sequence: string = trimStart(sequenceInfo.sequence);

    const offset = alignmentInfo.offset || (sequenceInfo?.sequence?.length || sequence.length)  - sequence.length || 0;

    return {
        offset,
        sequence,
        type: sequenceInfo.molType || SharedMolType.AA,
        residueValues: alignmentInfo.residueValues,
        label: sequenceInfo.description ? sequenceInfo.description : `#${index}`,
        name: sequenceInfo.description,
        id: sequenceInfo.description ? sequenceInfo.description : `id-#${index}`,
    };
};

export function prepareAlignmentViewRenderData(
    enrichData: ApiGatewayEnrichQueryResponse | null,
): AlignmentViewRenderData | null {
    if (!enrichData) return null;

    const longestFasta = maxBy(
        enrichData?.data,
        (rec: ApiGatewayEnrichedData) => (rec.sequenceInfo?.sequence?.length || 0) + (rec.alignmentInfo?.offset || 0),
    );

    const maxLength = (longestFasta && longestFasta.sequenceInfo && longestFasta.sequenceInfo.sequence)
        ? (longestFasta.sequenceInfo.sequence?.length || 0) + (longestFasta.alignmentInfo?.offset || 0)
        : 0;

    const sequenceRows =  enrichData?.data?.map(mapFastaToRenderData);
    const searchOffset = sequenceRows && sequenceRows[0] ? sequenceRows[0].offset : 0

    return {
        maxLength,
        sequenceRows: sequenceRows || [],
        searchOffset,
        searchPattern: {
            offset: 0,
            sequence: '',
            type: SharedMolType.AA,
            label: '',
            name: '',
            id: '',
        },
    };
}
