import { action } from 'typesafe-actions';
import {
    SearchAndRelateSequenceMetaData,
    SearchAndRelateTaxonomyResponse,
    AlignAlignResponse,
} from '../../api/generated';
import { ToolbarContext } from '../../pages/results/components/page-toolbar/page-toolbar';
import { ColumnConfig } from '../../pages/results/views/list-view/components/results-list/column-config';
import {
    AlignmentSearchParameters,
    BaseSearchParameters,
    Dimension,
    ExplorerResultViewData,
    ExplorerSearchParameters,
    ListResultViewData,
    ListSearchParameters,
    QuickFilterResultViewData,
    QuickFilterSearchParameters,
    SearchActionTypes,
} from './types';

export const toggleToolbarRequest = (isOpen: boolean) => action(SearchActionTypes.TOGGLE_TOOLBAR, isOpen);
export const closeToolbarRequest = () => action(SearchActionTypes.CLOSE_TOOLBAR);
export const openToolbarRequest = (context: ToolbarContext) => action(SearchActionTypes.OPEN_TOOLBAR, context);

export const runSearchRequest = (query: string) =>
    action(SearchActionTypes.RUN_SEARCH_REQUEST, [query] as [string]);

export const rootSearchRequest = (workspaceId: string, query: string) =>
    action(SearchActionTypes.ROOT_SEARCH_REQUEST, [workspaceId, query] as [string, string]);

export const setSearchRunning = ( isRunning:boolean ) =>
    action(SearchActionTypes.SEARCH_IS_RUNNING, [isRunning] as [boolean])

export const rootSearchRequestSuccess = (
    query: string,
    matchesCount: number,
    queryId: string,
    queryMetadata: SearchAndRelateSequenceMetaData,
) =>
    action(SearchActionTypes.ROOT_SEARCH_SUCCESS, [query, matchesCount, queryId, queryMetadata] as [
        string,
        number,
        string,
        SearchAndRelateSequenceMetaData,
    ]);

export const baseSearchRequest = (parameters: BaseSearchParameters) =>
    action(SearchActionTypes.BASE_SEARCH_REQUEST, parameters);

export const baseSearchRequesSuccess = (parameters: BaseSearchParameters, queryId: string, matchesCount: number, queryMetadata: SearchAndRelateSequenceMetaData) =>
    action(SearchActionTypes.BASE_SEARCH_SUCCESS, [parameters, queryId, matchesCount, queryMetadata] as [
        BaseSearchParameters,
        string,
        number,
        SearchAndRelateSequenceMetaData
    ]);

export const baseSearchRequestError = () =>
    action(SearchActionTypes.BASE_SEARCH_REQUEST_ERROR);

export const rootSearchRequestError = () =>
    action(SearchActionTypes.ROOT_SEARCH_REQUEST_ERROR);


export const quickFilterSearchRequest = (parameters: QuickFilterSearchParameters) =>
    action(SearchActionTypes.QUICK_FILTER_SEARCH_REQUEST, parameters);

export const quickFilterSearchRequestSuccess = (
    parameters: QuickFilterSearchParameters,
    data: QuickFilterResultViewData,
) =>
    action(SearchActionTypes.QUICK_FILTER_SEARCH_SUCCESS, [parameters, data] as [
        QuickFilterSearchParameters,
        QuickFilterResultViewData,
    ]);

export const explorerRequestError = () =>
    action(SearchActionTypes.EXPLORER_SEARCH_ERROR);

export const explorerRequestCanceled = () =>
    action(SearchActionTypes.EXPLORER_SEARCH_CANCELED);

export const alignmentSearchRequest = (parameters: AlignmentSearchParameters) =>
    action(SearchActionTypes.ALIGNMENT_SEARCH_REQUEST, parameters);

export const alignmentSearchError = () =>
    action(SearchActionTypes.ALIGNMENT_SEARCH_ERROR);

export const alignmentSearchCanceled = () =>
    action(SearchActionTypes.ALIGNMENT_SEARCH_CANCELED);

export const alignmentTooManyResults = () =>
    action(SearchActionTypes.ALIGNMENT_SEARCH_TOO_MANY_RESULTS);

export const alignmentSearchRequestSuccess = (parameters: AlignmentSearchParameters, data: AlignAlignResponse) =>
    action(SearchActionTypes.ALIGNMENT_SEARCH_SUCCESS, [parameters, data] as [
        AlignmentSearchParameters,
        AlignAlignResponse,
    ]);

export const explorerSearchRequest = (parameters: ExplorerSearchParameters) =>
    action(SearchActionTypes.EXPLORER_SEARCH_REQUEST, parameters);

export const explorerSearchRequestSuccess = (parameters: ExplorerSearchParameters, data: ExplorerResultViewData) =>
    action(SearchActionTypes.EXPLORER_SEARCH_SUCCESS, [parameters, data] as [
        ExplorerSearchParameters,
        ExplorerResultViewData,
    ]);

export const explorerSearchError = () =>
    action(SearchActionTypes.EXPLORER_SEARCH_ERROR);

export const explorerSearchCanceled = () =>
    action(SearchActionTypes.EXPLORER_SEARCH_CANCELED);


export const listSearchRequest = (parameters: ListSearchParameters) =>
    action(SearchActionTypes.LIST_SEARCH_REQUEST, parameters);

export const listSearchRequestSuccess = (
    parameters: ListSearchParameters,
    data: ListResultViewData,
    columns: ColumnConfig[],
) =>
    action(SearchActionTypes.LIST_SEARCH_SUCCESS, [parameters, data, columns] as [
        ListSearchParameters,
        ListResultViewData,
        ColumnConfig[],
    ]);

export const listSearchError = (message?: string) =>
    action(SearchActionTypes.LIST_SEARCH_ERROR, message);

export const listTooManyResults = () =>
    action(SearchActionTypes.LIST_SEARCH_TOO_MANY_RESULTS);

export const listSearchCanceled = () =>
    action(SearchActionTypes.LIST_SEARCH_CANCELED);

export const setCurrentDimensions = (query: string, currentDimensions: Dimension[]) =>
    action(SearchActionTypes.SET_CURRENT_DIMENSIONS, { query, currentDimensions });

export const setDimensions = (query: string, dimensions: Dimension[]) =>
    action(SearchActionTypes.SET_DIMENSIONS, { query, dimensions });

export const setRootQueryTaxonomy = (taxonomy: SearchAndRelateTaxonomyResponse) =>
    action(SearchActionTypes.SET_ROOT_QUERY_TAXONOMY, { taxonomy });

export const setCurrentTaxonomy = (taxonomy: SearchAndRelateTaxonomyResponse) =>
    action(SearchActionTypes.SET_CURRENT_QUERY_TAXONOMY, { taxonomy });

export const clearQuery = () => action(SearchActionTypes.CLEAR_QUERY);
