import { createBrowserHistory } from 'history';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import 'url-search-params-polyfill';
import Main from './app/main';
import configureStore from './app/store/configure-store';
import i18n from './i18n';
import './styles/global.scss';

const history = createBrowserHistory();
const store = configureStore(history);

ReactGA.initialize('UA-153265706-1', { gaOptions:{allowLinker: true}});

history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
});

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Main history={history} i18n={i18n} />
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
);
