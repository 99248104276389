import { ApplicationState } from '../index';
import { SharedMolType } from '../../api/generated';

export const queryMolTypeSelector = (state: ApplicationState):SharedMolType.AA | SharedMolType.DNA | undefined  => {
    const metadata = state.search.queryMetadata;
    if (metadata && metadata.data) {
        return metadata.data[0].molType as SharedMolType.AA | SharedMolType.DNA;
    }

    return undefined;
};

export const queryTranslationFrameSelector = (state: ApplicationState):string | undefined  => {
    const metadata = state.search.queryMetadata;
    if (metadata && metadata.data) {
        return metadata.data[0].translationFrame?.toString();
    }
    return undefined;
};

export const queryTranslationTableSelector = (state: ApplicationState):string | undefined  => {
    const metadata = state.search.queryMetadata;
    if (metadata && metadata.data) {
        return metadata.data[0].translationTable?.toString();
    }
    return undefined;
};

export const querySequenceSelector = (state: ApplicationState):string | undefined  => {
    const metadata = state.search.queryMetadata;
    if (metadata && metadata.data) {
        return metadata.data[0].sequence;
    }
    return undefined;
};

export const queryErrorSelector = (state: ApplicationState): boolean => {
    return !!(state.search.rootSearchRequestInError || state.search.baseSearchRequestInError);

};

