import { Http,WorkspaceResponse } from '../http/http';
import { ApiType,ApiUrl } from './api-urls';

export class DatasetPipelineApi {

    public static async getDatasetPipeline(datasetId: string): Promise<any> {
        return Http.get<WorkspaceResponse<any>>(
            ApiType.Gateway,
            ApiUrl.GetDatasetPipeline,
            { datasetId },
            { datasetId },
        );
    }
}
