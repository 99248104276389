import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { ReactElement, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceCreateNewApiTokenResponse } from '../../../../api/generated';
import OrganizationsApi from '../../../../api/organizations-api';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { addToast, ToastType } from '../../../../store/toast';
import { generateUUID } from '../../../../utils/uuid-helpers';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getOrganizationAdminMenuConfiguration } from '../organization-admin-menu.configuration';
import styles from './get-api-token-page.module.scss';

interface AllProps {}

function GetAPITokenPage(props: AllProps & WithTranslation): ReactElement {
    const { t } = props;

    const dispatch = useDispatch();
    const organizationId = useSelector((state: ApplicationState) => state.global.authorizationContext?.organization.id);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [tokenInfo, setTokenInfo] = useState<WorkspaceCreateNewApiTokenResponse | undefined>();

    const onGenerate = async () => {
        try {
            setIsGenerating(true);
            const result = await OrganizationsApi.createNewApiToken({ organizationId });

            setTokenInfo(result as WorkspaceCreateNewApiTokenResponse);
        } catch (e) {
            setTokenInfo(undefined);
        } finally {
            setIsGenerating(false);
        }
    };

    const onClientIDCopy = (event): void => {
        event.stopPropagation();
        if (tokenInfo && tokenInfo.clientId) {
            copy(tokenInfo.clientId);
            dispatch(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Info,
                    messages: [t('Client id is copied')],
                    removeAfterMilliseconds: 3000,
                }),
            );
        }
    };

    const onSecretKeyCopy = (event): void => {
        event.stopPropagation();
        if (tokenInfo && tokenInfo.clientSecret) {
            copy(tokenInfo.clientSecret);
            dispatch(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Info,
                    messages: [t('Client id is copied')],
                    removeAfterMilliseconds: 3000,
                }),
            );
        }
    };

    return (
        <div>
            <AdminPageHeader />
            <div className={styles.spacer} />
            <div className={styles.adminContainer}>
                <AdminMenu menuItems={getOrganizationAdminMenuConfiguration()} />
                <div className={styles.adminContent}>
                    <div className={styles.topPanel}>
                        <div className={styles.title}>{t('Integration API Token')}</div>
                        <div className={styles.buttonHolder}>
                            <button className={styles.button} type='button' onClick={() => onGenerate()}>
                                {t('Generate API Token')}
                            </button>
                            {isGenerating ? (
                                <div className={styles.loading}>
                                    <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                                    {t('Loading...')}
                                </div>
                            ) : null}
                        </div>
                        {tokenInfo ? (
                            <div className={styles.tokenInfo}>
                                <div className={styles.row}>
                                    <div>{t('Client ID:')}</div>
                                    <pre>{tokenInfo.clientId}</pre>
                                    <IconButton onClick={onClientIDCopy}>
                                        <FontAwesomeIcon icon={faClipboard} size='sm' />
                                    </IconButton>
                                </div>

                                <div className={styles.row}>
                                    <div>{t('Secret key:')}</div>
                                    <pre>{tokenInfo.clientSecret}</pre>
                                    <IconButton onClick={onSecretKeyCopy}>
                                        <FontAwesomeIcon icon={faClipboard} size='sm' />
                                    </IconButton>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(GetAPITokenPage);
