import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { conditionalClassList } from '../../../../../../utils/class-helpers';
import { generateUUID } from '../../../../../../utils/uuid-helpers';
import styles from './results-list-paging.module.scss';

class ResultsListPaging extends React.Component<AllProps, AllState> {
    private readonly placeholderPage = -1;

    constructor(props: AllProps) {
        super(props);

        this.state = {
            from: 0,
            to: 0,
            pageList: [],
            hasPreviousPage: false,
            hasNextPage: true,
        };
    }

    public componentDidMount(): void {
        const { page, pageSize, total } = this.props;

        const newState = this.buildState(page, pageSize, total);

        this.setState(newState);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { page, pageSize, total } = this.props;

        if (page !== prevProps.page || pageSize !== prevProps.pageSize) {
            const newState = this.buildState(page, pageSize, total);

            this.setState(newState);
        }
    }

    public render(): JSX.Element {
        const { page, total, t } = this.props;
        const { from, to, pageList } = this.state;

        return (
            <div className={styles.row}>
                <div className={styles.cell}>
                    <span className={styles.page}>
                        <span className={styles.navigation} onClick={(): void => this.onPreviousPage()}>
                            {t('Previous')}
                        </span>
                        <span className={styles.pageListContainer}>
                            {pageList.map((pageNumber) => {
                                const pageClasses = conditionalClassList(styles, {
                                    placeholderNumber: pageNumber === this.placeholderPage,
                                    pageNumber: pageNumber !== this.placeholderPage,
                                    pageNumberActive: pageNumber === page,
                                });
                                return (
                                    <div
                                        key={generateUUID()}
                                        className={pageClasses}
                                        onClick={(): void => this.onJumpToPage(pageNumber)}>
                                        {pageNumber === this.placeholderPage ? '...' : pageNumber}
                                    </div>
                                );
                            })}
                        </span>
                        <span className={styles.navigation} onClick={(): void => this.onNextPage()}>
                            {t('Next')}
                        </span>
                    </span>
                    <span className={styles.range}>
                        {t('Showing {{from}} - {{to}} out of {{total}}', { from, to, total })}
                    </span>
                </div>
            </div>
        );
    }

    private onPreviousPage(): void {
        const { page, onChangePage } = this.props;
        const { hasPreviousPage } = this.state;

        if (hasPreviousPage) {
            onChangePage(page - 1);
        }
    }

    private onNextPage(): void {
        const { page, onChangePage } = this.props;
        const { hasNextPage } = this.state;

        if (hasNextPage) {
            onChangePage(page + 1);
        }
    }

    private onJumpToPage(pageNumber: number): void {
        const { onChangePage } = this.props;

        if (pageNumber !== this.placeholderPage) {
            onChangePage(pageNumber);
        }
    }

    private buildState(page: number, pageSize: number, total: number): OwnState {
        const from = 1 + page * pageSize - pageSize;
        const to = Math.min(page * pageSize, total);
        const numberOfPages = Math.ceil(total / pageSize);
        const pageList = this.buildPageList(page, numberOfPages);
        const hasPreviousPage = page > 1;
        const hasNextPage = to < total;

        return {
            from,
            to,
            pageList,
            hasPreviousPage,
            hasNextPage,
        };
    }

    private buildPageList(currentPage: number, numberOfPages: number): number[] {
        if (numberOfPages <= 6) {
            return Array.from({ length: numberOfPages }, (page, index) => index + 1);
        } else if (currentPage < 4) {
            return [1, 2, 3, 4, this.placeholderPage, numberOfPages];
        } else if (currentPage + 2 >= numberOfPages) {
            return [1, this.placeholderPage, numberOfPages - 3, numberOfPages - 2, numberOfPages - 1, numberOfPages];
        } else {
            return [
                1,
                this.placeholderPage,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                this.placeholderPage,
                numberOfPages,
            ];
        }
    }
}

export default withTranslation()(ResultsListPaging);

interface OwnProps {
    page: number;
    pageSize: number;
    total: number;
    onChangePage: (newPage: number) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    from: number;
    to: number;
    pageList: number[];
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

type AllState = OwnState;
