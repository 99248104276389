import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import {
    DeleteUserRequestParameters,
    InviteUserRequestParameters,
    UpdateUserRequestParameters,
    UserInfo,
    UsersActionTypes,
} from './types';

export const getUsersForOrganizationRequest = (organizationId: string) =>
    action(UsersActionTypes.GET_USERS_FOR_ORGANIZATION_REQUEST, organizationId);
export const getUsersForOrganizationSuccess = (users: UserInfo[]) =>
    action(UsersActionTypes.GET_USERS_FOR_ORGANIZATION_SUCCESS, users);
export const getUsersForOrganizationFailure = (errorCode?: FailureCodes) =>
    action(UsersActionTypes.GET_USERS_FOR_ORGANIZATION_FAILURE, errorCode);

export const updateUserRequest = (parameters: UpdateUserRequestParameters) =>
    action(UsersActionTypes.UPDATE_USER_REQUEST, parameters);
export const updateUserSuccess = (user: UserInfo) => action(UsersActionTypes.UPDATE_USER_SUCCESS, user);
export const updateUserFailure = (errorCode?: FailureCodes) => action(UsersActionTypes.UPDATE_USER_FAILURE, errorCode);

export const inviteUserRequest = (parameters: InviteUserRequestParameters) =>
    action(UsersActionTypes.INVITE_USER_REQUEST, parameters);
export const inviteUserSuccess = (user: UserInfo) => action(UsersActionTypes.INVITE_USER_SUCCESS, user);
export const inviteUserFailure = (errorCode?: FailureCodes) => action(UsersActionTypes.INVITE_USER_FAILURE, errorCode);

export const deleteUserRequest = (parameters: DeleteUserRequestParameters) =>
    action(UsersActionTypes.DELETE_USER_REQUEST, parameters);
export const deleteUserSuccess = (email: string) => action(UsersActionTypes.DELETE_USER_SUCCESS, email);
export const deleteUserFailure = (errorCode?: FailureCodes) => action(UsersActionTypes.DELETE_USER_FAILURE, errorCode);
