import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { push } from 'connected-react-router';

import { FilterActionTypes } from './filter-types';
import { removeFilterItem, updateFilters } from './filter-actions';
import { ApplicationState } from '../index';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { QueryParameter, updateQueryParameters } from '../../utils/query-parameter-helpers';

function* handleUpdateFiltersRequest(action: ActionType<typeof updateFilters>) {
    try {
        const newFilters = action.payload;
        const currentLocation = yield select( ( state: ApplicationState ) => state.router.location);
        const urlSearchParameters = new URLSearchParams(currentLocation.search);
        const encodedFilters = btoa(unescape(encodeURIComponent(JSON.stringify(newFilters))));
        const parameters = {};
        parameters[QueryParameter.RootQuery] = urlSearchParameters.get(QueryParameter.RootQuery);
        parameters[QueryParameter.GlobalFilters] = encodedFilters;
        parameters[QueryParameter.AlignmentViewPage] = 1;
        parameters[QueryParameter.ListViewPage] = 1;

        yield put(push({
            search: updateQueryParameters(parameters, currentLocation.search),
        }))
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* handleRemoveFilterItemRequest(action: ActionType<typeof removeFilterItem>) {
    try {
        const filterName = action.payload;
        const currentLocation = yield select( ( state: ApplicationState ) => state.router.location);
        const urlSearchParameters = new URLSearchParams(currentLocation.search);
        const currentFilters = JSON.parse(atob(urlSearchParameters.get(QueryParameter.GlobalFilters) || '') || '[]');
        const newFilters = currentFilters.filter( f => f.dimensionName !== filterName );
        const encodedFilters = btoa(unescape(encodeURIComponent(JSON.stringify(newFilters))));

        const parameters = {};
        parameters[QueryParameter.RootQuery] = urlSearchParameters.get(QueryParameter.RootQuery);
        parameters[QueryParameter.GlobalFilters] = encodedFilters;

        yield put(push({
            search: updateQueryParameters(parameters, currentLocation.search),
        }))
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchFilterRequests() {
    yield takeEvery(FilterActionTypes.UPDATE_FILTERS, handleUpdateFiltersRequest);
    yield takeEvery(FilterActionTypes.REMOVE_FILTER_ITEM, handleRemoveFilterItemRequest);
}

function* filterSaga() {
    yield all([
        fork(watchFilterRequests)
    ]);
}

export default filterSaga;
