import { Button, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchHistory } from '../../../../components/page-header/search-page-header/search-history-actions';
import SearchHistoryCard from '../../../../components/page-header/search-page-header/search-history-card';
import { ApplicationState } from '../../../../store';
import { runSearchRequest, SearchHistoryEntry } from '../../../../store/search';
import { getSearchSettings, updateSearchSettings } from '../../../../utils/local-storage-helpers';
import SearchExamples from './search-examples';
import styles from './search-history.module.scss';

const RESULTS_TO_SHOW = 8;

function SearchHistory(props: AllProps) {
    const { history, t, canRunAgain, onSearch } = props;
    const [hideExamples, setHideExamples] = useState(false);

    const dispatch = useDispatch();
    const workspaceId = useSelector<string>((state: ApplicationState) => state.global.activeWorkspaceId);

    const firstResults = history ? history?.slice(0, RESULTS_TO_SHOW) : [];

    useEffect(() => {
        const settings = getSearchSettings(workspaceId);
        if (settings) {
            setHideExamples(!!settings.hideSearchExamples);
        }
    }, [workspaceId]);

    const search = (entry: SearchHistoryEntry): void => {
        dispatch(runSearchRequest(entry.query));
    };

    const onClearHistory = () => {
        dispatch(clearSearchHistory());
    };

    const onHideExamples = () => {
        setHideExamples(true);
        const settings = getSearchSettings(workspaceId);
        if (settings) {
            updateSearchSettings(workspaceId, {
                ...settings,
                hideSearchExamples: true,
            });
        }
    };

    const onShowExamples = () => {
        setHideExamples(false);
        const settings = getSearchSettings(workspaceId);
        if (settings) {
            updateSearchSettings(workspaceId, {
                ...settings,
                hideSearchExamples: false,
            });
        }
    };

    return (
        <div className={styles.container}>
            <Typography
                variant='h6'
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>
                {t('Previous Searches')}
            </Typography>

            {history && history.length > 0 ? (
                <>
                    <div className={styles.subtitle}>
                        {t('Showing {{amount}} most recent out of {{total}}', {
                            amount: history.length > RESULTS_TO_SHOW ? RESULTS_TO_SHOW : history.length,
                            total: history.length,
                        })}
                        <span style={{ marginLeft: 32, color: '#aaa' }}>/</span>
                        <Button style={{ color: '#aaa' }} onClick={onClearHistory}>
                            clear history
                        </Button>
                    </div>
                    <div className={styles.entriesContainer}>
                        {firstResults.map((entry) => {
                            return (
                                <SearchHistoryCard
                                    key={entry.date}
                                    entry={entry}
                                    enabled={canRunAgain}
                                    onClick={(): void => search(entry)}
                                />
                            );
                        })}
                    </div>
                </>
            ) : (
                <div
                    style={{
                        minHeight: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                    <Typography variant='subtitle1'>
                        {t('Your search history for the workspace will show up here, but is empty at the moment.')}
                    </Typography>
                    <Typography variant='subtitle1'>{t('You can start fom the examples below')}</Typography>
                </div>
            )}

            <div className={styles.subtitle}>
                <Typography
                    variant='h6'
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}>
                    {t('Search query examples')}
                </Typography>
                <span style={{ marginLeft: 32, color: '#aaa', marginRight: 8 }}>/</span>
                {hideExamples ? (
                    <Button style={{ color: '#aaa' }} onClick={onShowExamples}>
                        show examples
                    </Button>
                ) : (
                    <Button style={{ color: '#aaa' }} onClick={onHideExamples}>
                        hide
                    </Button>
                )}
            </div>
            {hideExamples ? null : <SearchExamples onSearch={onSearch} canRunAgain={canRunAgain} />}
        </div>
    );
}

export default withTranslation()(SearchHistory);

interface OwnProps {
    canRunAgain: boolean;
    onSearch: (query: string) => void;
    history?: SearchHistoryEntry[];
}

type AllProps = OwnProps & WithTranslation;
