import axios, { AxiosRequestConfig } from 'axios';
import { ApiType, buildBaseUrl } from '../api/api-urls';
import AuthenticationApi from '../api/authentication-api';

export class Http {
    public static async get<T>(
        apiType: ApiType,
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(apiType, path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.get<T>(url, configuration);
        return result.data;
    }

    public static async post<TBody, T>(
        apiType: ApiType,
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(apiType, path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.post<T>(url, body, configuration);
        return result.data;
    }

    public static async put<TBody, T>(
        apiType: ApiType,
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(apiType, path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.put<T>(url, body, configuration);
        return result.data;
    }

    public static async patch<TBody, T>(
        apiType: ApiType,
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(apiType, path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.patch<T>(url, body, configuration);
        return result.data;
    }

    public static async delete<T>(
        apiType: ApiType,
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(apiType, path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.delete<T>(url, configuration);
        return result.data;
    }

    public static buildUrl(apiType: ApiType, path: string, parameters?: { [key: string]: string | number }): string {
        const url = `${buildBaseUrl(apiType)}${path}`;
        if (parameters) {
            return Object.keys(parameters).reduce((urlWithParameters, parameterKey) => {
                return urlWithParameters.replace(`{${parameterKey}}`, parameters[parameterKey].toString());
            }, url);
        }
        return url;
    }

    public static async buildHeaders(headers?: {
        [key: string]: string | number;
    }): Promise<{ [key: string]: string | number }> {
        const token = await AuthenticationApi.getAccessToken();

        if (token) {
            const baseHeaders = {
                Authorization: `Bearer ${token}`,
            };
            if (headers) {
                return { ...baseHeaders, ...headers };
            }
            return baseHeaders;
        } else {
            return headers || {};
        }
    }
}

export interface WorkspaceResponse<T> {
    data: T;
    error: string;
}
