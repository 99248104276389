import { Location } from 'history';
import { QueryParameter, QueryParameterHelper, QueryParameterMap } from '../../../../utils/query-parameter-helpers';
import {
    defaultExplorerColumnDimensionSelection,
    defaultExplorerRowDimensionSelection,
} from '../../constants/dimension-constants';
import { ResultViewUrlParameters } from '../../result-view-url-parameters';
import { UrlParametersHandler } from '../../url-parameters-handler';

export class ExplorerUrlParametersHandler extends UrlParametersHandler<ExplorerResultViewUrlParameters> {
    public read(currentLocation: Location): ExplorerResultViewUrlParameters {
        const base = super.doRead(currentLocation);
        const urlSearchParameters = new URLSearchParams(currentLocation.search);

        const activeRowSelection =
            QueryParameterHelper.getEncodedObjectFromUrl<string[]>(urlSearchParameters, QueryParameter.ExplorerRow) ||
            defaultExplorerRowDimensionSelection;
        const activeColumnSelection =
            QueryParameterHelper.getEncodedObjectFromUrl<string[]>(
                urlSearchParameters,
                QueryParameter.ExplorerColumn,
            ) || defaultExplorerColumnDimensionSelection;

        return { ...base, activeRowSelection, activeColumnSelection };
    }

    public write(newUrlState: ExplorerResultViewUrlParameters): QueryParameterMap {
        const resultViewParameters = super.doWrite(newUrlState);
        const encodedRowSelection = btoa(unescape(encodeURIComponent(JSON.stringify(newUrlState.activeRowSelection))));
        resultViewParameters[QueryParameter.ExplorerRow] = encodedRowSelection;
        const encodedColumnSelection = btoa(
            unescape(encodeURIComponent(JSON.stringify(newUrlState.activeColumnSelection))),
        );
        resultViewParameters[QueryParameter.ExplorerColumn] = encodedColumnSelection;

        return resultViewParameters;
    }
}

export interface ExplorerResultViewUrlParameters extends ResultViewUrlParameters {
    activeRowSelection: string[];
    activeColumnSelection: string[];
}
