import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import capitalize from 'lodash.capitalize';
import { conditionalClassList } from '../../../../../../utils/class-helpers';
import styles from './column-configuration-popup.module.scss';
import { ColumnConfig } from '../results-list/column-config';

class ColumnConfigurationPopupComponent extends Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);
        const {selectedColumns} = this.props;

        this.state = {
            selection: [...selectedColumns],
        };
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const { availableColumns } = this.props;

        const { selection } = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.caption}>{t('Please select the fields to include')}</div>
                        <div style={{maxHeight: 400, overflowY: 'auto'}}>
                            {availableColumns.map((column) => {
                                const selectionIndex = selection.findIndex(s => s.key === column.key);
                                const isChecked = selectionIndex !== -1;
                                const itemClasses = conditionalClassList(styles, {
                                    column: true,
                                    checked: isChecked,
                                });

                                return (
                                    <div className={itemClasses} key={column.key} onClick={(): void => this.onToggle(column, selectionIndex)}>
                                        {isChecked ? (
                                            <FontAwesomeIcon
                                                size='lg'
                                                icon={faCheckSquare}
                                                className={styles.columnIcon}
                                            />
                                        ) : (
                                            <FontAwesomeIcon size='lg' icon={faSquare} className={styles.columnIcon} />
                                        )}
                                        <span>{capitalize(t(column.label))}</span>

                                        {column.computed ? <><span style={{color: '#aaa', paddingLeft:4}}>({t('computed')})</span></> : null}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.popupButtonsContainer}>
                        <button
                            className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onCancel()}>
                            {t('Cancel')}
                        </button>
                        <button
                            className={`${styles.button} ${styles.popupButton}`}
                            type='button'
                            onClick={(): void => this.onApply()}>
                            {t('Apply')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onToggle(column: ColumnConfig, selectionIndex: number): void {
        const {selection} = this.state;

       if (selectionIndex === -1) {
           selection.push(column);
       } else {
           selection.splice(selectionIndex, 1);
       }

       this.setState({selection});
    }

    private onApply(): void {
        const { onApply } = this.props;
        const { selection } = this.state;

        onApply(selection);
    }

    private onCancel(): void {
        const { onCancel } = this.props;

        onCancel();
    }
}

const ColumnConfigurationPopup = withTranslation()(ColumnConfigurationPopupComponent);

export default ColumnConfigurationPopup

interface OwnProps {
    availableColumns: ColumnConfig[];
    selectedColumns: ColumnConfig[];
    onApply: (columns: ColumnConfig[]) => void;
    onCancel: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    selection: ColumnConfig[];
}

type AllState = OwnState;
