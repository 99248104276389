import { Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TaxonomyCollapsedItems, TaxonomyLevelItem } from './taxonomy-tree-types';
import { MINIMAL_ITEM_SIZE } from './taxonomy-tree-utils';

const PIXELS_PER_CHARACTER_PER_FONTSIZE = {
    10: 5.5,
    11: 6,
    12: 6.7,
};

const getLabel = (item: TaxonomyLevelItem, fontSize: number, itemWidth: number): string => {
    const label = `${item.name} (${item.value})`;
    const ppc = PIXELS_PER_CHARACTER_PER_FONTSIZE[fontSize];

    const maxLength = itemWidth / ppc;
    if (label.length > maxLength) {
        return `${label.substring(0, 5)}…${label.substr(-(maxLength - 6))}`;
    }

    return label;
};

interface ItemRendererProps {
    itemWidth: number;
    selected?: boolean;
    onClick?: any;
    onDoubleClick?: any;
}

interface RegularItemRendererProps extends ItemRendererProps {
    item: TaxonomyLevelItem;
}

interface CollapsedItemRendererProps extends ItemRendererProps {
    item: TaxonomyCollapsedItems;
}

const getTooltipText = (items: TaxonomyLevelItem[]) => {

    const first50 = items.slice(1, 50).map((li) => `${li.name}(${li.value})`).join(', ');

    if (items.length > 50) {
        return `${first50} and ${items.length - 50} more items`;
    }
    return first50;
};

export const RegularItemRenderer = (props: RegularItemRendererProps) => {
    const { item, itemWidth, selected, onClick, onDoubleClick } = props;
    const height = (item.height || 1) - 1;
    const fontSize = height < MINIMAL_ITEM_SIZE + 1 ? 10 : height < MINIMAL_ITEM_SIZE + 3 ? 11 : 12;
    const label = getLabel(item, fontSize, itemWidth);

    const handleClick = (event) => {
        if (onClick) onClick(event, [item.id]);
    };

    const handleDoubleClick = (event) => {
        if (onDoubleClick) onDoubleClick(event, [item.id]);
    };

    return (
        <g onClick={handleClick} onDoubleClick={handleDoubleClick}>
            <rect
                x={item.depth * (itemWidth + 5)}
                fill={item.color}
                height={height}
                y={item.y || 0}
                width={itemWidth}
                opacity={selected ? 0.9 : 0.4}
            />
            <text fill='#000' x={item.depth * (itemWidth + 5) + 5} y={(item.y || 1) + fontSize - 1} fontSize={fontSize}>
                {label}
            </text>
        </g>
    );
};

export const CollapsedItemRenderer = (props: CollapsedItemRendererProps) => {
    const { item, itemWidth, selected, onClick, onDoubleClick } = props;

    const [ghostItem, setGhostItem] = useState<undefined | TaxonomyLevelItem>();

    useEffect(() => {
        if (item.items.length === 1) {
            setGhostItem({ ...item.items[0], id: item.id, y: item.y, height: item.height });
        } else {
            setGhostItem(undefined);
        }
    }, [item]);

    const handleClick = (event) => {
        if (onClick) onClick(event, item.items.map(itm => itm.id));
    };

    const handleDoubleClick = (event) => {
        if (onDoubleClick) onDoubleClick(event, item.items.map(itm => itm.id));
    };

    if (ghostItem) {
        return (
            <RegularItemRenderer
                item={ghostItem}
                itemWidth={itemWidth}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                selected={selected}
            />
        );
    }
    const height = (item.height || 1) - 1;
    const fontSize = height < MINIMAL_ITEM_SIZE + 1 ? 10 : height < MINIMAL_ITEM_SIZE + 3 ? 11 : 12;


    return (
        <Tooltip title={getTooltipText(item.items)} placement='right'>
            <g onDoubleClick={handleDoubleClick} onClick={handleClick}>
                <rect
                    x={item.depth * (itemWidth + 5)}
                    fill={item.color}
                    height={height}
                    y={item.y || 0}
                    width={itemWidth}
                    opacity={selected ? 0.9 : 0.4}
                />
                <text
                    fill='#000'
                    width={itemWidth}
                    textAnchor='end'
                    x={item.depth * (itemWidth + 5) + itemWidth - 5}
                    y={(item.y || 1) + fontSize - 1}
                    fontSize={fontSize}>
                    {item.name}
                </text>
            </g>
        </Tooltip>
    );
};
