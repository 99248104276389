import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CheckCircleOutline, RadioButtonUnchecked } from '@material-ui/icons';

import { conditionalClassList } from '../../../../../utils/class-helpers';
import { QuickFilterElement } from '../types/quick-filter-model';
import styles from './quick-filter-item.module.scss';

class QuickFilterItemComponent extends Component<AllProps> {
    private availableWidth = 200;

    public render(): JSX.Element {
        const { quickFilterItem, rowColor, selected } = this.props;

        const isGrouping = !!quickFilterItem.relatedElements && quickFilterItem.relatedElements.length > 0;

        const elementClasses = conditionalClassList(styles, {
            elementContainer: true,
            excluded: !selected,
            others: isGrouping,
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line no-restricted-globals
        const flexWidth = this.availableWidth * ( isFinite(quickFilterItem.ratio || 0) ?  quickFilterItem.ratio : 0);

        return (
            <div
                className={elementClasses}
                onClick={(): void => this.onSingleClick()}
                onDoubleClick={(): void => this.onDoubleClick()}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        {quickFilterItem.label}
                        {isGrouping ? (
                            <span className={styles.otherCount}>({quickFilterItem.relatedElements!.length})</span>
                        ) : null}
                    </div>
                    <div>
                        <span className={styles.highlight}>
                            {quickFilterItem.count.toString()} matches
                        </span>
                    </div>
                    {isGrouping ? null : (
                        <div className={styles.indicator}>
                            <div className={styles.fill} style={{ width: flexWidth, backgroundColor: rowColor }} />
                        </div>
                    )}

                    {!selected
                        ? <RadioButtonUnchecked width={32} height={32} style={{position: 'absolute', color: '#999999', top:8, right:8}} />
                        : <CheckCircleOutline width={32} height={32} style={{position: 'absolute', color: '#999999', top:8, right:8}} />}
                </div>
            </div>
        );
    }

    public onDoubleClick(): void {
        const { quickFilterItem, onDoubleClick } = this.props;
        onDoubleClick(quickFilterItem.uid);
    }

    public onSingleClick(): void {
        const { quickFilterItem, onClick } = this.props;
        onClick(quickFilterItem.uid);
    }
}

interface OwnProps {
    quickFilterItem: QuickFilterElement;
    onClick: (uid: string) => void;
    onDoubleClick: (uid: string) => void;
    rowColor: string;
    selected: boolean;
}

export default withTranslation()(QuickFilterItemComponent);

type AllProps = OwnProps & WithTranslation;
