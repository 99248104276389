import { action } from 'typesafe-actions';
import { SearchHistoryEntry } from '../../../store/search';


export enum SearchHistoryActionTypes {
    CLEAR_SEARCH_HISTORY= '@search-history/CLEAR_SEARCH_HISTORY',
    SET_SEARCH_HISTORY = '@search-history/SET_SEARCH_HISTORY'

}

export const clearSearchHistory = () => action(SearchHistoryActionTypes.CLEAR_SEARCH_HISTORY);
export const setSearchHistory = (history?: SearchHistoryEntry[]) => action(SearchHistoryActionTypes.SET_SEARCH_HISTORY, history);
