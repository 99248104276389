import {
    Dimension,
    DimensionFilter, DimensionWithAllElements,
    TAXONOMY_TREE_DIMENSION,
} from '../../../../store/search';

const getTaxonomyNames = (taxonomies: string[]): string => {
    return taxonomies.map( item => {
        const parts = item.split('::');
        return parts.pop();
    } ).join(',');
}

export class FilterSummaryCreator {
    public static createSummaries(filters: DimensionFilter[], dimensions: Dimension[], t: any): FilterSummary[] {
        const summaries: FilterSummary[] = [];
        filters.forEach((filter) => {
            const dimension = dimensions.find((d) => d.name === filter.dimensionName);

            if (filter.dimensionName === TAXONOMY_TREE_DIMENSION) {
                const taxonomy = filter.includes; //  && filter.includes[0];

                if (taxonomy) {
                    const parts = taxonomy[0].split('::');
                    const value = parts.pop();
                    const level = parts.pop();
                    if (taxonomy.length === 1) {
                        summaries.push({
                            name: TAXONOMY_TREE_DIMENSION,
                            displayName: ``,
                            operationLabel: '',
                            elementsLabel: `${level} is ${value}`,
                        });
                    } else {
                        summaries.push({
                            name: TAXONOMY_TREE_DIMENSION,
                            displayName: ``,
                            operationLabel: '',
                            elementsLabel: `${level} is ${value} (+${taxonomy.length-1})`,
                            tooltip: `${level} in [${getTaxonomyNames(taxonomy)}]`,
                        });
                    }
                }
            } else if (dimension) {
                const summary = FilterSummaryCreator.createSummary(filter, dimension, t);
                if (summary) {
                    summaries.push(summary);
                }
            }
        });
        return summaries;
    }

    private static createSummary(filter: DimensionFilter, dimension: Dimension, t: any): FilterSummary | undefined {
        if ((dimension as DimensionWithAllElements).elements) {
            if (filter && filter.includes && filter.includes.length > 0) {
                const useIncludes = filter.includes.length <= (dimension as DimensionWithAllElements).elements.length / 2;

                if (useIncludes) {
                    return FilterSummaryCreator.createIncludesSummary(filter, dimension, t);
                } else {
                    return FilterSummaryCreator.createExcludesSummary(filter, (dimension as DimensionWithAllElements), t);
                }
            }
        } else {
            if (filter.elementNamesContains) {
                return filter.excludesAll
                    ? {
                          name: dimension.name,
                          displayName: dimension.displayName,
                          operationLabel: t('NOT CONTAINS'),
                          elementsLabel: filter.elementNamesContains,
                          tooltip: `${dimension.displayName} ${t('NOT CONTAINS')} ${filter.elementNamesContains}`,

                    }
                    : {
                          name: dimension.name,
                          displayName: dimension.displayName,
                          operationLabel: t('CONTAINS'),
                          elementsLabel: filter.elementNamesContains,
                          tooltip: `${dimension.displayName} ${t('CONTAINS')} ${filter.elementNamesContains}`,
                      };

            }

            if (filter.includes) {
                return FilterSummaryCreator.createIncludesSummary(filter, dimension, t);
            }
        }
        return undefined;
    }

    private static createIncludesSummary(filter: DimensionFilter, dimension: Dimension, t: any): FilterSummary {
        let summary;
        if (filter && filter.includes && filter.includes.length > 0) {
            const all = filter.includes.join(', ');
            const tooltip = `${dimension.displayName} ${t('IS')} ${all}`;
            const elementsLabel =
                filter.includes.length > 1
                    ? `${filter.includes[0]} (+${filter.includes.length - 1})`
                    : filter.includes[0];
            summary = {
                name: dimension.name,
                displayName: dimension.displayName,
                operationLabel: t('IS'),
                elementsLabel,
                tooltip,
            };
        }
        return summary;
    }

    private static createExcludesSummary(
        filter: DimensionFilter,
        dimension: DimensionWithAllElements,
        t: any,
    ): FilterSummary {
        const excludes = dimension.elements.filter((element) => !filter.includes?.includes(element.name));
        let elementsLabel = '';
        let tooltip = '';
        if (excludes.length === 0) {
            elementsLabel = 'None';
        } else {
            elementsLabel = excludes.length > 1 ? `${excludes[0].name} (+${excludes.length - 1})` : excludes[0].name;
            const all = excludes.map((e) => e.name);
            tooltip = `${dimension.displayName} ${t('NOT')} ${all.join(', ')}`;
        }

        return {
            name: dimension.name,
            displayName: dimension.displayName,
            operationLabel: t('NOT'),
            elementsLabel,
            tooltip,
        };
    }
}

export interface FilterSummary {
    name: string;
    displayName: string;
    operationLabel: string;
    elementsLabel: string;
    tooltip?: string;
    filter?: DimensionFilter;
    dimension?: Dimension;
}
