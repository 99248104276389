import { faCopy, faSearch, faTimes, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import biostrandApi from '../../../../../../api/biostrand-api';
import { ApiGatewayGetSequenceField, ApiGatewayGetSequenceResponse } from '../../../../../../api/generated';
import { runSearchRequest, SequenceFields } from '../../../../../../store/search';
import { addToast, Toast, ToastType } from '../../../../../../store/toast';
import { generateUUID } from '../../../../../../utils/uuid-helpers';
import styles from './sequence-details-popup.module.scss';

interface Props {
    sequenceId: string;
    onCopiedToClipboard?: (toast: Toast) => void;
    onClose: () => void;
}

export const SequenceDetailsPopup = (props: Props) => {
    const excludedFromPopup: string[] = [SequenceFields.sequenceId, SequenceFields.url];
    const { sequenceId, onClose } = props;
    const { t } = useTranslation();
    const [sequenceInfo, setSequenceInfo] = useState<ApiGatewayGetSequenceResponse | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadSeq = async () => {
            setIsLoading(true);
            if (sequenceId) {
                try {
                    const result = await biostrandApi.biostrandGatewayGetSequence(sequenceId);
                    setSequenceInfo(result.data);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadSeq();
    }, [sequenceId]);

    const onSequenceSearch = (sequenceString: string) => {
        dispatch(runSearchRequest(sequenceString));
        onClose();
    };

    const onCopySequenceToClipboard = async (sequenceString: string): Promise<void> => {
        try {
            await copy(sequenceString);
            dispatch(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Info,
                    messages: ['Sequence was copied to clipboard.'],
                    removeAfterMilliseconds: 1000,
                }),
            );
        } catch (error) {
            dispatch(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Error,
                    messages: ['Failed to copy sequence to clipboard.'],
                }),
            );
        }
    };

    const onOpenExternalLink = (url: string): void => {
        window.open(url, '_blank');
    };

    if (!sequenceInfo) return <div>sequenceInfo </div>;

    const fields: ApiGatewayGetSequenceField[] = sequenceInfo.fields ? sequenceInfo.fields : [];
    const { sequence } = sequenceInfo;

    if (!fields || !sequence || isLoading) return <div>(!fields || !sequence || isLoading)</div>;

    const urlValue = fields.find((f) => f.name === SequenceFields.url);

    const sequenceUrl = urlValue && urlValue.value ? urlValue.value[0] : '';

    return (
        <div className={styles.popupContainer}>
            <div className={styles.popupBody}>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.closeIcon}
                    size='lg'
                    onClick={(): void => onClose()}
                />
                <div className={styles.popupContent}>
                    <>
                        <div className={styles.title}>
                            <div className={styles.highlight}>{sequenceId}</div>
                            { }
                        </div>
                        <div className={styles.fields}>
                            {fields
                                .filter((f) => !excludedFromPopup.includes(f.name || ''))
                                .map((f) => {
                                    return f.value ? (
                                        <div key={f.name} className={styles.field}>
                                            <div className={styles.label}>{t(f.displayName || '')}</div>
                                            <div className={styles.value}>{f.value ? f.value.join(', ') : ''}</div>
                                        </div>
                                    ) : null;
                                })}
                            {sequence ? (
                                <div className={styles.field}>
                                    <div className={styles.label}>{t('Sequence')}</div>
                                    <div className={styles.sequencePanel}>
                                        <div className={styles.valuePanel} title={sequence}>
                                            {sequence}
                                        </div>
                                        <Button
                                            color='primary'
                                            startIcon={<FontAwesomeIcon icon={faSearch} size='sm' />}
                                            onClick={(): void => onSequenceSearch(sequence)}>
                                            {t('search')}
                                        </Button>
                                        <Button
                                            color='primary'
                                            onClick={(): Promise<void> => onCopySequenceToClipboard(sequence)}
                                            startIcon={<FontAwesomeIcon icon={faCopy} size='sm' />}>
                                            {t('copy')}
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className={styles.popupButtonsContainer}>
                            {sequenceUrl ? (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    style={{ marginRight: 16, paddingRight: 8, paddingLeft: 8 }}
                                    onClick={(): void => {
                                        onOpenExternalLink(sequenceUrl);
                                    }}
                                    endIcon={<FontAwesomeIcon icon={faExternalLink} style={{marginLeft: 2, fontSize: 14}} />}
                                >
                                    {t('Open externally')}
                                </Button>
                            ) : null}

                            <Button variant='outlined' color='primary' onClick={(): void => onClose()}>
                                {t('Close')}
                            </Button>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};
