import { ActionType } from 'typesafe-actions';
import { OrganizationRole } from '../global';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum AuthenticationActionTypes {
    LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
    LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
    LOGIN_NEW_PASSWORD_REQUIRED = '@@auth/LOGIN_NEW_PASSWORD_REQUIRED',
    LOGIN_FAILURE = '@@auth/LOGIN_FAILURE',
    LOGOUT_REQUEST = '@@auth/LOGOUT_REQUEST',
    CONFIRM_NEW_PASSWORD_REQUEST = '@@auth/CONFIRM_NEW_PASSWORD_REQUEST',
    CONFIRM_NEW_PASSWORD_SUCCESS = '@@auth/CONFIRM_NEW_PASSWORD_SUCCESS',
    CONFIRM_NEW_PASSWORD_FAILURE = '@@auth/CONFIRM_NEW_PASSWORD_FAILURE',
    COMPLETE_PASSWORD_CHALLENGE_REQUEST = '@@auth/COMPLETE_PASSWORD_CHALLENGE_REQUEST',
    COMPLETE_PASSWORD_CHALLENGE_SUCCESS = '@@auth/COMPLETE_PASSWORD_CHALLENGE_SUCCESS',
    COMPLETE_PASSWORD_CHALLENGE_FAILURE = '@@auth/COMPLETE_PASSWORD_CHALLENGE_FAILURE',
    FORGOT_PASSWORD_REQUEST = '@@auth/RESET_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE = '@@auth/RESET_PASSWORD_FAILURE',
    INITIALIZATION_COMPLETE = '@@auth/INITIALIZATION_COMPLETE',
}

export type AuthenticationAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface AuthenticationState {
    readonly loginRequestStatus?: RequestStatus;
    readonly confirmPasswordRequestStatus?: RequestStatus;
    readonly forgotPasswordRequestStatus?: RequestStatus;
}

export interface ConfirmPasswordParameters {
    email: string;
    confirmationCode: string;
    newPassword: string;
}

export interface PasswordChallengeParameters {
    email: string;
    temporaryPassword: string;
    newPassword: string;
}

export enum AuthenticationFeedbackType {
    Success,
    NewPasswordRequired,
    Failure,
}

export interface Credentials {
    email: string;
    password: string;
}

export interface User {
    email: string;
    organizationRole: OrganizationRole;
}
