import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store';
import { closePopup } from '../../../../store/popup';
import styles from './query-metadata-popup.module.scss';
import { SearchAndRelateQueryMetaData, SharedMolType, SharedQueryType } from '../../../../api/generated';

class QueryMetadataPopup extends Component<AllProps> {
    public render(): JSX.Element {
        const { rootQuery, queryMetadata, t } = this.props;
        const metadata =  queryMetadata?.data ? queryMetadata?.data[0] : null;
        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.closeIcon}
                        size='lg'
                        onClick={(): void => this.close()}
                    />
                    <div className={styles.popupContent}>
                        <div className={styles.title}>
                            <div className={styles.highlight}>{t('Query details')}</div>
                        </div>
                        {rootQuery && queryMetadata && metadata ? (
                            <div className={styles.fields}>
                                <div className={styles.section}>{t('Query')}</div>
                                <div className={styles.field}>
                                    <div className={styles.label}>{t('Text')}</div>
                                    <div className={styles.value}>{rootQuery}</div>
                                </div>
                                <div className={styles.section}>{t('Interpretation')}</div>
                                <div className={styles.field}>
                                    <div className={styles.label}>{t('Query type')}</div>
                                    <div className={styles.value}>{this.readableQueryType(queryMetadata.type || '')}</div>
                                </div>
                                {metadata.molType ? (
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Sequence type')}</div>
                                        <div className={styles.value}>{metadata.molType}</div>
                                    </div>
                                ) : null}
                                {metadata.molType !== SharedMolType.AA && (metadata.translationTable ||
                                metadata.translationFrame ||
                                metadata.translationErrorCode ||
                                (metadata.translatedSequence && metadata.translatedSequence !== rootQuery)) ? (
                                    <>
                                        <div className={styles.section}>{t('Translation')}</div>
                                        {metadata.translationTable ? (
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Translation table')}</div>
                                                <div className={styles.value}>{metadata.translationTable}</div>
                                            </div>
                                        ) : null}
                                        {metadata.translationFrame ? (
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Translation frame')}</div>
                                                <div className={styles.value}>{metadata.translationFrame}</div>
                                            </div>
                                        ) : null}
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Translated successfully')}</div>
                                            <div className={styles.value}>
                                                {metadata.translationErrorCode ? t('No') : t('Yes')}
                                            </div>
                                        </div>
                                        {metadata.translatedSequence ? (
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Translated sequence')}</div>
                                                <div className={styles.value}>{metadata.translatedSequence}</div>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        ) : (
                            <div className={styles.empty}>{t('No query details found.')}</div>
                        )}
                        <div className={styles.popupButtonsContainer}>
                            <button
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.close()}>
                                {t('Close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private readableQueryType(queryType: string): string {
        const { t } = this.props;

        switch (queryType) {
            case SharedQueryType.SIMPLE_SEQUENCE:
                return t('Plain sequence');
            case SharedQueryType.SINGLE_FASTA:
                return t('Sequence in FASTA format');
            case SharedQueryType.NLP:
                return t('Natural language description (similar/iKnow)');
            case SharedQueryType.SEQUENCEID:
                return t('External database ID');
            default:
                return t('Unknown');
        }
    }

    private close(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = ({ search }: ApplicationState): PropsFromState => ({
    rootQuery: search.rootQuery,
    queryMetadata: search.queryMetadata,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QueryMetadataPopup));

interface PropsFromState {
    rootQuery?: string;
    queryMetadata?: SearchAndRelateQueryMetaData;
}

interface PropsFromDispatch {
    dispatchClosePopup: typeof closePopup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
