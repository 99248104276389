import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './admin-menu-item.module.scss';

export enum NavItemTypes {
    users,
    workspaces,
    billing,
}

class AdminMenuItemComponent extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { caption, isActive, onClick } = this.props;

        const itemClasses = conditionalClassList(styles, {
            isActive,
            menuItem: true,
        });

        return (
            <div className={itemClasses} onClick={() => onClick()}>
                <div className={styles.indicator} />
                <div className={styles.caption}>{caption}</div>
            </div>
        );
    }
}

export default connect()(withTranslation()(AdminMenuItemComponent));

interface OwnProps {
    type: NavItemTypes;
    caption: string;
    isActive: boolean;
    onClick: () => {};
}

type AllProps = OwnProps & WithTranslation;
