import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../routing/route-url';
import { ApplicationState } from '../../../store';
import {
    completePasswordChallengeRequest,
    confirmNewPasswordRequest,
    ConfirmPasswordParameters,
    PasswordChallengeParameters,
} from '../../../store/authentication';
import { FailureCodes, RequestStatus } from '../../../store/shared/types';
import { QueryParameter, QueryParameterHelper } from '../../../utils/query-parameter-helpers';
import styles from './confirm-password.module.scss';
import { LeftPanel } from '../left-panel';

enum ViewMode {
    ResetPassword,
    AccountActivation,
}

class ConfirmPasswordPage extends React.Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);

        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        const email = QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.Email) || '';
        const confirmationCode =
            QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.ConfirmationCode) || '';
        const temporaryPassword =
            QueryParameterHelper.getStringFromUrl(queryParameters, QueryParameter.TemporaryPassword) || '';
        const viewMode = confirmationCode ? ViewMode.ResetPassword : ViewMode.AccountActivation;

        this.state = {
            viewMode,
            confirmationCode,
            temporaryPassword,
            email,
            newPassword: '',
            showFeedback: false,
            feedbackMessage: '',
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { requestStatus } = this.props;

        if (requestStatus !== prevProps.requestStatus && requestStatus?.isFailed) {
            this.setState({
                showFeedback: true,
                feedbackMessage: this.getMessageForFailureCode(requestStatus),
            });
        }
    }

    public render(): JSX.Element {
        const { t, requestStatus } = this.props;
        const { showFeedback, feedbackMessage, viewMode } = this.state;

        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.contentPanel}>
                        <LeftPanel />
                        <div className={styles.rightPanel}>
                            <div className={styles.rightTopPanel}>
                                <Link to={RouteUrl.Login}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> {t('Back to Login')}
                                </Link>
                            </div>
                            <div className={styles.rightMiddlePanel} onKeyDown={(e) => this.onKeyPressed(e)}>
                                <div className={styles.header}>
                                    {viewMode === ViewMode.ResetPassword ? (
                                        <>
                                            <div className={styles.titleTwo}>{t('Welcome back!')}</div>
                                            <div className={styles.subtitle}>
                                                {t('Please provide us with you new password')}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={styles.titleOne}>{t('Almost there...')}</div>
                                            <div className={styles.titleTwo}>{t('Welcome to BioStrand!')}</div>
                                            <div className={styles.subtitle}>
                                                {t('Please choose a password so we can activate your account')}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={styles.field}>
                                    <input
                                        type='password'
                                        key='PasswordField'
                                        autoFocus
                                        placeholder={t('Enter your new password')}
                                        onChange={(e) => this.onPasswordChange(e)}
                                    />
                                </div>
                                <div className={styles.errorMessage}>{showFeedback ? feedbackMessage : ''}</div>
                                <div className={styles.buttons}>
                                    <button type='button' className={styles.button} onClick={() => this.onSubmitForm()}>
                                        {requestStatus?.isInProgress ? (
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        ) : viewMode === ViewMode.ResetPassword ? (
                                            t('Confirm')
                                        ) : (
                                            t('Get Started')
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;

        switch (requestStatus.failureCode) {
            case FailureCodes.AUTH3:
                return t('This confirmationCode is no longer valid. Please restart the process and try again.');
            case FailureCodes.AUTH4:
                return t('You have tried to reset your password too many times. Please contact your administrator.');
            default:
                return t('Something went wrong please try again or contact your administrator.');
        }
    }

    private onKeyPressed(e): void {
        if (e.keyCode === 13) {
            this.onSubmitForm();
        } else {
            this.setState({ showFeedback: false });
        }
    }

    private onPasswordChange(e): void {
        this.setState({ newPassword: e.target.value });
    }

    private onSubmitForm(): void {
        const { viewMode } = this.state;
        const { requestStatus } = this.props;

        if (!requestStatus?.isInProgress && this.validatePassword()) {
            switch (viewMode) {
                case ViewMode.ResetPassword:
                    this.confirmNewPassword();
                    break;
                default:
                    this.completePasswordChallenge();
                    break;
            }
        }
    }

    private completePasswordChallenge(): void {
        const { dispatchCompletePasswordChallengeRequest } = this.props;
        const { temporaryPassword, newPassword, email } = this.state;
        dispatchCompletePasswordChallengeRequest({ email, newPassword, temporaryPassword });
        this.setState({ showFeedback: true });
    }

    private confirmNewPassword(): void {
        const { dispatchConfirmNewPasswordRequest } = this.props;
        const { confirmationCode, newPassword, email } = this.state;
        dispatchConfirmNewPasswordRequest({ email, newPassword, confirmationCode });
        this.setState({ showFeedback: true });
    }

    private validatePassword(): boolean {
        const { newPassword } = this.state;
        const { t } = this.props;

        if (!newPassword || newPassword.length < 6) {
            this.setState({
                showFeedback: true,
                feedbackMessage: t('Please provide us with (at least) a six characters password.'),
            });
            return false;
        }
        return true;
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchConfirmNewPasswordRequest: (parameters: ConfirmPasswordParameters) =>
        dispatch(confirmNewPasswordRequest(parameters)),
    dispatchCompletePasswordChallengeRequest: (parameters: PasswordChallengeParameters) =>
        dispatch(completePasswordChallengeRequest(parameters)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    authentication,
}: ApplicationState) => ({
    currentLocation: router.location,
    requestStatus: authentication.confirmPasswordRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordPage));

interface PropsFromState {
    currentLocation: Location;
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchConfirmNewPasswordRequest: typeof confirmNewPasswordRequest;
    dispatchCompletePasswordChallengeRequest: typeof completePasswordChallengeRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    viewMode: ViewMode;
    newPassword: string;
    temporaryPassword: string;
    email: string;
    confirmationCode: string;
    showFeedback: boolean;
    feedbackMessage: string;
}

type AllState = OwnState;
