/* eslint-disable no-underscore-dangle */
import { SharedAttributeClass, SearchAndRelateListField } from '../../../../../api/generated';
import { ColumnConfig } from '../components/results-list/column-config';
import { RuntimeConfig } from '../../../../../config/runtime-config';
import { Feature } from '../../../../../store/global';
import { SequenceFields } from '../../../../../store/search';

const excludeFields = [SequenceFields.url.toString(), SequenceFields.sequenceId.toString()];

export const listFieldsToColumns = (fields: SearchAndRelateListField[]): ColumnConfig[] => {
    const isComputedFieldsSupported = RuntimeConfig.isSupported(Feature.ComputedFields);

    const columns: ColumnConfig[] = fields
        .filter(f => !excludeFields.includes(f.name as string))

        .filter(f => isComputedFieldsSupported ? true : f._class !== SharedAttributeClass.COMPUTED)
        .map(f => {
            return {
                key: f.name as string,
                filterKey: f.name as string,
                label: f.displayName as string,
                widthUnits: (f.name as string) === 'description' ? 4 : 1,
                canBeSorted: true,
                computed: f._class === SharedAttributeClass.COMPUTED,
            };
        });

    columns.unshift({
        key: SequenceFields.url,
        filterKey: SequenceFields.sequenceId,
        labelKey: SequenceFields.sequenceId,
        label: 'Sequence ID',
        widthUnits: 1,
        canBeSorted: false,
        isLink: true,
    });

    return columns;
};
