import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../../../store';
import {
    Dataset,
    getDatasetsForOrganizationRequest,
    linkDatasetsToWorkspaceRequest,
    LinkDatasetsToWorkspaceRequestParameters,
} from '../../../../../../store/datasets';
import { AuthorizationContext } from '../../../../../../store/global';
import { closePopup } from '../../../../../../store/popup';
import { RequestStatus } from '../../../../../../store/shared/types';
import { addToast, ToastType } from '../../../../../../store/toast';
import { AdminBasePage } from '../../../../admin-base.page';
import DatasetSelection from '../../../../components/dataset-selection/dataset-selection';
import styles from './link-dataset-popup.module.scss';
import { activeDatasetSelector } from '../../../../../../store/datasets/datasets-selectors';

class LinkDatasetPopup extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        const { authorizationContext, dispatchGetDatasetsForOrganizationRequest } = props;
        super(props);

        this.state = {
            selectedDatasetIds: [],
        };

        if (authorizationContext) {
            dispatchGetDatasetsForOrganizationRequest();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const { requestStatus, dispatchClosePopup, t } = this.props;

        if (requestStatus && prevProps.requestStatus !== requestStatus) {
            if (requestStatus.isSuccess) {
                dispatchClosePopup();
                this.showToast(ToastType.Success, t('Datasets Successfully Linked'));
            }
            if (requestStatus.isFailed) {
                this.showToast(
                    ToastType.Error,
                    t('Dataset linking failed, please contact your administrator if this error persists.'),
                );
            }
        }
    }

    public render(): JSX.Element {
        const { t, requestStatus, organizationDatasets, workspaceDatasets } = this.props;
        const { selectedDatasetIds } = this.state;
        const availableDatasets: Dataset[] =
            organizationDatasets && workspaceDatasets
                ? organizationDatasets.filter((od) => !workspaceDatasets.find((wd) => wd.id === od.id))
                : [];

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>{t('Link Datasets')}</div>
                        <DatasetSelection
                            availableDatasets={availableDatasets}
                            selectedDatasetIds={selectedDatasetIds}
                            emptyMessage={t('All available datasets are already linked to this workspace.')}
                            maxHeightOffset={400}
                            onSelectionChange={(ids): void => this.updateSelectedDatasetIds(ids)}
                        />
                        <div className={styles.buttons}>
                            <button
                                disabled={requestStatus?.isInProgress || selectedDatasetIds.length === 0}
                                className={`${styles.button} ${styles.popupButton}`}
                                type='button'
                                onClick={(): void => this.link()}>
                                {requestStatus?.isInProgress ? <FontAwesomeIcon icon={faSpinner} spin /> : t('Link')}
                            </button>
                            <button
                                disabled={requestStatus?.isInProgress}
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onClose()}>
                                {t('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private updateSelectedDatasetIds(ids: string[]): void {
        this.setState({ selectedDatasetIds: ids });
    }

    private link(): void {
        const { dispatchLinkDatasetsToWorkspaceRequest, authorizationContext, workspaceId } = this.props;
        const { selectedDatasetIds } = this.state;

        if (authorizationContext) {
            dispatchLinkDatasetsToWorkspaceRequest({
                organizationId: authorizationContext.organization.id,
                workspaceId,
                datasetIds: selectedDatasetIds,
            });
        }
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    const { global, datasets } = state;
    return {
        authorizationContext: global.authorizationContext,
        organizationDatasets: activeDatasetSelector(state),
        workspaceDatasets: datasets.datasetsForWorkspace,
        requestStatus: datasets.linkDatasetsToWorkspaceRequestStatus,
    }
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchGetDatasetsForOrganizationRequest: () =>
        dispatch(getDatasetsForOrganizationRequest()),
    dispatchLinkDatasetsToWorkspaceRequest: (parameters: LinkDatasetsToWorkspaceRequestParameters) =>
        dispatch(linkDatasetsToWorkspaceRequest(parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkDatasetPopup));

interface OwnProps {
    workspaceId: string;
}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    organizationDatasets?: Dataset[];
    workspaceDatasets?: Dataset[];
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchGetDatasetsForOrganizationRequest: typeof getDatasetsForOrganizationRequest;
    dispatchLinkDatasetsToWorkspaceRequest: typeof linkDatasetsToWorkspaceRequest;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedDatasetIds: string[];
}

type AllState = OwnState;
