import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { conditionalClassList } from '../../../../../utils/class-helpers';
import { generateUUID } from '../../../../../utils/uuid-helpers';
import styles from './selection-component.module.scss';

class SelectionComponent extends React.Component<AllProps, OwnState> {
    public render(): JSX.Element {
        const { items, selectedItems } = this.props;

        return (
            <div className={styles.selectionContainer}>
                <div className={styles.itemsSelector}>
                    {items.map((item) => {
                        const selected =
                            selectedItems &&
                            selectedItems.find((selectedItem) => selectedItem.name === item.name) !== undefined;

                        const itemClasses = conditionalClassList(styles, {
                            selected,
                            item: true,
                        });

                        return (
                            <div
                                key={item.displayName || generateUUID()}
                                className={itemClasses}
                                onClick={(): void => this.toggleItem(item)}
                                onDoubleClick={(): void => this.exclusiveSelect(item)}>
                                <div className={styles.indicator}>
                                    {selected ? <FontAwesomeIcon icon={faCheck} /> : null}
                                </div>
                                <div className={styles.caption}>{item.displayName}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    private toggleItem(item: SelectionItem): void {
        const { selectionChanged, selectedItems } = this.props;
        const updatedElements = [...selectedItems];

        const index = updatedElements.findIndex((element) => element.name === item.name);
        if (index !== -1) {
            updatedElements.splice(index, 1);
        } else {
            updatedElements.push(item);
        }

        selectionChanged(updatedElements);
    }

    private exclusiveSelect(item: SelectionItem): void {
        const { selectionChanged } = this.props;
        selectionChanged([item]);
    }
}

export default withTranslation()(SelectionComponent);

interface OwnProps {
    items: SelectionItem[];
    selectionChanged: (items: SelectionItem[]) => void;
    selectedItems: SelectionItem[];
}

interface OwnState {}

type AllProps = OwnProps & WithTranslation;

export interface SelectionItem {
    displayName: string;
    name: string;
}
