import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import React,{ ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';

import { clearQuery } from '../../store/search';
import { ApplicationState } from '../../store';
import { RouteUrl } from '../../routing/route-url';
import ErrorPanelBase from '../empty-panel/error-panel-base';

interface SomethingWentWrongProps {
    message?: string;
}

const SomethingWentWrong = (props: SomethingWentWrongProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activeWorkspaceId = useSelector((state: ApplicationState) => state.global.activeWorkspaceId);

    const {message} = props

    const onBackSearchClick = () => {
        dispatch(clearQuery())
        dispatch(push({ pathname: `/${activeWorkspaceId}${RouteUrl.Search}` }));
    }

    return <ErrorPanelBase title={message || t('Something went wrong, please try to reload the page or change the search request')}>
        <Button style={{marginTop:16, width: 145, paddingLeft:0, paddingRight:0 }} onClick={onBackSearchClick} variant='outlined'>{t('Back to search')}</Button>
    </ErrorPanelBase>
};

export default SomethingWentWrong;
