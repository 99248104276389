export class ColumnCombinationTransformer {
    public static transformColumnCombinationsIntoColumns(
        columnCombinations: string[][],
        amountOfSelectedColumnDimensions: number,
    ): string[][] {
        return columnCombinations.reduce((rows, combination) => {
            combination.forEach((element, index) => {
                rows[index].push(element);
            });

            return rows;
        }, Array.from({ length: amountOfSelectedColumnDimensions }, () => []) as string[][]);
    }
}
