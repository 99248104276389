import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import {
    Dataset,
    DatasetsActionTypes,
    LinkDatasetsToWorkspaceRequestParameters,
} from './types';
import { PipelinePipeline } from '../../api/generated';

export const getDatasetsForOrganizationRequest = () =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_REQUEST);
export const getDatasetsForOrganizationSuccess = (datasets: Dataset[]) =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_SUCCESS, datasets);
export const getDatasetsForOrganizationFailure = (failureCode?: FailureCodes) =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_FAILURE, failureCode);

export const getDatasetsForWorkspaceRequest = (organizationId: string, workspaceId: string) =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_REQUEST, [organizationId, workspaceId] as [string, string]);
export const getDatasetsForWorkspaceSuccess = (datasets: Dataset[]) =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_SUCCESS, datasets);
export const getDatasetsForWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_FAILURE, failureCode);

export const linkDatasetsToWorkspaceRequest = (parameters: LinkDatasetsToWorkspaceRequestParameters) =>
    action(DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_REQUEST, parameters);
export const linkDatasetsToWorkspaceSuccess = (datasets: Dataset[]) =>
    action(DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_SUCCESS, datasets);
export const linkDatasetsToWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_FAILURE, failureCode);

export const unlinkDatasetsToWorkspaceRequest = (parameters: LinkDatasetsToWorkspaceRequestParameters) =>
    action(DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_REQUEST, parameters);
export const unlinkDatasetsToWorkspaceSuccess = (datasetIds: string[]) =>
    action(DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_SUCCESS, datasetIds);
export const unlinkDatasetsToWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_FAILURE, failureCode);

export const startDatasetProcessing = (datasetId: string) =>
    action(DatasetsActionTypes.START_DATASET_PROCESSING, [datasetId] as [string]);

export const updateDatasetPipelineStatus = (datasetPipeline: PipelinePipeline) =>
    action(DatasetsActionTypes.UPDATE_DATASET_PIPELINE_STATUS, [datasetPipeline] as [PipelinePipeline]);

export const startWatchingDatasetPipelineStatus = () =>
    action(DatasetsActionTypes.WATCH_DATASET_PROCESSING);
