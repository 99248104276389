import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import { AuthorizationContext, Membership, WorkspaceRole } from '../../../../store/global';
import { closePopup } from '../../../../store/popup';
import { RequestStatus } from '../../../../store/shared/types';
import { addToast } from '../../../../store/toast';
import { updateDefaultMembershipRequest, UpdateDefaultMembershipRequestParameters } from '../../../../store/workspaces';
import { updateQueryParameters } from '../../../../utils/query-parameter-helpers';
import styles from './workspace-selection-popup.module.scss';

class WorkspaceSelectionPopup extends Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);
    }

    public render(): JSX.Element {
        const { authorizationContext, t } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>Your Workspaces</div>
                        <div className={styles.rows}>
                            {authorizationContext!.memberships.map((m) => {
                                return (
                                    <div className={styles.row} key={m.workspace.id}>
                                        <div className={styles.cell} style={{ flexGrow: 2 }}>
                                            {m.workspace.name}
                                        </div>
                                        <div className={styles.cell}>
                                            {!m.isDefault ? (
                                                <div className={styles.link} onClick={() => this.setAsDefault(m)}>
                                                    {t('Set as default')}
                                                </div>
                                            ) : (
                                                <div>{t('Default')}</div>
                                            )}
                                        </div>
                                        <div className={styles.cell}>
                                            {m.workspaceRole === WorkspaceRole.ADMIN ? (
                                                <div className={styles.link} onClick={() => this.openAdmin(m)}>
                                                    {t('Open Admin')}
                                                </div>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.buttons}>
                            <button
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onClose()}>
                                {t('Close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private setAsDefault(membership: Membership): void {
        const { dispatchUpdateDefaultMembership, authorizationContext } = this.props;
        dispatchUpdateDefaultMembership({
            email: authorizationContext!.user.email,
            organizationId: authorizationContext!.organization.id,
            workspaceId: membership.workspace.id,
        });
    }

    private openAdmin(membership: Membership): void {
        const { dispatchNavigateTo, activeworkspaceId, dispatchClosePopup } = this.props;
        dispatchClosePopup();
        dispatchNavigateTo({
            pathname: `/${activeworkspaceId}${RouteUrl.ManageMembers}`,
            search: updateQueryParameters({ workspaceId: membership.workspace.id }, ''),
        });
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = ({ users, workspaces, global }: ApplicationState): PropsFromState => ({
    authorizationContext: global.authorizationContext,
    activeworkspaceId: global.activeWorkspaceId,
    requestStatus: workspaces.updateDefaultMembershipRequestStatus,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchUpdateDefaultMembership: (parameters: UpdateDefaultMembershipRequestParameters) =>
        dispatch(updateDefaultMembershipRequest(parameters)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkspaceSelectionPopup));

interface OwnProps {}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    activeworkspaceId?: string;
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchUpdateDefaultMembership: typeof updateDefaultMembershipRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    name: string;
    showFeedback: boolean;
    feedbackMessage?: string;
}

type AllState = OwnState;
