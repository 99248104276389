import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationSquare, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { push } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdminPageHeader from '../../../../components/page-header/admin-page-header/admin-page-header';
import { ApplicationState } from '../../../../store';
import { AuthorizationContext } from '../../../../store/global';
import { closePopup, Popup, PopupType, showPopup } from '../../../../store/popup';
import { RequestStatus } from '../../../../store/shared/types';
import {
    createSetupIntentRequest,
    CreateSetupIntentRequestParameters,
    Customer,
    CustomerFormModel,
    deletePaymentMethodRequest,
    DeletePaymentMethodRequestParameters,
    getCustomerRequest,
    getPaymentMethodsRequest,
    getSubscriptionRequest,
    Invoice,
    PaymentMethod,
    renewSubscriptionRequest,
    setDefaultPaymentMethodRequest,
    SetDefaultPaymentMethodRequestParameters,
    SetupIntent,
    StripeSubscriptionStatus,
    SubscriptionOverview,
    updateCustomerRequest,
    UpdateCustomerRequestParameters,
} from '../../../../store/subscription';
import { SubscriptionHelper } from '../../../../store/subscription/helpers/subscription-helper';
import { addToast, ToastType } from '../../../../store/toast';
import { DateHelper } from '../../../../utils/date-helper';
import { AdminBasePage } from '../../admin-base.page';
import ActionsMenu, { ActionItem, ActionType } from '../../components/actions-menu/actions-menu';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { getOrganizationAdminMenuConfiguration } from '../organization-admin-menu.configuration';
import CancelSubscriptionPopup from './components/cancel-subscription-popup/cancel-subscription-popup';
import CreditCardInput from './components/credit-card-input/credit-card-input';
import CustomerForm from './components/customer-form/customer-form';
import styles from './manage-billing.module.scss';

export enum BillingPageTab {
    Overview,
    Settings,
    PaymentMethods,
}

const isInvoicesHidden = true; // temporary hide invoices

class ManageBillingPage extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);

        this.state = {
            activeTab: BillingPageTab.Overview,
        };

        const {
            authorizationContext,
            dispatchGetPaymentMethodsRequest,
            dispatchGetCustomerRequest,
            dispatchGetSubscriptionRequest,
        } = props;

        if (authorizationContext) {
            dispatchGetPaymentMethodsRequest(authorizationContext.organization.id);
            dispatchGetCustomerRequest(authorizationContext.organization.id);
            dispatchGetSubscriptionRequest(authorizationContext.organization.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {
            authorizationContext,
            updateCustomerRequestStatus,
            dispatchGetPaymentMethodsRequest,
            dispatchGetCustomerRequest,
            dispatchGetSubscriptionRequest,
            t,
        } = this.props;

        if (authorizationContext && authorizationContext !== prevProps.authorizationContext) {
            dispatchGetPaymentMethodsRequest(authorizationContext.organization.id);
            dispatchGetCustomerRequest(authorizationContext.organization.id);
            dispatchGetSubscriptionRequest(authorizationContext.organization.id);
        }

        if (updateCustomerRequestStatus && prevProps.updateCustomerRequestStatus !== updateCustomerRequestStatus) {
            if (updateCustomerRequestStatus.isSuccess) {
                this.showToast(ToastType.Success, t('Settings successfully updated'));
            }
        }
    }

    public render(): JSX.Element {
        const {
            authorizationContext,
            customer,
            paymentMethods,
            getCustomerRequestStatus,
            getPaymentMethodsRequestStatus,
            t,
        } = this.props;
        const { activeTab } = this.state;

        return (
            <div className={styles.wrapper}>
                <AdminPageHeader />
                <div className={styles.spacer} />
                <div id='adminContainer' className={styles.adminContainer}>
                    <AdminMenu menuItems={getOrganizationAdminMenuConfiguration()} />
                    <div className={styles.adminContent}>
                        <div className={styles.topPanel}>
                            <div className={styles.title}>{t('Manage Billing')}</div>
                            <div className={styles.tabContainer}>
                                <span
                                    className={`${styles.tab} ${
                                        activeTab === BillingPageTab.Overview ? styles.tabActive : ''
                                    }`}
                                    onClick={(): void => this.setActiveTab(BillingPageTab.Overview)}>
                                    {t('Overview')}
                                </span>
                                <span
                                    className={`${styles.tab} ${
                                        activeTab === BillingPageTab.Settings ? styles.tabActive : ''
                                    }`}
                                    onClick={(): void => this.setActiveTab(BillingPageTab.Settings)}>
                                    {t('Settings')}
                                    {authorizationContext &&
                                    !getCustomerRequestStatus?.isInProgress &&
                                    !SubscriptionHelper.areSettingsComplete(authorizationContext, customer) ? (
                                        <FontAwesomeIcon className={styles.errorIcon} icon={faExclamationSquare} />
                                    ) : null}
                                </span>
                                <span
                                    className={`${styles.tab} ${
                                        activeTab === BillingPageTab.PaymentMethods ? styles.tabActive : ''
                                    }`}
                                    onClick={(): void => this.setActiveTab(BillingPageTab.PaymentMethods)}>
                                    {t('Payment Methods')}
                                    {authorizationContext &&
                                    !getPaymentMethodsRequestStatus?.isInProgress &&
                                    !SubscriptionHelper.arePaymentMethodsComplete(
                                        authorizationContext,
                                        paymentMethods,
                                    ) ? (
                                        <FontAwesomeIcon className={styles.errorIcon} icon={faExclamationSquare} />
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className={styles.tabContent}>{this.renderTabContent(activeTab)}</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderTabContent(tab: BillingPageTab): JSX.Element | null {
        switch (tab) {
            case BillingPageTab.Overview:
                return this.renderOverviewTab();
            case BillingPageTab.Settings:
                return this.renderSettingsTab();
            case BillingPageTab.PaymentMethods:
                return this.renderPaymentMethodsTab();
            default:
                return this.renderOverviewTab();
        }
    }

    private renderOverviewTab(): JSX.Element | null {
        const { overview, getSubscriptionRequestStatus, renewSubscriptionRequestStatus, t } = this.props;

        return getSubscriptionRequestStatus?.isInProgress ? (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                {t('Loading...')}
            </div>
        ) : overview ? (
            <div>
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>{t('Subscription')}</span>
                        {overview.subscription.status !== StripeSubscriptionStatus.Canceled &&
                        !overview.subscription.cancelAtPeriodEnd ? (
                            <span className={styles.cancel} onClick={(): void => this.cancelSubscription()}>
                                {t('Cancel')}
                            </span>
                        ) : null}
                        {overview.subscription.status === StripeSubscriptionStatus.Canceled ||
                        overview.subscription.cancelAtPeriodEnd ? (
                            <button
                                className={`${styles.button} ${styles.renew}`}
                                type='button'
                                onClick={(): void => this.renewSubscription()}>
                                {renewSubscriptionRequestStatus?.isInProgress ? (
                                    <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                                ) : (
                                    t('Renew')
                                )}
                            </button>
                        ) : null}
                    </div>
                    <div className={styles.keyValuePair}>
                        <div className={styles.key}>{t('Status')}</div>
                        <div className={styles.value}>{overview.subscription.status}</div>
                    </div>
                    <div className={styles.keyValuePair}>
                        <div className={styles.key}>{t('Summary')}</div>
                        <div className={styles.value}>
                            {t(
                                '{{name}}, {{quantity}} seat{{pluralOfQuantity}}, billed every {{intervalCount}} {{interval}}{{pluralOfInterval}}',
                                {
                                    name: overview.subscription.items[0].price.product.name,
                                    quantity: overview.subscription.items[0].quantity,
                                    pluralOfQuantity: overview.subscription.items[0].quantity > 1 ? 's' : '',
                                    intervalCount: overview.subscription.items[0].price.recurring.intervalCount,
                                    interval: overview.subscription.items[0].price.recurring.interval,
                                    pluralOfInterval:
                                        overview.subscription.items[0].price.recurring.intervalCount > 1 ? 's' : '',
                                },
                            )}
                        </div>
                    </div>
                    {overview.subscription.cancelAt ? (
                        <div className={styles.keyValuePair}>
                            <div className={styles.key}>{t('Cancel after')}</div>
                            <div className={styles.value}>
                                {DateHelper.Iso8601ToLocalizedDate(overview.subscription.cancelAt)}
                            </div>
                        </div>
                    ) : null}
                    {overview.subscription.status === StripeSubscriptionStatus.Active ? (
                        <div className={styles.keyValuePair}>
                            <div className={styles.key}>{t('Current period')}</div>
                            <div className={styles.value}>
                                {`${DateHelper.Iso8601ToLocalizedDate(
                                    overview.subscription.currentPeriodStart,
                                )} - ${DateHelper.Iso8601ToLocalizedDate(overview.subscription.currentPeriodEnd)}`}
                            </div>
                        </div>
                    ) : null}
                    {overview.subscription.status === StripeSubscriptionStatus.Trialing ? (
                        <div className={styles.keyValuePair}>
                            <div className={styles.key}>{t('Trial period')}</div>
                            <div className={styles.value}>
                                {`${DateHelper.Iso8601ToLocalizedDate(
                                    overview.subscription.trialStart,
                                )} - ${DateHelper.Iso8601ToLocalizedDate(overview.subscription.trialEnd)}`}
                            </div>
                        </div>
                    ) : null}
                </div>
                {isInvoicesHidden ? <div className={styles.section}><div className={styles.key}>{t('Please contact us to get more details')}</div></div> :
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>{t('Invoices')}</div>
                        {this.renderInvoicesTable()}
                    </div>
                }
            </div>
        ) : null;
    }

    private renderInvoicesTable(): JSX.Element | null {
        const { overview, t } = this.props;

        return overview && overview.invoices.length > 0 ? (
            <div className={styles.table}>
                <div className={`${styles.row} ${styles.header}`}>
                    <div className={styles.cell}>{t('Period')}</div>
                    <div className={styles.cell}>{t('Total')}</div>
                    <div className={styles.cell}>{t('Paid')}</div>
                    <div className={styles.optionsCellSpacer} />
                </div>
                <div className={styles.rows}>
                    {overview.invoices.map((invoice, i) => {
                        return (
                            <div className={styles.row} key={invoice.invoicePdfUrl || 'next'}>
                                <div className={styles.cell}>
                                    {this.determineInvoicePeriod(
                                        overview.invoices.length,
                                        invoice,
                                        overview.invoices[i - 1],
                                    )}
                                </div>
                                <div className={styles.cell}>
                                    {(invoice.total || 0) / 100} {invoice.currency}
                                </div>
                                <div className={styles.cell}>
                                    {invoice.paid ? (
                                        <div className={styles.indicator}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    ) : invoice.nextPaymentAttempt ? (
                                        t('Charged on {{nextPaymentAttempt}}', {
                                            nextPaymentAttempt: DateHelper.Iso8601ToLocalizedDate(
                                                invoice.nextPaymentAttempt,
                                            ),
                                        })
                                    ) : (
                                        <div className={styles.indicator}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                    )}
                                </div>
                                <div className={styles.optionsCell}>
                                    <ActionsMenu actions={this.getActionsForInvoice(invoice)} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : null;
    }

    private renderSettingsTab(): JSX.Element {
        const { customer, getCustomerRequestStatus, updateCustomerRequestStatus, t } = this.props;

        return getCustomerRequestStatus?.isInProgress ? (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                {t('Loading...')}
            </div>
        ) : (
            <CustomerForm
                customer={customer}
                getCustomerRequestStatus={getCustomerRequestStatus}
                updateCustomerRequestStatus={updateCustomerRequestStatus}
                onSave={(customerModel: CustomerFormModel): void => this.saveCustomer(customerModel)}
                t={t}
            />
        );
    }

    private renderPaymentMethodsTab(): JSX.Element {
        const { getPaymentMethodsRequestStatus, createSetupIntentRequestStatus, setupIntent, t } = this.props;

        return getPaymentMethodsRequestStatus?.isInProgress ? (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                {t('Loading...')}
            </div>
        ) : (
            <div>
                {this.renderPaymentMethodsTable()}
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <CreditCardInput
                            elements={elements}
                            stripe={stripe}
                            createSetupIntentRequestStatus={createSetupIntentRequestStatus}
                            setupIntent={setupIntent}
                            onAddCard={(): void => this.addCard()}
                            onCardConfirmed={(paymentMethodId: string): void =>
                                this.handleCardConfirmed(paymentMethodId)
                            }
                            t={t}
                        />
                    )}
                </ElementsConsumer>
            </div>
        );
    }

    private renderPaymentMethodsTable(): JSX.Element | null {
        const { paymentMethods, t } = this.props;

        return paymentMethods && paymentMethods.length > 0 ? (
            <div className={styles.table}>
                <div className={`${styles.row} ${styles.header}`}>
                    <div className={styles.cell}>{t('Ending in')}</div>
                    <div className={styles.cell}>{t('Expiration date')}</div>
                    <div className={styles.cell}>{t('Added on')}</div>
                    <div className={styles.optionsCellSpacer} />
                </div>
                <div className={styles.rows}>
                    {paymentMethods?.map((paymentMethod) => {
                        return (
                            <div className={styles.row} key={paymentMethod.id}>
                                <div className={styles.cell}>{paymentMethod.card.endingIn}</div>
                                <div className={styles.cell}>
                                    {paymentMethod.card.expirationMonth}/{paymentMethod.card.expirationYear}
                                </div>
                                <div className={styles.cell}>
                                    {DateHelper.Iso8601ToLocalizedDateTime(paymentMethod.createdOn)}
                                </div>
                                <div className={styles.optionsCell}>
                                    {paymentMethod.card.isDefault ? (
                                        <div className={styles.indicator}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    ) : (
                                        <ActionsMenu actions={this.getActionsForPaymentMethod(paymentMethod)} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : null;
    }

    private setActiveTab(tab: BillingPageTab): void {
        this.setState({ activeTab: tab });
    }

    private addCard(): void {
        const { authorizationContext, dispatchCreateSetupIntentRequest } = this.props;

        if (authorizationContext) {
            dispatchCreateSetupIntentRequest({
                organizationId: authorizationContext.organization.id,
            });
        }
    }

    private handleCardConfirmed(paymentMethodId: string): void {
        const { authorizationContext, dispatchSetDefaultPaymentMethodRequest } = this.props;

        if (authorizationContext) {
            dispatchSetDefaultPaymentMethodRequest({
                organizationId: authorizationContext.organization.id,
                paymentMethodId,
            });
        }
    }

    private getActionsForPaymentMethod(paymentMethod: PaymentMethod): ActionItem[] {
        const { t } = this.props;

        return paymentMethod.card.isDefault
            ? []
            : [
                  {
                      label: t('Set as default'),
                      onClick: (): void => this.setCardAsDefault(paymentMethod),
                  },
                  {
                      label: t('Delete'),
                      onClick: (): void => this.deleteCard(paymentMethod),
                      type: ActionType.negative,
                  },
              ];
    }

    private setCardAsDefault(paymentMethod: PaymentMethod): void {
        const { authorizationContext, dispatchSetDefaultPaymentMethodRequest } = this.props;

        if (authorizationContext) {
            dispatchSetDefaultPaymentMethodRequest({
                organizationId: authorizationContext.organization.id,
                paymentMethodId: paymentMethod.id,
            });
        }
    }

    private deleteCard(paymentMethod: PaymentMethod): void {
        const { authorizationContext, dispatchDeletePaymentMethodRequest } = this.props;

        if (authorizationContext) {
            dispatchDeletePaymentMethodRequest({
                organizationId: authorizationContext.organization.id,
                paymentMethodId: paymentMethod.id,
            });
        }
    }

    private saveCustomer(customerForm: CustomerFormModel): void {
        const { authorizationContext, customer, dispatchUpdateCustomerRequest } = this.props;

        if (authorizationContext && customer) {
            dispatchUpdateCustomerRequest({
                organizationId: authorizationContext.organization.id,
                customerId: customer.id,
                name: customerForm.name,
                city: customerForm.city,
                country: customerForm.country,
                line1: customerForm.line1,
                line2: customerForm.line2,
                postalCode: customerForm.postalCode,
                state: customerForm.state,
                taxIdValue: customerForm.taxIdValue,
                taxIdType: customerForm.taxIdType,
            });
        }
    }

    private getActionsForInvoice(invoice: Invoice): ActionItem[] {
        const { t } = this.props;

        return invoice.hostedInvoiceUrl && invoice.invoicePdfUrl
            ? [
                  {
                      label: t('Open'),
                      onClick: (): void => this.openInvoice(invoice.hostedInvoiceUrl!),
                  },
                  {
                      label: t('Download PDF'),
                      onClick: (): void => this.downloadInvoicePdf(invoice.invoicePdfUrl!),
                  },
              ]
            : [];
    }

    private openInvoice(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    private downloadInvoicePdf(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    private determineInvoicePeriod(totalInvoices: number, a: Invoice, b?: Invoice): string {
        const { overview, t } = this.props;

        if (b) {
            return t('{{start}} - {{end}}', {
                start: DateHelper.Iso8601ToLocalizedDate(a.periodEnd),
                end: DateHelper.Iso8601ToLocalizedDate(b.periodEnd),
            });
        } else if (totalInvoices === 1 && overview) {
            return t('{{start}} - {{end}}', {
                start: DateHelper.Iso8601ToLocalizedDate(overview.subscription.currentPeriodStart),
                end: DateHelper.Iso8601ToLocalizedDate(overview.subscription.currentPeriodEnd),
            });
        } else {
            return t('Upcoming');
        }
    }

    private cancelSubscription(): void {
        const { dispatchShowPopup } = this.props;

        dispatchShowPopup({
            type: PopupType.CANCEL_SUBSCRIPTION,
            content: <CancelSubscriptionPopup />,
            isDismissible: true,
        });
    }

    private renewSubscription(): void {
        const { authorizationContext, dispatchRenewSubscriptionRequest } = this.props;

        if (authorizationContext) {
            dispatchRenewSubscriptionRequest(authorizationContext.organization.id);
        }
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchCreateSetupIntentRequest: (parameters: CreateSetupIntentRequestParameters) =>
        dispatch(createSetupIntentRequest(parameters)),
    dispatchGetPaymentMethodsRequest: (organizationId: string) => dispatch(getPaymentMethodsRequest(organizationId)),
    dispatchSetDefaultPaymentMethodRequest: (parameters: SetDefaultPaymentMethodRequestParameters) =>
        dispatch(setDefaultPaymentMethodRequest(parameters)),
    dispatchDeletePaymentMethodRequest: (parameters: DeletePaymentMethodRequestParameters) =>
        dispatch(deletePaymentMethodRequest(parameters)),
    dispatchGetCustomerRequest: (organizationId: string) => dispatch(getCustomerRequest(organizationId)),
    dispatchUpdateCustomerRequest: (parameters: UpdateCustomerRequestParameters) =>
        dispatch(updateCustomerRequest(parameters)),
    dispatchGetSubscriptionRequest: (organizationId: string) => dispatch(getSubscriptionRequest(organizationId)),
    dispatchRenewSubscriptionRequest: (organizationId: string) => dispatch(renewSubscriptionRequest(organizationId)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
});

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({
    router,
    global,
    subscription,
}: ApplicationState) => ({
    currentLocation: router.location,
    authorizationContext: global.authorizationContext,
    setupIntent: subscription.setupIntent,
    paymentMethods: subscription.paymentMethods,
    customer: subscription.customer,
    overview: subscription.subscription,
    createSetupIntentRequestStatus: subscription.createSetupIntentRequestStatus,
    getPaymentMethodsRequestStatus: subscription.getPaymentMethodsRequestStatus,
    getCustomerRequestStatus: subscription.getCustomerRequestStatus,
    updateCustomerRequestStatus: subscription.updateCustomerRequestStatus,
    getSubscriptionRequestStatus: subscription.getSubscriptionRequestStatus,
    renewSubscriptionRequestStatus: subscription.renewSubscriptionRequestStatus,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageBillingPage));

interface PropsFromState {
    currentLocation: Location;
    authorizationContext?: AuthorizationContext;
    setupIntent?: SetupIntent;
    paymentMethods?: PaymentMethod[];
    customer?: Customer;
    overview?: SubscriptionOverview;
    createSetupIntentRequestStatus?: RequestStatus;
    getPaymentMethodsRequestStatus?: RequestStatus;
    getCustomerRequestStatus?: RequestStatus;
    updateCustomerRequestStatus?: RequestStatus;
    getSubscriptionRequestStatus?: RequestStatus;
    renewSubscriptionRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchCreateSetupIntentRequest: typeof createSetupIntentRequest;
    dispatchGetPaymentMethodsRequest: typeof getPaymentMethodsRequest;
    dispatchSetDefaultPaymentMethodRequest: typeof setDefaultPaymentMethodRequest;
    dispatchDeletePaymentMethodRequest: typeof deletePaymentMethodRequest;
    dispatchGetCustomerRequest: typeof getCustomerRequest;
    dispatchUpdateCustomerRequest: typeof updateCustomerRequest;
    dispatchGetSubscriptionRequest: typeof getSubscriptionRequest;
    dispatchRenewSubscriptionRequest: typeof renewSubscriptionRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchAddToast: typeof addToast;
}

interface OwnProps {}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation & Element;

interface OwnState {
    activeTab: BillingPageTab;
}

type AllState = OwnState;
