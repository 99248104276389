import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';
import * as React from 'react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter-icon.svg';
import { ApplicationState } from '../../../../store';
import { Popup, PopupType, showPopup } from '../../../../store/popup';
import {
    closeToolbarRequest,
    Dimension,
    DimensionFilter,
    openToolbarRequest,
    toggleToolbarRequest,
} from '../../../../store/search';
import { conditionalClassList } from '../../../../utils/class-helpers';
import QueryMetadataPopup from '../query-metadata-popup/query-metadata-popup';
import GlobalFilterComponent from './filter/filter-component';
import PageToolbarButton from './page-toolbar-button';
import styles from './page-toolbar.module.scss';

export interface ToolbarAction {
    uid: string;
    actionId: ToolbarActionId;
    type: ToolbarActionType;
    title: string;
    faIcon?: IconDefinition;
    // TODO this is hack to support custom (not fontawesome icons). Unfortunately fontawesome icons doesn't support svg as a source
    customIcon?: JSX.Element;
    component?: JSX.Element;
    onClick?: () => void;
    isSelected?: boolean;
}

export interface ToolbarContext {
    actionId: ToolbarActionId;
    options?: any;
}

export enum ToolbarActionType {
    component,
    button,
    toggle,
}

export enum ToolbarActionId {
    GlobalFilterId,
    QuickFilterDimensionSelection,
    ExplorerToggleTable,
    ExplorerToggleChart,
    ExplorerRowSelection,
    ExplorerColumnSelection,
    ExplorerDownload,
    ListViewFields,
    ListViewDownload,
    AlignmentViewDownload,
}

class PageToolbar extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);
        const { toggleToolbar } = this.props;
        toggleToolbar(false);
    }

    public render(): JSX.Element {
        const { leftActions, rightActions, filters, matches, dimensions, isToolbarOpen, toolbarContext } = this.props;

        const activeToolbarAction = toolbarContext ? this.getAction(toolbarContext.actionId) : undefined;
        const isFilterOpen =
            isToolbarOpen && !!activeToolbarAction && activeToolbarAction.actionId === ToolbarActionId.GlobalFilterId;

        const expandPanelClasses = conditionalClassList(styles, {
            toolbarExpandPanel: true,
            open: isToolbarOpen,
        });
        const filterButtonClasses = conditionalClassList(styles, {
            filterButton: true,
            active: isFilterOpen,
        });

        const actionbarClasses = conditionalClassList(styles, {
            toolbarActionbar: true,
            open: isToolbarOpen,
        });

        return (
            <div className={styles.toolbarContainer}>
                <div className={actionbarClasses}>
                    <div className={styles.leftPanel}>
                        {leftActions ? (
                            <div className={styles.toolbarButtons}>
                                {leftActions.map((action) => {
                                    return (
                                        <PageToolbarButton
                                            key={action.uid}
                                            isToolbarOpen={isToolbarOpen}
                                            fixedWidth={!!action.title}
                                            action={action}
                                            activeToolbarAction={activeToolbarAction}
                                            activateAction={(a): void => this.activateAction(a.actionId)}
                                            deactivateAction={(): void => this.deactivateAction()}
                                        />
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.rightPanel}>
                        {rightActions ? (
                            <div className={styles.toolbarButtons}>
                                {rightActions.map((action) => {
                                    return (
                                        <PageToolbarButton
                                            key={action.uid}
                                            isToolbarOpen={isToolbarOpen}
                                            action={action}
                                            activeToolbarAction={activeToolbarAction}
                                            fixedWidth={false}
                                            activateAction={(a): void => this.activateAction(a.actionId)}
                                            deactivateAction={(): void => this.deactivateAction()}
                                        />
                                    );
                                })}
                            </div>
                        ) : null}
                        {dimensions && dimensions.length > 0 ? (
                            <div className={filterButtonClasses} onClick={(): void => this.toggleFilterAction()}>
                                <FilterIcon className={styles.filterIcon} />
                                {filters.length === 0 ? `No Filter` : `Filters (${filters.length})`}
                            </div>
                        ) : null}
                        <div className={styles.matchesSummary} onClick={(): void => this.showQueryMetadata()}>
                            <FontAwesomeIcon icon={faInfoCircle} size='lg' className={styles.infoIcon} />
                            <span className={styles.highlight}>{matches ? matches.toLocaleString() : 0}</span> matches
                            found
                        </div>
                    </div>
                </div>
                <div className={expandPanelClasses}>
                    {activeToolbarAction && activeToolbarAction.component ? activeToolbarAction.component : null}
                    {isFilterOpen ? (
                        <GlobalFilterComponent
                            filters={filters}
                            isOpen={isToolbarOpen}
                            contextOptions={toolbarContext?.options}
                            onFiltersChanged={(changedFilters: DimensionFilter[]) =>
                                this.onFiltersChanged(changedFilters)
                            }
                            dimensions={dimensions}
                        />
                    ) : null}
                </div>
            </div>
        );
    }

    private getAction(actionId: ToolbarActionId): ToolbarAction | undefined {
        const { leftActions, rightActions } = this.props;
        return (
            (actionId === ToolbarActionId.GlobalFilterId
                ? { actionId, type: ToolbarActionType.component, title: '', uid: 'ToolbarActionId.GlobalFilterId' }
                : undefined) ||
            (leftActions ? leftActions.find((a) => a.actionId === actionId) : undefined) ||
            (rightActions ? rightActions.find((a) => a.actionId === actionId) : undefined)
        );
    }

    private activateAction(actionId: ToolbarActionId): void {
        const { openToolbar } = this.props;
        openToolbar({ actionId });
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        const { onFiltersChanged } = this.props;
        onFiltersChanged(filters);
    }

    private deactivateAction(): void {
        const { closeToolbar } = this.props;
        closeToolbar();
    }

    private toggleFilterAction(): void {
        const { toolbarContext, isToolbarOpen } = this.props;
        if (isToolbarOpen && toolbarContext && toolbarContext.actionId === ToolbarActionId.GlobalFilterId) {
            this.deactivateAction();
        } else {
            this.activateAction(ToolbarActionId.GlobalFilterId);
        }
    }

    private showQueryMetadata(): void {
        const { dispatchShowPopup } = this.props;
        dispatchShowPopup({
            type: PopupType.QUERY_METADATA,
            content: <QueryMetadataPopup />,
            isDismissible: true,
        });
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ search }: ApplicationState) => ({
    isToolbarOpen: search.isToolbarOpen,
    matches: search.queryMatches,
    dimensions: search.dimensions,
    toolbarContext: search.toolbarContext,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    toggleToolbar: (isOpen: boolean) => dispatch(toggleToolbarRequest(isOpen)),
    closeToolbar: () => dispatch(closeToolbarRequest()),
    openToolbar: (context) => dispatch(openToolbarRequest(context)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PageToolbar));

interface PropsFromState {
    isToolbarOpen: boolean;
    matches?: number;
    dimensions: Dimension[];
    toolbarContext?: ToolbarContext;
}

interface PropsFromDispatch {
    toggleToolbar: typeof toggleToolbarRequest;
    closeToolbar: typeof closeToolbarRequest;
    openToolbar: typeof openToolbarRequest;
    dispatchShowPopup: typeof showPopup;
}

interface OwnProps {
    actionComponent?: JSX.Element;
    leftActions?: ToolbarAction[];
    rightActions?: ToolbarAction[];
    location: Location;
    filters: DimensionFilter[];
    onFiltersChanged: (changedFilters: DimensionFilter[]) => void;
}

interface OwnState {}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;
