import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DimensionFilter, DimensionWithPlaceholder } from '../../../../../store/search';
import { SEQUENCE_DIMENSION_NAME } from '../../../constants/dimension-constants';
import styles from './filter-dimension-placeholder-panel.module.scss';
import SequenceMotifFilterDimension from './sequence-motif-filter-dimension';

class FilterDimensionWithPlaceholderPanelComponent extends React.Component<AllProps, AllState> {
    public render(): JSX.Element {
        const { dimension, t, filterOnSelectedDimension, updateFilterForPlaceholder } = this.props;
        const filterText = filterOnSelectedDimension?.elementNamesContains || '';
        const includes = filterOnSelectedDimension?.includes || [];
        const hasSpecificIncludes = includes.length > 0;
        if (dimension.name === SEQUENCE_DIMENSION_NAME) {
            return (
                <SequenceMotifFilterDimension
                    dimension={dimension}
                    updateFilterForPlaceholder={updateFilterForPlaceholder}
                    filterOnSelectedDimension={filterOnSelectedDimension}
                />
            );
        }

        return (
            <div className={styles.placeholderContainer}>
                <div>
                    {t('There are more than {{countTotal}} matches in this dimension.', {
                        countTotal: dimension.placehold.count,
                    })}
                </div>
                {hasSpecificIncludes ? (
                    <div>
                        <div>
                            {t('Somebody has manually selected {{countFilter}} individual sequences to look at.', {
                                countFilter: includes.length,
                            })}
                        </div>
                        <div className={styles.buttons}>
                            <button type='button' className={styles.button} onClick={() => this.removeFilter()}>
                                {t('Clear this filter')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div>{t('Please use the fields below to filter the elements by name.')}</div>
                        <div className={styles.form}>
                            <div className={styles.formRow}>
                                <div className={styles.formLabel}>Value includes</div>
                                <div>
                                    <input
                                        className={styles.search}
                                        placeholder={t('Search by name')}
                                        value={filterText}
                                        onChange={(event): void =>
                                            this.onFilterTextChange(dimension.name, event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private removeFilter() {
        const { removeFilter } = this.props;
        removeFilter();
    }

    private onFilterTextChange(dimensionName: string, text: string, excludeAll = false): void {
        const { updateFilterForPlaceholder } = this.props;
        updateFilterForPlaceholder(dimensionName, text);
    }
}

const FilterDimensionWithPlaceholderPanel = withTranslation()(FilterDimensionWithPlaceholderPanelComponent);

export default FilterDimensionWithPlaceholderPanel;

interface OwnProps {
    dimension: DimensionWithPlaceholder;
    filterOnSelectedDimension?: DimensionFilter;
    updateFilterForPlaceholder: (dimensionName, elementNameFilter, excludeAll?) => void;
    removeFilter: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
