import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DimensionFilter } from '../../../../store/search';
import PageToolbar from '../../components/page-toolbar/page-toolbar';

const QuickFilterToolbar = (props: AllProps): JSX.Element => {
    const { selectedFilters } = props;

    const handleFiltersChanged = (filters: DimensionFilter[]): void => {
        const { onFiltersChanged } = props;
        onFiltersChanged(filters);
    };
    return <PageToolbar filters={selectedFilters} onFiltersChanged={handleFiltersChanged} />;
};

interface OwnProps {
    selectedFilters: DimensionFilter[];
    onFiltersChanged: (filters: DimensionFilter[]) => void;
}

export default withTranslation()(QuickFilterToolbar);

type AllProps = OwnProps & WithTranslation;
