import { GetSequencesResultSequenceMap } from '../../../../../store/search';
import { ColumnConfig } from '../components/results-list/column-config';

export class ListViewCsvCreator {
    public static createCsv(columns: ColumnConfig[], sequences: GetSequencesResultSequenceMap[]): string {
        const bom = '\uFEFF';
        const newLine = '\r\n';
        const separator = ';';

        const csvColumns = columns.reduce((concatenatedColumns, column, index) => {
            return `${concatenatedColumns}${column.label}${index === columns.length - 1 ? newLine : separator}`;
        }, '');
        const csvRows = sequences.reduce((concatenatedRows, sequence) => {
            const csvRow = columns.reduce((concatenatedColumns, column, index) => {
                return `${concatenatedColumns}${sequence[column.key] || ''}${
                    index === columns.length - 1 ? newLine : separator
                }`;
            }, '');
            return concatenatedRows + csvRow;
        }, '');

        return bom + csvColumns + csvRows;
    }
}
