import { Http, WorkspaceResponse } from '../http/http';
import {
    CreateMembershipRequestParameters,
    DeleteMembershipRequestParameters,
    DeleteWorkspaceRequestParameters,
    UpdateDefaultMembershipRequestParameters,
    UpdateMembershipRequestParameters,
    WorkspaceMembership,
} from '../store/workspaces';
import { ApiType, ApiUrl } from './api-urls';

export class MembershipsApi {
    public static async updateMembership(
        parameters: UpdateMembershipRequestParameters,
    ): Promise<WorkspaceResponse<WorkspaceMembership>> {
        return Http.put<UpdateMembershipRequestParameters, WorkspaceResponse<WorkspaceMembership>>(
            ApiType.Gateway,
            ApiUrl.UpdateMembership,
            parameters,
            {
                organizationId: parameters.organizationId,
                workspaceId: parameters.workspaceId,
                email: parameters.email,
            },
        );
    }

    public static async getMembershipsForWorkspace(
        organizationId: string,
        workspaceId: string,
    ): Promise<WorkspaceResponse<WorkspaceMembership[]>> {
        return Http.get<WorkspaceResponse<WorkspaceMembership[]>>(ApiType.Gateway, ApiUrl.GetMembershipsForWorkspace, {
            organizationId,
            workspaceId,
        });
    }

    public static async updateDefaultMembership(
        parameters: UpdateDefaultMembershipRequestParameters,
    ): Promise<WorkspaceResponse<any>> {
        return Http.put<UpdateDefaultMembershipRequestParameters, WorkspaceResponse<any>>(
            ApiType.Gateway,
            ApiUrl.UpdateDefaultWorkspaceForUser,
            parameters,
            {
                organizationId: parameters.organizationId,
                workspaceId: parameters.workspaceId,
                email: parameters.email,
            },
        );
    }

    public static async deleteWorkspace(parameters: DeleteWorkspaceRequestParameters): Promise<WorkspaceResponse<any>> {
        return Http.delete<WorkspaceResponse<any>>(ApiType.Gateway, ApiUrl.DeleteWorkspace, {
            organizationId: parameters.organizationId,
            workspaceId: parameters.workspaceId,
        });
    }

    public static async createMembership(
        parameters: CreateMembershipRequestParameters,
    ): Promise<WorkspaceResponse<WorkspaceMembership>> {
        return Http.post<CreateMembershipRequestParameters, WorkspaceResponse<WorkspaceMembership>>(
            ApiType.Gateway,
            ApiUrl.CreateMembership,
            parameters,
            {
                organizationId: parameters.organizationId,
                workspaceId: parameters.workspaceId,
            },
        );
    }

    public static async deleteMembership(
        parameters: DeleteMembershipRequestParameters,
    ): Promise<WorkspaceResponse<any>> {
        return Http.delete<WorkspaceResponse<any>>(ApiType.Gateway, ApiUrl.DeleteMembership, {
            organizationId: parameters.organizationId,
            workspaceId: parameters.workspaceId,
            email: parameters.email,
        });
    }
}
