import { GetSequencesResultSequence, GetSequencesResultSequenceMap, SequenceFields } from '../types';

export function populateSequenceFields(sequence: GetSequencesResultSequence): GetSequencesResultSequenceMap {
    const sequenceMap = {};
    sequence.attributes.forEach(field => {
        sequenceMap[field.name] = field.value;
    });
    // eslint-disable-next-line prefer-destructuring,@typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // eslint-disable-next-line prefer-destructuring
    sequenceMap.id = sequenceMap[SequenceFields.sequenceId][0];
    return sequenceMap as GetSequencesResultSequenceMap;
}
