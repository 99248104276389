import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Toast, ToastType } from '../../store/toast';
import * as toastActions from '../../store/toast/actions';
import { generateUUID } from '../../utils/uuid-helpers';

class ErrorBoundary extends React.Component<AllProps> {
    public componentDidCatch(): void {
        const { addToast } = this.props;

        addToast({
            id: generateUUID(),
            type: ToastType.Error,
            messages: ['Something unexpectedly went wrong.'],
        });
    }

    public render(): React.ReactNode {
        const { children } = this.props;

        return children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addToast: (toast: Toast) => dispatch(toastActions.addToast(toast)),
});

export default withTranslation()(connect(null, mapDispatchToProps)(ErrorBoundary));

interface PropsFromState {
    addToast: typeof toastActions.addToast;
}

type AllProps = PropsFromState & WithTranslation;
