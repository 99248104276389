import { SearchAndRelateTaxonomyLevel } from '../../api/generated';

export interface RenderItem {
    y?: number,
    height?: number,

}

export enum TAXONOMY_ITEM_TYPE {
    SINGLE = "SINGLE",
    COLLAPSED = "COLLAPSED",
}

export interface TaxonomyItem extends RenderItem{
    id: string,
    depth: number,
    color: string,
    value: number,
    name: string,
    parentId: string,
    parent?: TaxonomyItem,
    type: string,
}

export interface TaxonomyLevelItem extends TaxonomyItem{
    node: SearchAndRelateTaxonomyLevel,
}

export interface TaxonomyCollapsedItems extends TaxonomyItem {
    items: TaxonomyLevelItem[],
}

export interface LevelDescription {
    name?: string,
    depth: number,
    variants?: number,
}

export type TaxonomyRenderItem = TaxonomyLevelItem | TaxonomyCollapsedItems

export interface FlattenTx {
    items: TaxonomyLevelItem[],
    total: number;
    levelDescriptions: LevelDescription[];
}
