import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';
import { PipelinePipeline } from '../../api/generated';

export enum DatasetsActionTypes {
    GET_DATASETS_FOR_ORGANIZATION_REQUEST = '@@workspaces/GET_DATASETS_FOR_ORGANIZATION_REQUEST',
    GET_DATASETS_FOR_ORGANIZATION_SUCCESS = '@@workspaces/GET_DATASETS_FOR_ORGANIZATION_SUCCESS',
    GET_DATASETS_FOR_ORGANIZATION_FAILURE = '@@workspaces/GET_DATASETS_FOR_ORGANIZATION_FAILURE',

    GET_DATASETS_FOR_WORKSPACE_REQUEST = '@@workspaces/GET_DATASETS_FOR_WORKSPACE_REQUEST',
    GET_DATASETS_FOR_WORKSPACE_SUCCESS = '@@workspaces/GET_DATASETS_FOR_WORKSPACE_SUCCESS',
    GET_DATASETS_FOR_WORKSPACE_FAILURE = '@@workspaces/GET_DATASETS_FOR_WORKSPACE_FAILURE',

    LINK_DATASETS_TO_WORKSPACE_REQUEST = '@@workspaces/LINK_DATASETS_TO_WORKSPACE_REQUEST',
    LINK_DATASETS_TO_WORKSPACE_SUCCESS = '@@workspaces/LINK_DATASETS_TO_WORKSPACE_SUCCESS',
    LINK_DATASETS_TO_WORKSPACE_FAILURE = '@@workspaces/LINK_DATASETS_TO_WORKSPACE_FAILURE',

    UNLINK_DATASETS_FROM_WORKSPACE_REQUEST = '@@workspaces/UNLINK_DATASETS_FROM_WORKSPACE_REQUEST',
    UNLINK_DATASETS_FROM_WORKSPACE_SUCCESS = '@@workspaces/UNLINK_DATASETS_FROM_WORKSPACE_SUCCESS',
    UNLINK_DATASETS_FROM_WORKSPACE_FAILURE = '@@workspaces/UNLINK_DATASETS_FROM_WORKSPACE_FAILURE',

    START_DATASET_PROCESSING = '@@workspaces/START_DATASET_PROCESSING',

    WATCH_DATASET_PROCESSING = '@@workspaces/WATCH_DATASET_PROCESSING',
    UPDATE_DATASET_PIPELINE_STATUS = '@@workspaces/UPDATE_DATASET_PIPELINE_STATUS',
}

export interface DatasetsState {
    readonly datasetPipelinesMap?: {[key:string]: PipelinePipeline};
    readonly datasetsForOrganization?: Dataset[];
    readonly datasetsForWorkspace?: Dataset[];
    readonly getDatasetsForOrganizationRequestStatus?: RequestStatus;
    readonly getDatasetsForWorkspaceRequestStatus?: RequestStatus;
    readonly linkDatasetsToWorkspaceRequestStatus?: RequestStatus;
    readonly unlinkDatasetsFromWorkspaceRequestStatus?: RequestStatus;
}

export type DatasetsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface Dataset {
    id: string;
    owner?: string;
    name: string;
    createdOn: string;
    lastUpdatedOn: string;
    status?: string
}

export enum DatasetStatus {
    ACTIVE= 'ACTIVE',
    PENDING= 'PENDING',
}

export interface DatasetWithLimit {
    id: string;
    limit?: number;
}

export interface LinkDatasetsToWorkspaceRequestParameters {
    organizationId: string;
    workspaceId: string;
    datasetIds: string[];
}

export interface UnlinkDatasetsFromWorkspaceRequestParameters {
    organizationId: string;
    workspaceId: string;
    datasetIds: string[];
}
