import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElementChangeEvent, StripeCardElementOptions, StripeElements } from '@stripe/stripe-js';
import { TFunction } from 'i18next';
import * as React from 'react';
import { FormEvent } from 'react';
import { RequestStatus } from '../../../../../../store/shared/types';
import { SetupIntent } from '../../../../../../store/subscription';
import styles from './credit-card-input.module.scss';

class CreditCardInput extends React.Component<AllProps, AllState> {
    private cardElementOptions: StripeCardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                'color': '#32325d',
                'fontFamily': '"Open Sans", sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    public constructor(props: AllProps) {
        super(props);

        this.state = {
            inProgress: false,
            error: undefined,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const { setupIntent } = this.props;

        if (
            setupIntent &&
            (!prevProps.setupIntent || setupIntent.clientSecret !== prevProps.setupIntent.clientSecret)
        ) {
            this.confirmSetup();
        }
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const { inProgress, error } = this.state;

        return (
            <form className={styles.form} onSubmit={(event): void => this.submitPaymentMethod(event)}>
                <div className={styles.cardInput}>
                    <CardElement
                        id='card-element'
                        options={this.cardElementOptions}
                        onChange={(event): void => this.changePaymentMethod(event)}
                    />
                    <div className={styles.cardErrors} role='alert'>
                        {error}
                    </div>
                </div>
                <button className={styles.button} type='submit'>
                    {inProgress ? (
                        <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                    ) : (
                        t('Add card')
                    )}
                </button>
            </form>
        );
    }

    private submitPaymentMethod(event: FormEvent): void {
        event.preventDefault();

        const { elements, onAddCard } = this.props;

        if (elements) {
            const cardElement = elements.getElement(CardElement);
            if (cardElement) {
                cardElement.update({
                    disabled: true,
                });
            }
        }

        this.setState({ inProgress: true });

        onAddCard();
    }

    private changePaymentMethod(event: StripeCardElementChangeEvent): void {
        if (event.error) {
            this.setState({ error: event.error.message });
        } else {
            this.setState({ error: undefined });
        }
    }

    private confirmSetup(): void {
        const { stripe, elements, setupIntent, onCardConfirmed } = this.props;

        if (stripe && elements && setupIntent) {
            const cardElement = elements.getElement(CardElement);
            if (cardElement) {
                stripe
                    .confirmCardSetup(setupIntent.clientSecret, {
                        payment_method: {
                            card: cardElement,
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            this.setState({ error: result.error.message });
                        }
                        if (result.setupIntent && result.setupIntent.payment_method) {
                            this.setState({ error: undefined });

                            onCardConfirmed(result.setupIntent.payment_method);
                        }
                    })
                    .finally(() => {
                        cardElement.clear();
                        cardElement.update({
                            disabled: false,
                        });

                        this.setState({ inProgress: false });
                    });
            }
        }
    }
}

export default CreditCardInput;

interface OwnProps {
    t: TFunction;
    stripe: Stripe | null;
    elements: StripeElements | null;
    createSetupIntentRequestStatus?: RequestStatus;
    setupIntent?: SetupIntent;
    onAddCard: () => void;
    onCardConfirmed: (paymentMethodId: string) => void;
}

type AllProps = OwnProps;

interface OwnState {
    inProgress: boolean;
    error?: string;
}

type AllState = OwnState;
