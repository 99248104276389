import { Button } from '@material-ui/core';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import QueryMetadataPopup from '../../pages/results/components/query-metadata-popup/query-metadata-popup';
import { PopupType, showPopup } from '../../store/popup';
import ErrorPanelBase from './error-panel-base';

class EmptyPanel extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { style, t } = this.props;

        return (
            <ErrorPanelBase
                style={style}
                title={t("We couldn't find any results matching your query and filter.")}
                subtitle={t("We couldn't find any results matching your query and filter.")}>
                <Button
                    style={{ minWidth: 145, paddingLeft: 0, paddingRight: 0 }}
                    onClick={(): void => this.openQueryDetails()}
                    variant='contained'
                    color='primary'>
                    {t('Show query details')}
                </Button>
            </ErrorPanelBase>
        );
    }

    private openQueryDetails(): void {
        const { dispatchShowPopup } = this.props;
        dispatchShowPopup({
            type: PopupType.QUERY_METADATA,
            content: <QueryMetadataPopup />,
            isDismissible: true,
        });
    }
}

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchShowPopup: (popup) => dispatch(showPopup(popup)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(EmptyPanel));

interface PropsFromState {}

interface PropsFromDispatch {
    dispatchShowPopup: typeof showPopup;
}

interface OwnProps {
    style?: {};
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;
