import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import {
    CreateMembershipRequestParameters,
    CreateWorkspaceRequestParameters,
    DeleteMembershipRequestParameters,
    DeleteWorkspaceRequestParameters,
    UpdateDefaultMembershipRequestParameters,
    UpdateMembershipRequestParameters,
    WorkspaceInfo,
    WorkspaceMembership,
    WorkspacesActionTypes,
} from './types';

export const getWorkspacesForOrganizationRequest = (organizationId: string) =>
    action(WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_REQUEST, organizationId);
export const getWorkspacesForOrganizationSuccess = (workspaces: WorkspaceInfo[]) =>
    action(WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_SUCCESS, workspaces);
export const getWorkspacesForOrganizationFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.GET_WORKSPACES_FOR_ORGANIZATION_FAILURE, failureCode);

export const createWorkspaceRequest = (parameters: CreateWorkspaceRequestParameters) =>
    action(WorkspacesActionTypes.CREATE_WORKSPACE_REQUEST, parameters);
export const createWorkspaceSuccess = (workspace: WorkspaceInfo) =>
    action(WorkspacesActionTypes.CREATE_WORKSPACE_SUCCESS, workspace);
export const createWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.CREATE_WORKSPACE_FAILURE, failureCode);

export const updateMembershipRequest = (parameters: UpdateMembershipRequestParameters) =>
    action(WorkspacesActionTypes.UPDATE_MEMBERSHIP_REQUEST, parameters);
export const updateMembershipSuccess = (membership: WorkspaceMembership) =>
    action(WorkspacesActionTypes.UPDATE_MEMBERSHIP_SUCCESS, membership);
export const updateMembershipFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.UPDATE_MEMBERSHIP_FAILURE, failureCode);

export const getMembershipsForWorkspaceRequest = (organizationId: string, workspaceId: string) =>
    action(WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_REQUEST, [organizationId, workspaceId] as [
        string,
        string,
    ]);
export const getMembershipsForWorkspaceSuccess = (memberships: WorkspaceMembership[]) =>
    action(WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_SUCCESS, memberships);
export const getMembershipsForWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.GET_MEMBERSHIPS_FOR_WORKSPACE_FAILURE, failureCode);

export const updateDefaultMembershipRequest = (parameters: UpdateDefaultMembershipRequestParameters) =>
    action(WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_REQUEST, parameters);
export const updateDefaultMembershipSuccess = () => action(WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_SUCCESS);
export const updateDefaultMembershipFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.UPDATE_DEFAULT_MEMBERSHIP_FAILURE, failureCode);

export const deleteWorkspaceRequest = (parameters: DeleteWorkspaceRequestParameters) =>
    action(WorkspacesActionTypes.DELETE_WORKSPACE_REQUEST, parameters);
export const deleteWorkspaceSuccess = (workspaceId: string) =>
    action(WorkspacesActionTypes.DELETE_WORKSPACE_SUCCESS, workspaceId);
export const deleteWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.DELETE_WORKSPACE_FAILURE, failureCode);

export const createMembershipForWorkspaceRequest = (parameters: CreateMembershipRequestParameters) =>
    action(WorkspacesActionTypes.CREATE_MEMBERSHIP_REQUEST, parameters);
export const createMembershipForWorkspaceSuccess = (membership: WorkspaceMembership) =>
    action(WorkspacesActionTypes.CREATE_MEMBERSHIP_SUCCESS, membership);
export const createMembershipForWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.CREATE_MEMBERSHIP_FAILURE, failureCode);

export const deleteMembershipForWorkspaceRequest = (parameters: DeleteMembershipRequestParameters) =>
    action(WorkspacesActionTypes.DELETE_MEMBERSHIP_REQUEST, parameters);
export const deleteMembershipForWorkspaceSuccess = (email: string) =>
    action(WorkspacesActionTypes.DELETE_MEMBERSHIP_SUCCESS, email);
export const deleteMembershipForWorkspaceFailure = (failureCode?: FailureCodes) =>
    action(WorkspacesActionTypes.DELETE_MEMBERSHIP_FAILURE, failureCode);
