import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Dropdown, { DropdownOption } from '../../../../../../components/dropdown/dropdown';
import { ApplicationState } from '../../../../../../store';
import { AuthorizationContext, OrganizationRole } from '../../../../../../store/global';
import { closePopup } from '../../../../../../store/popup';
import { FailureCodes, RequestStatus } from '../../../../../../store/shared/types';
import { addToast, ToastType } from '../../../../../../store/toast';
import { inviteUserRequest, InviteUserRequestParameters } from '../../../../../../store/users';
import { getWorkspacesForOrganizationRequest, WorkspaceInfo } from '../../../../../../store/workspaces';
import { AdminBasePage } from '../../../../admin-base.page';
import styles from './invite-user-popup.module.scss';

class InviteUserPopup extends AdminBasePage<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);
        const { workspaces, t, authorizationContext, dispatchGetWorkspacesForOrganization } = this.props;
        const availableWorkspaces = this.createWorkspacesDropDownOptions(workspaces);
        const selectedWorkspace = availableWorkspaces.length > 0 ? availableWorkspaces[0] : undefined;
        const availableRoles: DropdownOption[] = [
            { id: OrganizationRole.REGULAR, label: t('Regular User') },
            { id: OrganizationRole.ADMIN, label: t('Administrator') },
        ];
        if (authorizationContext) {
            dispatchGetWorkspacesForOrganization(authorizationContext.organization.id);
        }

        this.state = {
            email: '',
            showFeedback: false,
            availableWorkspaces,
            selectedWorkspace,
            availableRoles,
            selectedRole: availableRoles[0],
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const { workspaces, t, requestStatus, dispatchClosePopup } = this.props;

        if (workspaces && workspaces !== prevProps.workspaces) {
            const availableWorkspaces = this.createWorkspacesDropDownOptions(workspaces);
            const selectedWorkspace = availableWorkspaces.length > 0 ? availableWorkspaces[0] : undefined;
            this.setState({ availableWorkspaces, selectedWorkspace });
        }

        if (requestStatus && prevProps.requestStatus !== requestStatus) {
            if (requestStatus.isSuccess) {
                dispatchClosePopup();
                this.showToast(ToastType.Success, t('User Successfully Invited'));
            }

            if (requestStatus.isFailed) {
                this.showToast(ToastType.Error, this.getMessageForFailureCode(requestStatus));
            }
        }
    }

    public render(): JSX.Element {
        const { t, requestStatus } = this.props;
        const {
            selectedWorkspace,
            showFeedback,
            feedbackMessage,
            availableWorkspaces,
            availableRoles,
            selectedRole,
        } = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                        <div className={styles.title}>{t('Invite User')}</div>
                        <div className={styles.form} onKeyDown={(e) => this.onKeyPressed(e)}>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Email')}</div>
                                <input
                                    className={styles.emailInput}
                                    type='text'
                                    key='EmailField'
                                    autoFocus
                                    placeholder={t('james@organization.com')}
                                    onChange={(e) => this.onEmailChange(e)}
                                />
                            </div>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Default Workspace')}</div>
                                <Dropdown
                                    selection={selectedWorkspace}
                                    options={availableWorkspaces}
                                    onSelectionChanged={(option: DropdownOption) =>
                                        this.onWorkspaceSelectionChanged(option)
                                    }
                                />
                            </div>
                            <div className={styles.field}>
                                <div className={styles.label}>{t('Role')}</div>
                                <Dropdown
                                    selection={selectedRole}
                                    options={availableRoles}
                                    onSelectionChanged={(option: DropdownOption) => this.onRoleSelectionChanged(option)}
                                />
                            </div>
                        </div>
                        <div className={styles.feedbackMessage}>{showFeedback ? feedbackMessage : ''}</div>
                        <div className={styles.buttons}>
                            <button
                                disabled={requestStatus?.isInProgress}
                                className={`${styles.button} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onInviteUser()}>
                                {requestStatus?.isInProgress ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                    t('Invite User')
                                )}
                            </button>
                            <button
                                disabled={requestStatus?.isInProgress}
                                className={`${styles.button} ${styles.negativePrimary} ${styles.popupButton}`}
                                type='button'
                                onClick={() => this.onClose()}>
                                {t('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onKeyPressed(e): void {
        const { requestStatus } = this.props;

        if (!requestStatus?.isInProgress && e.keyCode === 13) {
            this.onInviteUser();
        }
    }

    private getMessageForFailureCode(requestStatus: RequestStatus): string {
        const { t } = this.props;
        const { email } = this.state;

        switch (requestStatus.failureCode) {
            case FailureCodes.WS5:
                return t('Email {{email}} already has an account associated with it.', { email });
            default:
                return t('User invite failed, please contact your administrator if this error persists.');
        }
    }

    private onWorkspaceSelectionChanged(selectedWorkspace: DropdownOption): void {
        this.setState({ selectedWorkspace });
    }

    private onRoleSelectionChanged(selectedRole: DropdownOption): void {
        this.setState({ selectedRole });
    }

    private onEmailChange(e): void {
        this.setState({ email: e.target.value });
    }

    private onClose(): void {
        const { dispatchClosePopup } = this.props;
        dispatchClosePopup();
    }

    private onInviteUser(): void {
        const { dispatchInviteUser, authorizationContext } = this.props;
        const { email, selectedWorkspace, selectedRole } = this.state;

        if (this.validateForm() && selectedWorkspace && selectedRole) {
            dispatchInviteUser({
                email,
                workspaceId: selectedWorkspace.id,
                organizationRole: OrganizationRole[selectedRole.id],
                organizationId: authorizationContext!.organization.id,
            });
        }
    }

    private createWorkspacesDropDownOptions(workspaces?: WorkspaceInfo[]): DropdownOption[] {
        return workspaces
            ? workspaces.map((w) => {
                  return { id: w.id, label: w.name };
              })
            : [];
    }

    private validateForm(): boolean {
        const { email } = this.state;

        if (!email || email.length < 6) {
            this.setState({
                showFeedback: true,
                feedbackMessage: 'Email field is required',
            });
            return false;
        }

        return true;
    }
}

const mapStateToProps = ({ users, workspaces, global }: ApplicationState): PropsFromState => ({
    authorizationContext: global.authorizationContext,
    requestStatus: users.inviteUserRequestStatus,
    workspaces: workspaces.workspacesForOrganization,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchInviteUser: (parameters: InviteUserRequestParameters) => dispatch(inviteUserRequest(parameters)),
    dispatchGetWorkspacesForOrganization: (organizationId: string) =>
        dispatch(getWorkspacesForOrganizationRequest(organizationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteUserPopup));

interface OwnProps {}

interface PropsFromState {
    authorizationContext?: AuthorizationContext;
    workspaces?: WorkspaceInfo[];
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchAddToast: typeof addToast;
    dispatchClosePopup: typeof closePopup;
    dispatchInviteUser: typeof inviteUserRequest;
    dispatchGetWorkspacesForOrganization: typeof getWorkspacesForOrganizationRequest;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    availableWorkspaces: DropdownOption[];
    selectedWorkspace?: DropdownOption;
    availableRoles: DropdownOption[];
    selectedRole: DropdownOption;
    showFeedback: boolean;
    feedbackMessage?: string;
}

type AllState = OwnState;
