import { action } from 'typesafe-actions';
import { FailureCodes } from '../shared/types';
import { AuthorizationContext, CreateOrganizationRequestParameters, GlobalActionTypes, InstanceType } from './types';

export const setInstanceType = (type: InstanceType) => action(GlobalActionTypes.SET_INSTANCE_TYPE, type);
export const setActiveWorkspace = (workspaceId: string) => action(GlobalActionTypes.SET_ACTIVE_WORKSPACE, workspaceId);
export const markInitialAuthorizationFlowAsFinshed = () =>
    action(GlobalActionTypes.MARK_INITIAL_AUTHORIZATION_FLOW_AS_FINISHED);

export const getAuthorizationContext = () => action(GlobalActionTypes.GET_AUTHORIZATION_CONTEXT);
export const getAuthorizationContextSuccess = (authorizationContext: AuthorizationContext) =>
    action(GlobalActionTypes.GET_AUTHORIZATION_CONTEXT_SUCCESS, authorizationContext);
export const getAuthorizationContextFailure = (errorCode?: FailureCodes) =>
    action(GlobalActionTypes.GET_AUTHORIZATION_CONTEXT_FAILURE, errorCode);

export const createOrganizationRequest = (parameters: CreateOrganizationRequestParameters) =>
    action(GlobalActionTypes.CREATE_ORGANIZATION_REQUEST, parameters);
export const createOrganizationSuccess = () => action(GlobalActionTypes.CREATE_ORGANIZATION_SUCCESS);
export const createOrganizationFailure = (errorCode?: FailureCodes) =>
    action(GlobalActionTypes.CREATE_ORGANIZATION_FAILURE, errorCode);
