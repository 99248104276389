import { SearchHistoryEntry, SearchSettings } from '../store/search';

export function retrieveParsedFromLocalStorage<T>(key: string): T | null {
    return JSON.parse(localStorage.getItem(key) || 'null');
}

export function persistParsedToLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
}

export function localSearchHistoryKey(workspaceId: string): string {
    return `SearchHistory_V3_${workspaceId}`;
}

export function localSearchSettingsKey(workspaceId: string): string {
    return `SearchSettings_V4_${workspaceId}`;
}

export function getSearchHistory( workspaceId: string ): SearchHistoryEntry[] | null {
    return retrieveParsedFromLocalStorage<SearchHistoryEntry[]>(localSearchHistoryKey(workspaceId));
}

export function updateSearchHistory( workspaceId: string, history: SearchHistoryEntry[] ): void {
    return persistParsedToLocalStorage<SearchHistoryEntry[]>(localSearchHistoryKey(workspaceId), history);
}
// TODO: @Illia: replace raw call in code

export function getSearchSettings( workspaceId: string ): SearchSettings | null {
    return retrieveParsedFromLocalStorage<SearchSettings>(localSearchSettingsKey(workspaceId));
}

export function updateSearchSettings( workspaceId: string, settings: SearchSettings ) {
    return persistParsedToLocalStorage<SearchSettings>(localSearchSettingsKey(workspaceId), settings);
}
