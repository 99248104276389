import * as React from 'react';
import styles from './empty-panel.module.scss';

interface EmptyPanelProps {
    style?: {};
    title?: string;
    subtitle?: string;
    children?: JSX.Element;
}

const ErrorPanelBase = (props: EmptyPanelProps) => {

        const { style, children, title, subtitle } = props;

        return (
            <div className={styles.container} style={style}>
                {title && <div className={styles.title}>{title}</div>}
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {children}
            </div>
        );
}

export default ErrorPanelBase;
