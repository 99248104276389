export enum RouteUrl {
    Root = '/',
    Login = '/login',
    ForgotPassword = '/forgot-pwd',
    ConfirmationUnderway = '/check-confirmation',
    ConfirmPassword = '/confirm-pwd',
    CreateOrganizationPage = '/create-organization',

    Search = '/search',
    Alignment = '/results/alignment-view',
    QuickFilter = '/results/quick-filter-view',
    List = '/results/list-view',
    Explorer = '/results/explorer-view',
    ManageUsers = '/admin/organization/users',
    ManageWorkspaces = '/admin/organization/workspaces',
    ManageBilling = '/admin/organization/billing',
    GetAPIToken = '/admin/organization/apitoken',
    ManageMembers = '/admin/workspace/members',
    ManageOrganizationDatasets = '/admin/organization/datasets',
    ManageWorkspaceDatasets = '/admin/workspace/datasets',
}
