import { Http, WorkspaceResponse } from '../http/http';
import {
    Dataset,
    LinkDatasetsToWorkspaceRequestParameters,
    UnlinkDatasetsFromWorkspaceRequestParameters,
} from '../store/datasets';
import { ApiType, ApiUrl } from './api-urls';

export class DatasetsApi {
    public static async getDatasetsForOrganization(): Promise<WorkspaceResponse<Dataset[]>> {
        return Http.get<WorkspaceResponse<Dataset[]>>(ApiType.Gateway, ApiUrl.GetDatasetsForOrganization)
    }

    public static async linkDatasetsToWorkspace(
        parameters: LinkDatasetsToWorkspaceRequestParameters,
    ): Promise<WorkspaceResponse<Dataset[]>> {
        return Http.post<LinkDatasetsToWorkspaceRequestParameters, WorkspaceResponse<Dataset[]>>(
                  ApiType.Gateway,
                  ApiUrl.LinkDatasetsToWorkspace,
                  parameters,
                  {
                      organizationId: parameters.organizationId,
                      workspaceId: parameters.workspaceId,
                  },
              )
    }

    public static async unlinkDatasetsFromWorkspace(
        parameters: UnlinkDatasetsFromWorkspaceRequestParameters,
    ): Promise<WorkspaceResponse<void>> {
        return Http.post<UnlinkDatasetsFromWorkspaceRequestParameters, WorkspaceResponse<void>>(
                  ApiType.Gateway,
                  ApiUrl.UnlinkDatasetsFromWorkspace,
                  parameters,
                  {
                      organizationId: parameters.organizationId,
                      workspaceId: parameters.workspaceId,
                  },
              )
    }
}
