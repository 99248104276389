import { faFilter as falFilter, faSortAmountDown, faSortAmountUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import React, { MouseEvent, RefObject } from 'react';
import capitalize from 'lodash.capitalize';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../../../store';
import { Dimension } from '../../../../../../store/search';
import { ListSorting, SortDirection } from '../../../../../../types/sorting';
import { conditionalClassList } from '../../../../../../utils/class-helpers';
import { resultListWidthPerUnit } from './results-list';
import styles from './results-list-header.module.scss';
import { ColumnConfig } from './column-config';

class ResultsListHeader extends React.Component<AllProps, AllState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            columnReferences: {},
            columnWidths: {},
        };
    }

    public componentDidMount(): void {
        const { columns } = this.props;
        const { columnWidths } = this.state;

        if (columns) {
            this.setState({
                columnReferences: this.initializeColumnReferences(columns),
                columnWidths: this.initializeColumnWidths(columns, columnWidths),
            });
        }
    }

    public componentDidUpdate(previousProps: Readonly<AllProps>, previousState: Readonly<AllState>): void {
        const { columns, onChangeReferences } = this.props;
        const { columnReferences, columnWidths } = this.state;

        if (columns && columns !== previousProps.columns) {
            this.setState({
                columnReferences: this.initializeColumnReferences(columns),
                columnWidths: this.initializeColumnWidths(columns, columnWidths),
            });
        }

        if (columnReferences !== previousState.columnReferences) {
            onChangeReferences(columnReferences);
        }
    }

    public render(): JSX.Element {
        const { columns, isToolbarOpen, sorting, dimensions, isResizing, resizingColumnKey } = this.props;
        const { columnReferences } = this.state;

        const containerClasses = conditionalClassList(styles, {
            headerContainer: true,
            toolbarOpen: isToolbarOpen,
        });

        return (
            <div className={containerClasses} ref={columnReferences.container}>
                <div className={styles.rowSpacer} />
                <div className={`${styles.columnHeader} ${styles.selector}`} />
                {columns.map((columnConfig) => {
                    const canFilterOnDimension = dimensions.findIndex((d) => d.name === columnConfig.filterKey) !== -1;
                    return (
                        <div
                            key={columnConfig.key}
                            className={`${styles.columnHeader} ${isResizing ? styles.columnHeaderResizing : ''} ${
                                !columnConfig.canBeSorted ? styles.columnHeaderNotSortable : ''
                            }`}
                            style={{
                                width: resultListWidthPerUnit * columnConfig.widthUnits,
                                minWidth: resultListWidthPerUnit * columnConfig.widthUnits,
                            }}
                            ref={columnReferences[columnConfig.key]}>
                            <span
                                className={`${styles.columnHeaderLabel} ${
                                    !columnConfig.canBeSorted ? styles.columnHeaderLabelNotSortable : ''
                                }`}
                                onClick={(): void => this.onToggleSorting(columnConfig)}>
                                {capitalize(columnConfig.label)}
                            </span>
                            {columnConfig.canBeSorted && sorting.columnKey === columnConfig.key ? (
                                sorting.direction === SortDirection.Descending ? (
                                    <FontAwesomeIcon
                                        icon={faSortAmountDown}
                                        className={styles.sortingIcon}
                                        onClick={(): void => this.onToggleSorting(columnConfig)}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faSortAmountUp}
                                        className={styles.sortingIcon}
                                        onClick={(): void => this.onToggleSorting(columnConfig)}
                                    />
                                )
                            ) : null}
                            {canFilterOnDimension ? (
                                <FontAwesomeIcon
                                    icon={falFilter}
                                    className={styles.filterIcon}
                                    onClick={(event): void => this.onOpenFilter(columnConfig.filterKey)}
                                />
                            ) : null}
                            <span
                                className={`${styles.resizeHandle} ${
                                    isResizing && columnConfig.key === resizingColumnKey
                                        ? styles.resizeHandleFullSize
                                        : ''
                                }`}
                                ref={columnReferences[`${columnConfig.key}ResizeHandle`]}
                                onMouseDown={(event): void => this.onResizeStart(event, columnConfig.key)}
                                onMouseMove={(event): void => this.onResizeMove(event, columnConfig.key)}
                                onMouseUp={(): void => this.onResizeEnd()}
                                onMouseLeave={(): void => this.onResizeEnd()}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    public onToggleSorting(columnConfig: ColumnConfig): void {
        if (columnConfig.canBeSorted) {
            const { sorting, onChangeSorting } = this.props;

            const newSorting = (): ListSorting => {
                if (sorting.columnKey === columnConfig.key) {
                    return sorting.direction === SortDirection.Descending
                        ? {
                              columnKey: columnConfig.key,
                              direction: SortDirection.Ascending,
                          }
                        : {
                              columnKey: '',
                              direction: SortDirection.Descending,
                          };
                } else {
                    return {
                        columnKey: columnConfig.key,
                        direction: SortDirection.Descending,
                    };
                }
            };

            onChangeSorting(newSorting());
        }
    }

    private onOpenFilter(dimensionName: string): void {
        const { onOpenFilter } = this.props;
        onOpenFilter(dimensionName);
    }

    public onResizeStart(event: MouseEvent, columnKey: string): void {
        const { onChangeWidths, onChangeResizing } = this.props;
        const { columnWidths } = this.state;

        const newColumnWidths = {
            ...columnWidths,
            [`${columnKey}Original`]: columnWidths[columnKey],
        };

        this.setState({
            columnWidths: newColumnWidths,
        });

        onChangeWidths(newColumnWidths);
        onChangeResizing(true, columnKey, event.clientX);
    }

    public onResizeMove(event: MouseEvent, columnKey: string): void {
        const { isResizing, resizingColumnKey, resizeStartX, onChangeWidths } = this.props;
        const { columnWidths } = this.state;

        if (isResizing && columnKey === resizingColumnKey) {
            const width = columnWidths[`${columnKey}Original`] - (resizeStartX - event.clientX);
            const newColumnWidths = {
                ...columnWidths,
                [columnKey]: width,
            };

            this.setState({ columnWidths: newColumnWidths });

            onChangeWidths(newColumnWidths);
        }
    }

    public onResizeEnd(): void {
        const { resizingColumnKey, resizeStartX, onChangeResizing } = this.props;
        onChangeResizing(false, resizingColumnKey, resizeStartX);
    }

    private initializeColumnReferences(columns: ColumnConfig[]): ColumnReferenceMap {
        return columns.reduce(
            (references, column) => {
                return {
                    ...references,
                    [column.key]: React.createRef<HTMLDivElement>(),
                    [`${column.key}ResizeHandle`]: React.createRef<HTMLDivElement>(),
                };
            },
            { container: React.createRef<HTMLDivElement>() },
        );
    }

    private initializeColumnWidths(columns: ColumnConfig[], columnWidths: ColumnWidthMap): ColumnWidthMap {
        return columns.reduce((widths, column) => {
            return {
                ...widths,
                [column.key]: columnWidths[column.key]
                    ? columnWidths[column.key]
                    : resultListWidthPerUnit * column.widthUnits,
            };
        }, {});
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = ({ search, router }: ApplicationState) => ({
    currentLocation: router.location,
});

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResultsListHeader));

interface PropsFromState {
    currentLocation: LocationDescriptorObject;
}

interface PropsFromDispatch {
    navigateTo: (location: LocationDescriptorObject) => void;
}

interface OwnProps {
    dimensions: Dimension[];
    isToolbarOpen: boolean;
    columns: ColumnConfig[];
    sorting: ListSorting;
    isResizing: boolean;
    resizingColumnKey: string;
    resizeStartX: number;
    onOpenFilter: (dimensionName: string) => void;
    onChangeSorting: (sorting: ListSorting) => void;
    onChangeWidths: (widths: ColumnWidthMap) => void;
    onChangeResizing: (isResizing: boolean, resizingColumnKey: string, resizeStartX: number) => void;

    onChangeReferences: (references: ColumnReferenceMap) => void;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    columnReferences: ColumnReferenceMap;
    columnWidths: ColumnWidthMap;
}

type AllState = OwnState;

export interface ColumnWidthMap {
    [columnKey: string]: number;
}

export interface ColumnReferenceMap {
    [columnKey: string]: RefObject<HTMLDivElement>;
}
