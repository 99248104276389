import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './results-page-overlay.module.scss';

class ResultsPageOverlay extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { show, t } = this.props;

        const overlayClasses = conditionalClassList(styles, {
            show,
            overlay: true,
        });

        return (
            <div
                className={overlayClasses}
                onClick={() => {
                    this.onClick();
                }}>
                <div className={styles.redrawBar}>{t('Click here to apply changes')}</div>
                <div className={styles.mask} />
            </div>
        );
    }

    public onClick(): void {
        const { onClick } = this.props;
        onClick();
    }
}

export default withTranslation()(ResultsPageOverlay);

interface OwnProps {
    show: boolean;
    onClick: () => void;
}

type AllProps = OwnProps & WithTranslation;
