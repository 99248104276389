import { ActionType } from 'typesafe-actions';
import { WorkspaceRole } from '../global';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

// export const ERROR_CODE_DEFAULT_MEMBERSHIP_EXISTS = 'Workspace has default memberships';

export enum WorkspacesActionTypes {
    GET_WORKSPACES_FOR_ORGANIZATION_REQUEST = '@@workspaces/GET_WORKSPACES_FOR_ORGANIZATION_REQUEST',
    GET_WORKSPACES_FOR_ORGANIZATION_SUCCESS = '@@workspaces/GET_WORKSPACES_FOR_ORGANIZATION_SUCCESS',
    GET_WORKSPACES_FOR_ORGANIZATION_FAILURE = '@@workspaces/GET_WORKSPACES_FOR_ORGANIZATION_FAILURE',

    CREATE_WORKSPACE_REQUEST = '@@workspaces/CREATE_WORKSPACE_REQUEST',
    CREATE_WORKSPACE_SUCCESS = '@@workspaces/CREATE_WORKSPACE_SUCCESS',
    CREATE_WORKSPACE_FAILURE = '@@workspaces/CREATE_WORKSPACE_FAILURE',

    UPDATE_MEMBERSHIP_REQUEST = '@@workspaces/UPDATE_MEMBERSHIP_REQUEST',
    UPDATE_MEMBERSHIP_SUCCESS = '@@workspaces/UPDATE_MEMBERSHIP_SUCCESS',
    UPDATE_MEMBERSHIP_FAILURE = '@@workspaces/UPDATE_MEMBERSHIP_FAILURE',

    GET_MEMBERSHIPS_FOR_WORKSPACE_REQUEST = '@@workspaces/GET_MEMBERSHIPS_FOR_WORKSPACE_REQUEST',
    GET_MEMBERSHIPS_FOR_WORKSPACE_SUCCESS = '@@workspaces/GET_MEMBERSHIPS_FOR_WORKSPACE_SUCCESS',
    GET_MEMBERSHIPS_FOR_WORKSPACE_FAILURE = '@@workspaces/GET_MEMBERSHIPS_FOR_WORKSPACE_FAILURE',

    UPDATE_DEFAULT_MEMBERSHIP_REQUEST = '@@workspaces/UPDATE_DEFAULT_MEMBERSHIP_REQUEST',
    UPDATE_DEFAULT_MEMBERSHIP_SUCCESS = '@@workspaces/UPDATE_DEFAULT_MEMBERSHIP_SUCCESS',
    UPDATE_DEFAULT_MEMBERSHIP_FAILURE = '@@workspaces/UPDATE_DEFAULT_MEMBERSHIP_FAILURE',

    DELETE_WORKSPACE_REQUEST = '@@workspaces/DELETE_WORKSPACE_REQUEST',
    DELETE_WORKSPACE_SUCCESS = '@@workspaces/DELETE_WORKSPACE_SUCCESS',
    DELETE_WORKSPACE_FAILURE = '@@workspaces/DELETE_WORKSPACE_FAILURE',

    CREATE_MEMBERSHIP_REQUEST = '@@workspaces/CREATE_MEMBERSHIP_REQUEST',
    CREATE_MEMBERSHIP_SUCCESS = '@@workspaces/CREATE_MEMBERSHIP_SUCCESS',
    CREATE_MEMBERSHIP_FAILURE = '@@workspaces/CREATE_MEMBERSHIP_FAILURE',

    DELETE_MEMBERSHIP_REQUEST = '@@workspaces/DELETE_MEMBERSHIP_REQUEST',
    DELETE_MEMBERSHIP_SUCCESS = '@@workspaces/DELETE_MEMBERSHIP_SUCCESS',
    DELETE_MEMBERSHIP_FAILURE = '@@workspaces/DELETE_MEMBERSHIP_FAILURE',
}

export interface WorkspacesState {
    readonly workspacesForOrganization?: WorkspaceInfo[];
    readonly membershipsForWorkspace?: WorkspaceMembership[];
    readonly createWorkspaceRequestStatus?: RequestStatus;
    readonly getWorkspacesForOrganizationRequestStatus?: RequestStatus;
    readonly updateMembershipRequestStatus?: RequestStatus;
    readonly getMembershipsForWorkspaceRequestStatus?: RequestStatus;
    readonly updateDefaultMembershipRequestStatus?: RequestStatus;
    readonly deleteWorkspaceRequestStatus?: RequestStatus;
    readonly createMembershipRequestStatus?: RequestStatus;
    readonly deleteMembershipRequestStatus?: RequestStatus;
}

export type WorkspacesAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface WorkspaceInfo {
    id: string;
    name: string;
    membershipCount: number;
    datasetCount?: number;
    createdOn: string;
}

export interface CreateWorkspaceRequestParameters {
    name: string;
    organizationId: string;
    memberships: WorkspaceMembership[];
    datasetIds: string[];
}

export interface WorkspaceMembership {
    email: string;
    workspaceRole: WorkspaceRole;
}

export interface UpdateMembershipRequestParameters {
    email: string;
    workspaceRole: WorkspaceRole;
    workspaceId: string;
    organizationId: string;
}

export interface UpdateDefaultMembershipRequestParameters {
    email: string;
    workspaceId: string;
    organizationId: string;
}

export interface DeleteWorkspaceRequestParameters {
    workspaceId: string;
    organizationId: string;
}

export interface CreateMembershipRequestParameters {
    email: string;
    workspaceRole: WorkspaceRole;
    workspaceId: string;
    organizationId: string;
}

export interface DeleteMembershipRequestParameters {
    email: string;
    workspaceId: string;
    organizationId: string;
}
