import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum SubscriptionActionTypes {
    CREATE_SETUP_INTENT_REQUEST = '@@subscription/CREATE_SETUP_INTENT_REQUEST',
    CREATE_SETUP_INTENT_SUCCESS = '@@subscription/CREATE_SETUP_INTENT_SUCCESS',
    CREATE_SETUP_INTENT_FAILURE = '@@subscription/CREATE_SETUP_INTENT_FAILURE',

    GET_PAYMENT_METHODS_REQUEST = '@@subscription/GET_PAYMENT_METHODS_REQUEST',
    GET_PAYMENT_METHODS_SUCCESS = '@@subscription/GET_PAYMENT_METHODS_SUCCESS',
    GET_PAYMENT_METHODS_FAILURE = '@@subscription/GET_PAYMENT_METHODS_FAILURE',

    SET_DEFAULT_PAYMENT_METHOD_REQUEST = '@@subscription/SET_DEFAULT_PAYMENT_METHOD_REQUEST',
    SET_DEFAULT_PAYMENT_METHOD_SUCCESS = '@@subscription/SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    SET_DEFAULT_PAYMENT_METHOD_FAILURE = '@@subscription/SET_DEFAULT_PAYMENT_METHOD_FAILURE',

    DELETE_PAYMENT_METHOD_REQUEST = '@@subscription/DELETE_PAYMENT_METHOD_REQUEST',
    DELETE_PAYMENT_METHOD_SUCCESS = '@@subscription/DELETE_PAYMENT_METHOD_SUCCESS',
    DELETE_PAYMENT_METHOD_FAILURE = '@@subscription/DELETE_PAYMENT_METHOD_FAILURE',

    GET_CUSTOMER_REQUEST = '@@subscription/GET_CUSTOMER_REQUEST',
    GET_CUSTOMER_SUCCESS = '@@subscription/GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE = '@@subscription/GET_CUSTOMER_FAILURE',

    UPDATE_CUSTOMER_REQUEST = '@@subscription/UPDATE_CUSTOMER_REQUEST',
    UPDATE_CUSTOMER_SUCCESS = '@@subscription/UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAILURE = '@@subscription/UPDATE_CUSTOMER_FAILURE',

    GET_SUBSCRIPTION_REQUEST = '@@subscription/GET_SUBSCRIPTION_REQUEST',
    GET_SUBSCRIPTION_SUCCESS = '@@subscription/GET_SUBSCRIPTION_SUCCESS',
    GET_SUBSCRIPTION_FAILURE = '@@subscription/GET_SUBSCRIPTION_FAILURE',

    CANCEL_SUBSCRIPTION_REQUEST = '@@subscription/CANCEL_SUBSCRIPTION_REQUEST',
    CANCEL_SUBSCRIPTION_SUCCESS = '@@subscription/CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAILURE = '@@subscription/CANCEL_SUBSCRIPTION_FAILURE',

    RENEW_SUBSCRIPTION_REQUEST = '@@subscription/RENEW_SUBSCRIPTION_REQUEST',
    RENEW_SUBSCRIPTION_SUCCESS = '@@subscription/RENEW_SUBSCRIPTION_SUCCESS',
    RENEW_SUBSCRIPTION_FAILURE = '@@subscription/RENEW_SUBSCRIPTION_FAILURE',
}

export interface SubscriptionState {
    readonly setupIntent?: SetupIntent;
    readonly paymentMethods?: PaymentMethod[];
    readonly customer?: Customer;
    readonly subscription?: SubscriptionOverview;
    readonly createSetupIntentRequestStatus?: RequestStatus;
    readonly getPaymentMethodsRequestStatus?: RequestStatus;
    readonly setDefaultPaymentMethodRequestStatus?: RequestStatus;
    readonly deletePaymentMethodRequestStatus?: RequestStatus;
    readonly getCustomerRequestStatus?: RequestStatus;
    readonly updateCustomerRequestStatus?: RequestStatus;
    readonly getSubscriptionRequestStatus?: RequestStatus;
    readonly cancelSubscriptionRequestStatus?: RequestStatus;
    readonly renewSubscriptionRequestStatus?: RequestStatus;
}

export type SubscriptionAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface SetupIntent {
    clientSecret: string;
}

export interface PaymentMethod {
    id: string;
    billingDetails: BillingDetails;
    card: Card;
    createdOn: string;
}

export interface BillingDetails {
    address: Address;
    email: string;
    name: string;
    phone: string;
}

export interface Card {
    expirationMonth: number;
    expirationYear: number;
    endingIn: string;
    isDefault: boolean;
}

export interface Customer {
    id: string;
    address: Address;
    name: string;
    taxId: TaxId;
}

export interface CustomerFormModel {
    country: string;
    name: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    taxIdValue: string;
    taxIdType: string;
}

export interface Address {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
}

export interface TaxId {
    type: string;
    value: string;
}

export interface Subscription {
    id: string;
    cancelAt: string;
    cancelAtPeriodEnd: boolean;
    canceledAt: string;
    created: string;
    currentPeriodEnd: string;
    currentPeriodStart: string;
    daysUntilDue: number;
    endedAt: string;
    startDate: string;
    status: string;
    trialEnd: string;
    trialStart: string;
    items: SubscriptionItem[];
}

export interface SubscriptionItem {
    price: Price;
    quantity: number;
}

export interface Price {
    product: Product;
    recurring: Recurring;
}

export interface Product {
    name: string;
}

export interface Recurring {
    interval: string;
    intervalCount: number;
}

export interface Invoice {
    id: string;
    amountDue?: number;
    amountPaid?: number;
    currency: string;
    hostedInvoiceUrl?: string;
    invoicePdfUrl?: string;
    total?: number;
    nextPaymentAttempt: string;
    paid: boolean;
    periodStart: string;
    periodEnd: string;
}

export interface SubscriptionOverview {
    subscription: Subscription;
    invoices: Invoice[];
}

export interface SubscriptionStatus {
    status: string;
    hasDefaultPaymentMethod: boolean;
    areBillingDetailsComplete: boolean;
    remainingTrialDays: number;
    totalTrialDays: number;
}

export interface CreateSetupIntentRequestParameters {
    organizationId: string;
}

export interface SetDefaultPaymentMethodRequestParameters {
    organizationId: string;
    paymentMethodId: string;
}

export interface DeletePaymentMethodRequestParameters {
    organizationId: string;
    paymentMethodId: string;
}

export interface UpdateCustomerRequestParameters {
    organizationId: string;
    customerId: string;
    name: string;
    city: string;
    country: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
    taxIdValue: string;
    taxIdType: string;
}

export enum StripeSubscriptionStatus {
    Active = 'active',
    PastDue = 'past_due',
    Unpaid = 'unpaid',
    Canceled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    Trialing = 'trialing',
}
