import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SearchAndRelateTaxonomyResponse } from '../../../../../api/generated';
import TaxonomyTree from '../../../../../components/taxonomy-tree/taxonomy-tree';
import { ApplicationState } from '../../../../../store';
import { DimensionFilter, DimensionWithPlaceholder } from '../../../../../store/search';
import { flattenTaxonomy } from '../../../../../components/taxonomy-tree/taxonomy-tree-utils';

const TaxonomyFilterDimensionComponent = (props: AllProps) => {
    const { filterOnSelectedDimension } = props;

    const sel =
        filterOnSelectedDimension && filterOnSelectedDimension.includes;

    const [selectedValue, setSelectedValue] = useState(sel);

    const taxonomy: SearchAndRelateTaxonomyResponse = useSelector<SearchAndRelateTaxonomyResponse | undefined>(
        (state: ApplicationState) => state.search.rootTaxonomy,
    );

    const onFilterChange = (text): void => {
        const { updateFilterForPlaceholder } = props;
        setSelectedValue(text);
        updateFilterForPlaceholder(props.dimension.name, text);
    };

    if (!taxonomy) return <div />;

    const txDesc = flattenTaxonomy(taxonomy);
    const unknownCount = taxonomy.countUnknown;

    return (
        <div style={{ margin: 16, flex: 1 }}>
            {txDesc && txDesc.items && txDesc.items.length > 0 ? (
                <TaxonomyTree taxonomyDescription={txDesc}  onSelectionChange={onFilterChange} selected={selectedValue} />
            ) : null}
            <div style={{ height: 8 }} />
            {unknownCount && unknownCount > 0 ? (
                <Typography>
                    The <strong>{unknownCount}</strong> matches found without taxonomy information
                </Typography>
            ) : null}
        </div>
    );
};

const TaxonomyFilterDimension = withTranslation()(TaxonomyFilterDimensionComponent);

export default TaxonomyFilterDimension;

interface OwnProps {
    dimension: DimensionWithPlaceholder;
    filterOnSelectedDimension?: DimensionFilter;
    updateFilterForPlaceholder: (dimensionName, elementNameFilter) => void;
}

type AllProps = OwnProps & WithTranslation;
