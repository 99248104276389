import { Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchAndRelateTaxonomyResponse } from '../../../../../api/generated';
import TaxonomyTree from '../../../../../components/taxonomy-tree/taxonomy-tree';
import { ApplicationState } from '../../../../../store';
import { flattenTaxonomy } from '../../../../../components/taxonomy-tree/taxonomy-tree-utils';
import { FlattenTx } from '../../../../../components/taxonomy-tree/taxonomy-tree-types';

interface QuickFilterTaxonomyTreeProps {
    onBranchSelected: (branchId?: string[]) => void;
    selectedBranchesId?: string[];
}

const QuickFilterTaxonomyTree = (props: QuickFilterTaxonomyTreeProps): ReactElement => {
    const { onBranchSelected, selectedBranchesId } = props;
    const [taxonomyDescription, setTaxonomyDescription] = useState<FlattenTx | undefined>();
    const taxonomy: SearchAndRelateTaxonomyResponse = useSelector<SearchAndRelateTaxonomyResponse | undefined>(
        (state: ApplicationState) => state.search.currentTaxonomy,
    );
    useEffect( () => {
        if (taxonomy) {
            setTaxonomyDescription(flattenTaxonomy(taxonomy));
        }
    } ,[taxonomy] )

    if (!taxonomy) return <div />;

    const unknownCount = taxonomy.countUnknown;

    return (
        <div>
            {taxonomyDescription && taxonomyDescription.items && taxonomyDescription.levelDescriptions.length > 0 ? (
                <TaxonomyTree taxonomyDescription={taxonomyDescription} onSelectionChange={onBranchSelected} selected={selectedBranchesId} />
            ) : null}
            <div style={{ height: 8 }} />
            {unknownCount && unknownCount > 0 ? (
                <Typography>
                    the <strong>{unknownCount}</strong> matches found without taxonomy information
                </Typography>
            ) : null}
        </div>
    );
};

export default QuickFilterTaxonomyTree;
