import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dataset, DatasetWithLimit } from '../../../../store/datasets';
import { conditionalClassList } from '../../../../utils/class-helpers';
import styles from './dataset-limit-selection.module.scss';

class DatasetLimitSelection extends React.Component<AllProps, AllState> {
    public render(): JSX.Element | null {
        const { availableDatasets, selectedDatasets, emptyMessage, maxHeightOffset, t } = this.props;

        return availableDatasets.length === 0 && emptyMessage ? (
            <div style={{ maxHeight: `calc(100vh - ${maxHeightOffset}px)` }}>{emptyMessage}</div>
        ) : (
            <div style={{ maxHeight: `calc(100vh - ${maxHeightOffset}px)` }}>
                <div className={styles.elementsToolbar}>
                    <div onClick={(): void => this.toggleAllNone(true)} className={styles.link}>
                        {t('All')}
                    </div>
                    |
                    <div className={styles.link} onClick={(): void => this.toggleAllNone(false)}>
                        {t('None')}
                    </div>
                </div>
                <div
                    className={styles.elementsContainer}
                    style={{ maxHeight: `calc(100vh - ${maxHeightOffset + 30}px)` }}>
                    {availableDatasets.map((d) => {
                        const selectedDataset = selectedDatasets.find((s) => s.id === d.id);
                        const isIncluded = selectedDataset !== undefined;
                        const indicatorClasses = conditionalClassList(styles, {
                            indicator: true,
                            included: isIncluded,
                        });
                        const elementClasses = conditionalClassList(styles, {
                            elementItem: true,
                            included: isIncluded,
                        });
                        return (
                            <div key={d.id} className={elementClasses}>
                                <div
                                    className={styles.nameContainer}
                                    onClick={(): void => this.toggleDataset(!isIncluded, d)}>
                                    <div className={indicatorClasses}>
                                        {isIncluded ? <FontAwesomeIcon icon={faCheck} /> : null}
                                    </div>
                                    <div className={styles.caption}>{d.name}</div>
                                </div>
                                {isIncluded ? (
                                    <div className={styles.limitContainer}>
                                        <span
                                            className={styles.limitText}
                                            onClick={(event): void => this.toggleLimit(event, d)}>
                                            {t('Limit')}
                                        </span>
                                        {selectedDataset && selectedDataset.limit ? (
                                            <span
                                                className={`${styles.limitToggle} ${styles.limitToggleOn}`}
                                                onClick={(event): void => this.toggleLimit(event, d)}
                                            />
                                        ) : (
                                            <span
                                                className={`${styles.limitToggle} ${styles.limitToggleOff}`}
                                                onClick={(event): void => this.toggleLimit(event, d)}
                                            />
                                        )}
                                        {selectedDataset && selectedDataset.limit ? (
                                            <input
                                                type='number'
                                                min='1'
                                                className={styles.limitValue}
                                                value={selectedDataset.limit}
                                                onChange={(event): void =>
                                                    this.updateLimit(event.currentTarget.value, d)
                                                }
                                            />
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    private toggleDataset(include: boolean, dataset: Dataset): void {
        const { selectedDatasets, onSelectionChange } = this.props;

        if (include) {
            onSelectionChange(selectedDatasets.concat({ id: dataset.id }));
        } else {
            onSelectionChange(selectedDatasets.filter((selectedDataset) => selectedDataset.id !== dataset.id));
        }
    }

    private toggleAllNone(include: boolean): void {
        const { availableDatasets, onSelectionChange } = this.props;

        if (include) {
            onSelectionChange(
                availableDatasets.map((d) => {
                    return { id: d.id };
                }),
            );
        } else {
            onSelectionChange([]);
        }
    }

    private toggleLimit(event: React.MouseEvent<HTMLSpanElement>, dataset: Dataset): void {
        event.preventDefault();
        event.stopPropagation();

        const { selectedDatasets, onSelectionChange } = this.props;
        const selectedDatasetIndex = selectedDatasets.findIndex((d) => d.id === dataset.id);
        const selectedDataset = selectedDatasets[selectedDatasetIndex];
        const updatedDataset = { ...selectedDataset, limit: selectedDataset.limit === undefined ? 1000 : undefined };
        const updatedDatasets = [...selectedDatasets];

        updatedDatasets[selectedDatasetIndex] = updatedDataset;
        onSelectionChange(updatedDatasets);
    }

    private updateLimit(limit: string, dataset: Dataset): void {
        const { selectedDatasets, onSelectionChange } = this.props;
        const selectedDatasetIndex = selectedDatasets.findIndex((d) => d.id === dataset.id);
        const selectedDataset = selectedDatasets[selectedDatasetIndex];
        const updatedDataset = { ...selectedDataset, limit: parseInt(limit, 10) };
        const updatedDatasets = [...selectedDatasets];

        updatedDatasets[selectedDatasetIndex] = updatedDataset;
        onSelectionChange(updatedDatasets);
    }
}

export default withTranslation()(DatasetLimitSelection);

interface OwnProps {
    availableDatasets: Dataset[];
    selectedDatasets: DatasetWithLimit[];
    emptyMessage?: string;
    maxHeightOffset: number;
    onSelectionChange: (selectedDatasets: DatasetWithLimit[]) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
