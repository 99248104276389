import { all, call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { DatasetPipelineApi } from '../../api/dataset-pipeline-api';
import { DatasetsApi } from '../../api/datasets-api';
import { WorkspaceResponse } from '../../http/http';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    getDatasetsForOrganizationFailure,
    getDatasetsForOrganizationRequest,
    getDatasetsForOrganizationSuccess,
    getDatasetsForWorkspaceFailure,
    getDatasetsForWorkspaceRequest,
    getDatasetsForWorkspaceSuccess,
    linkDatasetsToWorkspaceFailure,
    linkDatasetsToWorkspaceRequest,
    linkDatasetsToWorkspaceSuccess,
    startWatchingDatasetPipelineStatus,
    unlinkDatasetsToWorkspaceFailure,
    unlinkDatasetsToWorkspaceRequest,
    unlinkDatasetsToWorkspaceSuccess,
    updateDatasetPipelineStatus,
} from './actions';
import { isDatasetReady } from './datasetUtils';
import {
    Dataset,
    DatasetsActionTypes,
    LinkDatasetsToWorkspaceRequestParameters,
    UnlinkDatasetsFromWorkspaceRequestParameters,
} from './types';
import biostrandApi from '../../api/biostrand-api';

function* handleGetDatasetsForOrganizationRequest(action: ActionType<typeof getDatasetsForOrganizationRequest>) {
    try {
        const response: WorkspaceResponse<Dataset[]> = yield call(DatasetsApi.getDatasetsForOrganization);
        if (response.data) {
            yield put(getDatasetsForOrganizationSuccess(response.data));
        } else {
            yield put(getDatasetsForOrganizationSuccess([]));
        }
        yield put(startWatchingDatasetPipelineStatus());
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getDatasetsForOrganizationFailure(err));
    }
}

function* handleGetDatasetsForWorkspaceRequest(action: ActionType<typeof getDatasetsForWorkspaceRequest>) {
    try {
        const organizationId: string = action.payload[0];
        const workspaceId: string = action.payload[1];

        const response = yield call(
            [biostrandApi, 'biostrandGatewayGetAllDatasetsOfWorkspace'],
            organizationId,
            workspaceId,
        );
        if (response.data.data) {
            yield put(getDatasetsForWorkspaceSuccess(response.data.data));
        } else {
            yield put(getDatasetsForWorkspaceSuccess([]));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(getDatasetsForWorkspaceFailure(err));
    }
}

function* handleLinkDatasetsToWorkspaceRequest(action: ActionType<typeof linkDatasetsToWorkspaceRequest>) {
    try {
        const parameters: LinkDatasetsToWorkspaceRequestParameters = action.payload;
        const response: WorkspaceResponse<Dataset[]> = yield call(DatasetsApi.linkDatasetsToWorkspace, parameters);
        yield put(linkDatasetsToWorkspaceSuccess(response.data));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(linkDatasetsToWorkspaceFailure(err));
    }
}

function* handleUnlinkDatasetsFromWorkspaceRequest(action: ActionType<typeof unlinkDatasetsToWorkspaceRequest>) {
    try {
        const parameters: UnlinkDatasetsFromWorkspaceRequestParameters = action.payload;
        yield call(DatasetsApi.unlinkDatasetsFromWorkspace, parameters);
        yield put(unlinkDatasetsToWorkspaceSuccess(parameters.datasetIds));
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(unlinkDatasetsToWorkspaceFailure(err));
    }
}

let isWatching = false;

function* handleWatchDataset() {
    if (isWatching) return;
    isWatching = true;
    const datasets = yield select((state) => state.datasets?.datasetsForOrganization);
    if (datasets) {
        const ownDatasets = datasets.filter((ds) => !!ds.owner);
        const datasetPipelines = yield select((state) => state.datasets?.datasetsPipelines);

        for (let i = 0; i < ownDatasets.length; i++) {
            try {
                const dataset = ownDatasets[i];
                const datasetPipeline = datasetPipelines && datasetPipelines[dataset.id];
                if (!isDatasetReady(dataset, datasetPipeline)) {
                    const result = yield call(DatasetPipelineApi.getDatasetPipeline, dataset.id);
                    yield put(updateDatasetPipelineStatus(result));
                }
            } catch (e) {
            }
        }
    }

    yield delay(10000);
    isWatching = false;
    yield put(startWatchingDatasetPipelineStatus());
}

function* watchRequests() {
    yield takeEvery(DatasetsActionTypes.GET_DATASETS_FOR_ORGANIZATION_REQUEST, handleGetDatasetsForOrganizationRequest);
    yield takeEvery(DatasetsActionTypes.GET_DATASETS_FOR_WORKSPACE_REQUEST, handleGetDatasetsForWorkspaceRequest);
    yield takeEvery(DatasetsActionTypes.LINK_DATASETS_TO_WORKSPACE_REQUEST, handleLinkDatasetsToWorkspaceRequest);
    yield takeEvery(
        DatasetsActionTypes.UNLINK_DATASETS_FROM_WORKSPACE_REQUEST,
        handleUnlinkDatasetsFromWorkspaceRequest,
    );

    yield takeEvery(DatasetsActionTypes.WATCH_DATASET_PROCESSING, handleWatchDataset);
}

function* datasetsSaga() {
    yield all([fork(watchRequests)]);
}

export default datasetsSaga;
