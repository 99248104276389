import { RouteUrl } from '../../../routing/route-url';
import { MenuItem } from '../components/admin-menu/admin-menu';

export function getOrganizationAdminMenuConfiguration(): MenuItem[] {
    return [
              { label: 'Users', url: RouteUrl.ManageUsers },
              { label: 'Workspaces', url: RouteUrl.ManageWorkspaces },
              { label: 'Datasets', url: RouteUrl.ManageOrganizationDatasets },
              { label: 'Billing', url: RouteUrl.ManageBilling },
              { label: 'Integration API Token', url: RouteUrl.GetAPIToken },
          ];
}
