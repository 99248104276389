import { Location } from 'history';
import { SortDirection } from '../../../../types/sorting';
import { QueryParameter, QueryParameterHelper, QueryParameterMap } from '../../../../utils/query-parameter-helpers';
import { ResultViewUrlParameters } from '../../result-view-url-parameters';
import { UrlParametersHandler } from '../../url-parameters-handler';

export class ListUrlParametersHandler extends UrlParametersHandler<ListResultViewUrlParameters> {
    public read(currentLocation: Location): ListResultViewUrlParameters {
        const base = super.doRead(currentLocation);
        const urlSearchParameters = new URLSearchParams(currentLocation.search);
        const defaultSorting = { columnKey: 'ranking', direction: SortDirection.Descending };

        const page = QueryParameterHelper.getNumberFromUrl(urlSearchParameters, QueryParameter.ListViewPage) || 1;
        const pageSize =
            QueryParameterHelper.getNumberFromUrl(urlSearchParameters, QueryParameter.ListViewPageSize) || 50;
        const sortingColumnKey =
            QueryParameterHelper.getStringFromUrl(urlSearchParameters, QueryParameter.ListViewSortingColumnKey) ||
            defaultSorting.columnKey;
        const sortingDirection =
            QueryParameterHelper.getEnumFromUrl<SortDirection>(
                urlSearchParameters,
                QueryParameter.ListViewSortingDirection,
            ) || defaultSorting.direction;

        return {
            ...base,
            activePage: page,
            activePageSize: pageSize,
            activeSortingColumnKey: sortingColumnKey,
            activeSortingDirection: sortingDirection,
        };
    }

    public write(newUrlState: ListResultViewUrlParameters): QueryParameterMap {
        const resultViewParameters = super.doWrite(newUrlState);
        resultViewParameters[QueryParameter.ListViewPage] = newUrlState.activePage;
        resultViewParameters[QueryParameter.ListViewPageSize] = newUrlState.activePageSize;
        resultViewParameters[QueryParameter.ListViewSortingColumnKey] = newUrlState.activeSortingColumnKey;
        resultViewParameters[QueryParameter.ListViewSortingDirection] = newUrlState.activeSortingDirection;
        return resultViewParameters;
    }
}

export interface ListResultViewUrlParameters extends ResultViewUrlParameters {
    activeSortingColumnKey: string;
    activeSortingDirection: SortDirection;
    activePage: number;
    activePageSize: number;
}
