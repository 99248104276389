import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styles from './help-link.module.scss';

class HelpLink extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { url, size } = this.props;

        return (
            <a className={styles.link} href={url} rel='noopener noreferrer' target='_blank'>
                <FontAwesomeIcon icon={faQuestion} size={size} />
            </a>
        );
    }
}

export default HelpLink;

interface OwnProps {
    url: string;
    size: SizeProp;
}

type AllProps = OwnProps;
