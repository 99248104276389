import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import * as React from 'react';
import { ChangeEvent } from 'react';
import Dropdown, { DropdownOption } from '../../../../../../components/dropdown/dropdown';
import { RequestStatus } from '../../../../../../store/shared/types';
import { Address, Customer, CustomerFormModel, TaxId } from '../../../../../../store/subscription';
import styles from './customer-form.module.scss';

class CustomerForm extends React.Component<AllProps, AllState> {
    public constructor(props: AllProps) {
        super(props);

        this.state = {
            name: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            postalCode: '',
            taxId: '',
            availableCountries: this.createAvailableCountries(),
            countryCodeToTaxIdTypeMap: this.createCountryCodeToTaxIdTypeMap(),
        };
    }

    public componentDidMount(): void {
        const { customer } = this.props;

        if (customer) {
            this.initializeModel(customer);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { customer } = this.props;

        if (customer && (!prevProps.customer || !this.isCustomerEqual(customer, prevProps.customer))) {
            this.initializeModel(customer);
        }
    }

    public render(): JSX.Element | null {
        const { updateCustomerRequestStatus, t } = this.props;
        const { selectedCountry, availableCountries, name, line1, line2, city, state, postalCode, taxId } = this.state;

        return (
            <form className={styles.form}>
                <div className={styles.field}>
                    <div className={styles.label}>{t('Country')}</div>
                    <Dropdown
                        selection={selectedCountry}
                        options={availableCountries}
                        onSelectionChanged={(option: DropdownOption): void => this.onCountryChange(option)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('Company name')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='NameField'
                        value={name}
                        onChange={(event): void => this.onNameChange(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('Street + number')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='Line1Field'
                        value={line1}
                        onChange={(event): void => this.onLine1Change(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('Suite/Unit/Apartment (optional)')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='Line2Field'
                        value={line2}
                        onChange={(event): void => this.onLine2Change(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('City')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='CityField'
                        value={city}
                        onChange={(event): void => this.onCityChange(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('State/Province/Region (optional)')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='StateField'
                        value={state}
                        onChange={(event): void => this.onStateChange(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('Postal code')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='PostalCodeField'
                        value={postalCode}
                        onChange={(event): void => this.onPostalCodeChange(event)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.label}>{t('VAT ID')}</div>
                    <input
                        className={styles.emailInput}
                        type='text'
                        key='TaxIdField'
                        value={taxId}
                        onChange={(event): void => this.onTaxIdChange(event)}
                    />
                </div>
                <button
                    className={styles.button}
                    type='button'
                    onClick={(): void => this.save()}
                    disabled={updateCustomerRequestStatus?.isInProgress || !this.canBeSaved()}>
                    {updateCustomerRequestStatus?.isInProgress ? (
                        <FontAwesomeIcon icon={faSpinner} className={styles.loadingIcon} spin />
                    ) : (
                        t('Save')
                    )}
                </button>
            </form>
        );
    }

    private createAvailableCountries(): DropdownOption[] {
        return [
            { id: 'AU', label: 'Australia' },
            { id: 'AT', label: 'Austria' },
            { id: 'BE', label: 'Belgium' },
            { id: 'BR', label: 'Brazil' },
            { id: 'BG', label: 'Bulgaria' },
            { id: 'CA', label: 'Canada' },
            { id: 'CL', label: 'Chile' },
            { id: 'HR', label: 'Croatia' },
            { id: 'CY', label: 'Cyprus' },
            { id: 'CZ', label: 'Czechia' },
            { id: 'DK', label: 'Denmark' },
            { id: 'EE', label: 'Estonia' },
            { id: 'FI', label: 'Finland' },
            { id: 'FR', label: 'France' },
            { id: 'DE', label: 'Germany' },
            { id: 'GR', label: 'Greece' },
            { id: 'HK', label: 'Hong Kong' },
            { id: 'HU', label: 'Hungary' },
            { id: 'IN', label: 'India' },
            { id: 'ID', label: 'Indonesia' },
            { id: 'IE', label: 'Ireland' },
            { id: 'IT', label: 'Italy' },
            { id: 'JP', label: 'Japan' },
            { id: 'KR', label: 'Korea, Republic of' },
            { id: 'LV', label: 'Latvia' },
            { id: 'LI', label: 'Liechtenstein' },
            { id: 'LT', label: 'Lithuania' },
            { id: 'LU', label: 'Luxembourg' },
            { id: 'MY', label: 'Malaysia' },
            { id: 'MT', label: 'Malta' },
            { id: 'MX', label: 'Mexico' },
            { id: 'NL', label: 'Netherlands' },
            { id: 'NZ', label: 'New Zealand' },
            { id: 'NO', label: 'Norway' },
            { id: 'PL', label: 'Poland' },
            { id: 'PT', label: 'Portugal' },
            { id: 'RO', label: 'Romania' },
            { id: 'RU', label: 'Russian Federation' },
            { id: 'SA', label: 'Saudi Arabia' },
            { id: 'SG', label: 'Singapore' },
            { id: 'SK', label: 'Slovakia' },
            { id: 'SI', label: 'Slovenia' },
            { id: 'ZA', label: 'South Africa' },
            { id: 'ES', label: 'Spain' },
            { id: 'SE', label: 'Sweden' },
            { id: 'CH', label: 'Switzerland' },
            { id: 'TW', label: 'Taiwan' },
            { id: 'TH', label: 'Thailand' },
            { id: 'AE', label: 'United Arab Emirates' },
            { id: 'GB', label: 'United Kingdom' },
            { id: 'US', label: 'United States' },
        ];
    }

    private createCountryCodeToTaxIdTypeMap(): { [countryCode: string]: string } {
        return {
            AU: 'au_abn',
            AT: 'eu_vat',
            BE: 'eu_vat',
            BR: 'br_cnpj',
            BG: 'eu_vat',
            CA: 'ca_bn',
            CL: 'cl_tin',
            HR: 'eu_vat',
            CY: 'eu_vat',
            CZ: 'eu_vat',
            DK: 'eu_vat',
            EE: 'eu_vat',
            FI: 'eu_vat',
            FR: 'eu_vat',
            DE: 'eu_vat',
            GR: 'eu_vat',
            HK: 'hk_br',
            HU: 'eu_vat',
            IN: 'in_gst',
            ID: 'id_npwp',
            IE: 'eu_vat',
            IT: 'eu_vat',
            JP: 'jp_cn',
            KR: 'kr_brn',
            LV: 'eu_vat',
            LI: 'li_uid',
            LT: 'eu_vat',
            LU: 'eu_vat',
            MY: 'my_frp',
            MT: 'eu_vat',
            MX: 'mx_rfc',
            NL: 'eu_vat',
            NZ: 'nz_gst',
            NO: 'no_vat',
            PL: 'eu_vat',
            PT: 'eu_vat',
            RO: 'eu_vat',
            RU: 'ru_inn',
            SA: 'sa_vat',
            SG: 'sg_gst',
            SK: 'eu_vat',
            SI: 'eu_vat',
            ZA: 'za_vat',
            ES: 'eu_vat',
            SE: 'eu_vat',
            CH: 'ch_vat',
            TW: 'tw_vat',
            TH: 'th_vat',
            AE: 'ae_trn',
            GB: 'eu_vat',
            US: 'us_ein',
        };
    }

    private initializeModel(customer: Customer): void {
        const { availableCountries } = this.state;

        this.setState({
            selectedCountry: availableCountries.find((c) => c.id === customer.address.country),
            name: customer.name || '',
            line1: customer.address.line1 || '',
            line2: customer.address.line2 || '',
            city: customer.address.city || '',
            state: customer.address.state || '',
            postalCode: customer.address.postalCode || '',
            taxId: customer.taxId.value || '',
        });
    }

    private isCustomerEqual(a: Customer, b: Customer): boolean {
        return (
            a.id === b.id &&
            a.name === b.name &&
            this.isAddressEqual(a.address, b.address) &&
            this.isTaxIdEqual(a.taxId, b.taxId)
        );
    }

    private isAddressEqual(a: Address, b: Address): boolean {
        return (
            a.city === b.city &&
            a.country === b.country &&
            a.line1 === b.line1 &&
            a.line2 === b.line2 &&
            a.postalCode === b.postalCode &&
            a.state === b.state
        );
    }

    private isTaxIdEqual(a: TaxId, b: TaxId): boolean {
        return a.type === b.type && a.value === b.value;
    }

    private onCountryChange(selectedCountry: DropdownOption): void {
        this.setState({ selectedCountry });
    }

    private onNameChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ name: event.target.value });
    }

    private onLine1Change(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ line1: event.target.value });
    }

    private onLine2Change(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ line2: event.target.value });
    }

    private onCityChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ city: event.target.value });
    }

    private onStateChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ state: event.target.value });
    }

    private onPostalCodeChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ postalCode: event.target.value });
    }

    private onTaxIdChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ taxId: event.target.value });
    }

    private canBeSaved(): boolean {
        const { selectedCountry, name, line1, city, postalCode, taxId } = this.state;

        return (
            selectedCountry !== undefined &&
            name !== '' &&
            line1 !== '' &&
            city !== '' &&
            postalCode !== '' &&
            taxId !== ''
        );
    }

    private save(): void {
        const { onSave } = this.props;
        const {
            selectedCountry,
            name,
            line1,
            line2,
            city,
            state,
            postalCode,
            taxId,
            countryCodeToTaxIdTypeMap,
        } = this.state;

        if (this.canBeSaved() && selectedCountry) {
            onSave({
                country: selectedCountry.id,
                name,
                line1,
                line2,
                city,
                state,
                postalCode,
                taxIdValue: taxId,
                taxIdType: countryCodeToTaxIdTypeMap[selectedCountry.id],
            });
        }
    }
}

export default CustomerForm;

interface OwnProps {
    t: TFunction;
    getCustomerRequestStatus?: RequestStatus;
    updateCustomerRequestStatus?: RequestStatus;
    customer?: Customer;
    onSave: (customer: CustomerFormModel) => void;
}

type AllProps = OwnProps;

interface OwnState {
    selectedCountry?: DropdownOption;
    name: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    taxId: string;
    availableCountries: DropdownOption[];
    countryCodeToTaxIdTypeMap: { [countryCode: string]: string };
}

type AllState = OwnState;
