import { push } from 'connected-react-router';
import * as FileSaver from 'file-saver';
import { Location, LocationDescriptorObject } from 'history';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import EmptyPanel from '../../../../components/empty-panel/empty-panel';
import { HelpLinkUrl } from '../../../../components/help-link/help-link-urls';
import InvalidConfigPanel from '../../../../components/invalid-config-panel/invalid-config-panel';
import LoadingPanel from '../../../../components/loading/loading-panel';
import ResultsPageHeader from '../../../../components/page-header/results-page-header/results-page-header';
import { RouteUrl } from '../../../../routing/route-url';
import { ApplicationState } from '../../../../store';
import {
    BaseSearchParameters,
    baseSearchRequest,
    closeToolbarRequest,
    Dimension,
    DimensionFilter,
    ExplorerResultViewData,
    ExplorerSearchParameters,
    explorerSearchRequest,
    rootSearchRequest,
    SearchAggregatesResultSummary,
} from '../../../../store/search';
import { DimensionFilterHelper } from '../../../../store/search/helpers/dimension-filter-helper';
import { getDistinctElementNamesForSingleDimensionInSummaries } from '../../../../store/search/helpers/summary-helper';
import { ExplorerSorting, SortAxis, SortDirection } from '../../../../types/sorting';
import { CollectionUtil } from '../../../../utils/collection-util';
import { QueryParameter, QueryParameterMap } from '../../../../utils/query-parameter-helpers';
import ResultsNavigationBar from '../../components/results-navigation-bar/results-navigation-bar';
import ResultsPageOverlay from '../../components/results-page-overlay/results-page-overlay';
import { ResultViewBasePage, ResultViewStatus } from '../../result-view-base.page';
import { ValidationResult } from '../../result-view-input-validator';
import ExplorerTable, { ExplorerTableRow } from './components/explorer-table/explorer-table';
import { ExplorerInputValidator } from './explorer-input-validator';
import ExplorerToolbar from './explorer-toolbar';
import { ExplorerResultViewUrlParameters, ExplorerUrlParametersHandler } from './explorer-url-parameters-handler';
import styles from './explorer.module.scss';
import { CartesianProductBuilder } from './utils/cartesian-product-builder';
import { ColumnCombinationTransformer } from './utils/column-combination-transformer';
import { ExplorerViewCsvCreator } from './utils/explorer.view.csv-creator';
import { getDownloadFileName } from '../../../../utils/get-file-name-util';
import { updateFilters } from '../../../../store/search/filter-actions';
import { queryErrorSelector } from '../../../../store/search/metadata-selectors';
import SomethingWentWrong from '../../../../components/wrong-page/wrong-page';

export enum ExplorerView {
    table,
    chart,
}

class ExplorerPage extends ResultViewBasePage<AllProps, AllState, ExplorerResultViewUrlParameters> {
    // Hack due to making sure the filter is set at least once
    private renderedAtLeastOneTime = false;

    constructor(props: AllProps) {
        super(props, new ExplorerUrlParametersHandler(), new ExplorerInputValidator());

        this.state = {
            selectedRowDimensions: [],
            selectedColumnDimensions: [],
            selectedViewMode: ExplorerView.table,
            selectedFilters: [],
            explorerSorting: { axis: SortAxis.Row, direction: SortDirection.Descending, property: 'count' },
        };

        // Make sure defaults are applied to url state
        this.updateUrl(this.urlParameters);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        super.componentDidUpdate(prevProps, prevState);
        const { explorerResultViewData } = this.props;

        const dataChanged = explorerResultViewData !== prevProps.explorerResultViewData;

        // Execute new search when URL state is different from last executed search
        if (this.needsNewSearch(this.urlParameters)) {
            const validationResult = this.inputValidator.validate(this.urlParameters);
            if (validationResult.isValid) {
                this.executeExplorerViewSearch();
            } else if (prevState.validationResult?.reason !== validationResult?.reason) {
                this.setState({ validationResult });
            }
        } else if (explorerResultViewData && (dataChanged || !this.renderedAtLeastOneTime)) {
            this.renderedAtLeastOneTime = true;
            const newState = { ...this.state };
            newState.selectedRowDimensions = this.urlParameters.activeRowSelection;
            newState.selectedColumnDimensions = this.urlParameters.activeColumnSelection;
            newState.selectedFilters = this.urlParameters.activeFilters;
            this.setState(newState);
        }
    }

    public render(): JSX.Element {
        const { dimensions, location, isToolbarOpen, totalResults } = this.props;
        const { selectedViewMode, selectedRowDimensions, selectedColumnDimensions, selectedFilters } = this.state;
        const { activeFilters } = this.urlParameters;

        const viewStatus = this.determineViewStatus();

        return (
            <div className={styles.container}>
                <ResultsPageHeader filters={activeFilters} />
                <ResultsNavigationBar
                    isNavigationDisabled={viewStatus === ResultViewStatus.inProgress}
                    location={location}
                    navigateTo={(url: RouteUrl, search) => this.navigateTo(url, search)}
                    helpUrl={HelpLinkUrl.ExplorerView}
                    totalResults={totalResults}
                />
                <ExplorerToolbar
                    allDimensions={dimensions}
                    selectedViewMode={selectedViewMode}
                    selectedFilters={selectedFilters}
                    selectedRowDimensions={selectedRowDimensions}
                    selectedColumnDimensions={selectedColumnDimensions}
                    onSetViewMode={(mode: ExplorerView) => this.onSetViewMode(mode)}
                    onFiltersChanged={(changedFilters: DimensionFilter[]) => this.onFiltersChanged(changedFilters)}
                    onDownloadCsv={(): void => this.onDownload()}
                    onRowDimensionSelectionChanged={(items): void => this.onRowSelectionsChanged(items)}
                    onColumnDimensionSelectionChanged={(items): void => this.onColumnSelectionsChanged(items)}
                />
                <div className={styles.pageContentContainer}>
                    <ResultsPageOverlay
                        show={isToolbarOpen} onClick={() => this.overlayClicked()}
                    />
                    <div className={styles.pageContent}
                         style={{ overflowY: isToolbarOpen ? 'hidden' : 'auto' }}>
                        {this.renderViewContent(viewStatus)}
                    </div>
                </div>
            </div>
        );
    }

    protected renderViewContent(viewStatus: ResultViewStatus): JSX.Element {
        const { t } = this.props;
        const { selectedViewMode } = this.state;

        switch (viewStatus) {
            case ResultViewStatus.error:
                return <SomethingWentWrong />;
            case ResultViewStatus.inProgress:
                return <LoadingPanel style={{ padding: '30px 40px' }} text={t('Aggregating the results...')} />;
            case ResultViewStatus.invalid:
                // eslint-disable-next-line no-case-declarations
                const reason = this.inputValidator.validate(this.urlParameters).reason || '';
                return <InvalidConfigPanel message={t(reason)} />;
            case ResultViewStatus.emptyResult:
                return <EmptyPanel />;
            default:
                switch (selectedViewMode) {
                    case ExplorerView.table:
                        return this.renderTable();
                    default:
                        return <InvalidConfigPanel />;
                }
        }
    }

    public determineViewStatus(): ResultViewStatus {
        const {
            rootSearchRequestInProgress,
            baseSearchRequestInProgress,
            explorerRequestInProgress,
            explorerResultViewData,
            explorerRequestInError,
            queryInError,
        } = this.props;

        if (explorerRequestInError || queryInError) return ResultViewStatus.error;

        if (!this.inputValidator.validate(this.urlParameters).isValid) {
            return ResultViewStatus.invalid;
        } else if (rootSearchRequestInProgress || explorerRequestInProgress || baseSearchRequestInProgress) {
            return ResultViewStatus.inProgress;
        } else if (
            !explorerResultViewData ||
            !explorerResultViewData.summaries ||
            explorerResultViewData.summaries.length === 0
        ) {
            return ResultViewStatus.emptyResult;
        } else {
            return ResultViewStatus.ready;
        }
    }

    private executeExplorerViewSearch(): void {
        const {
            dispatchBaseSearchRequest,
            dispatchExplorerSearchRequest,
            baseSearchParameters,
            baseQueryId,
        } = this.props;

        if (!baseSearchParameters || this.baseParametersOutOfSync(this.urlParameters, baseSearchParameters)) {
            dispatchBaseSearchRequest({
                rootQuery: this.urlParameters.rootQuery,
                filters: this.urlParameters.activeFilters,
            });
        } else {
            const { rootQuery, activeFilters, activeRowSelection, activeColumnSelection } = this.urlParameters;
            dispatchExplorerSearchRequest({
                rootQuery,
                filters: activeFilters,
                baseQueryId,
                rowSelection: activeRowSelection,
                columnSelection: activeColumnSelection,
            });
        }
    }

    private onFiltersChanged(filters: DimensionFilter[]): void {
        this.setState({ selectedFilters: filters });
    }

    private onSetViewMode(selectedViewMode: ExplorerView): void {
        this.setState({ selectedViewMode });
    }

    private renderTable(): JSX.Element {
        const { explorerSorting } = this.state;
        const { activeRowSelection, activeColumnSelection } = this.urlParameters;

        const sorting: ExplorerSorting = explorerSorting || {
            axis: SortAxis.Row,
            direction: SortDirection.Ascending,
            property: 'count',
        };

        const { explorerResultViewData } = this.props;
        const columnCombinations = this.getColumns(explorerResultViewData?.summaries || [], activeColumnSelection);
        const rows = this.getRows(explorerResultViewData?.summaries || [], activeRowSelection, columnCombinations);

        return (
            <ExplorerTable
                columnCombinations={columnCombinations}
                rows={rows}
                selectedColumnDimensions={activeColumnSelection}
                selectedRowDimensions={activeRowSelection}
                sorting={sorting}
                applyFilter={(selectedRowElements, selectedColumnElements): void =>
                    this.applyContextualFilter(selectedRowElements, selectedColumnElements)
                }
                changeSorting={(newSorting): void => this.onChangeSorting(newSorting)}
            />
        );
    }

    private needsNewSearch(urlParameters: ExplorerResultViewUrlParameters): boolean {
        const {
            explorerSearchParameters,
            explorerRequestInProgress,
            baseSearchRequestInProgress,
            explorerRequestInError,
            queryInError,
        } = this.props;

        if (this.initializingRootQuery || explorerRequestInProgress || baseSearchRequestInProgress || explorerRequestInError || queryInError) {
            return false;
        }

        if (!explorerSearchParameters) {
            return true;
        }

        if (this.baseParametersOutOfSync(urlParameters, explorerSearchParameters)) {
            return true;
        } else {
            const rowSelectionIsEqual = CollectionUtil.areEqual(
                urlParameters.activeRowSelection || [],
                explorerSearchParameters.rowSelection,
            );
            if (!rowSelectionIsEqual) {
                return true;
            }

            const columnSelectionIsEqual = CollectionUtil.areEqual(
                urlParameters.activeColumnSelection || [],
                explorerSearchParameters.columnSelection,
            );
            if (!columnSelectionIsEqual) {
                return true;
            }

            return false;
        }
    }

    protected overlayClicked(): void {

        super.overlayClicked();
        const { selectedFilters, selectedRowDimensions, selectedColumnDimensions } = this.state;
        const { dispatchUpdateFilters } = this.props;
        const { rootQuery } = this.urlParameters;

        dispatchUpdateFilters(selectedFilters || []);

        this.updateUrl({
            rootQuery,
            activeFilters: selectedFilters,
            activeRowSelection: selectedRowDimensions,
            activeColumnSelection: selectedColumnDimensions,
        });
    }

    private getRows(
        summaries: SearchAggregatesResultSummary[],
        selectedRows: string[],
        uniqueColumnCombinations: string[][],
    ): ExplorerTableRow[] {
        const uniqueCombinations = this.getUniqueCombinationsOfElementsInSummaries(summaries, selectedRows);

        // Create the list of rows with the proper data structure
        return uniqueCombinations.map((combination) => {
            // 1. Find all summaries that match the given combination
            const matchingSummaries = summaries.filter((summary) => {
                return selectedRows.every((row) => {
                    const elements: string[] = summary[row];
                    return elements.some((element) => combination.includes(element));
                });
            });

            const valuesByElement = matchingSummaries.reduce((values, summary) => {
                // Combine all elements of the summary into 1 list
                const allSummaryElements = Object.keys(summary).reduce((summaryElements, key) => {
                    return key !== 'count' ? summaryElements.concat(summary[key]) : summaryElements;
                }, [] as string[]);
                // Find all column combinations that match the current summary by comparing to the summary's elements
                const columnCombinations = uniqueColumnCombinations.filter((cc) =>
                    cc.every((c) => allSummaryElements.includes(c)),
                );

                // Add the summary's count to a map with the column combination as the key and sum it if the key already exists
                return columnCombinations.reduce((valuesP, columnCombination) => {
                    const columnCombinationKey = columnCombination.toString();
                    const existingCount = values[columnCombinationKey] || 0;

                    return { ...valuesP, [columnCombinationKey]: existingCount + summary.count };
                }, values);
            }, {});

            return {
                keys: combination,
                values: valuesByElement,
            };
        });
    }

    private getColumns(summaries: SearchAggregatesResultSummary[], selectedColumns: string[]): string[][] {
        return this.getUniqueCombinationsOfElementsInSummaries(summaries, selectedColumns);
    }

    private getUniqueCombinationsOfElementsInSummaries(
        summaries: SearchAggregatesResultSummary[],
        selectedRowsOrColumns: string[],
    ): string[][] {
        if (selectedRowsOrColumns.length > 1) {
            // 1. Create all possible combinations of elements for every row/column
            const allCombinations = summaries.reduce((combinations, summary) => {
                // Create a list of groups of elements occurring on this summary
                // Example:
                // [
                //     ["3.10.450 Nuclear Transport Factor 2; Chain: A;", "3.80.10 Leucine-rich repeat; LRR (right-handed beta-alpha superhelix)"]
                //     ["PDB"]
                //     ["biological process", "cellular component", "molecular function"]
                // ]
                const allGroupsOfElementsInRow = selectedRowsOrColumns.reduce((newCombinations, rowOrColumn) => {
                    const elements = summary[rowOrColumn];

                    return elements ? newCombinations.concat([elements]) : newCombinations;
                }, [] as string[][]);

                if (allGroupsOfElementsInRow.length > 1) {
                    return combinations.concat(CartesianProductBuilder.cartesianProduct(...allGroupsOfElementsInRow));
                } else {
                    return combinations;
                }
            }, [] as string[][]);

            // 2. Filter out duplicate combinations
            return allCombinations.filter((combination, index) => {
                return allCombinations.findIndex((c) => c.toString() === combination.toString()) === index;
            });
        } else {
            const uniqueElements = getDistinctElementNamesForSingleDimensionInSummaries(
                summaries,
                selectedRowsOrColumns[0],
            );
            return uniqueElements.map((element) => [element]);
        }
    }

    private applyContextualFilter(selectedRowElements: string[][], selectedColumnElements: string[][]): void {
        const filters = this.updateFilters(selectedRowElements, selectedColumnElements);
        const { dispatchUpdateFilters } = this.props;

        dispatchUpdateFilters(filters);
    }

    private updateFilters(selectedRowElements: string[][], selectedColumnElements: string[][]): DimensionFilter[] {
        const { selectedFilters } = this.state;
        const { dimensions } = this.props;
        const { activeRowSelection, activeColumnSelection } = this.urlParameters;

        return DimensionFilterHelper.updateContextualFilters(
            selectedFilters,
            selectedRowElements,
            selectedColumnElements,
            activeRowSelection,
            activeColumnSelection,
            dimensions,
        );
    }

    private onRowSelectionsChanged(selectedRowDimensions: string[]): void {
        this.setState({ selectedRowDimensions });
    }

    private onColumnSelectionsChanged(selectedColumnDimensions: string[]): void {
        this.setState({ selectedColumnDimensions });
    }

    private onChangeSorting(sorting: ExplorerSorting): void {
        this.setState({ explorerSorting: sorting });
    }

    private onDownload(): void {
        const { explorerResultViewData, rootQuery, dimensions } = this.props;
        const { activeRowSelection, activeColumnSelection } = this.urlParameters;

        if (explorerResultViewData && explorerResultViewData.summaries) {
            const activeRowOptions = dimensions.filter((d) => activeRowSelection.includes(d.name));
            const activeColumnOptions = dimensions.filter((d) => activeColumnSelection.includes(d.name));
            const summaries = explorerResultViewData.summaries || [];

            const columnCombinations = this.getColumns(summaries, activeColumnSelection);
            const columns = ColumnCombinationTransformer.transformColumnCombinationsIntoColumns(
                columnCombinations,
                activeColumnSelection.length,
            );
            const rows = this.getRows(summaries, activeRowSelection, columnCombinations);

            const csv = ExplorerViewCsvCreator.createCsv(
                activeRowOptions,
                activeColumnOptions,
                rows,
                columns,
                columnCombinations,
            );

            const fileName = `BioStrand-${getDownloadFileName(rootQuery)}-explorer data.csv`;
            const blob = new Blob([csv], { type: 'text/csv' });

            FileSaver.saveAs(blob, fileName);
        }
    }

    protected addViewSpecificStateToUrl(
        parameters: QueryParameterMap,
        urlParameters: ExplorerResultViewUrlParameters,
    ): QueryParameterMap {
        const resultViewParameters = { ...parameters };
        const encodedRowSelection = btoa(
            unescape(encodeURIComponent(JSON.stringify(urlParameters.activeRowSelection))),
        );
        resultViewParameters[QueryParameter.ExplorerRow] = encodedRowSelection;
        const encodedColumnSelection = btoa(
            unescape(encodeURIComponent(JSON.stringify(urlParameters.activeColumnSelection))),
        );
        resultViewParameters[QueryParameter.ExplorerColumn] = encodedColumnSelection;

        return resultViewParameters;
    }
}

const mapStateToProps: (state: ApplicationState) => PropsFromState = (state: ApplicationState) => {

    const {
        search,
        router,
        global,
    } = state;

    return {
        dimensions: search.dimensions,
        baseQueryId: search.baseQueryId,
        rootQuery: search.rootQuery,
        currentLocation: router.location,
        isToolbarOpen: search.isToolbarOpen,
        rootSearchRequestInProgress: search.rootSearchRequestInProgress,
        explorerResultViewData: search.explorerResultViewData,
        explorerRequestInProgress: search.explorerRequestInProgress,
        explorerSearchParameters: search.explorerSearchParameters,
        baseSearchParameters: search.baseSearchParameters,
        baseSearchRequestInProgress: search.baseSearchRequestInProgress,
        activeWorkspaceId: global.activeWorkspaceId,
        queryInError: queryErrorSelector(state),
        totalResults: search.queryMatches,
        explorerRequestInError: search.explorerRequestInError,
    };
};

const mapDispatchToProps: (dispatch: Dispatch) => PropsFromDispatch = (dispatch: Dispatch) => ({
    dispatchRootSearchRequest: (workspaceId: string, query: string) => dispatch(rootSearchRequest(workspaceId, query)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchCloseToolbar: () => dispatch(closeToolbarRequest()),
    dispatchExplorerSearchRequest: (parameters: ExplorerSearchParameters) =>
        dispatch(explorerSearchRequest(parameters)),
    dispatchBaseSearchRequest: (parameters: BaseSearchParameters) => dispatch(baseSearchRequest(parameters)),
    dispatchUpdateFilters: (parameters: DimensionFilter[]) => dispatch(updateFilters(parameters)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExplorerPage));

interface PropsFromState {
    dimensions: Dimension[];
    baseQueryId: string;
    rootQuery: string;
    currentLocation: Location;
    isToolbarOpen: boolean;
    rootSearchRequestInProgress: boolean;
    explorerResultViewData?: ExplorerResultViewData;
    explorerRequestInProgress: boolean;
    explorerSearchParameters?: ExplorerSearchParameters;
    baseSearchParameters?: BaseSearchParameters;
    baseSearchRequestInProgress: boolean;
    activeWorkspaceId?: string;
    queryInError?: boolean;
    totalResults: number;
    explorerRequestInError?: boolean;
}

interface PropsFromDispatch {
    dispatchRootSearchRequest: typeof rootSearchRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchCloseToolbar: typeof closeToolbarRequest;
    dispatchExplorerSearchRequest: typeof explorerSearchRequest;
    dispatchBaseSearchRequest: typeof baseSearchRequest;
    dispatchUpdateFilters: typeof updateFilters;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & OwnProps & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedFilters: DimensionFilter[];
    selectedRowDimensions: string[];
    selectedColumnDimensions: string[];
    selectedViewMode: ExplorerView;
    explorerSorting: ExplorerSorting;
    validationResult?: ValidationResult;
}

type AllState = OwnState;
