import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dataset } from '../../../../store/datasets';
import { getDatasetPipelineStatus, isDatasetReady } from '../../../../store/datasets/datasetUtils';
import { PipelinePipeline, SharedTaskStatusCode } from '../../../../api/generated';

interface PropsFromState {
    datasetPipeline?: PipelinePipeline;
    dataset?: Dataset;
}

type AllProps = PropsFromState & WithTranslation;

const ApprovalHint = withTranslation()(({ t, datasetName = '' }: WithTranslation & { datasetName?: string }) => {
    return (
        <span  title={t('Biostrand needs to approve your dataset, please click send email to speed up the process.\nThank you.')} >
            <a
                style={{ marginLeft: 16, marginRight: 8 }}
                href={`mailto:support@biostrand.be?subject=Approve%20dataset&body=Please approve dataset ${datasetName}`}>
                {t('Send email')}
            </a>
            <FontAwesomeIcon icon={faQuestionCircle} size='xs'/>
        </span>
    );
});

function DatasetPipelineStatus(props: AllProps): ReactElement | null {
    const { datasetPipeline, dataset, t } = props;
    if (!datasetPipeline) return null;

    if (isDatasetReady(dataset, datasetPipeline)) {
        return <div style={{marginTop: 4, fontSize: 12, color: '#999999' }}>{t('Ready')}</div>;
    }

    const status = getDatasetPipelineStatus(datasetPipeline);

    const isIndexed = status === SharedTaskStatusCode.SUCCEEDED;

    return (
        <div
            style={{
                marginTop: 4,
                fontSize: 12,
                color: status === SharedTaskStatusCode.FAILED ? '#990000' : '#999999',
            }}>
            {isIndexed ? (
                <>
                    {t('Waiting for approval')}
                    <ApprovalHint datasetName={dataset?.name} />{' '}
                </>
            ) : (
                t(status.toLowerCase())
            )}
        </div>
    );
}

export default withTranslation()(DatasetPipelineStatus);
